import { render, staticRenderFns } from "./MChannelSwipeItemDraggable.vue?vue&type=template&id=288f3099&scoped=true"
import script from "./MChannelSwipeItemDraggable.vue?vue&type=script&lang=ts"
export * from "./MChannelSwipeItemDraggable.vue?vue&type=script&lang=ts"
import style0 from "./MChannelSwipeItemDraggable.vue?vue&type=style&index=0&id=288f3099&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288f3099",
  null
  
)

export default component.exports