<template>
  <div class="MSearchBarWithTabs">
    <m-search-bar-tabs :selectedTab="selectedTab" :tabs="tabs" v-on:child-output="handleTabSwitch"/>
    <div class="search-bar">
      <a-search-input
          :selectedTab="selectedTab"
          :placeholder="$t(searchPlaceholder())"
          v-on:USER_ACTION="handleUserSearch"
          v-on:HASH_TAG_CLICK="handleHashTagClick"
      />
    </div>
    <div class="search-message" v-if="searchMessageShow">
      <span v-html="$t('tabs.tryExploreTab')" @click="loadExploreTab"/>
    </div>
  </div>
</template>

<style lang="scss">
.MSearchBarWithTabs {
  position: relative;
  z-index: 1;

  .search-bar {
    position: relative;
    max-width: 35vw;
    margin: 1rem auto 3.9rem;

    @media screen and (max-width: 1600px) {
      max-width: 45vw;
    }

    @media screen and (max-width: 1300px) {
      max-width: 55vw;
    }

    @media screen and (max-width: 1000px) {
      max-width: 80vw;
    }

    @media screen and (max-width: $tablet) {
      margin-bottom: 5rem;
    }
  }

  .collapse-content {
    margin-top: -1px;
    padding: 1.5rem;
    border: 1px solid rgba(#000, .17);
  }

  .settings-icon {
    position: absolute;
    top: 1rem;
    right: 3rem;
    opacity: .7;
    cursor: pointer;
    transition: opacity .2s;

    &.filter-active,
    &:hover {
      opacity: 1;
    }
  }

  .search-message {
    width: 100%;
    text-align: center;
    position: absolute;
    padding: 0 3rem;
    bottom: -2.5rem;

    @media screen and (max-width: $tablet) {
      margin-bottom: -1.25rem;
    }

    span b {
      cursor: pointer;
    }
  }
}

.main-container.full-width {
  @media screen and (min-width: 800px) {
    .MSearchBarWithTabs {
      margin-top: 6rem;
    }
  }
  @media screen and (max-width: 799px) {
    .MSearchBarWithTabs {
      padding-top: 2rem;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SettingsIcon } from 'vue-feather-icons';

import { CHILD_EMIT } from '@/constants/childEmitEventNames';
import MSearchBarTabs from '@/components/molecules/MSearchBarTabs.vue';
import MSearchInput, { MSearchInputOutput } from '@/components/molecules/MSearchInput.vue';
import { ASearchBarTabType } from '@/enums/SearchBarTabType';

export interface MSearchBarWithTabsIOutput {
  search: string;
  selectedTab: ASearchBarTabType;
  contentCategory?: string;
}

@Component({
  components: {
    MSearchBarTabs,
    ASearchInput: MSearchInput,
    SettingsIcon
  }
})
export default class MSearchBarWithTabs extends Vue {
  @Prop({
    default: () => {
      return [
        ASearchBarTabType.items,
        ASearchBarTabType.pinned,
        ASearchBarTabType.channels,
        ASearchBarTabType.people,
        ASearchBarTabType.explore,
      ];
    }
  })
  tabs!: string[];

  @Prop({ default: ASearchBarTabType.items })
  selectedTab!: ASearchBarTabType;

  output: MSearchBarWithTabsIOutput = {
    search: '',
    selectedTab: this.selectedTab,
    contentCategory: undefined
  };

  searchMessageShow = false;

  searchPlaceholder (): string {
    return `searchPlaceholder['${this.selectedTab}']`;
  }

  emitSearch () {
    this.searchMessageShow = this.tabs.includes(ASearchBarTabType.explore) && this.output.selectedTab === ASearchBarTabType.items && this.output.search.length > 0;
    // note - push route before emitting, as T components may also push into route which may need to overwrite this
    this.pushRoute().then(() => {
      // Emit the changes to the parent
      this.$emit(CHILD_EMIT, {
        search: this.output.search,
        selectedTab: this.output.selectedTab,
        contentCategory: this.output.contentCategory
      } as MSearchBarWithTabsIOutput);
    }).catch(console.error);
  }

  // keep the URl updated with the current search string and tab when switching between tabs
  async pushRoute () {
    // always add the tab into the query if a search is emitted
    const urlParams: any = { tab: this.output.selectedTab };
    if( this.output.search.length ){
      urlParams.text = this.output.search;
    }
    if (this.selectedTab === ASearchBarTabType.explore && this.output.contentCategory !== 'all') {
      urlParams.category = this.output.contentCategory;
    }
    const query: any = urlParams;
    // ensure if text is not present in new params, then it's deleted in the new query
    if(!urlParams.text || !urlParams.text.length){
      delete query.text;
    }
    await this.$router.push({ query });
  }

  handleUserSearch (input: MSearchInputOutput) {
    this.output.search = input.search;
    this.output.contentCategory = input.contentCategory;
    this.emitSearch();
  }

  handleHashTagClick (input: MSearchInputOutput) {
    this.output.search = input.search;
    this.output.selectedTab = ASearchBarTabType.explore;
    this.emitSearch();
  }

  handleTabSwitch (tab) {
    this.output.selectedTab = tab;
    this.emitSearch();
  }

  loadExploreTab () {
    this.handleTabSwitch(ASearchBarTabType.explore);
  }
}
</script>
