<template>
  <div class="card">
    <div class="user-image-wrapper">
      <a-router-link-profile :username="person.username">
        <figure class="image is-64x64 user-profile">
          <a-user-profile-pic :username="person.username"/>
        </figure>
      </a-router-link-profile>
    </div>
    <div>
      <a-router-link-profile :username="person.username">
        <p class="font-body-m">
          {{ `${person.firstName} ${person.lastName}` | ellipsisString(20) }}
        </p>
      </a-router-link-profile>
    </div>

    <div v-if="showConnectIcon" class="link-connection">
      <!-- Cancel request -->
      <a-connection-icon
          v-if="state === states.RequestSent"
          :connectionType="'cancelConnectionRequest'"
          :loading="cancelLoading"
          :size="iconSize"
          :tooltipText="cancelText"
          v-on:cancel-connection-request="removeRequest"
      />

      <!-- Reject request -->
      <a-connection-icon
          v-if="state === states.RequestReceived"
          :connectionType="'cancelConnectionRequest'"
          :loading="cancelLoading"
          :size="iconSize"
          :tooltipText="rejectText"
          v-on:cancel-connection-request="removeRequest"
      />

      <!-- Accept request -->
      <a-connection-icon
          v-if="state === states.RequestReceived"
          :connectionType="'acceptConnectionRequest'"
          :loading="acceptLoading"
          :size="iconSize"
          :tooltipText="acceptText"
          v-on:accept-connection-request="acceptRequest"
      />

      <!-- Disconnect existing connection -->
      <a-connection-icon
          v-if="[states.RequestReceivedAccepted, states.RequestSentAccepted].includes(state)"
          :connectionType="'removeConnection'"
          :loading="cancelLoading"
          :size="iconSize"
          :tooltipText="disconnectText"
          v-on:remove-connection-request="removeRequest"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  display: flex;
  gap: 1rem;
  align-items: center;
  overflow: visible;
  width: 100%;
  padding: .5rem;
}

figure.user-profile {
  display: flex;
  align-items: center;
}

.link-connection {
  margin-right: 10px;
  margin-left: auto;
  cursor: pointer;
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }
}

// safari fix
/* stylelint-disable */
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) {
    .user-image-wrapper {
      margin-right: 1rem;
    }
  }
}

/* stylelint-enable */
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import AConnectionIcon from '@/components/atoms/buttons/AConnectionIcon.vue';
import { Datum, State } from '@/api/ms-authentication/services/interfaces/Connections';
import { PeopleStore } from '@/store';
import ellipsisString from '@/utils/ellipsisString';

@Component({
  components: {
    AUserProfilePic,
    ARouterLinkProfile,
    AConnectionIcon,
  },
  filters: {
    ellipsisString
  }
})
export default class MPersonNotConnectedCard extends Vue {
  @Prop(Object)
  person!: Datum;
  @Prop({ default: true })
  showConnectIcon!: boolean;

  cancelText!: string;
  rejectText!: string;
  acceptText!: string;
  disconnectText!: string;
  reconnectText!: string;

  reconnectLoading: boolean = false;
  cancelLoading: boolean = false;
  acceptLoading: boolean = false;
  state!: State;
  states = State;

  iconSize = '1.6x';

  created () {
    this.cancelText = String(this.$t('dict.cancel'));
    this.rejectText = String(this.$t('dict.reject'));
    this.acceptText = String(this.$t('dict.accept'));
    this.disconnectText = String(this.$t('dict.disconnect'));
    this.reconnectText = String(this.$t('connectionState.reconnect'));
    this.state = this.person.state;
  }

  async removeRequest () {
    this.$buefy.dialog.confirm({
      title: this.$t('dict.areYouSure') as string,
      message: 'You will not be connected to this person after this action',
      confirmText: 'Yes',
      onConfirm: async () => {
        this.cancelLoading = true;
        const { state } = await PeopleStore.connectionRequestRemove(this.person.username);
        this.state = state;
        this.cancelLoading = false;
      }
    });
  }

  async acceptRequest () {
    this.$buefy.dialog.confirm({
      title: this.$t('dict.areYouSure') as string,
      message: 'You will be connected to this person after this action',
      confirmText: 'Yes',
      onConfirm: async () => {
        this.acceptLoading = true;
        const { state } = await PeopleStore.connectionRequestUpdate(this.person.username);
        this.state = state;
        this.acceptLoading = false;
      }
    });
  }
}
</script>
