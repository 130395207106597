<template>
  <div class="MBookDemo">
    <b-button type="is-primary" @click="openForm">
      {{ $t('forBusiness.getInTouch.learnMore') }}
    </b-button>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';
import OPricingContactForm from '@/components/organisms/forms/OPricingContactForm.vue';
import urlHashParamGet from '@/utils/urlHashParamGet';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component({
  components: { OPricingContactForm, OModalWrapper }
})
export default class MBookDemo extends Vue {
  created () {
    if (urlHashParamGet('getInTouch', { removeAndReplaceUrl: true })) {
      this.openForm();
    }
  }

  openForm () {
    EventBus.$emit(EventBusEvents.BOOK_A_DEMO);
  }
}
</script>
