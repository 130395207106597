<template>
  <b-button
      :class="{'is-outlined': isOutlined}"
      class="is-primary btn-add-item"
      @click="showAddItemModal()"
  >
    <plus-icon size="1.5x"/>
    {{ $t('dict.addAnItem') }}
  </b-button>
</template>

<style lang="scss" scoped>
::v-deep span {
  display: flex;
  align-items: center;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import EventBus, { EventBusEvents } from '@/EventBus';
import { PlusIcon } from 'vue-feather-icons';

@Component({
  components: {
    PlusIcon
  },
})
export default class AAddNewItem extends Vue {
  @Prop()
  isOutlined?: boolean;

  showAddItemModal () {
    EventBus.$emit(EventBusEvents.ITEM_ADD);
  }
}
</script>
