<template>
  <router-link :to="{ name }" class="menu-profile-item">
    <users-icon size="1x" class="header-menuitem-icon" v-if="hasIcon"></users-icon>
    {{ $t('dict.editFriends') }}
  </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { UsersIcon } from 'vue-feather-icons';

@Component({
  components: {
    UsersIcon
  }
})
export default class ARouterLinkConnections extends Vue {
  @Prop(Boolean)
  hasIcon!: boolean;

  name = RouteNames.ROUTE_PEOPLE_MANAGEMENT;
}
</script>
