<template>
  <div class="OPeopleManagement">

    <div v-if="notConnectedPeople.in.length || notConnectedPeople.out.length">

      <div class="mb-4">
        <div class="people-grid">
          <m-person-not-connected-card
              v-for="(person) in notConnectedPeople.out"
              :key="person.username"
              :person="person"
              :show-connect-icon="true"
              :show-disconnect-icon="true"
          />
          <m-person-not-connected-card
              v-for="(person) in notConnectedPeople.in"
              :key="person.username"
              :person="person"
              :show-connect-icon="true"
              :show-disconnect-icon="true"
          />
        </div>
      </div>
    </div>

    <div v-else-if="loadingConnections" class="people-grid">
      <m-person-card-skeleton v-for="index in 3" :key="index"/>
    </div>

    <div v-else-if="!loadingConnections" class="not-found-wrapper has-text-centered">
      <p class="font-body-m is-flex is-justify-content-center is-align-items-center">
        <mail-icon size="2x"/>
        <span class="ml-2">{{ $t('dict.notFoundMessagePeoplePendingInvites') }}</span>
      </p>
      <b-button
          class="is-primary is-outlined mt-5"
          @click="$emit('load-invitation-form')"
      >
        {{ $t('page.invitations.sendAnInvite') }}
      </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { PeopleStore } from '@/store';
import MPersonNotConnectedCard from '@/components/molecules/MPersonNotConnectedCard.vue';
import { Datum as ConnectionsDatum, State } from '@/api/ms-authentication/services/interfaces/Connections';
import MPersonCardSkeleton from '@/components/molecules/skeletons/MPersonCardSkeleton.vue';
import { MailIcon } from 'vue-feather-icons';

interface NotConnectedPeople {
  in: ConnectionsDatum[],
  out: ConnectionsDatum[]
}

@Component({
  components: {
    MPersonCardSkeleton,
    MPersonNotConnectedCard,
    MailIcon
  }
})
export default class OPeopleManagement extends Vue {

  loadingConnections: boolean = false;
  loadingMore: boolean = false;

  get notConnectedPeople (): NotConnectedPeople {
    const resp: NotConnectedPeople = {
      in: [],
      out: [],
    };
    PeopleStore.getConnectionRequests.forEach((p: ConnectionsDatum) => {
      if (p.state === State.RequestSent) {
        resp.out.push(p);
      } else {
        resp.in.push(p);
      }
    });
    return resp;
  }

  async created () {
    await this.fetchNotConnectedPeople();
  }

  async fetchNotConnectedPeople (): Promise<void> {
    this.loadingConnections = true;
    await PeopleStore.fetchNotConnectedPeople();
    this.loadingConnections = false;
  }
}
</script>
