<template>
  <div class="MLoginButtonTrigger">
    <b-button
        class='is-primary'
        :class="classes"
        v-if="!authenticated"
        @click="loginButtonClick">
      {{ $t('dict.loginOrSignup') }}
    </b-button>
  </div>
</template>

<style scoped lang="scss">
.MLoginButtonTrigger {
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component
export default class MLoginButtonTrigger extends Vue {
  @Prop({ default: '' })
  classes!: string;

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  loginButtonClick () {
    EventBus.$emit(EventBusEvents.AUTH_LOGIN_CLICKED);
  }
}
</script>
