<template>
  <div :class="classImage" class="MUploadImagePreview">
    <figure v-for="(item, index) in base64Strings" :key="index">
      <span :style="maxHeight ? { maxHeight: maxHeight + 'px' } : {}">
        <b-tooltip v-if="tooltip"
                   position="is-bottom"
        >
          {{ item.userFriendlyFilename }}
          <template v-slot:content>
            <img :class="{ 'no-margin': !showRemove}" :src="item.preview" alt=""/>
          </template>
        </b-tooltip>
        <img v-else :class="{ 'no-margin': !showRemove}" :src="item.preview" alt=""/>
      </span>
      <x-icon
          v-if="showRemove"
          class="close-icon"
          :class="{ 'tooltip': tooltip, 'borderless': borderlessCloseIcon }"
          :size="tooltip ? '14' : '16'"
          :title="$t('dict.remove')"
          @click="handleUploadRemove(index)"
      />
    </figure>
  </div>
</template>

<style lang="scss" scoped>
.MUploadImagePreview {
  display: flex;
  align-items: center;

  figure {
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 100%;

    span {
      min-height: 100%;
      min-width: 100%;

      img {
        object-fit: cover;
        width: 100%;
      }
    }

    .close-icon {
      position: absolute;
      cursor: pointer;
      top: 5px;
      right: 5px;
      border: var(--black-color) 1px solid;
      background-color: var(--white-color);
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;

      &.tooltip {
        top: 0;
        right: -20px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
      }
      &.borderless {
        border: none;
        background-color: transparent;
      }
    }
  }
}

img {
  max-width: 240px;
  max-height: 280px;
}

.tag {
  height: 100%;

  img {
    margin-left: 1rem;
  }
}

img.no-margin {
  margin-left: 0;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { XIcon } from 'vue-feather-icons';
import { ImageUpload } from '@/components/organisms/forms/OItemEntryForm.vue';
import { Provider } from '@/api/ms-channel/services/interfaces/ChannelGenerateImage';

export interface MUploadImagePreviewChildOutput {
  imageIndexRemoved: number;
}

export interface MUploadImagePreviewImageItem {
  /**
   * Base64 encoded string of the image
   */
  preview: string,
  /**
   * Friendly filename to display on screen if hiding image in tooltip
   */
  userFriendlyFilename?: string,
  /**
   * If image is generated include the original url and credits of the photographer
   */
  generationUrl?: string,
  credits?: string,
  /**
   * If the image was not uploaded by the user, i.e. generated, then record who provided the
   * image
   */
  provider?: Provider;
}

@Component({
  components: {
    XIcon
  }
})
export default class MUploadImagePreview extends Vue {
  @Prop({ default: () => [] })
  base64Strings!: ImageUpload[];
  @Prop({ default: true })
  showRemove!: boolean;
  @Prop()
  classImage!: string;
  @Prop()
  classDeleteButton!: string;
  @Prop({ default: false })
  tooltip!: boolean;
  @Prop({ required: false })
  maxHeight!: number;
  @Prop({required: false, default: false})
  borderlessCloseIcon!: boolean;

  handleUploadRemove (index) {
    this.$emit('child-output', { imageIndexRemoved: index } as MUploadImagePreviewChildOutput);
  }
}
</script>
