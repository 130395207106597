<template>
  <b-field expanded
           class="additional-data-column"
  >
    <template slot="label">
      {{ $t('channel.customFields.customSelect') }} <a class="as-link font-bold colour-primary"
                                                       @click="showHelp">(?)</a>
    </template>
    <b-input
        v-model="value"
        type="textarea"
    />
    <template slot="message">
      <a class="as-link font-bold is-pulled-right" @click="sort">
        {{ $t('dict.sort') }}
      </a>
    </template>
  </b-field>
</template>

<style scoped lang="scss">
::v-deep textarea {
  font-size: smaller !important;
  padding-top: 0.25rem !important;
}

.MChannelCustomFieldCustomSelect {
}
</style>

<script lang="ts">
import { Component, Model, Vue, Watch } from 'vue-property-decorator';

@Component
export default class MChannelCustomFieldCustomSelect extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: string[];

  value: string = '';

  created () {
    this.value = this.arrayToString(
      this.activeValue
    );
  }

  arrayToString (input: string[]): string {
    if (input && input.length) {
      return input.join('\n');
    } else {
      return '';
    }
  }

  stringToArray (input: string): string[] {
    return input.split('\n');
  }

  sort () {
    if (this.value && this.value.length) {
      this.value = this.arrayToString(
        this
          .stringToArray(this.value)
          .sort()
      );
    }
  }

  showHelp () {
    this.$buefy.dialog.alert({
      title: this.$t('channel.customFields.customSelect') as string,
      message: this.$t('channel.customFields.customSelectHelp') as string
    });
  }

  @Watch('value', { deep: true })
  updateValueL () {
    this.$emit(
      'updateModelValue',
      this.stringToArray(this.value)
    );
  }
}
</script>
