<template>
  <div class="AFormServerError">
    <b-message type="is-danger" v-if="errors">
      <button
        class="btn-close"
        type="button"
        @click="closeMessageEvent"
      >
        <x-icon size="2x"></x-icon>
      </button>
      <p v-for="translationKey in errors" v-bind:key="translationKey" class="content">
        {{ $t(translationKey) }}
      </p>
    </b-message>
  </div>
</template>

<style scoped lang="scss">
  .AFormServerError {
    position: relative;
  }

  .content {
    max-width: 95%;
  }

  .btn-close {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
</style>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { XIcon } from 'vue-feather-icons';

  @Component({
    components: {
      XIcon
    }
  })
  export default class AFormServerError extends Vue {
    @Prop({ required: true })
    errors!: string[];

    closeMessageEvent () {
      this.$emit('child-output', {});
    }
  }
</script>
