<template>
  <span class="navbar-header-item-icon header-add-item-to-liffery">

  <svg
      :class="classObj"
      fill="none"
      viewBox="0 0 727 727"
      xmlns="http://www.w3.org/2000/svg"
      @click="click"
  >
    <path
        d="M363.5 621.833C229.914 621.833 105.167 497.086 105.167 363.5C105.167 229.914 229.914 105.167 363.5 105.167C497.086 105.167 621.833 229.914 621.833 363.5C621.833 497.086 497.086 621.833 363.5 621.833ZM363.5 60.5835C323.72 60.5835 284.33 68.4187 247.579 83.6417C210.827 98.8646 177.434 121.177 149.306 149.306C92.4977 206.114 60.5833 283.162 60.5833 363.5C60.5833 443.839 92.4977 520.887 149.306 577.695C177.434 605.823 210.827 628.136 247.579 643.359C284.33 658.582 323.72 666.417 363.5 666.417C443.839 666.417 520.887 634.503 577.694 577.695C634.502 520.887 666.417 443.839 666.417 363.5C666.417 323.721 658.582 284.331 643.359 247.579C628.136 210.827 605.823 177.434 577.694 149.306C549.566 121.177 516.173 98.8646 479.421 83.6417C442.67 68.4187 403.28 60.5835 363.5 60.5835ZM393.792 212.042H333.208V333.208H212.042V393.792H333.208V514.959H393.792V393.792H514.958V333.208H393.792V212.042Z"
        fill="currentColor"/>
  </svg>

  </span>
</template>

<style lang="scss" scoped>
.navbar-header-item-icon {
  svg {
    width: 40px;
    height: 40px;
    margin-top: -.5rem;
    transition: all .2s;

    @media screen and (max-width: $desktop) {
      margin-top: -.8rem;
    }

    @media screen and (max-width: 450px) {
      width: 30px;
      height: 30px;
      margin-top: 0;
    }

    path {
      transition: all .2s;
    }

    &:hover,
    &.grow {
      transform: scale(1.2);

      path {
        fill: var(--primary-color);
      }
    }
  }
}

.button-icon {
  color: #fff; // todo, alter how the svg is imported so the colour can be changed
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import EventBus, { EventBusEvents } from '@/EventBus';
import { pause } from 'common-utils/time';

@Component
export default class AAddItemHeaderIcon extends Vue {
  @Prop({ default: 'navbar' })
  locationType!: string;

  grow: boolean = false;
  pulse: boolean = false;

  classObj = {
    'navbar-header-item-icon': this.locationType === 'navbar',
    'button-icon': this.locationType === 'button'
  };

  created () {
    EventBus.$on(EventBusEvents.ITEM_ADD_PULSE, 'AAddItemHeaderIcon.vue', () => {
      this.pulse = true;
      this.triggerPulse();
    });
  }

  beforeDestroy () {
    EventBus.$remove(EventBusEvents.ITEM_ADD_PULSE, 'AAddItemHeaderIcon.vue');
  }

  click () {
    this.$emit('click');
    this.pulse = false;
  }

  async triggerPulse () {
    this.grow = true;
    await pause(300);
    this.grow = false;
    await pause(2000);
    if (this.pulse) {
      await this.triggerPulse();
    }
  }
}
</script>
