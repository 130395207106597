<template>
  <div>
    <b-loading :active="showLoading"/>
    <a-add-item-header-icon @click="openEntryFormClick"/>
    <b-modal
        :active.sync="visible"
        :can-cancel="false"
        :full-screen="authenticated && (windowWidth <= modalWidthBreakpoint || windowHeight <= modalHeightBreakpoint)"
        aria-role="dialog"
        has-modal-card
    >
      <div :class="{'modal-entry-item': authenticated}" class="modal-card">
        <o-item-entry-form
            v-if="authenticated"
            :content-to-preload="preloadingContent"
            :is-mobile-view="windowWidth <= modalWidthBreakpoint"
            :is-editing-item="isEditingItem"
            :is-moving-item="isMovingItem"
            :is-item-recommendation="isItemRecommendation"
            v-on:child-output="handleItemEntryOutput"
            v-on:close-modal="closeItemEntryForm"
        />
        <template v-if="!authenticated">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ dialogTitle }}</p>
            <a-close-modal-button icon-size="24" v-on:child-output="closeItemEntryForm"/>
          </header>
          <m-authentication-tabs></m-authentication-tabs>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.animation-content {
  max-height: 950px;
}

.modal-entry-item {
  width: fit-content;

  .modal-card-body {
    padding: 0;
  }
}

.navbar-icon {
  margin-top: 8px;
}

@media screen and (max-height: 750px) {
  ::v-deep .modal.is-full-screen .animation-content {
    overflow-y: scroll;
  }
}
</style>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { PlusSquareIcon } from 'vue-feather-icons';

import EventBus, { EventBusEvents } from '@/EventBus';
import { AuthenticationStore, ItemDetailStore, ItemsStore } from '@/store';

import { TAB_LOGIN } from '@/constants/tabs';
import MAuthenticationTabs from '@/components/molecules/MAuthenticationTabs.vue';
import OItemEntryForm from '@/components/organisms/forms/OItemEntryForm.vue';
import AAddItemHeaderIcon from '@/components/atoms/icon/AAddItemHeaderIcon.vue';

import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';

import { Editable } from '@/api/ms-item/services/interfaces/ItemPost';
import { Item, Recommendation } from '@/api/ms-item/services/interfaces';
import AAddItemButton from '@/components/atoms/buttons/AAddItemButton.vue';
import { RouteNames } from '@/router/RouteNames';
import ItemService from '@/api/ms-item/services/ItemService';

export interface OItemEntryFormOutput {
  closeModal: boolean;
  newItem: Item,
  editingItem: boolean
}

export interface ItemAndImage extends Item {
  base64image?: string,
  imageGeo?: {
    lat: number,
    lng: number
  }
}

export interface MItemEntryTriggerItemEditClonePayload {
  editable: Editable,
  uniqueItemName?: string,
}

export interface MItemEntryTriggerItemContentPreload {
  content?: MItemEntryTriggerItemEditClonePayload;
}

enum Trigger {
  Add = 'add',
  Edit = 'edit',
  Move = 'move',
  Copy = 'copy'
}

@Component({
  components: {
    AAddItemButton,
    ACloseModalButton,
    AAddItemHeaderIcon,
    MAuthenticationTabs,
    OItemEntryForm,
    PlusSquareIcon,
  },
})
export default class MItemEntryTrigger extends Vue {
  showLoading = false;
  visible: boolean = false;
  dialogTitle: string = '';
  preloadingContent: ItemAndImage | null = null;
  isEditingItem: boolean = false;
  isMovingItem: boolean = false;
  modalWidthBreakpoint: number = 950;
  modalHeightBreakpoint: number = 700;
  windowWidth: number = 0;
  windowHeight: number = 0;
  lastTrigger: Trigger = Trigger.Add;
  isItemRecommendation = false;

  get activeTab () {
    return AuthenticationStore.prompt.loginActiveTab;
  }

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  created () {
    this.eventsBind();
    this.windowResizeFunction();
  }

  mounted () {
    if (!this.authenticated) {
      this.getAuthTitle();
    }
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  windowResizeFunction () {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  eventsUnbind () {
    const callerId = 'MItemEntryTrigger';
    EventBus.$remove(EventBusEvents.ITEM_COPY, callerId);
    EventBus.$remove(EventBusEvents.ITEM_EDIT, callerId);
    EventBus.$remove(EventBusEvents.ITEM_ADD, callerId);
    EventBus.$remove(EventBusEvents.ITEM_RECOMMENDATION_ADD, callerId);
    EventBus.$remove(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_FORM, callerId);
    window.removeEventListener('resize', this.windowResizeFunction);
  }

  // eslint-disable-next-line max-lines-per-function
  eventsBind (): void {
    const callerId = 'MItemEntryTrigger';
    EventBus.$on(EventBusEvents.ITEM_COPY, callerId, (payload: { uniqueItemName: string }) => {
      this.showLoading = true;
      ItemService.itemNameUniqueItemNameGet(payload, {}).then((item) => {
        this.showLoading = false;
        this.clearForm();
        this.dialogTitle = this.$i18n.tc('dict.newItemEntryTitle');
        this.preloadingContent = item;
        this.openItemEntryForm(Trigger.Copy);
      }).catch(() => {
        this.showLoading = false;
      });
    });

    EventBus.$on(EventBusEvents.ITEM_EDIT, callerId, (payload: { uniqueItemName: string, isMovingItem?: boolean }) => {
      this.showLoading = true;
      ItemService.itemNameUniqueItemNameGet(payload, {}).then((item) => {
        this.showLoading = false;
        this.clearForm();
        this.isEditingItem = true;
        this.isMovingItem = typeof payload.isMovingItem !== 'undefined' && payload.isMovingItem;
        this.dialogTitle = this.$i18n.tc('dict.editItemEntryTitle');
        this.preloadingContent = item;
        this.openItemEntryForm(this.isMovingItem ? Trigger.Move : Trigger.Edit);
      }).catch(() => {
        this.showLoading = false;
      });
    });

    EventBus.$on(EventBusEvents.ITEM_ADD, callerId, (payload?: ItemAndImage) => {
      this.clearForm();
      if (payload) {
        this.preloadingContent = payload;
      }
      this.openItemEntryForm(Trigger.Add);
      this.dialogTitle = this.$i18n.tc('dict.newItemEntryTitle');
    });

    EventBus.$on(EventBusEvents.ITEM_RECOMMENDATION_ADD, callerId, (payload: Recommendation) => {
      this.clearForm();
      this.preloadingContent = payload;
      this.isItemRecommendation = true;
      this.openItemEntryForm(Trigger.Add);
      this.dialogTitle = this.$i18n.tc('dict.newItemEntryTitle');
    });

    EventBus.$on(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_FORM, callerId, () => {
      this.closeItemEntryForm();
    });

    window.addEventListener('resize', this.windowResizeFunction);
  }

  /**
   * Reset the states in which the form can be triggered. Call this before any trigger, e.g. add or edit, then
   * set the correct state accordingly.
   */
  clearForm () {
    this.preloadingContent = null;
    this.isEditingItem = false;
    this.isMovingItem = false;
    this.isItemRecommendation = false;
  }

  eventsRebind () {
    this.eventsUnbind();
    this.eventsBind();
  }

  openEntryFormClick () {
    this.clearForm();
    this.openItemEntryForm(Trigger.Add);
  }

  openItemEntryForm (trigger: Trigger) {
    this.lastTrigger = trigger;
    this.visible = true;
  }

  closeItemEntryForm () {
    this.visible = false;
  }

  handleItemEntryOutput (output: OItemEntryFormOutput) {
    const { newItem, editingItem, closeModal } = output;

    this.visible = !closeModal;

    // Set the item in the detail store, this updates a detail page if that is where we are
    ItemDetailStore.ITEM_SET(newItem);

    if (this.updateCurrentView(newItem, editingItem)) {
      // Update item in store, or add item to store
      editingItem ?
        ItemsStore.spliceEditedItem({ editedItem: newItem, route: this.$route }) :
        ItemsStore.addNewItem(newItem);
    }
  }

  /**
   * If adding or editing an item we should only place it in the view if it's meant to be there.
   * Dashboard - Always
   * Profile page - If the profile page is the current user's profile page
   * Channel - If the item that was added is meant for this channel
   */
  updateCurrentView (item: Item, editing: boolean): boolean {
    const routesToAddStraightTo = [
      RouteNames.ROUTE_DASHBOARD,
      RouteNames.ROUTE_YOUR_PROFILE,
      RouteNames.ROUTE_PROFILE,
      RouteNames.ROUTE_CHANNEL_VIEW
    ];
    const currentView = this.$route.name as RouteNames;
    // if currentView is null or undefined then the route is the dashboard
    if (currentView === null || typeof currentView === 'undefined' || routesToAddStraightTo.includes(currentView)) {
      switch (currentView) {
        // Your profile always true
        case RouteNames.ROUTE_YOUR_PROFILE:
          return true;

        // A profile - if it's the current user's profile then true
        case RouteNames.ROUTE_PROFILE: {
          const profileOf = this.$route.params.username;
          return profileOf === this.currentUser.username;
        }

        // Channel view - if editing an item then always true, if adding item then true only if item is added to current channel
        case RouteNames.ROUTE_CHANNEL_VIEW: {
          if (editing) {
            return true;
          }
          const channelSlug = this.$route.params.channelSlug0 + '@' + this.$route.params.channelSlug1;
          return channelSlug === item.editable.channel?.slug;
        }

        // Dashboard - always true
        default:
          return true;
      }
    } else {
      // Route indicates we're not viewing items, return false
      return false;
    }
  }

  getAuthTitle () {
    this.dialogTitle = this.activeTab === TAB_LOGIN ? this.$i18n.tc('dict.welcomeBack') : this.$i18n.tc('dict.welcome');
  }

  @Watch('activeTab')
  activeTabChange () {
    this.getAuthTitle();
  }

  @Watch('visible')
  onVisibleChange () {
    if (!this.visible) {
      this.isEditingItem = false;
    }
  }

  // TODO: hacky solution, find out why this works
  @Watch('$route', { immediate: true, deep: true })
  onRouteChange () {
    this.eventsRebind();
  }
}
</script>
