import { render, staticRenderFns } from "./ADotDivide.vue?vue&type=template&id=11ae4f37&scoped=true"
import script from "./ADotDivide.vue?vue&type=script&lang=ts"
export * from "./ADotDivide.vue?vue&type=script&lang=ts"
import style0 from "./ADotDivide.vue?vue&type=style&index=0&id=11ae4f37&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ae4f37",
  null
  
)

export default component.exports