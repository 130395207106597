<template>
  <b-message :type="type" class="AMessage">
    <button
      class="btn-close"
      type="button"
      @click="closeMessageEvent"
      v-if="canClose"
    >
      <x-icon size="2x"></x-icon>
    </button>
    <p class="content">
      <slot></slot>
    </p>
  </b-message>
</template>

<style lang="scss" scoped>
  .AMessage {
    position: relative;
    margin-top: .8rem;
    margin-bottom: .8rem;
  }

  .content {
    max-width: 95%;
  }

  .btn-close {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
</style>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { XIcon } from 'vue-feather-icons';

  @Component({
    components: {
      XIcon
    }
  })
  export default class AMessage extends Vue {
    @Prop({ required: true })
    type!: string;
    @Prop({ default: true })
    canClose!: boolean;

    closeMessageEvent () {
      this.$emit('child-output', {});
    }
  }
</script>
