<template>
  <ValidationProvider
      v-slot="{ errors, pristine, validated, valid }"
      :name="nameAlt || name"
      :rules="rules"
      :vid="vid"
      :class="validationWrapperClass"
  >
    <b-field
        ref="bfield"
        :horizontal="horizontal"
        :class="groupFieldClass"
        :message="((validated && !valid) || !pristine) && errors.length ? errors : subMessage"
        :prop="name"
        :type="{ 'is-danger': errors[0] && ((validated && !valid) || !pristine)}"
        v-bind="$attrs"
    >
      <template v-if="labelTooltip">
        <span slot="label">
          <a-tooltip
              :tip="labelTooltip">
            <slot v-if="hasLabelSlot" name="label"></slot>
            <span v-else-if="label" v-html="label"/>
          </a-tooltip>
        </span>
      </template>
      <template v-else slot="label">
        <slot v-if="hasLabelSlot" name="label"></slot>
        <span
            v-else-if="label"
            slot="label"
            v-html="label"/>
      </template>

      <a-tribute-js v-if="tributeOptions.length" :options="tributeOptions" element-type="textarea">
        <a-resizable-textarea-wrapper v-if="isResizable" ref="resize" :reset-height="resetTextareaHeight">
          <textarea
              ref="textarea"
              v-model="innerValue"
              :autocomplete="autoComplete"
              :disabled="disabled"
              :name="name"
              :placeholder="placeholder"
              :readonly="readonly"
              :rows="textareaRows"
              :type="type"
              class="textarea"
              novalidate
              v-on:keydown="preventKey"
              v-on:keyup.enter="handleEnterPressed"
          />
        </a-resizable-textarea-wrapper>
        <textarea
            v-else
            ref="textarea"
            v-model="innerValue"
            :autocomplete="autoComplete"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :readonly="readonly"
            :rows="textareaRows"
            :type="type"
            class="textarea"
            novalidate
            v-on:keydown="preventKey"
            v-on:keyup.enter="handleKeyup"
        />
      </a-tribute-js>

      <a-resizable-textarea-wrapper v-else-if="isResizable" ref="resize" :reset-height="resetTextareaHeight">
        <textarea
            ref="textarea"
            v-model="innerValue"
            :autocomplete="autoComplete"
            :disabled="disabled"
            :name="name"
            :placeholder="placeholder"
            :readonly="readonly"
            :rows="textareaRows"
            :type="type"
            class="textarea"
            novalidate
            v-on:keydown="preventKey"
            v-on:keyup.enter="handleEnterPressed"
        />
      </a-resizable-textarea-wrapper>

      <b-input
          v-else
          ref="input"
          v-model="innerValue"
          :aria-readonly="readonly"
          :autocomplete="autoComplete"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder"
          :rows="textareaRows"
          :type="type"
          novalidate
          :step="isDecimalNumber ? 'any' : '1'"
          v-bind="$attrs"
          v-on:keydown="preventKey"
          @keyup.native="handleKeyup"
      />

      <smile-icon v-if="allowEmoji"
                  ref="emojiButton"
                  class="emoji-button"
                  size="1.5x"
                  @click="showEmojiPicker"/>

      <VEmojiPicker v-if="showEmoji"
                    emojisByRow="6"
                    :continuous-list="true"
                    @select="selectEmoji"/>
    </b-field>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
::v-deep .field {
  position: relative;
}

::v-deep .control {
  width: 100%;
}

::v-deep .control.has-icons-right .icon {
  top: 5px !important;
  right: 5px !important;
  width: .875rem;
  height: .875rem;
}

.emoji-button {
  position: absolute;
  right: 5px;
  bottom: 5px;
  cursor: pointer;
}

.emoji-picker {
  position: fixed;
  z-index: 100;
}

::v-deep .container-emoji {
  max-height: 275px;
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { VEmojiPicker } from 'v-emoji-picker';
import { TributeOptions } from 'tributejs';
import { Emoji } from 'v-emoji-picker/lib/models/Emoji';
import { SmileIcon } from 'vue-feather-icons';
import AResizableTextareaWrapper from '../atoms/AResizableTextareaWrapper.vue';
import ATributeJs from '../atoms/ATributeJs.vue';
import ATooltip from '../atoms/ATooltip.vue';

@Component({
  components: {
    ATributeJs,
    ATooltip,
    AResizableTextareaWrapper,
    ValidationObserver,
    ValidationProvider,
    VEmojiPicker,
    SmileIcon
  },
})
export default class MInputWithValidation extends Vue {
  @Prop({ required: true })
  value!: any;

  @Prop()
  label?: string;

  @Prop()
  labelTooltip?: string;

  @Prop({ default: '' })
  subMessage!: string;

  @Prop({ required: true })
  name!: string;

  @Prop({ default: 'off' })
  autoComplete!: string;

  @Prop({ default: false })
  readonly!: boolean;

  @Prop()
  autoFocus?: boolean;

  @Prop({ default: 'text' })
  type!: string;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop()
  nameAlt?: string;

  @Prop()
  placeholder?: string;

  @Prop()
  vid?: string;

  @Prop()
  rules?: string;

  @Prop({ default: false })
  horizontal!: boolean;

  @Prop()
  isResizable?: boolean;

  @Prop()
  isDecimalNumber?: boolean;

  @Prop()
  resetTextareaHeight?: boolean;

  @Prop({ default: 1 })
  textareaRows?: number;

  @Prop()
  groupFieldClass?: string;

  @Prop()
  allowEmoji?: boolean;

  @Prop()
  hashtagFetch?: (text: string, cb: (tags) => void) => void;

  @Prop()
  atMentionFetch?: (text: string, cb: (users) => void) => void;

  @Prop()
  atMentionNothingFound?: string;

  @Prop({default: 'bottom' })
  emojiPickerPosition?: 'top' | 'bottom';

  @Prop({ required: false })
  validationWrapperClass?: string;

  // array of keycodes to prevent, e.g. 13 = enter, prevent when enter should be form submission instead
  @Prop({required: false})
  preventKeypress?: number[];

  innerValue = '';
  showEmoji: boolean = false;
  body: any = null;
  $refs!: {
    bfield: any,
    input: HTMLElement,
    textarea: HTMLElement,
    emojiButton: HTMLElement
  };

  get hasLabelSlot () {
    return this.$slots.label !== undefined;
  }

  get tributeOptions (): TributeOptions<any>[] {
    const tributeOptions: TributeOptions<any>[] = [];

    if (this.atMentionFetch) {
      tributeOptions.push({
        trigger: '@',
        selectClass: 'highlight-item',
        values: (text: string, cb): void => {
          if (text.length > 0) {
            setTimeout(() => {
              // @ts-ignore
              this.atMentionFetch(text, users => cb(users));
            }, 200);
          }
        },
        noMatchTemplate: (): string => `<span class="mentioned-not-found">${this.atMentionNothingFound}</span>`
      });
    }
    if (this.hashtagFetch) {
      tributeOptions.push({
        trigger: '#',
        selectClass: 'highlight-item',
        values: (text: string, cb): void => {
          if (text.length > 0) {
            setTimeout(() => {
              // @ts-ignore
              this.hashtagFetch(text, tags => cb(tags));
            }, 200);
          }
        },
        noMatchTemplate: (): string => '<span class="mentioned-not-found hashtag"/>'
      });
    }
    return tributeOptions;
  }

  created () {
    if (typeof this.value !== 'undefined') {
      this.innerValue = this.value;
    }
  }

  mounted () {
    if (this.$refs.input && this.autoFocus) {
      this.$refs.input.focus();
    }

    if (this.$refs.textarea && this.autoFocus) {
      this.$refs.textarea.focus();
    }
  }

  beforeDestroy () {
    if (this.body) {
      this.body.removeEventListener('click', this.clickOutside);
      window.removeEventListener('scroll', this.getButtonPosition);
      window.removeEventListener('resize', this.getButtonPosition);
    }
  }

  focusOnInput () {
    const input = this.$refs.bfield.$el.querySelector('input, textarea');
    input && input.focus();
  }

  handleEnterPressed (e): void {
    if (!e.shiftKey) {
      this.$emit('enter');
    }
  }

  preventKey (e): void {
    if( !this.preventKeypress ){
      return;
    }
    else if( this.preventKeypress.includes(e.keyCode)){
      e.preventDefault();
    }
  }

  handleKeyup (e): void {
    this.$emit('keyup', e);
  }

  selectEmoji (emoji: Emoji): void {
    this.innerValue += emoji.data;
    setTimeout(() => {
      this.getButtonPosition();
    }, 100);
    this.focusOnInput();
    this.showEmoji = false;
  }

  showEmojiPicker (e): void {
    e.stopPropagation();

    // emoji picker will follow button position
    window.addEventListener('scroll', this.getButtonPosition);
    window.addEventListener('resize', this.getButtonPosition);

    // it will close emoji picker if clicked outside
    this.body = document.querySelector('body');
    this.body.addEventListener('click', this.clickOutside);

    // toggle visibility
    this.showEmoji = !this.showEmoji;

    // set emoji picker position
    this.$nextTick(() => {
      this.getButtonPosition();
    });
  }

  getButtonPosition (): void {
    const emojiPicker: any = document.querySelector('#EmojiPicker');

    if (emojiPicker) {
      const emojiRect = this.$refs.emojiButton.getBoundingClientRect();

      // sets picker position based on prop and button position
      emojiPicker.style.top = this.emojiPickerPosition === 'top' ?
        emojiRect.top - (emojiPicker.clientHeight + 20) + 'px' :
        emojiRect.top + 30 + 'px';
      emojiPicker.style.left = emojiRect.left - emojiPicker.clientWidth / 2 + 'px';
    }
  }

  clickOutside (e): void {
    if (this.showEmoji) {
      const emojiPicker: any = document.querySelector('#EmojiPicker');

      if (!(emojiPicker == e.target || emojiPicker.contains(e.target))) {
        this.showEmoji = false;
      }
    }
  }

  @Watch('innerValue')
  innerValueHandle (newVal) {
    this.$emit('child-output', newVal);
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueValueHandle (newVal) {
    this.innerValue = newVal;
  }
}
</script>
