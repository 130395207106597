import { RouteNames } from '@/router/RouteNames';

export default [
  {
    name: RouteNames.ROUTE_YOUR_PROFILE,
    path: '',
    component: () => import(/* webpackChunkName: "TProfile" */ '@/components/templates/TProfile.vue'),
  },
  {
    name: RouteNames.ROUTE_PEOPLE_MANAGEMENT,
    path: 'people-management',
    meta: { ownOnly: true },
    component: () => import(/* webpackChunkName: "TPeopleManagement" */ '@/components/templates/TPeopleManagement.vue')
  },
  {
    name: RouteNames.ROUTE_CHANNEL_MANAGEMENT,
    path: 'channel-management',
    meta: { ownOnly: true },
    component: () => import(/* webpackChunkName: "TChannelManagement" */ '@/components/templates/TChannelManagement.vue')
  },
  {
    name: RouteNames.ROUTE_PRODUCT_RESEARCH_START,
    path: 'product-researcher-start',
    meta: { ownOnly: true },
    component: () => import(/* webpackChunkName: "TProductResearcherCreate" */ '@/components/templates/TProductResearcherCreate.vue'),
  },
  {
    name: RouteNames.ROUTE_CHANNEL_CREATE,
    path: 'channel-management/create',
    meta: { ownOnly: true },
    component: () => import(/* webpackChunkName: "TChannelCreate" */ '@/components/templates/TChannelCreate.vue'),
  },
  {
    name: RouteNames.ROUTE_CHANNEL_EDIT,
    path: 'channel-management/edit/:channelSlug',
    meta: { ownOnly: true },
    component: () => import(/* webpackChunkName: "TChannelEdit" */ '@/components/templates/TChannelEdit.vue'),
  },
  {
    name: RouteNames.ROUTE_GETGOT,
    path: 'shopping-list',
    meta: { ownOnly: true },
    component: () => import(/* webpackChunkName: "TShoppingList" */ '@/components/templates/TShoppingList.vue'),
  },
  {
    name: RouteNames.ROUTE_NOTIFICATIONS,
    path: 'notifications',
    meta: { ownOnly: true },
    component: () => import(/* webpackChunkName: "TNotifications" */ '@/components/templates/TNotifications.vue'),
  },
  {
    name: RouteNames.ROUTE_NOTIFICATIONS_ID,
    path: 'notifications/:notificationId',
    meta: { ownOnly: true },
    component: () => import(/* webpackChunkName: "TNotifications" */ '@/components/templates/TNotifications.vue'),
  },
  {
    name: RouteNames.ROUTE_SETTINGS,
    path: 'settings',
    meta: { ownOnly: true },
    component: () => import(/* webpackChunkName: "TSettings" */ '@/components/templates/TSettings.vue'),
    children: [
      {
        name: RouteNames.ROUTE_SETTINGS_GENERAL,
        path: '',
        component: () => import(/* webpackChunkName: "OProfileSettingsGeneralForm" */ '@/components/organisms/forms/OProfileSettingsGeneralForm.vue'),
      },
      {
        name: RouteNames.ROUTE_SETTINGS_SECURITY,
        path: 'security',
        component: () => import(/* webpackChunkName: "OProfileSettingsEmailForm" */ '@/components/organisms/forms/OProfileSettingsEmailForm.vue'),
      },
      {
        name: RouteNames.ROUTE_SETTINGS_NOTIFICATIONS,
        path: 'notifications',
        meta: { ownOnly: true },
        component: () => import(/* webpackChunkName: "OProfileSettingsNotificationsForm" */ '@/components/organisms/forms/OProfileSettingsNotificationsForm.vue'),
      },
      {
        name: RouteNames.ROUTE_SETTINGS_INVITATIONS,
        path: 'invitations',
        meta: { ownOnly: true },
        component: () => import(/* webpackChunkName: "OProfileSettingsInvitationsForm" */ '@/components/organisms/forms/OProfileSettingsInvitationsForm.vue'),
      },
      {
        name: RouteNames.ROUTE_SETTINGS_WATCHERS,
        path: 'watchers',
        meta: { ownOnly: true },
        component: () => import(/* webpackChunkName: "OProfileSettingsWatchersForm.vue" */ '@/components/organisms/forms/OProfileSettingsWatchersForm.vue'),
      }
    ],
  },
];
