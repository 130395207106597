<template>
  <div class="AAddItemButtonLink" @click="click">
    <a-add-item-icon :size="iconSize"/><p :class="textClass">{{ $t('dict.addToChannel') }}</p>
  </div>
</template>

<style scoped lang="scss">
.AAddItemButtonLink {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: .3rem;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    transform: scale(1.025);
    color: var(--primary-color);
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CHILD_EMIT } from '@/constants/childEmitEventNames';
import AAddItemIcon from '@/components/atoms/icon/AAddItemIcon.vue';

@Component({
  components: { AAddItemIcon }
})
export default class AAddItemButtonLink extends Vue {
  @Prop({ required: false, default: '1' })
  iconSize!: string | number;
  @Prop({ required: false, default: 'font-body-s' })
  textClass!: string;

  click () {
    this.$emit(CHILD_EMIT);
  }
}
</script>