<template>
  <svg
      data-v-327ea383="" xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :viewBox="viewBox"
      xml:space="preserve"
  >
    <path :fill="fillColour" d="M13 21h3.863v-3.752h1.167a3.124 3.124 0 1 0 0-6.248H13v10zm5.863 2H11V9h7.03a5.124 5.124 0 0 1 .833 10.18V23z" />
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class APexelsSvgIcon extends Vue {
  @Prop({ default: 1 })
  size!: number;

  @Prop({default: 'currentColor'})
  fillColour!: string;

  width = 0;
  height = 0;

  viewBox = '';
  viewMinX = 0;
  viewMinY = 0;
  viewWidth = 32;
  viewHeight = 32;

  created () {
    this.width = this.viewWidth * this.size;
    this.height = this.viewHeight * this.size;
    const minYK = Math.ceil((1 - this.size) * 10);
    if (this.size > 1) {
      this.viewMinY -= minYK;
    } else if (this.size < 1) {
      this.viewMinY += minYK;
    }
    this.viewBox = `${this.viewMinX} ${this.viewMinY} ${this.viewWidth} ${this.viewHeight}`;
  }
}
</script>