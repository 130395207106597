<template>
  <div class="MItemEntryChannelSelector">
    <!-- Header row - back link, title, new channel button -->
    <div class="headerTitleBar">
      <p class="return-text" @click="showCreateChannelForm ? showCreateChannelForm = false : hideChannelSelector()">
        <arrow-left-icon class="back-icon" size="1.3x"/>
        {{ $t('dict.back') }}
      </p>
      <div class="btn-create-channel btn-create-channel-container">
        <b-button v-if="!showCreateChannelForm" class="is-primary btn-create-channel is-small"
                  @click="showCreateChannelForm = true">
          <plus-icon size="1.5x"/>
          {{ $t('dict.newChannel') }}
        </b-button>
      </div>
    </div>
    <h5 class="headerTitle has-text-centered mb-4">
      {{ showCreateChannelForm ? $t('dict.createChannel') : $t('dict.selectingChannel') }}
    </h5>

    <!-- Warning if moving out of custom fields -->
    <b-message type="is-danger" v-if="displayCustomFieldWarning">
      {{ $t('channel.customFields.changeChannelWarning') }}
    </b-message>

    <!-- Creating channel via this selector -->
    <div v-if="showCreateChannelForm" class="channels-wrapper">
      <o-channel-upsert-form-stepped :simple-form="true" v-on:child-output="channelCreated" class="pb-6 pt-4"/>
    </div>

    <!-- The actual list of channels to add to -->
    <div v-else class="channels-wrapper">
      <m-channels-recently-added-to v-if="channelsFetched.length > 5" class="mt-3"
                                    v-on:channel-selected="handleSelection"/>

      <div class="is-relative">
        <b-loading v-model="loadingSomething" :is-full-page="false"/>

        <div class="mt-4 p-1">
          <m-input-with-validation
              v-model="search"
              label="Search your channels"
              placeholder="Type to search..."
              name="search"
              v-on:input="searchChannels"
          />
        </div>

        <template v-if="loading">
          <m-channel-selector-skeleton v-for="index in 4" :key="index"/>
        </template>
        <template v-else>
          <p v-if="!formattedChannels.length" class="title-7 not-found-message">
            {{ $t('dict.notFoundMessageChannels') }}
          </p>
          <div v-for="(channel, index) in formattedChannels" :key="index" @click="handleSelection(channel)">
            <!-- Channel row -->
            <b-field class="channel-item">
              <b-radio
                  v-model="selectedChannel.channel.slug"
                  :disabled="!channel.currentUserCanAdd && !channel.isDefaultChannel && !channel.allCanAdd"
                  :native-value="channel.slug"
                  :title="!channel.currentUserCanAdd && !channel.isDefaultChannel && !channel.allCanAdd ? $t('channelInviteState.youCannotAddTo') : ''"
                  name="selectedChannel"
              >
                <a-image
                    v-if="channel.imagePath"
                    :alt-text="channel.name"
                    :image-path="channel.imagePath"
                    :size="imageSize"
                    class="channel-image"
                />
                <div :class="channel.isDefaultChannel ? 'channel-default-text' : 'channel-text'">
                  <p class="channel-name">
                    {{ channel.privacy === privacy.FriendsOnly ? 'General: ' : '' }}
                    <calendar-icon v-if="channel.channelType === channelTypes.Event" size="1x" class="mr-1"/>
                    <a-lightbulb-on-outline-icon v-if="channel.channelType === channelTypes.ResearchConcierge" size="1"
                                                 class="mr-1"/>
                    <b v-html="channel.name"/>
                  </p>
                  <p :class="[{ 'line-clamp-1': !channel.isDefaultChannel }, 'channel-description']"
                     class="font-body-s">
                    {{ channel.description | stripTags }}
                  </p>
                </div>

                <div v-if="!channel.isDefaultChannel" class="channel-group-meta">
                  <div v-if="channel.privacy === privacy.Private" class="channel-privacy">
                    <eye-off-icon class="privacy-icon" size="1.1x"/>
                    <span class="ml-1">{{ isMobileView ? $t('dict.private') : $t('dict.privateChannel') }}</span>
                  </div>
                  <div v-else-if="channel.privacy === privacy.Public" class="channel-privacy">
                    <eye-icon class="privacy-icon" size="1.1x"/>
                    <span class="ml-1">{{ isMobileView ? $t('dict.public') : $t('dict.publicChannel') }}</span>
                  </div>
                  <div v-else class="channel-privacy">
                    <span>{{ $t('dict.general') }} {{ $t('dict.channel') }}</span>
                  </div>
                  <div class="channel-users">
                    <users-icon class="user-icon" size="1.1x"></users-icon>
                    <span class="ml-1">{{ channel.memberCount }}</span>
                    <span v-if="isMobileView"> {{ $t('dict.members') }} </span>
                  </div>
                </div>
              </b-radio>
            </b-field>
          </div>

          <b-button
              v-if="!noMoreResults"
              :disabled="loadingMore"
              class="is-primary btn-load-more"
              @click="handleLoadMore"
          >
            {{ $t('dict.loadMore') }}
          </b-button>
        </template>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
@import 'MItemEntryChannelSelector';
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ChannelsService from '@/api/ms-channel/services/ChannelsService';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { AuthenticationStore, ItemEntryStore } from '@/store';
import { User } from '@/api/ms-authentication/services/interfaces';
import { Datum as ChannelsDatum } from '@/api/ms-channel/services/interfaces/Channels';
import { Privacy } from '@/api/ms-channel/services/interfaces/Channel';

import AImage from '@/components/atoms/AImage.vue';
import { ArrowLeftIcon, CalendarIcon, EyeIcon, EyeOffIcon, PlusIcon, UsersIcon } from 'vue-feather-icons';
import MChannelSelectorSkeleton from '@/components/molecules/skeletons/MChannelSelectorSkeleton.vue';
import { Datum } from '@/api/ms-channel/services/interfaces/ChannelWithRelationalMetas';

import { Channel } from '@/api/ms-channel/services/interfaces';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import updateItemEntryLastChannelUsed, { FormattedChannel } from '@/utils/updateItemEntryLastChannelUsed';
import { ChannelType } from '@/api/ms-item/services/interfaces/Item';
import { translation } from '@/plugins/i18n/Translation';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';
import OChannelUpsertFormStepped from '@/components/organisms/forms/OChannelUpsertFormStepped.vue';
import seoInjector from '@/services/SeoInjector';
import { stripTags } from 'common-utils/string';
import MChannelsRecentlyAddedTo from '@/components/molecules/channel/MChannelsRecentlyAddedTo.vue';
import { clone } from 'common-utils/object';

export interface OChannelUpsertFormInterfacesChildOutput {
  action: string,
  channel: Channel
}

@Component({
  components: {
    MChannelsRecentlyAddedTo,
    OChannelUpsertFormStepped,
    ALightbulbOnOutlineIcon,
    MInputWithValidation,
    MChannelSelectorSkeleton,
    AImage,
    ArrowLeftIcon,
    CalendarIcon,
    EyeIcon,
    EyeOffIcon,
    UsersIcon,
    PlusIcon
  },
  filters: {
    stripTags: stripTags
  }
})
export default class MItemEntryChannelSelector extends Vue {
  @Prop({ required: true })
  isMobileView!: boolean;
  @Prop({ required: true })
  displayCustomFieldWarning!: boolean;

  loading: boolean = false;
  loadingMore: boolean = false;
  channelsFetched: ChannelsDatum[] = [];
  formattedChannels: FormattedChannel[] = [];
  imageSize = Size.The60X60;
  privacy = Privacy;
  noMoreResults: boolean = false;
  showCreateChannelForm: boolean = false;
  search: string = '';
  channelTypes = ChannelType;

  storePageTitle = {
    str: '',
    get title () {
      return this.str.replace(' | Liffery', '');
    }
  };

  get selectedChannel () {
    return ItemEntryStore.lastChannelAddedToGet;
  }

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get selectedOptionText (): string {
    return this.selectedChannel.name === '' || this.selectedChannel.name === 'default' ?
        this.$t('dict.general') as string :
        this.selectedChannel.name;
  }

  get language () {
    return translation.currentLanguage;
  }

  get loadingSomething () {
    return this.loading || this.loadingMore;
  }

  hideChannelSelector (): void {
    this.$emit('hide-channel-selector');
  }

  beforeDestroy () {
    seoInjector.setPageTitle(this.storePageTitle.title);
  }

  async created () {
    this.storePageTitle.str = seoInjector.getPageTitle();
    seoInjector.setPageTitle('Select Channel');

    await this.setChannels();
    await this.checkSelectedChannel();
  }

  formatChannels (channels): FormattedChannel[] {
    return channels.map((channel: Datum) => ({
      imagePath: channel.imagePath,
      isDefaultChannel: false,
      allCanAdd: channel.allCanAdd,
      currentUserCanAdd: channel.currentUserCanAdd,
      event: channel.event,
      name: channel.name,
      description: channel.description,
      privacy: channel.privacy,
      slug: channel.slug,
      channelType: channel.channelType,
      memberCount: channel.memberCount,
      customFields: channel.customFields
    }));
  }

  async setChannels (): Promise<void> {
    this.loading = true;
    const { data } = await ChannelsService.channelsGet({
      memberOf: true,
      text: this.search
    });
    this.channelsFetched = data;
    this.noMoreResults = data.length < 20;

    const newChannels: FormattedChannel[] = this.formatChannels(data);

    newChannels.unshift({
      name: String(this.$t('dict.general')),
      currentUserCanAdd: true,
      slug: '',
      channelType: ChannelType.General,
      description: String(this.$t('dict.generalChannelDescription')),
      isDefaultChannel: true,
      memberCount: -1,
      customFields: []
    });
    this.formattedChannels = newChannels;
    this.loading = false;
  }

  searchChannelsTimeout: any = null;

  searchChannels (input: string) {
    clearTimeout(this.searchChannelsTimeout);
    this.searchChannelsTimeout = setTimeout(() => {
      this.search = input;
      this.setChannels();
    }, 500);
  }

  /**
   * Preloading form with a channel selected, i.e. editing an item and changing channel, moving an item.
   * Ensure the selected channel is loaded, and also splice at the top of the list beneath general.
   */
  async checkSelectedChannel () {
    const selectedChannelSlug = this.selectedChannel.channel?.slug;
    if (selectedChannelSlug) {
      if (!this.channelsFetched.find((channel: ChannelsDatum) => channel.slug === selectedChannelSlug)) {
        // if there are no more channels to load, the channel must be deleted or user removed, clear
        if (this.noMoreResults) {
          ItemEntryStore.CLEAR_LAST_CHANNEL_USED();
        } else {
          // otherwise load the next batch of channels and try to find again
          await this.handleLoadMore();
          await this.checkSelectedChannel();
          this.spliceSelectedChannelToTop(selectedChannelSlug);
        }
      } else {
        this.spliceSelectedChannelToTop(selectedChannelSlug);
      }
    }
  }

  /**
   * Moves the selected channel from whatever position it is in to the top of the list, directly beneath general
   */
  spliceSelectedChannelToTop (slug: string) {
    const index = this.formattedChannels.findIndex((channel) => channel.slug === slug);
    if (index !== -1) {
      const channel = clone(this.formattedChannels[index]);
      // delete the old position then place into the top position beneath general
      this.formattedChannels.splice(index, 1);
      this.formattedChannels.splice(1, 0, channel);
    }
  }

  async handleSelection (channel: FormattedChannel): Promise<void> {
    if (!channel.currentUserCanAdd && !channel.allCanAdd) {
      return;
    }
    if (!channel) {
      ItemEntryStore.CLEAR_LAST_CHANNEL_USED();
      return;
    }

    // set the selected channel
    updateItemEntryLastChannelUsed(channel);

    this.hideChannelSelector();
  }

  channelCreated (output: OChannelUpsertFormInterfacesChildOutput): void {
    ItemEntryStore.UPDATE_LAST_CHANNEL_USED({
      name: output.channel.name,
      channel: {
        name: output.channel.name,
        slug: output.channel.slug as string,
        channelType: output.channel.channelType || ChannelType.General
      },
      channelPrivacy: output.channel.privacy,
      customFields: output.channel.customFields
    });

    this.hideChannelSelector();
  }

  async handleLoadMore () {
    this.loadingMore = true;

    const { data } = await ChannelsService.channelsGet({ memberOf: true, offset: this.formattedChannels.length - 1 });
    this.channelsFetched = data;
    this.noMoreResults = data.length < 20;

    const newChannels: FormattedChannel[] = this.formatChannels(data);
    this.formattedChannels = this.formattedChannels.concat(newChannels);

    this.loadingMore = false;
  }
}
</script>
