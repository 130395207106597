<template>
  <span>
    <span v-for="(part, index) in textParts" :key="index">
      <template v-if="isHashtag(part)">
        <a class="as-link colour-primary font-bold"
           @click="(e) => tagClick(e, part)">{{ part }}</a>
      </template>
      <span v-else v-html="nl2br(hasLinkedText(part))"/>
    </span>
  </span>
</template>

<style scoped lang="scss">
.MItemCardText {
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import remove1stUrlFromString from '@/utils/remove1stUrlFromString';
import { isHashtag } from 'common-utils/regex';
import { RouteNames } from '@/router/RouteNames';
import EventBus, { EventBusEvents } from '@/EventBus';
import { ASearchBarTabType } from '@/enums/SearchBarTabType';
import linkedText from '@/utils/linkedText';
import nl2br from '@/utils/nl2br';

@Component
export default class MItemCardText extends Vue {
  @Prop({ required: true })
  text!: string;

  tags: string[] = [];

  get textParts(): string[]{
    return remove1stUrlFromString(this.text)
      .split(/(#[À-žA-Za-z0-9_]+)/);
  }

  nl2br = nl2br;

  isHashtag (tag) {
    return isHashtag(tag);
  }

  hasLinkedText (input: string): string {
    return linkedText(input);
  }

  tagClick (e, tag: string) {
    e.preventDefault();
    if (this.$route.name === RouteNames.ROUTE_DASHBOARD) {
      EventBus.$emit(EventBusEvents.HASH_TAG_CLICK, { tag });
    } else {
      // navigate to the dashboard
      this.$router.push({
        name: RouteNames.ROUTE_DASHBOARD,
        query: {
          tab: ASearchBarTabType.explore,
          text: tag
        }
      });
    }
  }
}
</script>
