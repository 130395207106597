<template>
  <div class="MItemSwipeScoreUser">
    <div class="swipeIcon">
      <m-channel-swipe-action-icons :direction="direction" :unset-position="true" icon-size="2x" />
    </div>
    <span class="ml-2">{{ $t('item.swipeScore.yourSwipe', {swipeScore: (score.scoreApplied < 1 ? '' : '+') + score.scoreApplied, date: displayDate}) }}</span>
  </div>
</template>

<style scoped lang="scss">
.MItemSwipeScoreUser {
  display: flex;
  align-items: center;
  justify-content: center;

  .swipeIcon {
    display: inline-block;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Datum } from '@/api/ms-item/services/interfaces/SwipeScoress';
import { Action } from '@/api/ms-item/services/interfaces/SwipeScores';
import MChannelSwipeActionIcons from '@/components/molecules/channel-swipe/MChannelSwipeActionIcons.vue';
import { translation } from '@/plugins/i18n/Translation';
import formatDateDayjs from '@/utils/formatDateDayjs';

@Component({
  components: { MChannelSwipeActionIcons },
})
export default class MItemSwipeScoreUser extends Vue {
  @Prop()
  score!: Datum;

  SwipeDirections = Action;
  iconSize = '1x';

  displayDate:string = '';

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get direction () {
    return this.score.action;
  }

  async created () {
    this.displayDate = this.score.updatedAt ? await formatDateDayjs(this.score.updatedAt, this.currentLanguage) : '';
  }

}
</script>