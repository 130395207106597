<template>
  <router-link :to="{ name }" class="menu-profile-item font-body-s" @click="$emit('click')">
    <template v-if="!hasText">
      <a-tooltip :tip="$t('dict.watchersManagement')" :discreet="true" size="is-small" :multilined="false">
        <activity-icon v-if="hasIcon" class="header-menuitem-icon" :size="iconSize"/>
      </a-tooltip>
    </template>
    <template v-else>
      <activity-icon v-if="hasIcon" class="header-menuitem-icon" :size="iconSize"/> {{ $t('dict.watchersManagement') }}
    </template>
  </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { ActivityIcon } from 'vue-feather-icons';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';

@Component({
  components: {
    ATooltip,
    ActivityIcon
  }
})
export default class ARouterLinkSettingWatchers extends Vue {
  @Prop(Boolean)
  hasIcon!: boolean;

  @Prop({ default: true })
  hasText!: boolean;

  @Prop({ default: '1x' })
  iconSize!: string;

  name = RouteNames.ROUTE_SETTINGS_WATCHERS;
}
</script>
