<template>
  <div class="channel-header">
    <figure class="channel-hero-wrapper" @click="openImageModal">
      <a-image
          v-if="channel.imageHeroPath"
          :alt-text="channel.name"
          :image-path="channel.imageHeroPath"
          :size="sizeHero"
          class="image channel-image-hero"
      />
      <span class="channel-top-bar" :class="{'gradient': (channel.archived || (!channel.archived && channel.channelType === channelTypes.Event) || channel.channelType === channelTypes.ResearchConcierge)}">
        <span class="maximise-button channel-info-open is-clickable" @click="openImageModal">
          <maximize2-icon size="1.5x" class="channel-info-open"/>
        </span>
        <span v-if="channel.archived" class="channel-info-sash" :title="$t(`page.channel.archived.archivedChannel`)">
          <archive-icon size="0.75x"/> {{ $t('page.channel.archived.archivedChannel')|capitaliseFirstLetterOfWords }}
          <template v-if="isChannelEvent">
            -<calendar-icon size="1x" class="ml-1.5"/> {{ formattedEventDate }}
          </template>
        </span>
        <span v-else-if="!channel.archived && channel.channelType === channelTypes.Event" class="channel-info-sash">
          <calendar-icon size="1x"/> {{ formattedEventDate }}
          <template v-if="channel.event.eventType !== eventTypes.OneOff">
            <repeat-icon size="0.9x" class="ml-1.5"/> {{ $t(`datetime.dict.${channel.event.eventType}`)|ucfirst }}
          </template>
        </span>
        <span v-else-if="channel.channelType === channelTypes.ResearchConcierge" class="channel-info-sash">
          <a-lightbulb-on-outline-icon
              size="0.9"/> {{ $t('page.channel.researchConcierge.dict')|capitaliseFirstLetterOfWords }}<span
            v-if="channel.concierge.active.jobStatus === jobStatuses.Completed">, {{ $t('dict.completed') }}</span>
        </span>
      </span>
    </figure>
    <div class="channel-info" @click="openImageModal">
      <div class="channel-thumb-wrapper">
        <a-image
            v-if="channel.imagePath"
            :alt-text="channel.name"
            :image-path="channel.imagePath"
            :size="sizeThumbnail"
            class="image channel-image-thumb"
        />
      </div>

      <div class="channel-details-wrapper">
        <p class="line-clamp-1 channel-title font-body-xl">
          <span v-html="channel.name"/>
        </p>

        <div class="channel-details">
          <span class="channel-details-component mr-3">
            <eye-icon v-if="channel.privacy === privacyOptions.Public" size="0.9x"></eye-icon>
            <eye-off-icon v-else-if="channel.privacy === privacyOptions.Private" size="0.9x"/>
            <users-icon v-else size="1x"/>
            <a-router-link-profile
                v-if="channel.privacy === privacyOptions.FriendsOnly"
                :username="channel.owner.username"
            >
              {{ $t(`dict['${channel.privacy + 'Channel'}']`, { name: channel.owner.firstName }) }}
            </a-router-link-profile>
            <template v-else>
              {{ $t(`dict['${channel.privacy + 'Channel'}']`, { name: channel.owner.firstName }) }}
            </template>
          </span>

          <span v-if="channel.privacy !== privacyOptions.FriendsOnly"
                :class="{'is-clickable is-underlined': currentUserCanEditChannel && !channel.archived}"
                class="channel-details-component mr-3"
                @click="navigateToEdit">
              <users-icon class="members-icon" size="1x"/>
              {{ `${channel.memberCount || 0} ${channel.memberCount === 1 ? $t('dict.member') : $t('dict.members')}` }}
          </span>
        </div>

        <div class="channel-details mt-2">
          <template v-if="currentUserJoined">
            <span class="channel-details-component mr-3">
              <check-icon size="1x"/>
              {{
                currentUserOwnsChannel ? $t('dict.channelOwner') : currentUserCanEditChannel ? $t('dict.channelManager') : $t('dict.joined')
              }}
            </span>
          </template>
          <template v-else-if="channel.privacy !== privacyOptions.FriendsOnly">
            <span class="channel-details-component mr-3">
              <x-icon size="1x"/>
              {{ $t('dict.joinedNot') }}
            </span>
          </template>

          <span class="channel-details-component channel-notifications is-clickable mr-2" @click="channelNotifications">
            <bell-icon v-if="channelNotificationsEnabled" size="0.85x" class="channel-notifications"/>
            <bell-off-icon v-else size="0.85x" class="channel-notifications"/>
            <span class="channel-notifications"> {{ $t('dict.notifications') }}</span>
          </span>

          <span class="channel-details-component channel-details-component-more is-clickable">
            <m-channel-header-dropdown-menu
                :channel="channel"
                :current-user-can-edit-channel="currentUserCanEditChannel"
                :is-channel-event="isChannelEvent"
                :is-research-concierge="isResearchConcierge"
                :current-user-joined="currentUserJoined"
                :current-user-owns-channel="currentUserOwnsChannel"
                v-on:dropdown-action="dropdownActionController"
            />
          </span>
        </div>
      </div>

      <!-- Channel is archived, present a disabled button with tooltip -->
      <b-tooltip
          v-if="channel.archived"
          :label="$t('page.channel.archived.addItemJoinButton')"
          :multilined="true"
          position="is-left"
          class="btn-add-to-channel"
      >
        <b-button
            :disabled="true"
        >
          {{ $t('dict.archived') }}
        </b-button>
      </b-tooltip>

      <!-- Join the channel button -->
      <b-button
          v-else-if="!currentUserJoined && channel.privacy !== privacyOptions.FriendsOnly"
          :loading="requestingToJoinLoading"
          class="btn-add-to-channel"
          v-on:click="showJoinChannel"
      >
        {{ $t('dict.joinChannel') }}
      </b-button>

      <!-- Add an item to the channel button if the user joined -->
      <b-button
          v-else-if="currentUserJoined"
          :disabled="!channel.currentUserCanAdd"
          class="btn-add-to-channel"
          @click="addItemToChannel"
      >
        <b-tooltip v-if="!channel.currentUserCanAdd" :label="$t('dict.addItemToChannelNoPermission')">
          {{ $t('dict.addItemToChannel') }}
        </b-tooltip>
        <span v-else>{{ $t('dict.addItemToChannel') }}</span>
      </b-button>
    </div>

    <!-- Modal for the secret share link-->
    <o-modal-wrapper :show-modal="showSecretShareLink" v-on:close="showSecretShareLink = false">
      <template slot="title">
        <key-icon size="1.0x"/>
        Channel secret link
      </template>
      <template slot="body">
        <div class="content">
          <template v-if="channelSecretLink">
            <p>{{ $t('channel.secretLink.modalTitle') }}:</p>
            <p class="mt-4">
              <a :href="channelSecretLink">{{ channelSecretLink }}</a>
            </p>
          </template>
          <template v-else>
            <p>{{ $t('channel.secretLink.disabledMsg') }}</p>
          </template>
          <b-collapse
              :open="false"
              position="is-bottom"
              aria-id="contentIdForA11y4">
            <template #trigger="props">
              <a
                  aria-controls="contentIdForA11y4"
                  :aria-expanded="props.open"
                  class="is-italic"
              >
                {{ !props.open ? $t('dict.moreInfo') : '' }}
              </a>
            </template>
            <div>
              <p class="mt-4 mt-4 is-italic">
                {{ $t('channel.secretLink.info') }}
              </p>
            </div>
          </b-collapse>
        </div>
      </template>
    </o-modal-wrapper>

    <!-- Shuffle the items -->
    <o-modal-wrapper
        :show-modal="loadWhenOfItems"
        v-on:close="loadWhenOfItems = false"
        :overflow-y="true"
    >
      <template slot="title">
        <img src="../../assets/svg/shuffle-svg.svg" alt="Shuffle"
             style="height: 1.5rem;"
             class="is-inline-block"
        />
        {{ $t('dict.shuffleItems') }}
      </template>
      <template slot="body">
        <m-items-shuffler :channel-slug="channel.slug"/>
      </template>
    </o-modal-wrapper>

    <!-- Join channel confirmation -->
    <b-modal
        :active.sync="showJoinChannelModal"
        :destroy-on-hide="true"
        aria-modal
        aria-role="dialog"
        has-modal-card
        trap-focus
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('modals.joinChannelConfirm.title') }}</p>
          <a-close-modal-button v-on:child-output="showJoinChannelModal = false"/>
        </header>
        <div class="modal-card-body content">
          <template v-if="channel.privacy === privacyOptions.Public">
            <p>
              {{ $t('modals.joinChannelConfirm.bodyGeneral') }}
            </p>
          </template>
          <template v-else>
            <p>{{ $t('modals.joinChannelConfirm.bodyNotPublic') }}</p>
            <p>{{ $t('modals.joinChannelConfirm.bodyGeneral') }}</p>
          </template>
        </div>
        <div class="modal-card-footer">
          <b-button :disabled="requestingToJoinLoading" class="is-primary is-outlined"
                    @click="showJoinChannelModal = false">
            {{ $t('dict.cancel') }}
          </b-button>
          <b-button
              :loading="requestingToJoinLoading"
              class="is-primary btn-send-request"
              @click="callJoinChannel"
          >
            {{ channel.privacy === privacyOptions.Public ? $t('dict.join') : $t('dict.requestToJoin') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
@import './OChannelHeader';
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import EventBus, { EventBusEvents } from '@/EventBus';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';

import { AuthenticationStore, ChannelStore, ItemsStore, PeopleStore, PinnedItemsStore } from '@/store';

import { RouteNames } from '@/router/RouteNames';

import OItemsContainer from '@/components/organisms/OItemsContainer.vue';
import MSearchBarWithTabs from '@/components/molecules/MSearchBarWithTabs.vue';
import OPeopleContainer from '@/components/organisms/OPeopleContainer.vue';
import OChannelMembersContainer from '@/components/organisms/OChannelMembersContainer.vue';
import AImage from '@/components/atoms/AImage.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import {
  ArchiveIcon,
  BellIcon,
  BellOffIcon,
  CalendarIcon,
  CheckIcon,
  EyeIcon,
  EyeOffIcon,
  InfoIcon,
  KeyIcon,
  Maximize2Icon,
  RepeatIcon,
  SearchIcon,
  UsersIcon,
  XIcon
} from 'vue-feather-icons';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';
import { Privacy } from '@/api/ms-channel/services/interfaces/ChannelPost';
import { MItemEntryTriggerItemEditClonePayload } from '@/components/molecules/itemEntry/MItemEntryTrigger.vue';
import config from '@/config';
import shareOrCopy, { SharedFrom, ShareOrCopy } from '@/utils/shareOrCopy';
import {
  OModalsContainerArchiveChannel,
  OModalsContainerChannelAsPayload
} from '@/components/organisms/OModalsContainer.vue';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';
import MItemsShuffler from '@/components/molecules/MItemsShuffler.vue';
import MChannelInformation from '@/components/molecules/MChannelInformation.vue';
import SeoInjector from '@/services/SeoInjector';
import { translation } from '@/plugins/i18n/Translation';
import formatDateDayjs from '@/utils/formatDateDayjs';
import { capitaliseFirstLetterOfString, capitaliseFirstLetterOfWords } from 'common-utils/string';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';
import { joinedSubscriptionStatuses } from '@/components/templates/TChannel.vue';
import {
  ChannelType,
  CurrentUserSubscriptionStatus
} from '@/api/ms-channel/services/interfaces/ChannelWithRelationalMeta';
import { EventType, JobStatus } from '@/api/ms-channel/services/interfaces/Channel';
import MChannelHeaderDropdownMenu, { EventsToEmit } from '@/components/molecules/MChannelHeaderDropdownMenu.vue';
import { EnumRegisteredFromAction } from '@/store/modules/interfaces/AuthenticationModule';

enum DropdownPositions {
  isTopRight = 'is-top-right',
  isTopLeft = 'is-top-left',
  isBottomLeft = 'is-bottom-left',
  isBottomRight = 'is-bottom-right'
}

@Component({
  components: {
    MChannelHeaderDropdownMenu,
    ALightbulbOnOutlineIcon,
    ATooltip,
    MChannelInformation,
    MItemsShuffler,
    OModalWrapper,
    ACloseModalButton,
    OChannelMembersContainer,
    OPeopleContainer,
    MSearchBarWithTabs,
    OItemsContainer,
    AImage,
    AUserProfilePic,
    ARouterLinkProfile,
    ArchiveIcon,
    BellIcon,
    BellOffIcon,
    CalendarIcon,
    CheckIcon,
    EyeIcon,
    EyeOffIcon,
    InfoIcon,
    KeyIcon,
    Maximize2Icon,
    RepeatIcon,
    SearchIcon,
    UsersIcon,
    XIcon
  },
  filters: {
    capitaliseFirstLetterOfWords: capitaliseFirstLetterOfWords,
    ucfirst: capitaliseFirstLetterOfString
  }
})
export default class OChannelHeader extends Vue {
  requestSent = false;

  channelTypes = ChannelType;
  eventTypes = EventType;
  jobStatuses = JobStatus;
  privacyOptions = Privacy;

  currentUserJoinedSubscriptionStatuses = joinedSubscriptionStatuses as unknown as CurrentUserSubscriptionStatus;

  channelSlug!: string;
  requestingToJoinLoading = false;
  sizeHero = Size.The950X;
  sizeThumbnail = Size.The100X100;
  showJoinChannelModal = false;
  loadWhenOfItems = false;
  formattedEventDate: string = '';
  showSecretShareLink = false;

  dropdownPosition: DropdownPositions = DropdownPositions.isBottomLeft;

  get channel () {
    return ChannelStore.getCurrentChannelViewed;
  }

  get channelNotificationsEnabled () {
    return ChannelStore.getCurrentChannelNotificationsEnabled;
  }

  get channelSecretLink (): string | false {
    return this.channel.shareLinkEnabled ?
        window.location.origin + '/channel/' + this.channel._id :
        false;
  }

  get isAuthenticated () {
    return AuthenticationStore.getAuthenticated;
  }

  get isChannelEvent () {
    return this.channel.channelType === ChannelType.Event;
  }

  get isResearchConcierge () {
    return this.channel.channelType === ChannelType.ResearchConcierge;
  }

  get language () {
    return translation.currentLanguage;
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  get currentUserJoined (): boolean {
    return this.channel.currentUserSubscriptionStatus !== undefined && this.currentUserJoinedSubscriptionStatuses.includes(this.channel.currentUserSubscriptionStatus);
  }

  get currentUserCanEditChannel (): boolean {
    return this.isAuthenticated &&
        (
            this.channel.owner.username === this.currentUser.username ||
            this.channel.currentUserCanEdit === true
        );
  }

  get currentUserOwnsChannel (): boolean {
    return this.isAuthenticated && this.channel.owner.username === this.currentUser.username;
  }

  async setFormattedEventDate () {
    if (this.isChannelEvent && this.channel.event && this.channel.event.eventDate) {
      this.formattedEventDate = capitaliseFirstLetterOfString(await formatDateDayjs(this.channel.event.eventDate, this.language));
    }
  }

  beforeRouteEnter (to, from, next) {
    ItemsStore.CLEAR_ITEMS();
    PinnedItemsStore.CLEAR_PINNED_ITEMS();
    PeopleStore.RESET();
    next();
  }

  created () {
    SeoInjector.setPageTitle(this.channel.name);
    this.setFormattedEventDate();
    this.setDropdownPosition();
    this.bindEvents();
  }

  mounted () {
    setTimeout(() => {
      SeoInjector.init(this.$route, {
        title: this.channel.name,
        description: this.channel.description
      });
    }, 300);
  }

  beforeDestroy () {
    SeoInjector.clearPageTitle();
    this.unbindEvents();
  }

  bindEvents () {
    window.addEventListener('resize', this.setDropdownPosition);
  }

  unbindEvents () {
    window.removeEventListener('resize', this.setDropdownPosition);
  }

  setDropdownPosition () {
    if ((window.innerWidth < 421 && window.innerWidth > 362) || (window.innerWidth < 311) || ([343, 344].includes(window.innerWidth))) {
      this.dropdownPosition = DropdownPositions.isBottomRight;
    } else {
      this.dropdownPosition = DropdownPositions.isBottomLeft;
    }
  }

  getSlug (): string {
    if (!this.$route.params.channelSlug1) {
      return this.$route.params.channelSlug0;
    }
    return this.$route.params.channelSlug0 + '@' + this.$route.params.channelSlug1;
  }

  showLogin () {
    AuthenticationStore.TOGGLE_PROMPT_SIGNUP({
      state: true,
      metaData: {
        registeredFromAction: EnumRegisteredFromAction.ctaChannelHeader
      }
    });
  }

  showJoinChannel () {
    if (!this.isAuthenticated) {
      return this.showLogin();
    }
    this.showJoinChannelModal = true;
  }

  // eslint-disable-next-line max-lines-per-function
  dropdownActionController (event: EventsToEmit) {
    switch (event) {
      case EventsToEmit.ArchiveChannel:
        this.archiveChannel();
        break;

      case EventsToEmit.ChannelInfo:
        EventBus.$emit(EventBusEvents.CHANNEL_INFO_SHOW, { channel: this.channel } as OModalsContainerChannelAsPayload);
        break;

      case EventsToEmit.ChannelNotifications:
        this.loadChannelNotificationsModal();
        break;

      case EventsToEmit.LeaveChannel:
        this.leaveChannel();
        break;

      case EventsToEmit.NavigateToEdit:
        this.navigateToEdit();
        break;

      case EventsToEmit.ReportChannel:
        this.reportChannel();
        break;

      case EventsToEmit.ShuffleItems:
        this.loadShuffleChannel();
        break;

      case EventsToEmit.ShowSecretShareLink:
        this.showSecretShareLinkModal();
        break;

      case EventsToEmit.ShareChannel:
        this.shareChannel();
        break;

      case EventsToEmit.SwipeItems:
        this.$emit('swipe-items');
        break;
    }
  }

  showSecretShareLinkModal () {
    if (!this.isAuthenticated) {
      return this.showLogin();
    }
    this.showSecretShareLink = true;
  }

  loadShuffleChannel () {
    if (!this.isAuthenticated) {
      return this.showLogin();
    }
    this.loadWhenOfItems = true;
  }

  navigateToEdit () {
    if (!this.isAuthenticated) {
      return this.showLogin();
    }

    if (!this.currentUserCanEditChannel || this.channel.archived) {
      return;
    }
    const redirect = {
      name: RouteNames.ROUTE_CHANNEL_EDIT,
      params: {
        channelSlug: this.channel.slug,
        username: this.currentUser.username,
      }
    };

    this.$router.push(redirect);
  }

  addItemToChannel () {
    if (!this.isAuthenticated) {
      return this.showLogin();
    }
    EventBus.$emit(
        EventBusEvents.ITEM_ADD,
        {
          editable: {
            channel: {
              name: this.channel.name,
              slug: this.channel.slug,
              channelType: this.channel.channelType
            },
            text: ''
          }
        } as MItemEntryTriggerItemEditClonePayload
    );
  }

  leaveChannel () {
    if (!this.isAuthenticated) {
      return this.showLogin();
    }
    this.$buefy.dialog.confirm({
      title: this.$t('channel.leaveChannel.title') as string,
      message: this.$t('channel.leaveChannel.message', {channelName: this.channel.name}) as string,
      onConfirm: () => {
        ChannelMemberService.channelMemberSlugLeavePatch({
          slug: this.channel.slug
        }).then(() => {
          this.$router.push({
            name: RouteNames.ROUTE_DASHBOARD
          });
        });
      }
    });
  }

  shareChannel () {
    let payload: ShareOrCopy = {
      text: this.channel.name,
      url: config.api.baseUrl + this.$route.fullPath,
      sharedFrom: SharedFrom.channelPage
    };
    shareOrCopy(payload);
  }

  channelNotifications (e) {
    if (!e) {
      return;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (e.target && e.target.classList.contains('channel-notifications')) {
      this.loadChannelNotificationsModal();
    }
  }

  loadChannelNotificationsModal () {
    if (!this.isAuthenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.CHANNEL_NOTIFICATIONS, { channel: this.channel } as OModalsContainerChannelAsPayload);
  }

  reportChannel () {
    if (!this.isAuthenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.CHANNEL_REPORT, { channel: this.channel } as OModalsContainerChannelAsPayload);
  }

  archiveChannel () {
    if (!this.isAuthenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.CHANNEL_ARCHIVE, { channel: this.channel } as OModalsContainerArchiveChannel);
  }

  async callJoinChannel () {
    if (!this.isAuthenticated) {
      return this.showLogin();
    }
    this.requestingToJoinLoading = true;
    await ChannelMemberService.channelMemberSlugJoinRequestPost({
      slug: this.channel.slug
    });
    this.requestSent = true;
    this.requestingToJoinLoading = false;
    this.showJoinChannelModal = false;
    window.location.reload();
  }

  openImageModal (e) {
    if (!e) {
      return;
    }
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (e.target) {
      const isMobileView: boolean = window.innerWidth <= 1050;
      if (
          (e.target.classList.contains('channel-info') && !isMobileView) ||
          e.target.classList.contains('channel-image-hero') ||
          e.target.classList.contains('channel-info-open')
      ) {
        EventBus.$emit(EventBusEvents.CHANNEL_INFO_SHOW, { channel: this.channel } as OModalsContainerChannelAsPayload);
      }
    }
  }
}
</script>
