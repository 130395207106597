<template>
  <div class="MComingSoonBanner">
    <div class="banner-content">
      <div class="text-container">
        <p class="headerp">Live on Product Hunt</p>
        <p class="">Come show your support  ❤️ <span class="pt-2" style="
            font-size: xxx-large;
            margin-left: 15px;
            margin-top: 5px;
            display: inline-block;">➪ ➪</span></p>
      </div>

      <div class="is-flex is-flex-direction-column">
        <a href="https://www.producthunt.com/posts/liffery?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-liffery"
           target="_blank"><img
            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=396233&theme=light&period=daily"
            alt="Liffery - Your&#0032;personal&#0032;research&#0032;assistant | Product Hunt"
            style="width: 250px; height: 54px;" width="250" height="54"/></a>

        <a href="https://www.producthunt.com/posts/liffery?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-liffery"
           target="_blank"><img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=396233&theme=light"
            alt="Liffery - Your&#0032;personal&#0032;research&#0032;assistant | Product Hunt"
            style="width: 250px; height: 54px;" width="250" height="54"/></a>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.MComingSoonBanner {
  padding: 1rem;
  border-bottom: solid .2rem #ff6154;
  background-color: var(--white-color);

  .banner-content {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;


    .text-container {
      max-width: 450px;
    }

    p.headerp {
      //color: var(--primary-color);
      font-size: 1.4rem;
      line-height: 1.5rem;
      font-weight: 600;

      @media screen and (max-width: 980px) {
        font-size: x-large;
      }

      @media screen and (max-width: 770px) {
        font-size: large;
      }
    }

    a img {
      padding: 0;
      margin: 0;
      display: inline-block;
    }
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MComingSoonBanner extends Vue {
}
</script>
