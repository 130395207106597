<template>
  <div class="search-wrapper">
    <a-content-category-dropdown
        v-if="selectedTab === searchBarTabs.explore"
        v-model="contentCategory"
    />

    <b-autocomplete
        v-if="[searchBarTabs.items, searchBarTabs.explore].includes(selectedTab)"
        v-model="search"
        ref="search"
        :data="getRecentItemsSearch"
        :open-on-focus="true"
        :placeholder="placeholder"
        :selectable-header="false"
        :class="selectedTab === searchBarTabs.explore ? 'explore-tab-true' : ''"
        v-on:input="handleSearch"
    >
      <template #header>
        <div class="is-flex is-justify-content-space-between">
          <span class="font-bold">{{ $t('searchRecent.header') }}</span>
          <span v-if="getRecentItemsSearch.length"
                @click="headerSearchCLicked" class="is-clickable">
              <x-icon size="1.4x"/>
          </span>
        </div>
      </template>
      <template #empty>
        <span>{{ $t('searchRecent.headerNoResults') }}</span>
      </template>
    </b-autocomplete>

    <b-input
        v-else
        ref="search"
        v-model="search"
        :placeholder="placeholder"
        type="search"
        v-on:input="handleSearch"
    />


    <button class="search-bar-icon filter-icon" @click="openFilters"
            v-if="[searchBarTabs.items, searchBarTabs.channels].includes(selectedTab)">
      <b-tooltip :active="selectedTab === searchBarTabs.channels">
        <template slot="content">
          <span v-if="getChannelsSearchSortBy === sortBy.CreatedAt">
          {{ $t('filters.orderItems.sortBy.age') }}
          </span>
          <span v-else-if="getChannelsSearchSortBy === sortBy.ItemLastAdded">
          {{ $t('filters.orderItems.sortBy.activity') }}
          </span>
          <span v-else>
          {{ $t('filters.orderItems.sortBy.name') }}
          </span>
        </template>
        <filter-icon
            size="1.8x"
            :class="{'colour-primary': getFilterSortCount}"
        />
        <sub
            v-if="getFilterSortCount > 0"
            @click="openFilters"
            class="search-bar-icon filter-counter font-sub2 colour-primary">
          x{{ getFilterSortCount }}
        </sub>
      </b-tooltip>
    </button>

    <search-icon
        v-if="showSearchIcon"
        class="search-bar-icon search-icon"
        :class="{'no-filter-icon': ![searchBarTabs.items, searchBarTabs.channels].includes(selectedTab)}"
        size="1.5x"
        @click="handleSearch"
    />

    <x-icon
        v-else
        class="search-bar-icon search-icon"
        :class="{'no-filter-icon': ![searchBarTabs.items, searchBarTabs.channels].includes(selectedTab)}"
        size="1.5x"
        @click="clearThenHandleSearch"
    />
  </div>
</template>

<style lang="scss" scoped>
.search-wrapper {
  position: relative;
}

::v-deep polygon {
  stroke: var(--black-color);
}

::v-deep .control input {
  border: unset;
  border-radius: var(--radius-big) !important;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .11);
  box-shadow: 0 2px 6px rgb(0 0 0 / 11%);

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

.search-bar-icon {
  position: absolute;
  top: 50%;
  cursor: pointer;
  transition: all .2s;
  transform: translateY(-50%);

  &:hover {
    stroke: var(--primary-color);
  }

  &.search-icon {
    right: 4.4rem;

    &.no-filter-icon {
      right: 1.2rem;
    }
  }

  &.filter-icon {
    right: 0;
    z-index: 1;
    height: 100%;
    padding: 0 3px;
    width: 3.5rem;
    cursor: pointer;
    border: unset;
    border-radius: 1rem;
    border-radius: var(--radius-big);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-style: solid;
    border-left-width: 2px;
    border-left-color: #dcdcdc;
    border-left-color: var(--grey-light-color);
    -webkit-box-shadow: 0 2px 6px rgb(0 0 0 / 11%);
    box-shadow: 0 2px 6px rgb(0 0 0 / 11%);

    &:hover ::v-deep polygon {
      stroke: var(--primary-color);
    }
  }

  &.filter-counter {
    right: 0.25rem;
    margin-top: 1rem;
    text-align: right;
  }
}

::v-deep .explore-tab-true input {
  padding-left: 6rem;

  @media screen and (max-width: 450px) {
    padding-left: 4rem;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FilterIcon, SearchIcon, SettingsIcon, XIcon } from 'vue-feather-icons';
import { SearchSettingsStore } from '@/store';
import { ASearchBarTabType } from '@/enums/SearchBarTabType';
import EventBus, { EventBusEvents } from '@/EventBus';
import ABadge from '@/components/atoms/ABadge.vue';
import AContentCategoryDropdown from '@/components/atoms/inputs/AContentCategoryDropdown.vue';
import { SortBy, SortDir } from '@/api/ms-channel/services/interfaces/ChannelsGetQuery';
import { channelsFilterSortDefaultsHashMap } from '../organisms/forms/OChannelsFilterForm.vue';
import { itemsFilterSortDefaultsHashMap } from '../organisms/forms/OItemsFilterForm.vue';
import countFilterSortsUsedInQuery from '@/utils/countFilterSortsUsedInQuery';

export interface MSearchInputOutput {
  search: string;
  contentCategory?: string;
}

@Component({
  components: {
    AContentCategoryDropdown,
    ABadge,
    FilterIcon,
    SearchIcon,
    SettingsIcon,
    XIcon
  }
})
export default class MSearchInput extends Vue {
  @Prop({ default: 'Search...' })
  placeholder!: string;

  @Prop({ default: ASearchBarTabType.items })
  selectedTab!: ASearchBarTabType;

  sortBy = SortBy;
  searchBarTabs = ASearchBarTabType;
  showSearchIcon = true;
  search: string = '';
  timeoutDebounce: any;
  timeoutDebounce2: any;

  eventBusId = 'MSearchInput';

  contentCategory = 'all';

  $refs!: {
    search: HTMLElement
  };

  get getItemFilterSortCount () {
    return countFilterSortsUsedInQuery(this.$route, itemsFilterSortDefaultsHashMap);
  }

  get getChannelFilterSortCount () {
    let num = countFilterSortsUsedInQuery(this.$route, channelsFilterSortDefaultsHashMap);
    // if sort by item last added, it will also be descending, remove 1 from the count as for the user they only added 1
    if(this.$route.query && this.$route.query.sortBy && this.$route.query.sortBy === SortBy.ItemLastAdded && this.$route.query.sortDir && this.$route.query.sortDir === SortDir.Desc){
      --num;
    }
    return num;
  }

  get getFilterSortCount () {
    return this.selectedTab === ASearchBarTabType.channels ? this.getChannelFilterSortCount : this.getItemFilterSortCount;
  }

  get getChannelsSearchSortBy () {
    return SearchSettingsStore.getChannelsSearchSettingsSortOnly.sortBy;
  }

  get getRecentItemsSearch () {
    return SearchSettingsStore.getItemsSearchRecents;
  }

  created () {
    this.setup(this.$route.query?.text as string);
    this.eventsBind();
    if (this.search.length > 0) {
      this.emitEvent('USER_ACTION');
    }
    if (this.$route.query.category && typeof this.$route.query.category === 'string') {
      this.contentCategory = this.$route.query.category;
    }
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  eventsBind () {
    EventBus.$on(EventBusEvents.HASH_TAG_CLICK, this.eventBusId, (payload: { tag: string }) => {
      this.setup(payload.tag);
      this.emitEvent('HASH_TAG_CLICK');
    });
  }

  eventsUnbind () {
    EventBus.$remove(EventBusEvents.HASH_TAG_CLICK, this.eventBusId);
  }

  setup (searchText: string = '') {
    this.search = searchText;
    this.toggleIcon();
  }

  handleSearch () {
    this.search = this.search.trim();
    if (this.timeoutDebounce) {
      clearTimeout(this.timeoutDebounce);
    }
    this.timeoutDebounce = setTimeout(
        () => this.emitEvent('USER_ACTION'),
        300
    );

    if (this.timeoutDebounce2) {
      clearTimeout(this.timeoutDebounce2);
    }
    this.timeoutDebounce2 = setTimeout(
        () => SearchSettingsStore.ITEMS_SEARCH_RECENT_ADD(this.search),
        3000
    );
  }

  headerSearchCLicked () {
    this.$buefy.dialog.confirm({
      title: this.$t('dict.areYouSure') as string,
      message: this.$t('searchRecent.clearConfirm') as string,
      onConfirm: () => {
        SearchSettingsStore.ITEMS_SEARCH_RECENT_CLEAR_ALL();
      }
    });
  }

  clearThenHandleSearch () {
    this.search = '';
    this.contentCategory = 'all';
    this.handleSearch();
  }

  emitEvent (eventName: 'USER_ACTION' | 'HASH_TAG_CLICK') {
    this.storeText(this.search);
    this.toggleIcon();
    const output: MSearchInputOutput = { search: this.search };
    if (this.selectedTab === ASearchBarTabType.explore) {
      output.contentCategory = this.contentCategory;
    }
    this.$emit(eventName, output);
  }



  storeText (text: string) {
    // SearchSettingsStore.CROSS_TAB_TEXT(text);
  }

  toggleIcon () {
    this.showSearchIcon = this.search.length === 0;
  }

  openFilters () {
    EventBus.$emit(
        this.selectedTab === ASearchBarTabType.channels ?
            EventBusEvents.CHANNEL_FILTERS_OPEN :
            EventBusEvents.ITEM_FILTERS_OPEN
    );
  }

  @Watch('contentCategory')
  searchByCategory () {
    this.emitEvent('USER_ACTION');
  }
}
</script>
