<template>
  <div class="ARouterLinkIssueDetail">
    <router-link :to="to" class="link-detail">
      <slot></slot>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.link-detail {
  display: flex;
  gap: 5px;
  align-items: center;
  max-width: 100%;
  color: currentColor;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';

@Component
export default class ARouterLinkIssueDetail extends Vue {
  @Prop({ required: true })
  uniqueItemName!: string;

  @Prop()
  goToTab!: string;

  @Prop({required: false})
  query?: {[key:string]: string};

  name = this.goToTab ? this.goToTab : RouteNames.ROUTE_ITEM_DETAIL_VIEW;

  get to () {
    const { name, uniqueItemName, query } = this;
    const to: { [key:string]: any } = {
      name,
      params: { uniqueItemName }
    };
    if( query ){
      to.query = query;
    }

    return to;
  }
}
</script>
