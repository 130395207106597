<template>
  <div class="OItemComments">
    <o-item-comment-form :show-comment-type-selector="showCommentTypeSelector" />
    <m-item-comment-list/>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import OItemCommentForm from '@/components/organisms/forms/OItemCommentForm.vue';
import MItemCommentList from '@/components/molecules/comment/MItemCommentList.vue';

@Component({
  components: { MItemCommentList, OItemCommentForm }
})
export default class OItemComments extends Vue {
  @Prop()
  showCommentTypeSelector!: boolean;
}
</script>
