<template>
  <div ref="container" class="OPinnedItemsContainer">

    <slot></slot>

    <!-- No results -->
    <div v-if="!items.busy && noItems" class="not-found-wrapper">
      <div>
        <template>
          <p class="title-7 not-found-message">
            <!-- No content but no filters or sort applied -->
            <template v-if="!hasSearchParams.content">
              <!-- DASHBOARD MESSAGE, [SHOW ADD ITEM] -->
              <template v-if="searchType === 'dashboard'">
                <m-no-items-empty-search/>
              </template>
              <!-- CHANNEL PAGE-->
              <template v-else-if="searchType === 'channel'">
                <span v-html="$t('containers.items.nonFound.channelPins')"/>
              </template>
              <!-- PROFILE PAGE, OWN OR OTHER-->
              <template v-else>
                <span v-if="isOwnProfile()" v-html="$t('containers.items.nonFound.profilePins')"/>
                <span v-else
                      v-html="$t('containers.items.nonFound.otherPersonsProfilePins', {firstName: userOtherMeta.firstName})"/>
              </template>
            </template>

            <template v-else-if="hasSearchParams.qs !== '' && hasSearchParams.filtersCount === 0">
            <span v-html="$t('containers.items.nonFound.fromSearch', {
                qs: hasSearchParams.qs,
                sortCount: hasSearchParams.filtersCount
              })"/>
            </template>
            <template v-else-if="hasSearchParams.qs !== '' && hasSearchParams.filtersCount !== 0">
            <span v-html="$t('containers.items.nonFound.fromSearchAndSort', {
                qs: hasSearchParams.qs,
                sortCount: hasSearchParams.filtersCount
              })"/>
            </template>
            <template v-else-if="hasSearchParams.qs === '' && hasSearchParams.filtersCount !== 0">
            <span v-html="$t('containers.items.nonFound.fromSort', {
              sortCount: hasSearchParams.filtersCount
            })"/>
            </template>
          </p>
        </template>
      </div>
    </div>

    <!-- Items grid -->
    <div v-else class="OPinnedItemsGrid">
      <div v-for="(item, index) in items.items"
           :key="index"
           class="item-card"
           :data-card-height="calculateItemCardHeight(item.data.item)"
           :style="{ minHeight: calculateItemCardHeight(item.data.item)}"
      >
        <template v-if="item.data.skeleton">
          <m-item-card-skeleton/>
        </template>
        <template v-else-if="item.data.phantom">
          <div class="phantom-card"></div>
        </template>
        <template v-else>
          <o-item-card
              :key="item.data.item.uniqueItemName"
              :currentUser="currentUser"
              :item="item.data.item"
              :search-type="searchType"
              :show-get-got-control="cardControls.showGetGotControl"
              v-on:item-request-update="(item) => $emit('item-request-update', item)"
          />
        </template>
      </div>

      <!-- Infinity loader triggers the load more -->
      <infinite-loading v-if="!items.busy && firstResizeComplete && !noMoreResults"
                        @infinite="scrollBottom"></infinite-loading>
    </div>
  </div>
</template>

<style scoped lang="scss">
.OPinnedItemsContainer {
  margin-bottom: 3rem;

  .OPinnedItemsGrid {
    display: grid;
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 400px));
    grid-auto-rows: 1px;
    justify-content: center;

    .item-card {
      width: 400px;

      @media screen and (max-width: 450px) {
        width: 90%;
        margin-left: 5%;
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PinnedItemsStoreItemsComponent } from '@/store/modules/interfaces/PinnedItemsModule';
import { AuthenticationStore, PinnedItemsStore } from '@/store';
import { SearchType } from '@/store/modules/enums/SearchType';
import { User } from '@/api/ms-authentication/services/interfaces';
import MNoItemsEmptySearch from '@/components/molecules/MNoItemsEmptySearch.vue';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import AToggleChannelView from '@/components/atoms/buttons/AToggleChannelView.vue';
import OItemCard from '@/components/organisms/OItemCard.vue';
import MItemCardSkeleton from '@/components/molecules/skeletons/MItemCardSkeleton.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { RefreshCwIcon, XIcon } from 'vue-feather-icons';
import calculateItemCardHeight from '@/utils/calculateItemCardHeight';
import vScrollHeightWidth from '@/utils/vScrollHeightWidth';
import countFilterSortsUsedInQuery from '@/utils/countFilterSortsUsedInQuery';
import { itemsFilterSortDefaultsHashMap } from './forms/OItemsFilterForm.vue';

@Component({
  components: {
    MNoItemsEmptySearch,
    MButtonWithIcon,
    AToggleChannelView,
    OItemCard,
    MItemCardSkeleton,
    InfiniteLoading,
    XIcon,
    RefreshCwIcon
  }
})
export default class OPinnedItemsContainer extends Vue {
  @Prop({
    default: () => {
      return {};
    }
  })
  cardControls!: {
    showGetGotControl?: boolean
  };

  @Prop({ required: true })
  searchType!: SearchType;

  @Prop()
  username!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ required: false, default: false })
  asAnon!: boolean;

  $refs!: {
    container: any
  };

  search: string = '';
  resizeGridTimeout: any;
  firstResizeComplete: boolean = false;
  noMoreResults: boolean = false;

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get userOtherMeta () {
    return AuthenticationStore.userMetaOtherData;
  }

  get items (): PinnedItemsStoreItemsComponent {
    return PinnedItemsStore.getPinnedItems;
  }

  get noItems (): boolean {
    if (!this.items.items.length) {
      return true;
    }
    if (this.items.items.length === PinnedItemsStore.getColumnCount) {
      let pCount = 0;
      this.items.items.forEach(item => {
        if (item.data.phantom) {
          ++pCount;
        }
      });
      if (pCount === PinnedItemsStore.getColumnCount) {
        return true;
      }
    }
    return false;
  }

  //todo - does this need to be made specific for pinned items?
  get hasSearchParams (): {
    content: boolean,
    filtersCount: number,
    qs: string
  } {
    return {
      content: this.getItemFiltersSortCount > 0 || this.search !== '',
      filtersCount: this.getItemFiltersSortCount,
      qs: this.search
    };
  }

  get getItemFiltersSortCount () {
    return countFilterSortsUsedInQuery(this.$route, itemsFilterSortDefaultsHashMap);
  }

  get newItemsCount (): number {
    return PinnedItemsStore.getNewItemsFetchedCount;
  }

  calculateItemCardHeight (item) {
    const { width, minDivisionValue } = vScrollHeightWidth(true);
    const cardWidth = width >= minDivisionValue ? 400 : width * .9;

    return calculateItemCardHeight(
      item,
      cardWidth,
      this.asAnon
    );
  }

  eventsBind () {
    window.addEventListener('resize', this.itemsChanged);
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.itemsChanged);
  }

  created () {
    this.setSearch(this.$route.query?.text as string);
    this.eventsBind();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  setSearch (searchText: string = '') {
    this.search = searchText;
  }

  //Watcher on items to resize the grid whenever new items appear, and to disable the infinity scroller when all results are loaded
  @Watch('items', { immediate: true, deep: true })
  itemsChanged () {
    clearTimeout(this.resizeGridTimeout);
    this.resizeGridTimeout = setTimeout(this.resizeAllGridItems, 100);
    this.noMoreResults = this.items.noMoreResults;
  }

  resizeGridItem (item) {
    const grid = document.getElementsByClassName('OPinnedItemsGrid')[0];
    const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    const cardHeight = parseInt(item.dataset.cardHeight);
    const rowSpan = Math.ceil((cardHeight + rowGap + 32) / (rowHeight + rowGap));
    item.style.gridRowEnd = 'span ' + rowSpan;
  }

  resizeAllGridItems () {
    const allItems = document.querySelectorAll('.OPinnedItemsGrid > div:not(.infinite-loading-container)');
    for (let i = 0; i < allItems.length; i++) {
      this.resizeGridItem(allItems[i]);
    }
    //boolean value used to ensure the infinity scroller doesn't get triggered before the first resize has finished
    this.firstResizeComplete = true;
  }

  scrollBottom () {
    PinnedItemsStore.paginate({ searchType: this.searchType });
  }

  isOwnProfile (): boolean {
    //if a username isn't found in the url you can only be looking at your own profile
    if (this.$route.path === '/-/your-profile/') {
      return true;
    }
    return this.currentUser.username === this.$route.params.username;
  }
}
</script>
