<template>
  <div class="MNotificationHeader">
    <b-dropdown
        v-if="authenticated"
        :max-height="maxHeight"
        :mobile-modal="false"
        :scrollable="true"
        aria-role="menu"
        class="header-dropdown"
        position="is-bottom-left"
        v-on:active-change="dropdownClick"
    >
      <m-notification-bell
          slot="trigger"
          :count="getUnreadNotifications"
      />

      <b-dropdown-item
          v-if="!notifications.length"
          aria-role="listitem"
          class="notification-header"
      >
        <span>{{ $t('notificationHeaderMsg.noNewNotifications') }}</span>
      </b-dropdown-item>

      <template v-for="(notification, index) in notifications">
        <b-dropdown-item v-if="index < 7" v-bind:key="index" aria-role="listitem">
          <m-notification-item :notification="notification"/>
        </b-dropdown-item>
      </template>


      <b-dropdown-item class="notification-footer" has-link>
        <a-router-link-notifications></a-router-link-notifications>
      </b-dropdown-item>
    </b-dropdown>

    <div v-else>
      <m-notification-bell v-on:click="visible = !visible"/>
      <b-modal :active.sync="visible" aria-role="dialog" has-modal-card>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ $t('dict.welcome') }}</p>
            <a-close-modal-button v-on:child-output="visible = false"/>
          </header>
          <m-authentication-tabs></m-authentication-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.MNotificationHeader {
  display: flex;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 550px) {
    ::v-deep .is-bottom-left .dropdown-menu {
      right: -6rem;

      &::after {
        right: 5rem;
        left: unset;
      }
    }

    ::v-deep .dropdown-menu {
      width: 80%;
    }
  }
}

::v-deep .dropdown-menu {
  min-width: 21rem;
}

.dropdown-item {
  padding: 0 .8rem .5rem;
}

::v-deep .notification-line {
  border-top: 2px solid #f5f5f5;
}

::v-deep .has-link:first-child .notification-line {
  border-top-width: 0;
}

::v-deep .notification-footer {
  margin-top: 7px;
  margin-bottom: -8px;
  background-color: rgba(0, 0, 0, .03);
  text-align: right;

  &.has-link .notification-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: .8rem 1.2rem .8rem;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationStore, NotificationsStore } from '@/store';
import { Datum as Notification } from '@/api/ms-notification/services/interfaces/Notifications';

import ARouterLinkNotifications from '@/components/atoms/link/ARouterLinkNotifications.vue';
import MNotificationItem from '@/components/molecules/notification/MNotificationItem.vue';
import MNotificationBell from '@/components/molecules/notification/MNotificationBell.vue';
import MAuthenticationTabs from '@/components/molecules/MAuthenticationTabs.vue';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';

@Component({
  components: {
    ACloseModalButton,
    ARouterLinkNotifications,
    MNotificationItem,
    MNotificationBell,
    MAuthenticationTabs,
  },
})
export default class MNotificationHeader extends Vue {
  visible = false;
  maxHeight = 0;

  get notifications (): Notification[] {
    return NotificationsStore.getHeaderNotifications;
  }

  get getUnreadNotifications (): number {
    return NotificationsStore.getUnseenStateCount;
  }

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  created () {
    if (AuthenticationStore.getAuthenticated) {
      // there is no point in collecting notifications for the unauthenticated user
      NotificationsStore.getLatestHeader();
    }
    this.bindEvents();
  }

  mounted () {
    this.setMaxSize();
  }

  beforeDestroy () {
    this.unbindEvents();
  }

  unbindEvents () {
    window.addEventListener('resize', this.setMaxSize);
  }

  bindEvents () {
    window.addEventListener('resize', this.setMaxSize);
  }

  setMaxSize () {
    const navBar = document.querySelector('.TMainLayout .MHeader');
    if (navBar) {
      this.maxHeight = window.innerHeight - navBar.getBoundingClientRect().height - 15;
    }
  }

  dropdownClick (open: boolean) {
    if (!open) {
      // when the ddm closes we mark as read
      setTimeout(() => {
        NotificationsStore.markLatestHeaderAsRead();
      }, 500);
    }
  }
}
</script>
