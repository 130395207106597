<template>
  <section class="MModalGetGot">
    <b-loading :active.sync="loading" :is-full-page="true" :can-cancel="false"></b-loading>

    <template v-if="!loading">

      <div class="mt-2 mb-5 item-details">
        <label>
          <b>{{ getItemTitle | ellipsis }}</b>
        </label>
        <div class="mt-2 pl-4">
          <p>
            {{$tc('dict.added')}} {{ item.createdAt | formatDate(currentLanguage) }} {{ $tc('dict.in') }} "<b>{{ item.editable.channel.name }}</b>"
            {{ $tc('dict.byBlank') }} "<b>{{ userMeta.firstName }} {{ userMeta.lastName }}</b>"

          </p>
        </div>
      </div>

      <div v-if="item.getgot.length && step === 1">
        <label class="label">
          {{ $t('modals.shoppingListAdd.alreadyOnOtherLists') }}
        </label>
        <o-item-lists class="pt-6 pl-6 pr-6" :item="item"/>
      </div>
      <div v-else>
        <label class="label">
          {{ $t('modals.shoppingListAdd.forWhoLabel') }}
        </label>

        <div class="pl-4">
          <b-radio v-model="isForMyself"
                   :native-value="true"
                   @input="handleIsForMyself">
            {{ $t('modals.shoppingListAdd.forMyself') }}
          </b-radio>
          <br/>
          <b-radio
              v-model="isForMyself"
              :native-value="false">
            {{ $t('modals.shoppingListAdd.forSomebodyElse') }}
          </b-radio>
        </div>


        <div class="user-select-container mb-5 pl-4">
          <b-autocomplete
              :value="`${selectedConnection.firstName} ${selectedConnection.lastName}`"
              :data="connections"
              :placeholder="$t('searchPlaceholder.people')"
              :custom-formatter="() => `${selectedConnection.firstName} ${selectedConnection.lastName}`"
              :loading="searchLoading"
              @typing="searchPeople"
              @select="option => selectedConnection = option"
              class="user-select"
              :disabled="isForMyself"
          >
            <template slot-scope="props">
              <div class="default-options">
                <a-image
                    :image-path="`/user/profile-pic/${props.option.username}.png`"
                    :size="userImageSize"
                    :alt-text="props.option.firstName"
                    class="select-image"
                />
                {{ props.option.firstName }} {{ props.option.lastName }}
              </div>
            </template>
          </b-autocomplete>
        </div>

        <div class="secrecyLevel">
          <label class="label" :class="{ 'disabled': isForMyself }">
            {{ $t('modals.shoppingListAdd.isSecretLabel') }}
          </label>
          <div class="pl-4">
            <b-radio v-model="isPrivate" :disabled="isForMyself"
                     :native-value="true">
              {{ $t('modals.shoppingListAdd.isSecretYes') }}
            </b-radio>
            <b-radio v-model="isPrivate" :disabled="isForMyself"
                     :native-value="false">
              {{ $t('modals.shoppingListAdd.isSecretNo') }}
            </b-radio>
          </div>
        </div>
      </div>

      <div class="modal-card-footer">
        <template v-if="item.getgot.length && step === 1">
          <b-button
              class="is-primary is-outlined"
              @click="$emit('close-modal')">
            {{ $t('dict.cancel') }}
          </b-button>
          <b-button
              class="is-primary btn-add"
              @click="++step">
            {{ $t('dict.continue') }}
          </b-button>
        </template>
        <template v-else>
          <b-button
              v-if="item.getgot.length"
              class="is-primary is-outlined"
              @click="--step">
            {{ $t('dict.back') }}
          </b-button>
          <b-button
              v-else
              class="is-primary is-outlined"
              @click="$emit('close-modal')">
            {{ $t('dict.cancel') }}
          </b-button>
          <b-button
              class="is-primary btn-add"
              @click="shoppingListAdd">
            {{ $t('dict.add') }}
          </b-button>
        </template>
      </div>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.item-details {
  a {
    color: unset;
  }
}

.secrecyLevel {
  .label.disabled {
    color: var(--grey-dark-color);
  }
}

.user-select-container {
  padding-left: 1.875rem;
}

.search-placeholder {
  padding-left: 10px;
}

.autocomplete ::v-deep .input {
  height: 2.5rem;
}

.modal-card-footer {
  margin-top: 2rem;
  text-align: center;
}

.btn-add {
  margin-left: 1rem;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import { ItemActor } from '@/api/ms-item/services/interfaces/Item';
import { Datum as ConnectionsDatum } from '@/api/ms-authentication/services/interfaces/Connections';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import ConnectionsService from '@/api/ms-authentication/services/ConnectionsService';

import AImage from '@/components/atoms/AImage.vue';
import { AuthenticationStore, ShoppingListStore } from '@/store';
import ItemService from '@/api/ms-item/services/ItemService';
import { StateName } from '@/api/ms-item/services/interfaces/ItemGetgotPost';
import updateItemInStores from '@/utils/updateItemInStores';
import UserService from '@/api/ms-authentication/services/UserService';
import { State } from '@/api/ms-authentication/services/interfaces/Connection';
import OItemLists from '@/components/organisms/OItemLists.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import { UserMeta } from '@/api/ms-authentication/services/interfaces';
import ellipsisString from '@/utils/ellipsisString';
import { format } from 'timeago.js';
import { translation } from '@/plugins/i18n/Translation';

@Component({
  components: {
    OItemLists,
    AImage
  },
  filters: {
    ellipsis: (input: string, limit = 50): string => {
      return ellipsisString(input, limit);
    },
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
  }
})
export default class MModalShoppingList extends Vue {
  @Prop({ required: true })
  item!: Item;

  userMeta: UserMeta = {
    channelMemberOfCount: 0,
    channelsOwnedCount: 0,
    connectionCount: 0,
    firstName: '',
    lastName: '',
    shoppingListCountNotGot: 0,
    username: ''
  };
  step: number = 1;
  connections: ConnectionsDatum[] = [];
  selectedConnection!: ItemActor;
  isForMyself: boolean = false;
  isPrivate: boolean = false;

  userImageSize = Size.The32X32;
  loading: boolean = false;
  snackBarTime: number = 5000;
  searchLoading: boolean = false;

  callerId = 'MModalShoppingList';

  get currentUser () {
    return AuthenticationStore.user;
  }

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get getUrl (): string | undefined {
    return this.item.urlCache?.url || undefined;
  }

  get getItemTitle (): string | undefined {
    if (this.item.urlCache && this.item.urlCache.meta && this.item.urlCache?.meta?.title) {
      return this.item.urlCache.meta.title;
    }
    if (this.item.editable.text.length) {
      return this.item.editable.text;
    }
    return 'Only has a picture...';
  }

  async created () {
    this.eventsBind();
    this.loading = true;

    if (this.item.actor.username === this.currentUser.username) {
      this.selectedConnection = { ...this.item.actor };
      this.isForMyself = true;
    } else {
      try {
        const state = await UserService.userUsernameIsConnectedToCurrentUserGet({
          username: this.item.actor.username
        });

        if (![State.RequestReceivedAccepted, State.RequestSentAccepted].includes(state.state)) {
          this.selectedConnection = {
            firstName: '',
            lastName: '',
            username: '',
          };
        } else {
          this.selectedConnection = { ...this.item.actor };
        }

        await this.itemOwnerDetailsFetch();
      } catch (e: any) {
        console.error(e);
        if (e.request.status === 403) {
          this.selectedConnection = { ...this.currentUser };
          this.isForMyself = true;
        }
      }
    }

    this.loading = false;
  }

  async itemOwnerDetailsFetch () {
    this.userMeta = await UserService.userUsernameGet({
      username: this.item.actor.username
    });
  }

  eventsBind () {
    const { callerId } = this;
    EventBus.$on(EventBusEvents.SHOPPING_LIST_MODAL_SUBMIT, callerId, this.shoppingListAdd);
  }

  eventsUnbind () {
    const { callerId } = this;
    EventBus.$remove(EventBusEvents.SHOPPING_LIST_MODAL_SUBMIT, callerId);
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  async searchPeople (qs: string) {
    if (qs.length >= 2) {
      this.searchLoading = true;

      const { data } = await ConnectionsService.connectionsGet({ qs });
      this.connections = data.map((payload: ConnectionsDatum) => {
        return payload;
      });

      this.searchLoading = false;
    }
  }

  handleSelection () {
    this.connections = [];
  }

  handleIsForMyself () {
    if (this.isForMyself) {
      this.selectedConnection = { ...this.currentUser };
    } else {
      this.selectedConnection = { ...this.item.actor };
    }
  }

  async shoppingListAdd () {
    this.loading = true;

    try {
      const newItem = await ItemService.itemNameUniqueItemNameGetgotPost({
        private: this.isPrivate,
        stateName: StateName.StageGet,
        forActor: {
          username: this.selectedConnection.username,
          firstName: this.selectedConnection.firstName,
          lastName: this.selectedConnection.lastName,
        }
      }, {
        uniqueItemName: this.item.uniqueItemName
      });

      await ShoppingListStore.fetchShoppingListToGet();
      updateItemInStores(newItem);
      this.loading = false;
      this.$emit('close-modal');
    } catch (e: any) {
      console.error(e);
      this.loading = false;
      // TODO: catch the error and display to the user
      this.$buefy.toast.open({
        message: this.$t('dict.getGotSnackBarMessageAddedError').toString(),
        queue: false,
        type: 'is-danger',
        duration: this.snackBarTime,
      });
      this.$emit('close-modal');
    }
  }
}
</script>
