<template>
  <div class="MFooterGeneralSidebar font-body-l pt-6 pr-auto pl-auto">
    <h4 class="pl-6 pt-6">Navigate:</h4>
    <ul class="pl-6 pt-6">
      <li v-for="(link, index) in footerLinks" :key="index" class="pt-5 pb-5">
        <a-link v-if="link.link"
                :link="link.link"
                :key="index"
        >- {{ $t(link.textKey) }}</a-link>
        <router-link
            v-else
            :to="{ name: link.name }"
            @click.native="$emit('close')"
        >
          <span
              :class="{'link-active font-bold': link.activeAlsoWith.concat([link.name]).includes($route.name)}">
            - {{ $t(link.textKey) }}
          </span>
        </router-link>

      </li>

      <li class="mt-6 pt-6 pb-5">
        <m-language-switcher/>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.MFooterGeneralSidebar {
  height: 100vh;
  border-left: solid 1px var(--primary-color);

  ::v-deep a {
    color: rgb(74, 74, 74) !important;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import ALink from '@/components/atoms/link/ALink.vue';

@Component({
  components: { ALink, MLanguageSwitcher }
})
export default class MFooterGeneralSidebar extends Vue {
  @Prop({ required: true })
  footerLinks!: any[];
}
</script>
