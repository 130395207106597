<template>
  <div class="OItemsFilterForm">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form
          ref="form"
          class="form"
          novalidate
          @submit.prevent="handleSubmit(applyFilters)"
      >
        <div class="mb-4 fon">
          <p class="font-body-l mb-4">{{ $t('dict.filterBy') }}</p>
          <b-field :label="$t('dict.comments')">
            <b-select v-model="form.filterBy.comments">
              <option :value="allWithWithoutEnum.All">{{ $t('dict.all') }}</option>
              <option :value="allWithWithoutEnum.With">{{ $t('filters.withComments') }}</option>
              <option :value="allWithWithoutEnum.Without">{{ $t('filters.withoutComments') }}</option>
            </b-select>
          </b-field>

          <b-field :label="$t('dict.images')">
            <b-select v-model="form.filterBy.images">
              <option :value="allWithWithoutEnum.All">{{ $t('dict.all') }}</option>
              <option :value="allWithWithoutEnum.With">{{ $t('filters.withImages') }}</option>
              <option :value="allWithWithoutEnum.Without">{{ $t('filters.withoutImages') }}</option>
            </b-select>
          </b-field>

          <b-field :label="$t('dict.likes')">
            <b-select v-model="form.filterBy.likes">
              <option :value="allWithWithoutEnum.All">{{ $t('dict.all') }}</option>
              <option :value="allWithWithoutEnum.With">{{ $t('filters.withLikes') }}</option>
              <option :value="allWithWithoutEnum.Without">{{ $t('filters.withoutLikes') }}</option>
            </b-select>
          </b-field>
        </div>

        <div class="mb-4">
          <p class="font-body-l mb-4">{{ $t('filters.sortOrder') }}</p>
          <b-field>
            <b-radio v-model="form.sortBy" :native-value="sortByEnum.CreatedAt" name="sort">
              {{ $t('filters.sortAge') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.sortBy" :native-value="sortByEnum.CommentCount" name="sort">
              {{ $t('filters.sortCommentCount') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.sortBy" :native-value="sortByEnum.LikeCount" name="sort">
              {{ $t('filters.sortLikeCount') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.sortBy" :native-value="sortByEnum.SwipeScore" name="sort">
              {{ $t('dict.swipeScore') }}
            </b-radio>
          </b-field>
        </div>

        <b-button :loading="resetting" class="is-outlined mr-2" type="is-primary" @click="reset">
          {{ $t('filters.clearAll') }}
        </b-button>
        <a-button-submit :loading="applying">
          {{ $t('filters.applyAndClose') }}
        </a-button-submit>
      </form>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
.OItemsFilterForm {
  ::v-deep .field {
    padding-left: 1rem;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import { ItemsGetQuery } from '@/api/ms-item/services/interfaces';
import { SortBy, SortDir, With } from '@/api/ms-item/services/interfaces/ItemsGetQuery';
import clone from '@/utils/clone';
import { pause } from 'common-utils/time';
import itemsSearchQuery from '@/utils/itemsSearchQuery';

enum AllWithWithoutEnum {
  All = 'all',
  With = 'with',
  Without = 'without'
}

interface OItemsFilterFormFilterBy {
  comments: AllWithWithoutEnum,
  likes: AllWithWithoutEnum,
  images: AllWithWithoutEnum,
}

export interface OItemsFilterFormForm {
  filterBy: OItemsFilterFormFilterBy,
  sortBy: SortBy
}

export const itemsFilterSortDefaultsHashMap: ItemsGetQuery = {
  sortBy: SortBy.CreatedAt,
  sortDir: SortDir.Desc,
  withComments: undefined,
  withImage: undefined,
  withLikes: undefined
};

const defaultForm: OItemsFilterFormForm = {
  filterBy: {
    comments: AllWithWithoutEnum.All,
    likes: AllWithWithoutEnum.All,
    images: AllWithWithoutEnum.All,
  },
  sortBy: SortBy.CreatedAt
};

@Component({
  components: {
    AButtonSubmit,
    ValidationObserver
  }
})
export default class OItemsFilterForm extends Vue {

  form: OItemsFilterFormForm = clone(defaultForm);

  allWithWithoutEnum = AllWithWithoutEnum;
  sortByEnum = SortBy;

  applying = false;
  resetting = false;

  created () {
    const searchQuery = itemsSearchQuery(this.$route);
    if (searchQuery.withImage) {
      this.form.filterBy.images = searchQuery.withImage === With.With ? AllWithWithoutEnum.With : AllWithWithoutEnum.Without;
    }
    if (searchQuery.withLikes) {
      this.form.filterBy.likes = searchQuery.withLikes === With.With ? AllWithWithoutEnum.With : AllWithWithoutEnum.Without;
    }
    if (searchQuery.withComments) {
      this.form.filterBy.comments = searchQuery.withComments === With.With ? AllWithWithoutEnum.With : AllWithWithoutEnum.Without;
    }
    if (searchQuery.sortBy) {
      this.form.sortBy = searchQuery.sortBy;
    }
  }

  async reset () {
    this.resetting = true;
    this.form = clone(defaultForm);
    await this.submit(true);
  }

  async applyFilters () {
    this.applying = true;
    await this.submit();
  }

  // eslint-disable-next-line max-lines-per-function
  async submit (clear = false) {
    const urlParams: ItemsGetQuery = {};
    // set query here and measure length, then delete as needed
    let query: any = { ...this.$route.query };
    if (clear) {
      query.clear = true;
    } else {
      delete query.clear;
    }
    const existingQueryLength = Object.keys(this.$route.query).length;
    const { sortBy, filterBy } = this.form;
    urlParams.sortBy = sortBy ? sortBy : SortBy.CreatedAt;
    for (const filterByKey in filterBy) {
      switch (filterByKey) {
        case 'comments':
          if (filterBy[filterByKey] === AllWithWithoutEnum.All) {
            delete query.withComments;
          } else {
            urlParams.withComments = filterBy.comments as unknown as With;
          }
          break;
        case 'images':
          if (filterBy[filterByKey] === AllWithWithoutEnum.All) {
            delete query.withImage;
          } else {
            urlParams.withImage = filterBy.images as unknown as With;
          }
          break;
        case 'likes':
          if (filterBy[filterByKey] === AllWithWithoutEnum.All) {
            delete query.withLikes;
          } else {
            urlParams.withLikes = filterBy.likes as unknown as With;
          }
          break;
      }
    }

    // merge the params into the query, then delete any default settings so the URL can be shorter where possible
    query = { ...query, ...urlParams };
    const keys = Object.keys(query);
    for (let i = 0; i < keys.length; i++) {
      if (query[keys[i]] === itemsFilterSortDefaultsHashMap[keys[i]]) {
        delete query[keys[i]];
      }
    }
    // length comparison of old query and new is first method to compare if changes
    let identical = existingQueryLength === Object.keys(query).length;
    // if length is the same, do a deeper comparison
    if (identical) {
      // after deletions, ensure the query params are not completely identical before pushing (i.e. someone hit save without any changes)
      const filteredKeys = Object.keys(query);
      for (let i = 0; i < filteredKeys.length; i++) {
        if (!this.$route.query[filteredKeys[i]] || this.$route.query[filteredKeys[i]] !== query[filteredKeys[i]]) {
          identical = false;
          break;
        }
      }
    }
    // if differences, push to route and emit out
    if (!identical) {
      await this.$router.push({ query });
      // emit picked up in TProfile, TChannel, and TDashboard which will reload the items respectively
      EventBus.$emit(EventBusEvents.ITEM_FILTERS_APPLY);
    }

    await pause(300);
    this.$emit('close');
  }
}
</script>
