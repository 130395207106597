<template>
  <router-link :to="{ name }" class="menu-profile-item">
    <globe-icon size="1x" class="header-menuitem-icon" />
    <span>
      {{ $t('dict.language') }}
    </span>
  </router-link>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { RouteNames } from '@/router/RouteNames';
  import { GlobeIcon } from 'vue-feather-icons';

  @Component({
    components: {
      GlobeIcon
    }
  })
  export default class ARouterLinkLanguage extends Vue {
    @Prop(Boolean)
    hasIcon!: boolean;

    name = RouteNames.ROUTE_SETTINGS_GENERAL;
  }
</script>
