<template>
  <div class="MSelectLanguageModal">
    <b-loading v-model="loading" />
    <b-field>
      <b-select v-model="languageForm" class="default-select">
        <option
            v-for="option in languages"
            :key="option.value"
            :placeholder="$t('dict.language')"
            :value="option.value">
          {{ option.label }}
        </option>
      </b-select>
    </b-field>

    <div class="mt-4 has-text-right">
      <b-button
          class="is-danger is-outlined mr-2"
          @click="close"
      >
        {{ $t('dict.close') }}
      </b-button>
      <b-button
        class="is-primary"
        @click="selectLanguage"
      >
        {{ $t('dict.save') }}
      </b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.MSelectLanguageModal {
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Language } from '@/api/ms-authentication/services/interfaces/UserPut';
import { AuthenticationStore } from '@/store';
import { translation } from '@/plugins/i18n/Translation';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import setLanguage from '@/utils/setLanguage';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';

@Component({
  components: { MLanguageSwitcher }
})
export default class MSelectLanguageModal extends Vue {
  languages: { label: TranslateResult; value: string; }[] = [];
  languageForm: Language = Language.EnGB;
  loading = false;

  get language () {
    return translation.currentLanguage;
  }

  get supportedLanguages () {
    return translation.supportedLanguages;
  }

  created () {
    this.languageForm = AuthenticationStore.currentUser.language;
    this.getSupportedLanguages();
  }

  getSupportedLanguages () {
    this.languages = this.supportedLanguages.map(lang => {
      return { label: this.$t('language.' + lang), value: lang };
    });
  }

  async selectLanguage () {
    this.loading = true;
    await setLanguage(this.languageForm);
    this.loading = false;
  }

  close () {
    this.$emit('close');
  }
}
</script>
