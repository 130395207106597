<template>
  <div class="OChannelItemsMap">
    <section ref="mapContainer" class="map-container"></section>
    <div v-if="zeroItems" class="noItemsOverlay"></div>
    <div v-if="zeroItems" class="noItemsContainer">
      <img
          src="@/assets/svg/not-found.svg"
          class="not-found-image"
          :alt="$t('dict.notFoundGeneral')"
      />
      <div class="not-found-message-container">
        <p class="not-found-message">
          {{ $t('dict.notFoundMessageItemsGeolocation') }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'OChannelItemsMap';
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import injectGoogleMapScript from '@/utils/injectGoogleMapScript';
import { Item } from '@/api/ms-item/services/interfaces';
import ItemsService from '@/api/ms-item/services/ItemsService';
import OItemCard from './OItemCard.vue';
import { i18n } from '@/plugins/i18n';
import router from '@/router';
import { waitTillElementIsVisible } from '@/utils/waitTillElementIsVisible';
import AFetchFromLocationButton from '@/components/atoms/buttons/AFetchFromLocationButton.vue';
import OItemCardMapView from '@/components/organisms/OItemCardMapView.vue';
import AResetMapClearSearchButton from '@/components/atoms/buttons/AResetMapClearSearchButton.vue';
import scrollWindowToElement from '@/utils/scrollWindowToElement';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component({
  components: {
    OItemCard
  },
})
export default class OChannelItemsMap extends Vue {
  @Prop({ required: true })
  channel!: string;

  @Prop({ default: () => {} })
  mapOptions?: any;

  @Prop({ default: () => [] })
  mapStyles?: any[];

  @Prop({ default: true })
  scrollIntoViewOnload!: boolean;

  @Prop({ default: true })
  showFetchItemsButton!: boolean;

  @Prop()
  itemUniqueNameOpen?: string;

  @Prop({ default: 2 })
  itemUniqueNameOpenDelayMSec!: number;

  @Prop({ default: true })
  addMapCoordsToUrl!: boolean;

  @Prop({ default: false })
  hideFetchMoreButtom!: boolean;

  offset: number = 0;
  limit: number = 50;
  initialLoadComplete = false;

  firstLoad = true;
  map: any;
  mapInitialBoundsChanged = false;
  mapInitialZoomTriggered = false;
  markers: any[] = [];
  repositionMapNotice: any = false;
  loadedWithLatLng = false;
  icon: {
    url: string
  } = {
    url: '/img/icons/MapPinBlackGreenDotWhiteBorderThin30x34.png'
  };
  mapCenter: {
    lat: number,
    lng: number
  } = {
    lat: 0,
    lng: 0
  };
  fullscreenMode: boolean = false;

  infoWindow: any;

  isMobileView: boolean = false;
  mobileWidthBreakpoint: number = 800;

  zeroItems = false;
  moreResultsToLoad = true;
  fetchFromLocationButton: any = false;
  fetchFromLocationButtonSettings: {
    disabled: boolean,
    size?: string,
    loading: boolean,
    buttonText: string,
    buttonClasses: string,
    tooltipLabel: string
  } = {
    disabled: true,
    loading: false,
    buttonText: 'Fetch from Location',
    buttonClasses: 'button is-primary fetchMoreButton',
    tooltipLabel: ''
  };
  resetButton?: HTMLElement;
  resetButtonTextOptions = {
    resetMap: this.$t('item.mapView.noMarkersVisible') as string,
    clearSearch: this.$t('item.mapView.clearSearch') as string
  };
  resetButtonSettings: {
    display: boolean,
    loading: boolean,
    buttonText: string,
    buttonClasses: string
  } = {
    display: true,
    loading: false,
    buttonText: this.resetButtonTextOptions.resetMap,
    buttonClasses: 'button is-primary is-outlined resetViewButton',
  };
  tooltipLabels: {
    loadMore: string,
    nonToLoad: string,
    moveToActivate: string
  } = {
    loadMore: this.$t('item.mapView.fetchFromLocationButton.tooltip.loadMore') as string,
    nonToLoad: this.$t('item.mapView.fetchFromLocationButton.tooltip.nonToLoad') as string,
    moveToActivate: this.$t('item.mapView.fetchFromLocationButton.tooltip.moveToActivate') as string
  };
  clearSearchParameters = {
    title: this.$t('item.mapView.clearSearchParameters.title') as string,
    text: this.$t('item.mapView.clearSearchParameters.text') as string,
    cancelText: this.$t('item.mapView.clearSearchParameters.cancelText') as string,
    confirmText: this.$t('item.mapView.clearSearchParameters.confirmText') as string
  };

  items: Item[] = [];
  itemToView: any;

  $refs!: {
    mapContainer: HTMLElement
  };

  windowWidth: number = 0;
  windowHeight: number = 0;

  get searchText () {
    const text = (this.$route.query && this.$route.query.text) ? this.$route.query.text as string : '';
    return typeof text !== 'undefined' ? text : '';
  }

  created () {
    this.eventsBind();
    this.windowResizeFunction();
  }

  eventsBind (): void {
    window.addEventListener('resize', this.windowResizeFunction);
    EventBus.$on(EventBusEvents.ITEM_EDITED, 'OChannelItemsMap', (item) => {
      this.handleItemEdit(item);
    });
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.windowResizeFunction);
    EventBus.$remove(EventBusEvents.ITEM_EDITED, 'OChannelItemsMap');
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  async mounted () {
    await injectGoogleMapScript();
    if (!this.$route.query.lat) {
      await this.initialLoadAsyncData();
    } else {
      //if we have lat/lng run a fetch more which uses those coordinates instead
      this.mapCenter.lat = Number(this.$route.query.lat);
      this.mapCenter.lng = Number(this.$route.query.lng);
      //there is infinity scroll left/right on maps, reduce/increase the longitude number if number is out of bounds
      while (this.mapCenter.lng > 180) {
        this.mapCenter.lng -= 360;
      }
      while (this.mapCenter.lng < -180) {
        this.mapCenter.lng += 360;
      }
      this.initialLoadComplete = true;
      this.moreResultsToLoad = true;
      this.loadedWithLatLng = true;
      await this.fetchMoreAsyncData();
    }
    this.loadMap();
    if (this.scrollIntoViewOnload) {
      scrollWindowToElement('smooth', this.$refs.mapContainer, true);
    }
  }

  @Watch('searchText')
  searchTextHandle () {
    this.initialLoadComplete = true;
    this.moreResultsToLoad = true;
    this.loadedWithLatLng = true;
    this.fetchMoreAsyncData();
  }

  windowResizeFunction () {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.isMobileView = this.windowWidth < this.mobileWidthBreakpoint;
  }

  async initialLoadAsyncData () {
    if (this.initialLoadComplete) {
      return;
    }
    const items = await ItemsService.itemsByGeolocationGet({
      offset: this.offset,
      channel: this.channel,
      limitGeo: this.limit,
      text: this.searchText
    });

    this.items = items.data;
    this.initialLoadComplete = true;

    if (!this.items.length) {
      this.zeroItems = true;
      return;
    }

    this.setFetchMoreButtonStatus();
  }

  async fetchMoreAsyncData () {
    if (!this.initialLoadComplete || !this.moreResultsToLoad) {
      return;
    }

    const thisIsInitialLoad = !this.markers.length;
    this.clearAllMarkers();

    this.fetchFromLocationButtonSettings.loading = true;

    const items = await ItemsService.itemsByGeolocationByMapViewGet({
      offset: this.offset,
      channel: this.channel,
      text: this.searchText,
      limitGeo: this.limit,
      lat: this.mapCenter.lat,
      lng: this.mapCenter.lng
    });

    this.items = items.data;
    this.resetButtonSettings.buttonText = this.items.length ? this.resetButtonTextOptions.resetMap : this.resetButtonTextOptions.clearSearch;

    //if this isn't being run as the first load of markers, then make sure to add the new markers to the map
    //if this is the first load of markers then loadMap() will add them, so don't duplicate by running here as well
    if (!thisIsInitialLoad) {
      this.addItemMarkersToMap();
    }

    this.fetchFromLocationButtonSettings.loading = false;
    this.fetchFromLocationButtonSettings.disabled = true;
    this.fetchFromLocationButtonSettings.tooltipLabel = this.tooltipLabels.moveToActivate;
    this.checkIfMarkersVisible();

    this.setFetchMoreButtonStatus();
  }

  setFetchMoreButtonStatus (): void {
    if (this.items.length < this.limit) {
      this.moreResultsToLoad = false;
      this.fetchFromLocationButtonSettings.tooltipLabel = this.tooltipLabels.nonToLoad;
    } else {
      this.fetchFromLocationButtonSettings.tooltipLabel = this.tooltipLabels.moveToActivate;
    }
  }

  loadMap () {
    if (this.zeroItems) {
      //if no items try to find the users current location so we can load in that region
      const geo = navigator.geolocation;
      if (geo) {
        const success = (position) => {
          this.mapCenter.lng = position.coords.longitude;
          this.mapCenter.lat = position.coords.latitude;
          this.loadMapZeroItems();
        };
        const failure = () => {
          this.loadMapZeroItems();
        };

        geo.getCurrentPosition(success, failure);
      } else {
        this.loadMapZeroItems();
      }
    } else {
      //else we don't need the users location, it will load based on the location of the last item added
      this.loadMapWithItems();
    }
  }

  loadMapZeroItems () {
    this.map = new window.google.maps.Map(this.$refs.mapContainer, {
      zoom: 6,
      center: new window.google.maps.LatLng(this.mapCenter.lat, this.mapCenter.lng),
      gestureHandling: 'none',
      zoomControl: false
    });
    this.firstLoad = false;
  }

  // eslint-disable-next-line max-lines-per-function
  loadMapWithItems () {
    const lng = this.$route.query.lng ? this.$route.query.lng : 0;
    const lat = this.$route.query.lat ? this.$route.query.lat : 0;
    const zoom = this.$route.query.z ? Number(this.$route.query.z) : 5;
    const defaultOptions = {
      disableDefaultUI: true,
      mapTypeId: 'terrain',
      center: new window.google.maps.LatLng(lat, lng),
      streetViewControl: false,
      zoomControl: true,
      zoom: zoom,
      mapTypeControl: true,
      fullscreenControl: true,
      gestureHandling: 'greedy'
    };
    const options = Object.assign(
      defaultOptions,
      this.mapOptions
    );

    this.map = new window.google.maps.Map(this.$refs.mapContainer, options);

    if (this.mapStyles) {
      this.map.setOptions({
        styles: this.mapStyles,
      });
    }

    this.addItemMarkersToMap();

    this.loadFetchFromLocationButton();

    this.infoWindow = new window.google.maps.InfoWindow();

    new window.google.maps.event.addListener(this.map, 'click', (event) => {
      this.addListenerClick(event);
    });

    //activates the button to get new results based on map location
    new window.google.maps.event.addListener(this.map, 'bounds_changed', () => {
      this.addListenerBoundsChanged();
    });

    this.firstLoad = true;
  }

  addListenerClick (event) {
    //prevents the info window from displaying on random places
    if (event.placeId) {
      event.stop();
    } else {
      //hide the infowindow if clicking on a random part of the map and ensure fetch more button is showing
      if (this.infoWindow) {
        this.infoWindow.close();
        this.fetchFromLocationButton.style.display = 'block';
      }
    }
  }

  urlReplaceStateTimeout: any = false;

  addListenerBoundsChanged () {
    //only proceed with updating the lat/lng if there are potentially more markers to load
    if (this.moreResultsToLoad) {
      //we change the bounds after loading the markers for the first time so don't do anything if that's still to happen
      if (this.mapInitialBoundsChanged) {
        this.fetchFromLocationButtonSettings.disabled = false;
        this.fetchFromLocationButtonSettings.tooltipLabel = this.tooltipLabels.loadMore;
      } else {
        this.mapInitialBoundsChanged = true;
      }
    }

    //store lat/lng to the url for the browser back function to work
    clearTimeout(this.urlReplaceStateTimeout);
    this.urlReplaceStateTimeout = setTimeout(() => {
      const center = this.map.getCenter();

      this.mapCenter.lat = center.lat();
      this.mapCenter.lng = center.lng();

      const newQuery: Record<any, any> = Object.assign({}, this.$route.query, {
        lat: this.mapCenter.lat,
        lng: this.mapCenter.lng,
        z: this.map.getZoom()
      });

      if (this.addMapCoordsToUrl) {
        window.history.replaceState(null, '', this.$route.path + '?' + new URLSearchParams(newQuery).toString());
      }

      this.checkIfMarkersVisible();

      this.checkIfFullscreen();
    }, 250);
  }

  loadFetchFromLocationButton () {
    this.fetchFromLocationButton = document.createElement('div');
    this.map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(this.fetchFromLocationButton);
    const t = document.createElement('template');
    this.fetchFromLocationButton.appendChild(t);
    // just don't actually mount the button
    if (this.hideFetchMoreButtom) {
      return false;
    }
    new Vue({
      i18n,
      router,
      render: (h) => h(AFetchFromLocationButton, {
        props: {
          disabled: this.fetchFromLocationButtonSettings.disabled,
          size: this.fetchFromLocationButtonSettings.size,
          loading: this.fetchFromLocationButtonSettings.loading,
          buttonText: this.fetchFromLocationButtonSettings.buttonText,
          buttonClasses: this.fetchFromLocationButtonSettings.buttonClasses,
          tooltipLabel: this.fetchFromLocationButtonSettings.tooltipLabel
        }
      }),
    }).$mount(t);

    this.fetchFromLocationButton.addEventListener('click', () => {
      if (!this.fetchFromLocationButtonSettings.disabled) {
        this.fetchMoreAsyncData();
      }
    });
  }

  /**
   * Finds the marker on the page that was just edited and updates the values.
   * Repositions the marker for that item.
   */
  handleItemEdit (item: Item) {
    const markerToEdit = this.markers.findIndex((markers) => {
      return markers.item.uniqueItemName === item.uniqueItemName;
    });

    this.markers[markerToEdit].item = item;

    const lngLat = item.editable.geolocationData?.position.coordinates;
    //if location was removed from the item, remove the pin from the map, otherwise move the marker to it's new position
    if (typeof lngLat === 'undefined') {
      this.markers[markerToEdit].setMap(null);
      this.markers[markerToEdit] = null;
    } else {
      this.markers[markerToEdit].setPosition(new window.google.maps.LatLng(lngLat[1], lngLat[0]));
    }
  }

  // eslint-disable-next-line max-lines-per-function
  addItemMarkersToMap () {
    if (this.items.length > 0) {
      let markerIterationToOpen: number = -1;

      //loop through the items adding a marker to the map for each, and pan to the first item in the array
      let bounds: any = new window.google.maps.LatLngBounds();
      for (let i = 0; i < this.items.length; i++) {
        const obj = this.items[i];
        const lng = obj.editable.geolocationData?.position.coordinates[0];
        const lat = obj.editable.geolocationData?.position.coordinates[1];
        const position = {
          lat: lat,
          lng: lng
        };
        if (obj.uniqueItemName === this.itemUniqueNameOpen) {
          markerIterationToOpen = i;
        }
        const marker = new window.google.maps.Marker({
          position: position,
          map: this.map,
          item: obj,
          icon: this.icon
        });
        this.markers.push(marker);
        this.loadMarkerInfoWindow(marker);
        bounds.extend(position);
      }

      //following only done on first load up
      if (!this.mapInitialZoomTriggered && !this.loadedWithLatLng) {
        this.map.fitBounds(bounds);
        this.setMapMinZoom();
        this.mapInitialZoomTriggered = true;
      }

      // if the marker to open is not -1 trigger the click
      if (markerIterationToOpen > -1) {
        setTimeout(() => {
          window.google.maps.event.trigger(this.markers[markerIterationToOpen], 'click');
        }, this.itemUniqueNameOpenDelayMSec);
      }
    }
  }

  loadMarkerInfoWindow (marker) {
    // window.google.maps.event.addListener(marker, 'click', async () => {
    marker.addListener('click', async () => {
      try {
        if (this.isMobileView && !this.fullscreenMode) {
          const button = document.getElementsByClassName('gm-fullscreen-control')[0] as HTMLElement;
          if (typeof button === 'undefined') {
            console.log('Browser does not support full screen, loading without.');
          } else {
            button.click();
          }
        }
        let style = 'width:' + (this.windowWidth < 500 ? (this.windowWidth < 450 ? 200 : 267) : 340) + 'px;';
        this.infoWindow.setContent('<div id="googleInfoWindowItem" style="' + style + '"></div>');
        this.infoWindow.open(this.map, marker);
        await waitTillElementIsVisible('div#googleInfoWindowItem');
        this.itemToView = marker.item;
        this.injectItemCardToInfoWindow();
      } catch (e) {
        console.error('Could not load item to info window.', e);
      }
    });
  }

  injectItemCardToInfoWindow () {
    //on mobiles hide the fetch more button otherwise it appears over the info window
    if (this.isMobileView) {
      this.fetchFromLocationButton.style.display = 'none';
    }
    const infoWindow = document.getElementById('googleInfoWindowItem');
    const t = document.createElement('template');
    infoWindow?.appendChild(t);
    new Vue({
      i18n,
      router,
      render: (h) => h(OItemCardMapView, {
        props: {
          item: this.itemToView,
          smallWindow: this.isMobileView
        }
      }),
    }).$mount(t);
  }

  /**
   * If there are items, redraws the map bounds according the markers on the map.
   * Else no items, it asks whether the user wants to reset the search text, if yes then deletes search text and refetches.
   */
  setMapBoundsByMarkers () {
    this.resetButtonSettings.loading = true;
    if (!this.markers.length) {
      this.$buefy.dialog.confirm({
        title: this.clearSearchParameters.title,
        message: this.clearSearchParameters.text,
        cancelText: this.clearSearchParameters.cancelText,
        confirmText: this.clearSearchParameters.confirmText,
        onConfirm: this.clearSearch,
        onCancel: () => this.resetButtonSettings.loading = false
      });
      return;
    }
    const bounds: any = new window.google.maps.LatLngBounds();
    for (let i = 0; i < this.markers.length; i++) {
      bounds.extend({
        lat: this.markers[i].getPosition().lat(),
        lng: this.markers[i].getPosition().lng()
      });
    }
    this.map.fitBounds(bounds);
    this.setMapMinZoom();
    this.resetButtonSettings.loading = false;
  }

  clearSearch () {
    this.resetButtonSettings.loading = false;
  }

  setMapMinZoom () {
    //zoom level 16 is a nice zoom level, if it's higher than this zoom it out
    //run on timeout to ensure map render has finished
    setTimeout(() => {
      if (this.map.getZoom() > 16) {
        this.map.setZoom(16);
      }
    }, 300);
  }

  /**
   * Check to see if all markers are visible.
   * Runs on a delay. Checks to ensure that we don't have a stack of checks, then runs only once the stack is empty,
   * to reduce number of calls. Shows or hides message as appropriate.
   */
  checkIfMarkersVisibleTimeout: any = false;

  checkIfMarkersVisible () {
    clearTimeout(this.checkIfMarkersVisibleTimeout);
    this.checkIfMarkersVisibleTimeout = setTimeout(() => {
      //loop through all the markers, if they are all out of view, add a small message explaining that
      const qtyMarkers = this.markers.length;
      let outOfView = 0;
      for (let i = 0; i < qtyMarkers; i++) {
        if (this.map.getBounds().contains(this.markers[i].getPosition())) {
          //at least 1 marker visible, break out
          this.noItemsInViewMsgHide();
          break;
        } else {
          //marker not visible
          outOfView++;
        }
      }
      if (outOfView == qtyMarkers) {
        this.noItemsInViewMsgShow();
      }
    }, 250);
  }

  checkIfFullscreen () {
    this.fullscreenMode = this.map.getDiv().firstChild.clientHeight === window.innerHeight &&
      this.map.getDiv().firstChild.clientWidth === window.innerWidth;
  }

  createRepositionMapNotice (): HTMLElement {
    this.resetButton = document.createElement('div');
    this.map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(this.resetButton);
    const t = document.createElement('template');
    this.resetButton.appendChild(t);
    new Vue({
      i18n,
      router,
      render: (h) => h(AResetMapClearSearchButton, {
        props: {
          display: this.resetButtonSettings.display,
          loading: this.resetButtonSettings.loading,
          buttonText: this.resetButtonSettings.buttonText,
          buttonClasses: this.resetButtonSettings.buttonClasses
        }
      }),
    }).$mount(t);

    this.resetButton.addEventListener('click', () => {
      this.setMapBoundsByMarkers();
    });

    return this.resetButton;
  }

  noItemsInViewMsgShow () {
    //create element if it doesn't exist
    if (!this.resetButton) {
      this.repositionMapNotice = this.createRepositionMapNotice();
    } else {
      this.repositionMapNotice.style.display = 'block';
    }
  }

  noItemsInViewMsgHide () {
    //if element doesn't exist yet nothing to hide
    if (this.repositionMapNotice) {
      this.repositionMapNotice.style.display = 'none';
    }
  }

  clearAllMarkers () {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers.length = 0;
  }

}
</script>
