<template>
  <button aria-label="Play" class="ytp-large-play-button ytp-button" @click="$emit('click')">
    <svg height="100%" viewBox="0 0 68 48" width="100%">
      <path class="ytp-large-play-button-bg"
            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
            fill="#f00"></path>
      <path d="M 45,24 27,14 27,34" fill="#fff"></path>
    </svg>
  </button>
</template>

<style lang="scss" scoped>
.ytp-button {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: 100%;
  font-family: inherit;
  line-height: inherit;
  text-align: inherit;
  cursor: pointer;
}

.ytp-button:focus,
.ytp-button {
  outline: 0;
}

.ytp-large-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 68px;
  height: 48px;
  margin-top: -24px;
  margin-left: -34px;
  -webkit-transition: opacity .25s cubic-bezier(0, 0, .2, 1);
  transition: opacity .25s cubic-bezier(0, 0, .2, 1);
}

.ytp-large-play-button svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ytp-large-play-button-bg {
  fill: #212121;
  -webkit-transition: fill .1s cubic-bezier(.4, 0, 1, 1), fill-opacity .1s cubic-bezier(.4, 0, 1, 1);
  transition: fill .1s cubic-bezier(.4, 0, 1, 1), fill-opacity .1s cubic-bezier(.4, 0, 1, 1);
  fill-opacity: .8;
}

.ytp-button:hover .ytp-large-play-button-bg {
  fill: #f00;
}

.ytp-button:active .ytp-large-play-button-bg {
  fill: #212121;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AYoutubePlay extends Vue {
}
</script>
