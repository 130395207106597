<template>
  <div class="MItemsShuffler">
    <b-loading :active="loading" :is-full-page="false"/>
    <div class="main-buttons">
      <b-field :label="$t('dict.shuffleHowMany') + ` (x${timesToShuffle})`">
        <b-slider v-model="timesToShuffle"
                  :tooltip-type="String(timesToShuffle)"
                  :min="1"
                  :max="15"
        />
      </b-field>
      <b-button
          @click="reset"
          class="button mt-4 mr-4"
          :disabled="isDeckBeingShuffled"
      >
        Reset
      </b-button>
      <b-button
          @click="shuffleDeck"
          class="button is-primary mt-4"
          :disabled="isDeckBeingShuffled"
      >
        {{ $t('dict.shuffle') }}
      </b-button>
    </div>

    <transition-group :name="shuffleSpeed" tag="div" class="deck">
      <div v-for="item in innerCards" :key="item.uniqueItemNameKey"
           class="card"
           :class="{
              small: innerCards.length > 50 && innerCards.length < 400,
              verySmall: innerCards.length >= 400
            }"
           :style="{
              backgroundColor: hex2rgb(item.c ? '#' + item.c : '#fff', .8)
            }"
      >
        <a-router-link-issue-detail :unique-item-name="item.uniqueItemName">
        <span class="card__suit card__suit--top has-text-centered font-sub2">
        </span>
          <span class="card__number">
            <a-image
                v-if="item.userPhotos && item.userPhotos.length"
                :image-path="item.userPhotos[0].filePath"
                class="item-image"
                size="400x"
            />
            <a-image
                v-else-if="item.urlCache && item.urlCache.image && item.urlCache.image.href"
                :image-path="item.urlCache.image.href"
                class="item-image"
                size="400x"
            />
            <template v-else>
              <span class="text-only">{{ getText(item) | ellipsisString }}</span>
            </template>
        </span>
        </a-router-link-issue-detail>
      </div>
    </transition-group>
  </div>
</template>

<style scoped lang="scss">

.title {
  font-family: Roboto Slab, sans-serif;
  text-align: center;
  padding-top: 30px;
  margin-bottom: 0 !important;
  font-weight: $fontWeightNormal;
  font-size: 3rem;
}

.speed-buttons {
  text-align: center;
  padding-top: 30px;
}

.speed-buttons .button {
  height: 2.5em;
}

.main-buttons {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding-top: 30px;
  margin-bottom: 0 !important;
}

.count-section {
  position: absolute;
  top: 10px;
  right: 10px;
}

.fas {
  padding-left: 5px;
}

.deck {
  margin-left: 30px;
  padding-top: 30px;
}

.card {
  width: 75px;
  height: 100px;
  border-radius: .4rem;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;

  &:hover {
    transform: scale(1.1);
  }

  &.small {
    width: 37px;
    height: 50px;

    img {
      max-height: 35px;
    }

    .text-only {
      font-size: .35rem;
    }
  }

  &.verySmall {
    width: 18px;
    height: 25px;

    img {
      max-height: 16px;
    }

    .text-only {
      font-size: .16rem;
    }
  }

  .text-only {
    font-size: .7rem;
  }

  img {
    max-height: 75px;
    object-fit: scale-down;
  }
}

.card__suit {
  width: 100%;
  display: block;
}

.card__suit--bottom {
  position: absolute;
  bottom: 0;
  text-align: left;
  padding-left: 5px;
}

.card__number {
  width: 100%;
  position: absolute;
  top: 20%;
  text-align: center;
}

.shuffleSlow-move {
  transition: transform 2s;
}

.shuffleMedium-move {
  transition: transform .6s;
}

.shuffleFast-move {
  transition: transform .4s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 1210px) {
  .deck {
    position: initial;
    top: 0;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { parser } from 'qs-in-filename';
import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';
import ellipsisString from '@/utils/ellipsisString';
import AImage from '@/components/atoms/AImage.vue';
import { Item } from '@/api/ms-item/services/interfaces';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import ItemsService from '@/api/ms-item/services/ItemsService';
import clone from '@/utils/clone';

@Component({
  components: { MInputWithValidation, AImage, ARouterLinkIssueDetail },
  filters: {
    ellipsisString: (input: string) => {
      return ellipsisString(input, 10);
    }
  }
})
export default class MItemsShuffler extends Vue {
  @Prop({ required: true })
  channelSlug!: string;

  original: any[] = [];
  loading: boolean = false;
  innerCards: any[] = [];
  shuffleSpeed = 'shuffleMedium';
  shuffleTypes = ['Slow', 'Medium', 'Fast'];
  isDeckBeingShuffled = false;
  shuffleCount = 0;
  timesToShuffle = 4;

  async created () {
    await this.fetchCards();
    this.displayInitialDeck();
  }

  displayInitialDeck () {
    this.isDeckBeingShuffled = false;
    this.shuffleCount = 0;
    this.innerCards.forEach((item) => {
      if (item.urlCache?.image?.href) {
        const parsed = parser(item.urlCache?.image?.href);
        item.c = parsed.c[0];
      }
    });
  }

  set () {
    const data = clone(this.original);
    let multi = false;
    for (let i = 0; i < data.length; i++) {
      if (data[i].inChannelWeight && data[i].inChannelWeight as number > 1) {
        multi = true;
      }
      data[i].uniqueItemNameKey = data[i].uniqueItemName + '_' + i;
    }
    if (multi) {
      this.innerCards = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].inChannelWeight) {
          const k = data[i].inChannelWeight as number * 10;
          for (let j = 0; j < k; j++) {
            const cloned = clone(data[i]);
            cloned.uniqueItemNameKey = cloned.uniqueItemName + '_' + i + j;
            this.innerCards.push(cloned);
          }
        } else {
          this.innerCards.push(data[i]);
        }
      }
    } else {
      this.innerCards = data;
    }
  }

  reset () {
    this.innerCards = [];
    this.set();
  }

  async fetchCards () {
    this.loading = true;
    this.original = (await ItemsService.itemsWithWeightsInChannelGet({
      channel: this.channelSlug,
      mostUserInvitations: this.channelSlug.startsWith('liffery.official@')
    })).data;
    this.set();
    this.loading = false;
  }

  getText (item: Item) {
    if (item.urlCache?.meta?.title) {
      return item.urlCache?.meta?.title;
    } else {
      return item.editable.text;
    }
  }

  shuffleDeck () {
    this.isDeckBeingShuffled = true;
    for (let i = this.innerCards.length - 1; i > 0; i--) {
      let randomIndex = Math.floor(Math.random() * i);
      let temp = this.innerCards[i];
      Vue.set(this.innerCards, i, this.innerCards[randomIndex]);
      Vue.set(this.innerCards, randomIndex, temp);
    }
    if (this.shuffleCount <= this.timesToShuffle) {
      ++this.shuffleCount;
      setTimeout(() => {
        this.shuffleDeck();
      }, 250);
    } else {
      setTimeout(() => {
        this.shuffleCount = 0;
        this.isDeckBeingShuffled = false;
      }, 650);
    }
  }

  showWinner () {
    this.$buefy.dialog.alert({
      title: 'Shuffle result',
      message: `
        Top of the stack is an item belonging to, ${this.innerCards[0].actor.firstName} ${this.innerCards[0].actor.lastName}.
      `,
    });
  }

  hex2rgb (hex, alpha: 1) {
    // return {r, g, b} // return an object
    return `rgba(${parseInt(hex.slice(1, 3), 16)}, ${parseInt(hex.slice(3, 5), 16)}, ${parseInt(hex.slice(5, 7), 16)}, ${alpha})`;
  }
}
</script>
