<template>
  <span class="dot-divider"></span>
</template>

<style scoped lang="scss">
.dot-divider {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: var(--primary-color);
  opacity: .8;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ADotDivide extends Vue {
}
</script>
