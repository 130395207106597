<template>
  <div class="MChatBox">
    <div class="chat-messages-wrapper lfr-scrollbar font-body-s">
      <div class="chat-messages">
        <div v-if="loading" class="loading-container-indicator">
          <b-loading v-model="loading" :is-full-page="false"></b-loading>
        </div>
        <m-chat-default-message v-if="hasNoMoreMessages"/>
        <div v-if="messages.length !== 0 && hasNoMoreMessages" class="chat-divider font-sub2">
          <span>{{ $t('dict.firstMessage') }}</span>
        </div>
        <div v-for="message in messages" :key="message._id" :ref="message._id">
          <div v-if="firstUnreadMessage === message._id" class="chat-divider font-sub2">
            <span>{{ $t('dict.newMessages') }}</span>
          </div>
          <m-chat-message :message="message"/>
        </div>
      </div>
    </div>

    <div v-if="socketConnectionFailed" class="socket-connection-failed">
      <div>
        <span><zap-off-icon size="1.2x" />{{ $t('errors.socketConnectionFailed') }}</span>
      </div>
    </div>

    <o-channel-chat-form
        :channel-slug="channelSlug"
        :chat-enabled="chatEnabled"
        :loading="loading"
        v-on:send-message="chatFormSubmitHandle"
    />
  </div>
</template>

<style lang="scss" scoped>
.MChatBox {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-messages-wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column-reverse;
  overflow-y: scroll;
  max-height: 95%;
  margin-bottom: 1rem;
}

.chat-box-title {
  text-align: center;
}

.loading-container-indicator {
  position: absolute;
  top: 0;
  width: 100%;
  height: 75px;

  ::v-deep .loading-background {
    background-color: rgba(255, 255, 255, 0);
  }
}

.socket-connection-failed {
  text-align: right;
  margin-right: 1.5rem;
  margin-bottom: 1rem;

  > div {
    display: inline-block;
    padding: 0.5rem;
    background-color: #ffebeb;
    color: var(--danger-color);
    border-radius: var(--default-border-radius);
    box-shadow: 0 3px 6px rgba(#ff4343, .6);

    > span {
      display: flex;

      svg {
        margin-right: 0.3rem;
        vertical-align: baseline;
      }
    }
  }
}

.chat-divider {
  position: relative;
  display: block;
  width: 92%;
  margin: 5px auto;
  text-align: center;

  span {
    position: relative;
    z-index: 1;
    display: inline;
    padding: 2px 4px;
    background-color: var(--bg-color);
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 1px;
    background-color: var(--primary-color-light);
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Datum as ChatsDatum } from '@/api/ms-chat/services/interfaces/Chats';

import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import MChatMessage from '@/components/molecules/MChatMessage.vue';
import isTouchDevice from '@/utils/isTouchScreen';
import MChatDefaultMessage from '@/components/molecules/MChatDefaultMessage.vue';
import { RouteNames } from '@/router/RouteNames';
import OChannelChatForm from '@/components/organisms/forms/OChannelChatForm.vue';
import { ZapOffIcon } from 'vue-feather-icons';

@Component({
  components: {
    ZapOffIcon,
    OChannelChatForm,
    MChatDefaultMessage,
    MChatMessage,
    MInputWithValidation
  }
})
export default class MChatBox extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  messages!: ChatsDatum[];

  @Prop()
  firstUnreadMessage?: string;

  @Prop({ default: 0 })
  unreadCount!: number;

  @Prop()
  hasNoMoreMessages!: boolean;

  @Prop({ required: true })
  channelSlug!: string;

  @Prop({ default: true })
  chatEnabled!: boolean;

  @Prop({ required: false, default: false })
  socketConnectionFailed?: boolean;

  chatBox: any = null;
  mentionedProfiles: any = null;

  get isTouchScreen (): boolean {
    return isTouchDevice();
  }

  mounted () {
    this.chatBox = document.querySelector('.chat-messages-wrapper');
    this.chatBox.addEventListener('scroll', this.handleScroll);
  }

  updated () {
    const tempMentions: any = document.querySelectorAll('.mentioned-user-link');

    if (JSON.stringify(tempMentions) !== JSON.stringify(this.mentionedProfiles)) {
      this.mentionedProfiles = tempMentions;

      Array.from(this.mentionedProfiles, (item: any) => {
        item.addEventListener('click', (e) => {
          this.$router.push({
            name: RouteNames.ROUTE_PROFILE, params: {
              username: e.target.innerText,
            }
          });
        });
      });
    }
  }

  // updated () {
  //   if (this.unreadCount > 0 && this.firstUnreadMessage && this.$refs[this.firstUnreadMessage]) {
  //     this.$nextTick(() => this.$refs[this.firstUnreadMessage as string][0].scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'center'
  //     }));
  //   }
  // }

  destroyed () {
    this.chatBox.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll (): void {
    const scrollTop = Math.abs(this.chatBox.scrollTop);

    if (scrollTop + this.chatBox.offsetHeight >= this.chatBox.scrollHeight - 10) {
      this.$emit('chatbox-scrolled-to-top');
    }
    // check if the distance from the bottom of the container to the bottom of the viewport of the container is greater than 55
    if (scrollTop >= 55) {
      this.$emit('chatbox-scrolled-not-bottom');
    } else {
      this.$emit('chatbox-scrolled-at-bottom');
    }
  }

  chatFormSubmitHandle (msg: string) {
    this.$emit('send-message', msg);
    this.scrollChatBoxToBottom();
  }

  scrollChatBoxToBottom (): void {
    this.chatBox.scrollTop = 0;
  }
}
</script>