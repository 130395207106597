<template>
  <div class="MChannelInformation">
    <div v-if="!hideHero" class="card-image channel-header">
      <a-image
          v-if="channel.imageHeroPath"
          :alt-text="channel.name"
          :image-path="channel.imageHeroPath"
          :size="sizeHero"
          class="image is-fullwidth"
      />
      <span v-if="channel.imageHeroPathCredit && !hideImageCredits" class="image-credits">
        {{ $t('dict.photoCredit' + (channel.imageHeroPathProvider === Provider.Liffery ? 'Liffery' : 'Pexels'), { name: channel.imageHeroPathCredit }) }}
      </span>
    </div>
    <div class="card-content">
      <div class="media" style="border: none;">
        <div class="media-left">
          <figure v-if="!hideThumbnail" class="image is-48x48">
            <a-image
                v-if="channel.imagePath"
                :alt-text="channel.name"
                :image-path="channel.imagePath"
                :size="sizeThumbnail"
                class="image channel-image-thumb"
            />
          </figure>
        </div>
        <div class="media-content">
          <p class="title mb-0" v-html="channel.name" />
          <p v-if="isChannelEvent">
            <calendar-icon size="0.9x" /> {{ formattedEventDate }}
            <span v-if="channel.event.eventType !== eventTypes.OneOff">
              <repeat-icon size="0.9x" /> {{ $t(`datetime.dict.${channel.event.eventType}`)|ucfirst }}
            </span>
          </p>
          <p v-else-if="isResearchConcierge">
            <a-lightbulb-on-outline-icon size="0.9" /> {{ $t('page.channel.researchConcierge.dict') }}
          </p>
        </div>
      </div>

      <div class="content">
        <template>
          <!-- Channel description -->
          <p v-if="channel.description" v-html="nl2br(channel.description)"></p>

          <!-- Research concierge columns (inc. budget which can be included on non rc channels) -->
          <div class="columns mb-4">
            <a-user-content-display class="column" v-if="channel.channelMeta && channel.channelMeta.budget" :label="$t('page.channelUpsert.form.channelMeta.budget')" :content="budgetString" />
            <a-user-content-display class="column" v-if="isResearchConcierge" :label="capitaliseFirstLetterOfWords($t('dict.qtyItems'))" :content="channel.concierge.active.settings.qtyItems" />
            <a-user-content-display class="column" v-if="isResearchConcierge" :label="capitaliseFirstLetterOfWords($t('channel.conciergeType'))" :content="camelCaseToSentenceCase(channel.concierge.active.settings.conciergeType)" />
            <a-user-content-display class="column" v-if="isResearchConcierge" :label="$t('dict.urgency')" :content="$t('page.channelUpsert.form.conciergeSettings.urgencyOptions.' + channel.concierge.active.settings.urgency)" />
          </div>

          <!-- Channel meta (exc. budget which is printed above in concierge settings if present) -->
          <template v-if="channel.channelMeta">
            <a-user-content-display class="content" v-if="channel.channelMeta.geolocationData" :label="$t('page.channelUpsert.form.channelMeta.geolocation')" :content="channel.channelMeta.geolocationData.formattedAddress" />
            <a-user-content-display class="content" v-if="channel.channelMeta.requirements" :label="$t('dict.requirements')" :content="channel.channelMeta.requirements" />
            <a-user-content-display class="content" v-if="channel.channelMeta.likes" :label="$t('dict.likes')" :content="channel.channelMeta.likes" />
            <a-user-content-display class="content" v-if="channel.channelMeta.dislikes" :label="$t('dict.dislikes')" :content="channel.channelMeta.dislikes" />
          </template>

          <!-- "Signature" of channel owner -->
          <small class="mb-6">
            {{ $t('dict.created') }} {{ new Date(channel.createdAt) | dateFormat('MMMM `YY') }}
            {{ $t('dict.byBlank')|lowerCase }} {{ channel.owner.firstName }}
          </small>
        </template>
      </div>


    </div>

    <footer class="card-footer is-justify-content-center" v-if="!hideFooterControls">
      <b-button
          class="card-footer-item is-flex-grow-0 mt-3 mb-3 is-primary is-outlined"
          @click="closeInfo"
      >
        {{ $t('dict.close') }}
      </b-button>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.image-credits {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2px 8px;
  background-color: rgba(0, 0, 0, .4);
  color: var(--white-color);
  text-align: right;
  font-style: italic;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Channel } from '@/api/ms-channel/services/interfaces';
import nl2br from '@/utils/nl2br';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import AImage from '@/components/atoms/AImage.vue';
import { translation } from '@/plugins/i18n/Translation';
import formatDateDayjs from '@/utils/formatDateDayjs';
import { CalendarIcon, RepeatIcon } from 'vue-feather-icons';
import { ChannelType, EventType } from '@/api/ms-channel/services/interfaces/Channel';
import {
  camelCaseToSentenceCase,
  capitaliseFirstLetterOfString,
  capitaliseFirstLetterOfWords
} from 'common-utils/string';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';
import ASpanFormatDateDayJs from '@/components/atoms/ASpanFormatDateDayJs.vue';
import AUserContentDisplay from '@/components/atoms/AUserContentDisplay.vue';
import { Provider } from '@/api/ms-channel/services/interfaces/ChannelGenerateImage';

@Component({
  computed: {
    Provider () {
      return Provider;
    }
  },
  components: {
    AUserContentDisplay,
    ASpanFormatDateDayJs,
    ALightbulbOnOutlineIcon,
    AImage,
    CalendarIcon,
    RepeatIcon
  },
  filters: {
    lowerCase: (str) => str.toLowerCase(),
    ucfirst: capitaliseFirstLetterOfString
  },
  methods: {
    capitaliseFirstLetterOfWords: capitaliseFirstLetterOfWords,
    camelCaseToSentenceCase: camelCaseToSentenceCase
  }
})
export default class MChannelInformation extends Vue {
  @Prop()
  channel!: Channel;

  @Prop({ required: false, default: Size.The950X })
  sizeHero!: Size;

  @Prop({ required: false, default: Size.The100X100 })
  sizeThumbnail!: Size;

  @Prop({ required: false, default: false })
  hideHero!: boolean;

  @Prop({ required: false, default: false })
  hideThumbnail!: boolean;

  @Prop({ required: false, default: false })
  includeItemMeta!: boolean;

  @Prop({ required: false, default: false })
  hideImageCredits!: boolean;

  @Prop({ required: false, default: false })
  hideFooterControls!: boolean;

  isChannelEvent: boolean = false;
  isResearchConcierge: boolean = false;

  formattedEventDate: string = '';
  eventTypes = EventType;

  nl2br (input: string): string {
    return nl2br(input);
  }

  //todo - incorporate more meta data, no. items and stuff like that
  get itemMeta () {
    if (this.includeItemMeta) {
      return 'abc';
    } else {
      return false;
    }
  }

  get language () {
    return translation.currentLanguage;
  }

  get budgetString () {
    return this.channel.channelMeta && this.channel.channelMeta.budget ? this.channel.channelMeta.budget.currency + ' - ' + this.numberWithCommas(this.channel.channelMeta.budget.value) : '';
  }

  created () {
    this.setIsChannelType();
    this.setFormattedEventDate();
  }

  numberWithCommas (x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  setIsChannelType () {
    this.isChannelEvent = this.channel.channelType === ChannelType.Event;
    this.isResearchConcierge = this.channel.channelType === ChannelType.ResearchConcierge;
  }

  async setFormattedEventDate() {
    if( this.isChannelEvent && this.channel.event && this.channel.event.eventDate ){
      this.formattedEventDate = capitaliseFirstLetterOfString(await formatDateDayjs(this.channel.event.eventDate, this.language));
    }
  }

  closeInfo () {
    this.$emit('close-info');
  }
}
</script>