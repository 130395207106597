<template>
  <div class="OChannelMembersContainer">
    <div class="people-container">
      <b-button v-if="channelOwner === currentUser.username && !channelArchived" @click="goToEditMembers" class="is-primary btn-add-people">
        <plus-icon size="1.5x"/>
        {{ $t('dict.addPeople') }}
      </b-button>
      <div v-if="loading" class="people-grid">
        <m-person-card-skeleton v-for="index in 3" :key="index"/>
      </div>
      <p v-else-if="notMemberOf" class="title-7 not-member-message">
        {{ $t('dict.notChannelMemberMessage') }}
      </p>
      <div v-else-if="members.length" class="people-grid">
        <m-person-not-connected-card
            v-for="(member, index) in members"
            :key="index"
            :person="member"
        />
      </div>
      <p v-else class="title-7 not-member-message">
        {{ $t('dict.notFoundMessageOtherConnections') }}
      </p>

      <div class="load-more-wrapper" v-if="!noMoreResults && !notMemberOf">
        <b-button
            class="is-primary btn-load-more"
            @click="$emit('child-output')"
            :disabled="loadingMore"
        >
          {{ $t('dict.loadMore') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.OChannelMembersContainer {
  margin-bottom: 2rem;
}

.not-member-message {
  text-align: center;
}

.load-more-wrapper {
  margin-top: 2rem;
  text-align: center;
}

.btn-add-people {
  display: flex;
  margin: 0 auto 2rem;

  ::v-deep span {
    display: flex;
    align-items: center;
  }
}
</style>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Datum as ChannelMembersDatum} from '@/api/ms-channel/services/interfaces/ChannelMembers';
import {AuthenticationStore, ChannelsStore, PeopleStore} from '@/store';
import {User} from '@/api/ms-authentication/services/interfaces/Login';
import {SearchType} from '@/store/modules/enums/SearchType';
import {Connections} from '@/api/ms-authentication/services/interfaces';

import MPersonNotConnectedCard from '@/components/molecules/MPersonNotConnectedCard.vue';
import MPersonCardSkeleton from '@/components/molecules/skeletons/MPersonCardSkeleton.vue';
import {RouteNames} from '@/router/RouteNames';
import { PlusIcon } from 'vue-feather-icons';
import htmlBodyClassToggle from '@/utils/htmlBodyClassToggle';

@Component({
  components: {
    MPersonNotConnectedCard,
    MPersonCardSkeleton,
    PlusIcon
  }
})
export default class OChannelMembersContainer extends Vue {
  @Prop({ required: true })
  channelSlug!: string;

  @Prop({ required: true})
  channelOwner!: string;

  @Prop({ required: true})
  channelArchived!: boolean;

  @Prop()
  loading!: boolean;

  @Prop()
  loadingMore!: boolean;

  people: string[] = [];

  get members (): ChannelMembersDatum[] {
    return ChannelsStore.getCurrentChannelViewedMembers;
  }

  get notMemberOf (): boolean {
    return ChannelsStore.getCurrentChannelNotMemberOf;
  }

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get peopleConnected (): Connections {
    return PeopleStore.getPeople[SearchType.channel].connected;
  }

  get noMoreResults () {
    return ChannelsStore.getMembersNoMoreResults;
  }

  created () {
    this.people = this.peopleConnected.data.map(person => person.username);
  }

  mounted () {
    htmlBodyClassToggle('hide-scroll', true);
  }

  goToEditMembers (): void {
    const redirect = {
      name: RouteNames.ROUTE_CHANNEL_EDIT,
      params: {
        channelSlug: this.channelSlug,
        username: this.currentUser.username,
      }
    };

    this.$router.push({ ...redirect, hash: '#invitations' });
  }

  // hasConnection (username): boolean {
  //   return this.people.includes(username);
  // }
}
</script>
