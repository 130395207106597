<template>
  <div class="OItemLists">
    <div class="lists-wrapper" :class="{'not-on-sl': isNotOnShoppingList}">
      <p v-if="!item.getgot.length" class="pt-6">
        {{ $t('dict.notFoundMessageListTab') }}
      </p>
      <template v-else>
        <div v-for="(list, index) in item.getgot" :key="index" class="user-list">
          <b-tooltip multilined>
            <template v-slot:content>
              <b v-if="list.private && currentUser.username !== list.actor.username">{{ $t('dict.secretShopper') }}</b>
              <b v-else>
                {{ $t('dict.by', { name: list.actor.firstName + ' ' + list.actor.lastName }) }}
              </b>
              <span>{{ $t('dict.forName', { name: list.forActor.firstName + ' ' + list.forActor.lastName }) }}</span>
            </template>

            <div v-if="list.private && currentUser.username !== list.actor.username" class="user-icon">
              <user-icon size="40"/>
            </div>
            <template v-else>
              <router-link
                  v-if="currentUser.username === list.actor.username"
                  :to="{ name, params: { username: currentUser.username }, hash: `#${item.uniqueItemName}`}"
              >
                <a-user-profile-pic :imageSize="userImageSize" :username="list.actor.username"></a-user-profile-pic>
              </router-link>
              <a-router-link-profile v-else :username="list.actor.username">
                <a-user-profile-pic :imageSize="userImageSize" :username="list.actor.username"></a-user-profile-pic>
              </a-router-link-profile>
            </template>
          </b-tooltip>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.OItemLists {
  padding: .5rem 0;
}

.lists-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: -2rem;
}

.user-list {
  flex-shrink: 0;
  margin: 2.5rem 1rem 1rem;
}

b {
  display: block;
  margin-bottom: .5rem;
}

.user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 2px solid var(--primary-color-light);
  border-radius: 50%;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';

import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import { UserIcon } from 'vue-feather-icons';
import { User } from '@/api/ms-authentication/services/interfaces/Login';
import { RouteNames } from '@/router/RouteNames';
import { Item } from '@/api/ms-item/services/interfaces';

@Component({
  components: {
    AUserProfilePic,
    ARouterLinkProfile,
    UserIcon
  }
})
export default class OItemLists extends Vue {
  @Prop()
  isNotOnShoppingList!: boolean;

  @Prop({ required: true })
  item!: Item;

  name = RouteNames.ROUTE_GETGOT;
  userImageSize = Size.The60X60;

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }
}
</script>
