<template>
  <div class="MPromptBanner" :class="{ 'important-banner': isImportant }" ref="bannerContainer">
    <p class="banner-message">{{ message }}</p>
    <div class="buttons-wrapper">
      <button v-if="cancelActionText" @click="$emit('cancel-action')" class="action-button btn-cancel">
        <span class="button-text">
          {{ cancelActionText }}
        </span>
      </button>
      <button v-if="confirmActionText" @click="$emit('confirm-action')" class="action-button btn-confirm">
        <span class="button-text">
          {{ confirmActionText }}
        </span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.MPromptBanner {
  position: fixed;
  left: 50%;
  z-index: 50;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  padding: 1rem 1.5rem;
  border-radius: 4px;
  background-color: var(--darker-bg-color);
  box-shadow: 0 3px 6px rgba(#000, .2);
  transform: translateX(-50%);

  &.important-banner {
    background-color: var(--secondary-color);
    color: #fff;

    .btn-confirm {
      color: #1c616a;
    }

    .btn-cancel {
      color: #e8e8e8;
    }
  }
}

.banner-message {
  flex-grow: 1;
  color: var(--text-color);
  text-align: center;
}

.buttons-wrapper {
  margin: 0 auto;
}

.action-button {
  border: none;
  background-color: transparent;
  font-size: .875rem;
  cursor: pointer;
}

.btn-cancel {
  color: var(--primary-color-light);
}

.button-text {
  border-bottom: 1px solid transparent;
  font-weight: bold;
  transition: border-color .2s;

  &:hover {
    border-bottom-color: currentColor;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { waitTillElementIsVisible } from '../../utils/waitTillElementIsVisible';

@Component
export default class MPromptBanner extends Vue {
  @Prop({ required: true })
  message!: string

  @Prop()
  cancelActionText!: string

  @Prop()
  confirmActionText!: string

  @Prop()
  isImportant!: boolean

  @Prop({default: 'top'  })
  position!: 'top' | 'bottom';

  $refs!: {
    bannerContainer: HTMLElement
  };

  async created () {
    const containerClass = this.position === 'top' ? '.navbar-header' : '.MFooterGeneral';
    const container = await waitTillElementIsVisible(containerClass);
    this.$refs.bannerContainer.style[this.position] = container.getBoundingClientRect().height + 15 + 'px';
  }
}
</script>
