<template>
  <div :class="{ 'has-msg-notification': unreadCount > 0 || forceShow, 'is-not-touch-screen': !isTouchScreen }"
       class="MChatToggleHandle"
       @click="$emit('click')">
    <div class="toggle-wrapper">
      <div v-if="unreadCount > 0 || forceShow" class="toggle-circle pulse-white"></div>
      <p class="toggle-text">{{ $t('dict.channelChat') }}</p>
      <chevron-down-icon :class="!isOpen ? 'icon-right' : 'icon-left'" class="toggle-icon" size="1.5x"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.MChatToggleHandle {
  position: absolute;
  right: -5.1rem;
  bottom: 10%;
  border-bottom-right-radius: var(--default-border-radius);
  border-bottom-left-radius: var(--default-border-radius);
  background-color: var(--primary-color);
  color: #fff;
  box-shadow: 0 3px 6px rgba(#000, .2);
  cursor: pointer;
  transform: translateY(-50%) rotate(-90deg);

  &.has-msg-notification {
    right: -5.6rem;

    .toggle-wrapper {
      padding-right: 1.75rem;
    }
  }

  &.is-not-touch-screen {
    &:hover {
      .icon-right {
        transform: translateY(10px);
      }

      .icon-left {
        transform: translateY(-5px) rotate(180deg);
      }
    }
  }
}

.toggle {
  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: .1rem .625rem .3125rem .3125rem;
    transition: all .3s;
  }

  &-circle {
    position: absolute;
    right: .5625rem;
    bottom: .5625rem;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--white-color);
  }

  &-icon {
    margin-right: 1px;
    transition: all .3s;
    transform: translateY(2px);

    &.icon-left {
      transform: translateY(1px) rotate(180deg);
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons';
import isTouchScreen from '@/utils/isTouchScreen';

@Component({
  components: {
    ChevronUpIcon,
    ChevronDownIcon
  }
})
export default class MChatToggleHandle extends Vue {
  @Prop({ default: 0 })
  unreadCount!: number;

  @Prop({ default: true })
  forceShow!: boolean;

  @Prop()
  isOpen!: boolean;

  isTouchScreen = isTouchScreen();
}
</script>