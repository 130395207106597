<template>
  <router-link :to="{ name, params: { username }}" class="notification-link">
    <chevron-right-icon size="1.2x"></chevron-right-icon>
    {{ $t('notificationHeaderMsg.seeAll') }}
  </router-link>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { AuthenticationStore } from '@/store';
import { ChevronRightIcon } from 'vue-feather-icons';

@Component({
  components: {
    ChevronRightIcon
  }
})
export default class ARouterLinkNotifications extends Vue {
  name = RouteNames.ROUTE_NOTIFICATIONS;
  username!: string;

  created () {
    this.username = AuthenticationStore.currentUser.username;
  }
}
</script>
