<template>
  <div class="OChannelItemsTable">
    <m-channel-custom-field-table-settings
        :custom-field-display="channelViewSetting.displayCustomFields"
        :non-custom-fields="channelViewSetting.nonCustomFields"
        :current-user-owns-channel="currentUserOwnsChannel"
        v-on:allStandardOff="allStandardOff"
        v-on:allStandardOn="allStandardOn"
        v-on:standardReset="standardReset"
        v-on:allCustomOff="allCustomOff"
        v-on:allCustomOn="allCustomOn"
        v-on:customFieldToggle="customFieldToggle"
        v-on:standardFieldToggle="standardFieldToggle"
        v-on:saveSettingsAsDefault="saveCurrentSettingsAsChannelDefault"
    />

    <div class="is-relative">
      <b-loading :active="loadingInternal || loading" :is-full-page="false"/>
      <b-table
          v-if="items.length && !loadingInternal"
          :data="items"
          :key="tableKey"
          :loading="loading"
          scrollable
          paginated
          hoverable
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          :default-sort-direction="sortingDirection"
          :default-sort="[sortId ? sortId : sortBy]"
          @sort="onSort"
      >
        <!-- Item actions -->
        <b-table-column
            v-slot="props"
        >
          <div class="is-flex is-justify-content-center">
            <chevron-right-icon class="goToItem" @click="goToItemDetail(props.row)"/>
            <m-item-actions-dropdown
                :item="props.row"
                :current-user="currentUser"
                :dropdown-position="dropdownPosition"
                :append-to-body="true"
                :include-item-detail-link="true"
            />
          </div>
        </b-table-column>

        <!-- Loop over the standardFields as columns-->
        <template v-for="column in channelViewSetting.nonCustomFields">
          <b-table-column
              v-if="column.show"
              :key="column.key"
              :label="column.label"
              :field="column.key"
              :sortable="sortByOptionsArray.includes(column.key)"
          >
            <template v-slot:header="{ column }">
              <b>{{ column.label }}</b>
            </template>
            <template v-slot="props">
              <template v-if="'createdAt' === column.key">
                {{ new Date(props.row.createdAt) | dateFormat('YYYY-MM-DD @ hh:mm') }}
              </template>
              <template v-else-if="'likeCount' === column.key">
                {{ props.row.performanceValues.likes }}
              </template>
              <template v-else-if="'commentCount' === column.key">
                {{ props.row.performanceValues.commentsAndReplies }}
              </template>
              <template v-else-if="'swipeScore' === column.key">
                {{ props.row.performanceValues.swipeScore }}
              </template>
              <template v-else-if="'userPhotos' === column.key">
                <m-image-with-lightbox
                    v-if="props.row.userPhotos && props.row.userPhotos.length"
                    :image-path="props.row.userPhotos[0].filePath"
                    :size="imageSize"
                    :item="props.row"
                    class="item-image"
                />
              </template>
              <template v-else-if="'urlCache.url' === column.key">
                <a-router-link-item-url
                    v-if="props.row.urlCache && props.row.urlCache.url"
                    :url="props.row.urlCache.url"
                    classes="item-link"
                />
              </template>
              <template v-else-if="'urlCache.image' === column.key">
                <m-image-with-lightbox
                    v-if="props.row.urlCache && props.row.urlCache.image && props.row.urlCache.image.href"
                    :image-path="props.row.urlCache.image.href"
                    :size="imageSize"
                    :item="props.row"
                    class="item-image"
                />
              </template>
              <template v-else-if="'urlCache.price.price' === column.key">
                <p v-if="props.row.urlCache && props.row.urlCache.price && props.row.urlCache.price.price">
                  <a-item-price :item="props.row" :force-decimal="true"/>
                </p>
                <div v-else>
                  <!--    TODO: add input     -->
                </div>
              </template>
              <!-- For all the rest simply display the data-->
              <template v-else>
                {{ getFieldValue(props.row, column.key) | removeUrl }}
              </template>
            </template>
          </b-table-column>
        </template>

        <!-- Loop over the customFieldDisplay as columns-->
        <template v-for="column in channelViewSetting.displayCustomFields">
          <b-table-column
              v-if="column.show"
              :key="'customFieldDisplay' + column._id"
              :field="column._id"
              :label="getCustomColumnLabel(column)"
              sortable
          >
            <template v-slot:header>
              <b>
                {{ getCustomColumnLabel(column) }}
                <template v-if="sortId === column._id">
                  <chevron-down-icon v-if="sortDir === sortDirections.Asc"/>
                  <chevron-up-icon v-else-if="sortDir === sortDirections.Desc"/>
                </template>
              </b>
            </template>
            <template v-slot="props">
              <m-custom-field-display
                  :field="customFieldValueById(column._id, props.row)"
                  :custom-channel-field-data="customChannelFieldData(column._id)"
                  :display-units="false"
                  :channel-slug="props.row.editable.channel.slug"
                  :unique-item-name="props.row.uniqueItemName"
              />
            </template>
          </b-table-column>
        </template>

      </b-table>
      <div v-else-if="!loading && !loadingInternal" class="not-found-wrapper">
        <template>
          <p class="title-7 not-found-message">
            {{ $t('containers.items.nonFound.channel') }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .item-image-cell {
  max-width: 220px;
}

.goToItem {
  cursor: pointer;
  transition: color .2s;

  &:hover {
    color: var(--secondary-color);
  }
}

.item-image {
  object-fit: cover;
  max-width: 200px;
  border-radius: var(--default-border-radius);
}

::v-deep .item-desc-cell {
  min-width: 300px;
  max-width: 400px;
  word-break: break-all;
}

.item-link {
  display: inline-block;
  transition: color .2s;

  &:hover {
    color: var(--secondary-color);
  }
}

.item-user-desc,
.item-title,
.item-desc {
  margin-bottom: 1rem;
}

.like-icon,
.comment-icon {
  margin-left: 4px;
}

::v-deep {
  .item-date-cell,
  .item-price-cell {
    min-width: 120px;
  }

  .item-comments-cell,
  .item-likes-cell {
    min-width: 85px;
    padding-left: 1.5rem;
  }
}

.not-found-wrapper {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-width: 1150px) {
  .item-image {
    max-width: 100px;
  }
}

@media screen and (max-width: 768px) {
  ::v-deep {
    .item-image-cell,
    .item-desc-cell {
      max-width: 100% !important;

      &::before {
        content: unset !important;
      }
    }

    .item-desc-cell {
      flex-direction: column;
      align-items: flex-start !important;
      text-align: left !important;
    }

    .item-comments-cell,
    .item-likes-cell {
      padding: .5em .75em; // buefy default padding
    }
  }

  .item-image {
    max-width: 50px;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import AImage from '@/components/atoms/AImage.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import remove1stUrlFromString from '@/utils/remove1stUrlFromString';
import ARouterLinkItemUrl from '@/components/atoms/link/ARouterLinkItemUrl.vue';
import { SortBy, SortDir } from '@/api/ms-item/services/interfaces/ItemsGetQuery';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  MessageSquareIcon,
  MoreVerticalIcon,
  SettingsIcon,
  ThumbsUpIcon
} from 'vue-feather-icons';
import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';
import scrollWindowToElement from '@/utils/scrollWindowToElement';
import { Channel } from '@/api/ms-channel/services/interfaces';
import { CustomField } from '@/api/ms-channel/services/interfaces/Channel';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import { AuthenticationStore, ChannelViewStore, ItemsStore } from '@/store';
import StaticApiDataService from '@/services/StaticApiDataService';
import { CustomFieldsUnitGroupeds } from '@/api/ms-static-api-data/services/interfaces';
import { clone, getObjectValue } from 'common-utils/object';
import { CustomFieldDisplay, NonCustomField, NonCustomFields } from '@/store/modules/interfaces/ChannelViewModule';
import MChannelCustomFieldTableSettings from '@/components/molecules/channel/MChannelCustomFieldTableSettings.vue';
import CustomFields from '@/services/CustomFields';
import MCustomFieldDisplay from '@/components/molecules/MCustomFieldDisplay.vue';
import MItemActionsDropdown from '@/components/molecules/MItemActionsDropdown.vue';
import { RouteNames } from '@/router/RouteNames';
import MImageWithLightbox from '@/components/molecules/MImageWithLightbox.vue';
import ChannelService from '@/api/ms-channel/services/ChannelService';
import AItemPrice from '@/components/atoms/AItemPrice.vue';

@Component({
  components: {
    MImageWithLightbox,
    MItemActionsDropdown,
    MCustomFieldDisplay,
    MChannelCustomFieldTableSettings,
    MButtonWithIcon,
    ARouterLinkIssueDetail,
    ARouterLinkItemUrl,
    AImage,
    AItemPrice,
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ThumbsUpIcon,
    MessageSquareIcon,
    MoreVerticalIcon,
    SettingsIcon
  },
  filters: {
    removeUrl: remove1stUrlFromString,
  },
})
export default class OChannelItemsTableCustomFields extends Vue {
  @Prop()
  loading!: boolean;
  @Prop({ required: true })
  items!: Item[];
  @Prop({ required: true })
  channel!: Channel;
  @Prop({ required: true })
  total!: number;
  @Prop({ default: 20 })
  perPage!: number;
  @Prop({ required: true })
  sortingDirection!: SortDir;
  @Prop({ required: true })
  sortBy!: SortBy;
  @Prop({ required: true })
  sortDir!: SortDir;
  @Prop({ required: true })
  sortId!: string;

  loadingInternal: boolean = false;
  imageSize: Size = Size.The200X;
  sortByOptionsArray = Object.values(SortBy);
  sortDirections = SortDir;
  customFieldDisplay: CustomFieldDisplay[] = [];
  staticApiData: CustomFieldsUnitGroupeds = [];
  dropdownPosition = 'is-top-right';
  refetchItemsTimeout: any = {};
  tableKey = 0;

  get channelViewSetting () {
    return ChannelViewStore.getChannelsViewed.find((channel) => channel.slug === this.channel.slug);
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  get currentUserOwnsChannel (): boolean {
    return this.currentUser.username === this.channel.owner.username;
  }

  get noItemsInChannel () {
    return ItemsStore.getItems.items.length;
  }

  eventsBind () {
    window.addEventListener('resize', this.tableHitModalBreakpoint);
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.tableHitModalBreakpoint);
  }

  async created () {
    ChannelViewStore.pruneOldChannels();
    this.setColumns();
    this.eventsBind();
    this.tableHitModalBreakpoint();
    this.staticApiData = await StaticApiDataService.channelsCustomFieldsAllGet();
    this.checkLoadedSortBy();
  }

  mounted () {
    scrollWindowToElement('smooth', '.OChannelItemsTable', true);
    //if using browser back no items will have been loaded yet, emit to fetch the first page of results.
    if (!this.items.length) {
      this.$emit('page-change', 1);
    }
  }

  tableHitModalBreakpoint () {
    // 769px is the tablet breakpoint https://bulma.io/documentation/overview/responsiveness/
    this.dropdownPosition = window.innerWidth > 768 ? 'is-top-right' : 'is-bottom-left';
  }

  getFieldValue (object, path) {
    return getObjectValue(object, path);
  }

  allStandardOff () {
    ChannelViewStore.setAllStandardOff({ slug: this.channel.slug });
    this.forceTableReload();
  }

  allStandardOn () {
    ChannelViewStore.setAllStandardOn({ slug: this.channel.slug });
    this.forceTableReload();
  }

  standardReset () {
    ChannelViewStore.setStandardToDefault({ slug: this.channel.slug });
    this.forceTableReload();
  }

  allCustomOff () {
    ChannelViewStore.setAllCustomOff({ slug: this.channel.slug });
    this.forceTableReload();
  }

  allCustomOn () {
    ChannelViewStore.setAllCustomOn({ slug: this.channel.slug });
    this.forceTableReload();
  }

  async saveCurrentSettingsAsChannelDefault (input: {
    customFieldDisplay: CustomFieldDisplay[],
    nonCustomFields: NonCustomFields
  }) {
    await ChannelService.channelSlugSlugCustomFieldOrderPut({
      customFieldDisplay: input.customFieldDisplay.map((field) => ({
        _id: field._id,
        show: field.show
      })),
      nonCustomFields: input.nonCustomFields.map((field) => ({
        key: field.key,
        show: field.show
      })),
    }, {
      slug: this.channel.slug
    });
  }

  forceTableReload () {
    ++this.tableKey;
    this.checkSortByOverride();
  }

  // either adds the channel columns entirely, or updates the custom fields columns for an existing channel
  setColumns (): void {
    if (this.channel.customFields) {
      const channelFields = clone(this.channel.customFields);
      // if a channel doesn't exist in store yet, adds it in with the relevant custom fields so getter returns object to use
      if (!this.channelViewSetting) {
        // if we have settings stored against the channel, set these, else set the default
        ChannelViewStore.addNewChannel({
          slugCustomFields: {
            slug: this.channel.slug,
            fields: channelFields.map((field) => {
                  return {
                    ...{ show: true },
                    ...field
                  };
                }
            )
          },
          savedCustomFieldsOrder: this.channel.customFieldsOrder
        });
      } else {
        // the channel exists in the store, update the fields with channels object as names may change or fields added/removed
        ChannelViewStore.setAllCustomFields({
          slug: this.channel.slug,
          fields: channelFields.map((field) => {
            // @ts-ignore
            const exists = this.channelViewSetting.displayCustomFields.find((f) => f._id === field._id);
            return {
              ...{ show: typeof exists !== 'undefined' ? exists.show : true },
              ...field
            };
          })
        });
      }
    }
  }

  standardFieldToggle (input: { show: boolean, field: NonCustomField }) {
    const { show, field } = input;
    field.show = show;
    // save the change to the store
    ChannelViewStore.setOneStandardField({ slug: this.channel.slug, field });
    this.forceTableReload();
  }

  customFieldToggle (input: { show: boolean, field: CustomFieldDisplay }) {
    const { show, field } = input;
    field.show = show;
    // save the change to the store
    ChannelViewStore.setOneCustomField({ slug: this.channel.slug, field });
    this.forceTableReload();
  }

  customChannelFieldData (id: string): CustomField {
    return this.channel.customFields?.find((field) => field._id === id) as CustomField;
  }

  customFieldValueById (id: string, item: Item) {
    return item.editable.channel?.customFields?.find((field) => field._id === id);
  }

  getCustomColumnLabel (column) {
    const unit = CustomFields.channelUnitDisplay(column);
    return column.label + (unit ? ` (${column.unit})` : '');
  }

  onPageChange (page: number): void {
    this.$emit('page-change', page);
  }

  onSort (sortBy: SortBy, sortDir: SortDir): void {
    let sortId: string | undefined;
    let sortColumn = sortBy;
    if (!this.sortByOptionsArray.includes(sortBy)) {
      sortColumn = SortBy.CustomFields;
      sortId = sortBy;
    }
    this.$emit('sort-change', { sortBy: sortColumn, sortDir, sortId });
  }

  goToItemDetail (item: Item) {
    this.$router.push({
      name: RouteNames.ROUTE_ITEM_DETAIL_VIEW, params: {
        uniqueItemName: item.uniqueItemName,
      }
    });
  }

  channelViewSettingWatch: () => void = () => {};

  checkLoadedSortBy () {
    this.channelViewSettingWatch = this.$watch('channelViewSetting', function (newValue) {
      // wait for channel setting load, then check the sortBy is applicable
      if (typeof newValue !== 'undefined') {
        // find the column we're sorting by, look in defaults first
        if (this.channelViewSetting) {
          this.checkSortByOverride();
        }
        this.channelViewSettingWatch(); // clear watcher
      }
    }, { immediate: true });
  }

  checkSortByOverride () {
    if (!this.channelViewSetting) {
      return;
    }
    let sortObj: NonCustomFields | Partial<CustomFieldDisplay> | undefined = this.channelViewSetting.nonCustomFields.find((field) => field.key === this.sortBy);
    if (!sortObj) {
      const sortingBy = this.sortBy === SortBy.CustomFields ? this.sortId : this.sortBy;
      // allows a text representation of the custom key or the actual id as when pressed on sortable column
      sortObj = this.channelViewSetting.displayCustomFields.find((field) => field._id === sortingBy || field.fieldType === sortingBy as string);
    }
    // if no sort object found or it's set to no show, reset the sort by
    if (!sortObj || !sortObj.show) {
      // get the first default column
      const sortByOptions = Object.values(SortBy);
      const defaultSort = this.channelViewSetting.nonCustomFields.find((field) => field.show && sortByOptions.includes(field.key as SortBy));
      if( defaultSort ){
        this.onSort(defaultSort.key as SortBy, SortDir.Desc);
      } else {
        // if no default column, get the first custom
        const customSort = this.channelViewSetting.displayCustomFields.find((field) => field.show);
        if (customSort) {
          this.onSort(customSort._id as unknown as SortBy, SortDir.Desc);
        } else {
          console.error('No fields are turned on, cannot sort by anything.');
        }
      }
    }
  }

  @Watch('items')
  itemsUpdated () {
    this.forceTableReload();
  }

  @Watch('noItemsInChannel')
  itemsStoreUpdated () {
    clearTimeout(this.refetchItemsTimeout);
    this.refetchItemsTimeout = setTimeout(() => this.$emit('refetch-items'), 350);
  }
}
</script>
