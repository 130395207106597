<template>
  <b-button
      :class="classes"
      :disabled="disabled"
      :loading="loading"
      :native-type="nativeType"
      :type="type"
      class="MButtonWithIcon"
      size="is-small"
      @click="$emit('click')">
    <template v-if="iconRhs">
      <p class="text-span">
        <slot name="text"/>
      </p>
      <p class="icon-span">
        <slot name="icon"/>
      </p>
    </template>
    <template v-else>
      <p class="icon-span">
        <slot name="icon"/>
      </p>
      <p class="text-span">
        <slot name="text"/>
      </p>
    </template>
  </b-button>
</template>

<style lang="scss" scoped>
.MButtonWithIcon {
  display: flex;
  align-items: center;
  padding-top: 14px;

  ::v-deep span {
    display: flex;
    align-items: flex-start;
  }

  p {
    display: inline-block;
    padding: 0 !important;

    &.text-span {
      padding-bottom: 3px;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MButtonWithIcon extends Vue {
  @Prop({ default: 'is-outlined' })
  classes!: string;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  iconRhs!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: 'button' })
  nativeType!: string;

  @Prop({ default: 'is-primary' })
  type!: string;
}
</script>
