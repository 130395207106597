import axios, { AxiosError } from 'axios';
import Auth from '@/services/AuthenticationService';
import TokenService from '@/api/ms-authentication/services/TokenService';
import { AuthenticationStore } from '@/store';

export default async (err: AxiosError) => {
  const renewalToken = await Auth.getRenewalJWT();
  if (renewalToken) {
    try {
      const data = await TokenService.tokenRenewAccessPost({ renewalToken });
      err.config.headers = err.config.headers || {};
      err.config.headers.Authorization = 'Bearer ' + data.value;
      // @ts-ignore
      err.config.__isRetryRequest = true;
      await Auth.injectAccessJWT(data.value);
      return axios(err.config);
    } catch (e) {
      // Else the access and renew token are invalid, throw the main error
      throw err;
    }
  } else {
    // We have no access nor rewal, logout
    await AuthenticationStore.logout();
    AuthenticationStore.TOGGLE_PROMPT_LOGIN({
      state: true
    });
    throw err;
  }
};
