<template>
  <div class="sso-wrapper">
    <m-facebook-button/>
<!--    <m-google-button/>-->
  </div>
</template>

<style lang="scss" scoped>
.sso-wrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 2rem;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import MFacebookButton from '@/components/molecules/buttons/MFacebookButton.vue';
import MGoogleButton from '@/components/molecules/buttons/MGoogleButton.vue';

@Component({
  components: {
    MFacebookButton,
    MGoogleButton
  },
})
export default class MSSOButtons extends Vue {

}
</script>
