<template>
  <div class="ACustomFieldDisplayRating">
    <!-- If less than maxDisplay (default 10), show all stars -->
    <b-rate
        v-if="field.modifier <= maxDisplay"
        icon="star"
        icon-pack="vfi"
        :value="Number(field.value)"
        :max="field.modifier"
        :rtl="false"
        :disabled="true"
        :show-score="false"
    />
    <!-- Else display a number out of total next to a singular star -->
    <section v-else class="highMaxRating">
      <span>{{ field.value }} / {{ field.modifier }}</span>
      <b-rate
          v-if="displayUnit"
          class="is-inline-block ml-1"
          icon="star"
          icon-pack="vfi"
          :value="1"
          :max="1"
          :rtl="false"
          :disabled="true"
          :show-score="false"
      />
    </section>
  </div>
</template>

<style scoped lang="scss">
.ACustomFieldDisplayRating {
  .highMaxRating {
    display: flex;
    align-items: center;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomField, FieldType } from '@/api/ms-item/services/interfaces/Item';

@Component
export default class ACustomFieldDisplayRating extends Vue {
  @Prop({ required: true })
  field!: CustomField;
  @Prop({required: false, default: 10})
  maxDisplay!: number;
  @Prop({required:true, default: true})
  displayUnit!:boolean;

  created () {
    if( this.field.fieldType !== FieldType.Rating ){
      console.error('Wrong field type. Expects rating.');
    }
  }
}
</script>