<template>
  <a @click="logout()" class="menu-profile-item">
    <log-out-icon size="14" class="header-menuitem-icon"></log-out-icon>
    <span>{{ $t('dict.logout') }}</span>
  </a>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';

import { LogOutIcon } from 'vue-feather-icons';

@Component({
  components: {
    LogOutIcon
  },
})
export default class ARouterLinkLogout extends Vue {
  logout () {
    AuthenticationStore.logout();
  }
}
</script>
