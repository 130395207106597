<template>
  <div>
    <label class="label">
      <a-tooltip :tip="$t('channel.conciergeTypeTooltip')" position="is-top">
        {{ $t('channel.conciergeType') }}
      </a-tooltip>
    </label>
    <div>
      <m-radio-cards
          v-model="value"
          :value="conciergeTypes.SingleItem"
          :title="$t('channel.conciergeTypes.singleItem')"
          :description="$t('channel.conciergeTypes.singleItemDescription')"
          :disabled="disabled"
          :recalculate-accordion="recalculateAccordion"
      />
      <m-radio-cards
          v-model="value"
          :value="conciergeTypes.Collection"
          :title="$t('channel.conciergeTypes.collection')"
          :description="$t('channel.conciergeTypes.collectionDescription')"
          :disabled="disabled"
          :recalculate-accordion="recalculateAccordion"
      />
      <m-radio-cards
          v-model="value"
          :value="conciergeTypes.Travel"
          :title="$t('channel.conciergeTypes.travel')"
          :description="$t('channel.conciergeTypes.travelDescription')"
          :disabled="disabled"
          :recalculate-accordion="recalculateAccordion"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import MRadioCards from '@/storybook-components/src/stories/molecules/MRadioCards.vue';
import { ConciergeType } from '@/api/ms-channel/services/interfaces/Channel';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';

@Component({
  components: { ATooltip, MRadioCards }
})
export default class MChannelConciergeSettingsConciergeType extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: ConciergeType;
  @Prop({required: false, default: false})
  recalculateAccordion!: boolean;
  @Prop({required: false, default: false})
  disabled!: boolean;

  value: ConciergeType = ConciergeType.SingleItem;
  conciergeTypes = ConciergeType;

  created () {
    this.value = this.activeValue;
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
</script>