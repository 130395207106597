<template>
  <svg viewBox="0 0 37 37" :style="style" fill="none" xmlns="http://www.w3.org/2000/svg" @mouseover="mouseover = true" @mouseout="mouseover = false">
    <circle cx="18.2823" cy="18.3666" r="17.1199" :fill="fillColour" :stroke="circleStrokeColour" stroke-width="1.5"/>

    <path d="M9.92642 11.6372L18.2823 18.2731L9.69104 25.0959" :stroke="strokeColour" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M19.7027 11.6372L28.0586 18.2731L19.4673 25.0959" :stroke="strokeColour" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AChannelSwipeItemDown extends Vue {
  @Prop({default: '1x'})
  size!: string;
  @Prop({required: false, default: false})
  fill!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;

  mouseover = false;

  colours: {
    disabled: string,
    swipeDown: string,
    white: string
  } = {
    disabled: 'var(--grey-mid-color)',
    swipeDown: 'rgb(var(--channel-swipe-down))',
    white: 'var(--white-color)'
  };

  get style () {
    const size = parseFloat(this.size);
    return `width: ${size}rem; height: ${size}rem;`;
  }

  get fillColour () {
    if (this.disabled) {
      return this.colours.white;
    }

    return (this.mouseover || this.fill) ? this.colours.swipeDown : this.colours.white;
  }

  get strokeColour () {
    if (this.disabled) {
      return this.colours.disabled;
    }

    return (!this.mouseover && !this.fill) ? this.colours.swipeDown : this.colours.white;
  }

  get circleStrokeColour () {
    return this.disabled ? this.colours.disabled : this.colours.swipeDown;
  }
}
</script>