<template>
  <div class="OChannelEventNotificationsForm">
    <b-loading :is-full-page="false" v-model="loading"/>
    <div class="controls-container">
      <p class="control mb-3" v-html="$t('channel.notifications.explain', {channelName: channel.name})"/>
      <b-field v-for="(notification, index) in allChannelNotificationSettings" :key="index">
        <b-radio-button v-model="channelNotificationModels[index]"
                        :native-value="false"
                        type="is-danger is-light">
          <x-icon size="0.9x"/>
          <span>{{ $t('dict.no') }}</span>
        </b-radio-button>
        <b-radio-button v-model="channelNotificationModels[index]"
                        :native-value="true"
                        type="is-success is-light">
          <check-icon size="0.9x"/>
          <span>{{ $t('dict.yes') }}</span>
        </b-radio-button>
        <p class="control ml-3 notificationName">{{ $t('channel.notifications.' + notification.notificationType) }}</p>
      </b-field>
    </div>
    <div class="controls-container mt-6" v-if="isChannelEvent">
      <div class="is-flex event-notifications-title">
        <p class="modal-card-title mb-4 mr-2">{{ $t('dict.eventNotifications') }}</p>
        <mail-icon size="1.5x"/>
      </div>
      <p class="mb-4">{{ $t('channel.event.notificationSettings') }}:</p>
      <b-field v-for="(notification, index) in allChannelEventNotificationSettings" :key="index">
        <b-radio-button v-model="eventNotificationModels[index]"
                        :native-value="false"
                        type="is-danger is-light">
          <x-icon size="0.9x"/>
          <span>{{ $t('dict.no') }}</span>
        </b-radio-button>
        <b-radio-button v-model="eventNotificationModels[index]"
                        :native-value="true"
                        type="is-success is-light">
          <check-icon size="0.9x"/>
          <span>{{ $t('dict.yes') }}</span>
        </b-radio-button>
        <div class="control ml-3 notificationName">{{ writtenEventNotificationSetting[notification] }}</div>
      </b-field>
    </div>
    <div class="has-text-right">
      <b-button
          @click="close"
          class="button mt-4 mr-4"
      >
        {{ $t('dict.close') }}
      </b-button>
      <b-button
          @click="save"
          class="button is-primary mt-4"
          :disabled="loading"
      >
        {{ $t('dict.save') }}
      </b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.OChannelEventNotificationsForm {
  .controls-container {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;

    .notificationName {
      padding-top: .5rem;
    }
  }

  .event-notifications-title {
    > p {
      flex-grow: 0;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { Channel, EventNotificationsPut } from '@/api/ms-channel/services/interfaces';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import {
  NotificationSetting as EventNotificationSetting
} from '@/api/ms-channel/services/interfaces/UserSubscriptions';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';
import { camelCaseToSentenceCase, capitaliseFirstLetterOfString } from 'common-utils/string';
import { CheckIcon, MailIcon, XIcon } from 'vue-feather-icons';
import { ChannelType } from '@/api/ms-channel/services/interfaces/Channel';
import NotificationService from '@/api/ms-channel/services/NotificationService';
import {
  NotificationSetting as ChannelNotificationSetting,
  NotificationType
} from '@/api/ms-channel/services/interfaces/NotificationBaseAttributesArray';
import { AuthenticationStore, ChannelsStore, ChannelStore } from '@/store';

@Component({
  components: {
    AButtonSubmit,
    MInputWithValidation,
    CheckIcon,
    MailIcon,
    XIcon
  }
})
export default class OChannelNotificationsForm extends Vue {
  @Prop({ required: true })
  channel!: Channel;

  allChannelNotificationSettings: ChannelNotificationSetting[] = [];
  channelNotificationModels: boolean[] = [];
  channelNotificationItemAdded: boolean = false;

  userEventNotificationSettings: EventNotificationSetting[] = [];
  allChannelEventNotificationSettings: EventNotificationSetting[] = Object.values(EventNotificationSetting);
  writtenEventNotificationSetting: any = {};
  eventNotificationModels: boolean[] = [];
  mapWrittenNumbers = {
    'zero': 0,
    'one': 1,
    'two': 2,
    'three': 3,
    'four': 4,
    'five': 5,
    'six': 6,
    'seven': 7,
    'eight': 8,
    'nine': 9
  };

  loading = false;

  async created () {
    this.loading = true;
    await this.loadChannelNotificationsData();
    if (this.isChannelEvent) {
      await this.loadChannelEventNotificationsData();
    }
    this.loading = false;
  }

  get isChannelEvent () {
    return this.channel.channelType === ChannelType.Event;
  }

  async loadChannelNotificationsData (): Promise<void> {
    if (!AuthenticationStore.getAuthenticated) {
      return;
    }
    const { notificationSettings } = await NotificationService.notificationSlugGet({
      slug: this.channel.slug
    });
    // ensure we have all types regardless whether saved on db for this user yet
    const allTypes = Object.values(NotificationType);
    for (let i = 0; i < allTypes.length; i++) {
      const thisNotification = notificationSettings.find((el) => el.notificationType === allTypes[i]);
      // enabled is what is saved on db or false if nothing on db
      const thisNotificationEnabled = typeof thisNotification !== 'undefined' ? thisNotification.enabled : false;
      this.allChannelNotificationSettings.push({
        enabled: thisNotificationEnabled,
        notificationType: allTypes[i]
      });
      // set the dynamic model for this notification setting
      this.channelNotificationModels[i] = thisNotificationEnabled;
    }
  }

  async loadChannelEventNotificationsData (): Promise<void> {
    await this.orderAndTranslateWrittenNotificationSettings();
    const { notificationSettings } = await ChannelMemberService.channelMemberSlugEventNotificationsGet({
      slug: this.channel.slug
    });
    this.userEventNotificationSettings = notificationSettings;
    // set the models dynamically per notification setting
    for (let i = 0; i < this.allChannelEventNotificationSettings.length; i++) {
      this.eventNotificationModels[i] = notificationSettings.includes(this.allChannelEventNotificationSettings[i]);
    }
  }

  /**
   * Have the shortest notification setting at the top according to how a human would understand it. Three hours is
   * shorter than one day...
   *
   * Also, dynamically create the translated version of this event notification setting.
   */
  orderAndTranslateWrittenNotificationSettings () {
    const ordered = {};
    const hourSeconds = 3600;
    const daySeconds = 86400;
    const weekSeconds = 604800;
    for (let i = 0; i < this.allChannelEventNotificationSettings.length; i++) {
      const writtenWords = camelCaseToSentenceCase(this.allChannelEventNotificationSettings[i]).toLowerCase().split(' ');
      const multiplier =
        ['hour', 'hours'].includes(writtenWords[1]) ? hourSeconds :
          ['day', 'days'].includes(writtenWords[1]) ? daySeconds :
            ['week', 'weeks'].includes(writtenWords[1]) ? weekSeconds : weekSeconds + 1;
      const key = this.mapWrittenNumbers[writtenWords[0]] * multiplier;
      ordered[key] = this.allChannelEventNotificationSettings[i];
      // Setting the translated written version of this setting
      this.writtenEventNotificationSetting[this.allChannelEventNotificationSettings[i]] = capitaliseFirstLetterOfString(this.$t(`datetime.numbers.${writtenWords[0]}`) as string);
      this.writtenEventNotificationSetting[this.allChannelEventNotificationSettings[i]] += ' ' + this.$t(`datetime.dict.${writtenWords[1]}`) as string;
      this.writtenEventNotificationSetting[this.allChannelEventNotificationSettings[i]] += ' ' + this.$t('datetime.dict.before') as string;
    }

    // Numeric keys will automatically be sorted low to high, just assign values from object and we have an ordered human list
    this.allChannelEventNotificationSettings = Object.values(ordered);
  }

  async save () {
    this.loading = true;
    // send off whether the user is turning channel notifications on or off
    const channelNotificationSettings: ChannelNotificationSetting[] = this.allChannelNotificationSettings.map((el, index) => {
      return {
        enabled: this.channelNotificationModels[index],
        notificationType: el.notificationType
      };
    });
    await NotificationService.notificationSlugPut({
      notificationSettings: channelNotificationSettings
    }, {
      slug: this.channel.slug
    });
    // if this is an event channel, also send all the event channel notification settings
    let eventNotificationSettings: EventNotificationsPut | undefined = undefined;
    if (this.isChannelEvent) {
      eventNotificationSettings = {
        notificationSettings: []
      };
      for (let i = 0; i < this.allChannelEventNotificationSettings.length; i++) {
        if (this.eventNotificationModels[i]) {
          eventNotificationSettings.notificationSettings.push(this.allChannelEventNotificationSettings[i]);
        }
      }

      await ChannelMemberService.channelMemberSlugEventNotificationsPut(eventNotificationSettings, {
        slug: this.channel.slug
      });
    }
    // update the store for whether notifications are enabled on this channel
    this.setChannelStore(channelNotificationSettings, eventNotificationSettings);
    this.loading = false;
    this.close();
  }

  // determine whether any notifications are enabled after being saved
  setChannelStore (channelNotificationSettings, eventNotificationSettings) {
    let enabled = false;
    if (channelNotificationSettings.filter((notification) => notification.enabled).length > 0) {
      enabled = true;
    }
    if (!enabled && eventNotificationSettings) {
      if (eventNotificationSettings.notificationSettings.length > 0) {
        enabled = true;
      }
    }
    if (enabled) {
      ChannelStore.SET_NOTIFICATIONS_ENABLED_TRUE();
      ChannelsStore.SET_CHANNEL_MEMBER_OF_NOTIFICATIONS_ENABLED({ channel: this.channel });
    } else {
      ChannelStore.SET_NOTIFICATIONSS_ENABLED_FALSE();
      ChannelsStore.SET_CHANNEL_MEMBER_OF_NOTIFICATIONS_DISABLED({ channel: this.channel });
    }
  }

  close () {
    this.$emit('close');
  }
}
</script>
