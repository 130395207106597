<template>
  <div>
    <b-modal
      :active.sync="visible"
      aria-role="dialog"
      has-modal-card
      :can-cancel="false"
    >
      <div class="modal-card">
        <header class="modal-card-head has-text-left">
          <p class="modal-card-title">{{ activeTab ? $t('dict.welcome') : $t('dict.welcomeBack') }}</p>
          <a-close-modal-button v-on:child-output="visible = false"/>
        </header>
        <m-authentication-tabs></m-authentication-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MAuthenticationTabs from '@/components/molecules/MAuthenticationTabs.vue';
import { AuthenticationStore } from '@/store';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import { EnumRegisteredFromAction } from '@/store/modules/interfaces/AuthenticationModule';

@Component({
  components: {
    ACloseModalButton,
    MAuthenticationTabs,
  },
})
export default class MHeaderLoginTrigger extends Vue {
  @Prop({ default: '' })
  classes!: string;

  visible: boolean = false;

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  get loginPrompt () {
    return AuthenticationStore.prompt.login;
  }

  get activeTab () {
    return AuthenticationStore.prompt.loginActiveTab;
  }

  mounted () {
    this.eventsBind();

    if (!AuthenticationStore.getAuthenticated) {
      const authPromptObj = {
        state: true,
        metaData: {
          registeredFromAction: EnumRegisteredFromAction.ctaHeader
        }
      };
      if (this.$route.query.inviteCode) {
        AuthenticationStore.TOGGLE_PROMPT_SIGNUP(authPromptObj);
      } else if (this.$route.hash === '#login') {
        AuthenticationStore.TOGGLE_PROMPT_LOGIN(authPromptObj);
      } else if (this.$route.hash === '#signup') {
        AuthenticationStore.TOGGLE_PROMPT_SIGNUP(authPromptObj);
      }
    }
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  eventsBind () {
    EventBus.$on(EventBusEvents.AUTH_LOGIN_CLICKED, 'MHeaderLoginTrigger', () => {
      this.loginButtonClick();
    });
  }

  eventsUnbind () {
    EventBus.$remove(EventBusEvents.AUTH_LOGIN_CLICKED, 'MHeaderLoginTrigger');
  }

  loginButtonClick () {
    this.visible = true;
    this.$emit('openFormClicked');
  }

  @Watch('loginPrompt', { deep: true, immediate: true })
  onLoginPromptChange (newLoginPromptValue: boolean) {
    // 1st grab the user object and see if this is a new user
    if (!newLoginPromptValue) {
      AuthenticationStore.userMetaData;
    }
    if (newLoginPromptValue !== this.visible) {
      this.visible = newLoginPromptValue;
    }
  }

  @Watch('visible')
  onVisibleChange (value: boolean) {
    if (AuthenticationStore.prompt.login !== value) {
      AuthenticationStore.TOGGLE_PROMPT_LOGIN({
        state: value,
        metaData: {
          registeredFromAction: EnumRegisteredFromAction.ctaHeader
        }
      });
    }
  }
}
</script>
