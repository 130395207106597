<template>
  <div class="MFullPageErrors main-container">
    <div class="error-wrapper">
      <img
          v-if="errorCode === 404"
          src="@/assets/svg/not-found.svg"
          class="error-image"
          :alt="message"
      />
      <img
          v-else-if="[401, 403].includes(errorCode)"
          src="@/assets/svg/forbidden.svg"
          class="error-image"
          :alt="message"
      />
      <div class="error-group">
        <p class="error-message font-body-l">
          {{ message }}
        </p>
        <template v-if="errorCode === 401">
          <b-button type="is-primary" @click="launchLogin">
            {{ $t('dict.loginOrSignup') }}
          </b-button>
        </template>
        <template v-else>
          <router-link tag="b-button" class="is-primary btn-back" :to="{path: '/'}">
            {{ $t('dict.returnToHomepage') }}
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.MFullPageErrors {
  height: 80vh;

  @media screen and (max-width: 1350px) {
    .error {
      &-image {
        width: 49%;
      }

      &-group {
        margin-left: 2rem;
      }
    }
  }

  @media screen and (max-width: 950px) {
    .error {
      &-wrapper {
        flex-direction: column;
      }

      &-image {
        width: 70%;
      }

      &-group {
        margin-top: 3rem;
        margin-left: 0;
      }
    }
  }
}

.error {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &-image {
    width: 34.5rem;
  }

  &-group {
    margin-left: 8rem;
    text-align: center;
  }

  &-message {
    margin-bottom: 2rem;

    span {
      white-space: nowrap;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component
export default class MFullPageErrors extends Vue {
  @Prop({ required: true })
  errorCode!: number;

  @Prop({ required: true })
  message!: string;

  launchLogin () {
    AuthenticationStore.setCurrentRouteAsForwardingLocation(this.$route);
    EventBus.$emit(EventBusEvents.AUTH_LOGIN_CLICKED);
  }
}
</script>
