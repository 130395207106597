<template>
  <div class="TMainLayout">
    <m-app-header/>
    <section>
      <router-view/>
    </section>
    <m-footer-general v-if="showFooter"/>
  </div>
</template>

<style lang="scss">
.TMainLayout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import MAppHeader from '../molecules/MAppHeader.vue';
import OModalsContainer from '@/components/organisms/OModalsContainer.vue';
import { AuthenticationStore } from '@/store';
import MFooterGeneral from '@/components/molecules/MFooterGeneral.vue';

@Component({
  components: {
    MFooterGeneral,
    MAppHeader,
    OModalsContainer
  },
})
export default class TMainLayout extends Vue {
  style: { minHeight: string } = {
    minHeight: (window.innerHeight - 100) + 'px'
  };

  get authenticated (): boolean {
    return AuthenticationStore.getAuthenticated;
  }

  get showFooter () {
    return this.$route.matched.some((record) => record.meta.landingPage);
  }

  created () {
    this.setStyle();
    window.addEventListener('resize', this.setStyle);
  }

  setStyle () {
    this.style.minHeight = (window.innerHeight - 100) + 'px';
  }
}
</script>
