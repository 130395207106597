<template>
  <div class="OB2CMessage">
    <div class="business-image-wrapper">
      <a-business-profile-image
          :base-url-and-path="baseURLAndPath"
          :image-path="domainProfileImageUrl()"
          :alt-text="businessName"
          :size="imageSize"
      />
      <div class="expiry-message">
        <section v-if="!expiredAt">
          <span v-if="expiryDate">{{ textElements.expires }}: <br/>{{ expiryDate }}</span><span
            v-else>{{ textElements.doesntExpire }}</span>
        </section>
        <section v-else>
          <span>{{ textElements.expired }}</span>
        </section>
      </div>
    </div>

    <div class="b2c-message-wrapper break-words" :class="{'expired-message': expiredAt}">
      <div class="b2c-message-header">
        <div class="b2c-business-info">
          <strong>{{ businessName }}</strong>
        </div>

        <div class="b2c-message-actions">
          <b-dropdown position="is-bottom-left" v-if="!expiredAt">
            <template #trigger>
              <more-vertical-icon role="button" size="1x" class="is-clickable"/>
            </template>

            <b-dropdown-item @click="markAsNotInterested">
              {{ textElements.notInterested }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <p class="b2c-message">{{ message }}</p>

      <div class="is-flex is-justify-content-flex-end">
        <div class="clickout-link-container has-text-centered">
          <a-button-link
              :link="clickoutUrl"
              :button-classes="clickoutButtonClass()"
              v-on:click="goToClickout"
          >
            {{ textElements.clickoutButton }}
          </a-button-link>
          <div class="expired-message" v-if="expiredAt">
            <span>({{ textElements.expired|lowerCase }} {{ expiredAt }})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.OB2CMessage {
  display: flex;

  .business-image-wrapper {
    flex-shrink: 0;
    max-width: 110px;

    > div {
      width: 80%;
    }

    .expiry-message {
      margin-top: 0.5rem;
      opacity: .6;
      font-size: smaller;
      font-style: italic;
      text-align: center;
    }
  }

  @media screen and (max-width: 700px) {
    .business-image-wrapper > div {
      width: 65%;
      margin: 0 auto;
    }
  }

  .b2c-message-wrapper {
    flex-grow: 1;
    padding: 1rem;
    background-color: #fff;

    &.expired-message {

    }

    .b2c-message-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: .5rem;

      .b2c-business-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      .b2c-message-actions {
        display: flex;
        gap: 8px;
        align-items: flex-start;
      }
    }

    .b2c-message {
      font-size: 14px;
      margin-bottom: 1rem;
    }
  }

  .clickout-link-container {
    .expired-message {
      margin-top: .25rem;
      color: var(--grey-dark-color);
      font-style: italic;
      font-size: smaller;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ABusinessProfileImage from '../atoms/ABusinessProfileImage.vue';
import AButtonLink from '../atoms/links/AButtonLink.vue';
import { MoreVerticalIcon } from 'vue-feather-icons';

interface Parameter {
  key: string;
  value: string;
}

interface OB2CMessageTextElements {
  notInterested: string;
  clickoutButton: string;
  expires: string;
  expired: string;
  doesntExpire: string;
}

interface Locale {
  language: string;
}

@Component({
  components: {
    AButtonLink,
    ABusinessProfileImage,
    MoreVerticalIcon
  },
  filters: {
    lowerCase: (str: string) => {
      return str.toLowerCase();
    }
  }
})
export default class OB2CMessage extends Vue {
  @Prop({required: true})
  baseUrlAndPath!: string;

  @Prop({ required: true })
  messageId!: string;

  @Prop({ required: true })
  domainId!: string;

  @Prop({ required: true })
  businessName!: string;

  @Prop({ required: true })
  message!: string;

  @Prop({ required: false })
  expiredAt?: Date;

  @Prop({ required: false })
  expiryDate?: Date;

  @Prop({ required: true })
  url!: string;

  @Prop()
  imageSize!: string;

  @Prop({ required: false })
  parameters?: Parameter[];

  @Prop({ required: false })
  componentViewed?: boolean;

  @Prop({
    type: Object,
    default: () => ({
      notInterested: 'Not interested',
      clickoutButton: 'Go to shop',
      expires: 'Expires',
      expired: 'Expired',
      doesntExpire: 'Doesn\'t expire'
    })
  })
  textElements!: OB2CMessageTextElements;

  @Prop({
    type: Object,
    default: () => ({
      language: 'enGB'
    })
  })
  locale!: Locale;

  viewedYet: boolean = false;
  clickoutUrl: string = '';

  created () {
    this.generateClickoutUrl();
  }

  generateClickoutUrl () {
    let clickoutUrl = this.url;
    if (this.parameters) {
      let params = '?';
      for (let i = 0; i < this.parameters?.length; i++) {
        if (this.parameters[i].key && this.parameters[i].value) {
          params += this.parameters[i].key;
          params += '=';
          params += this.parameters[i].value;
          params += '&';
        }
      }
      params = params.replace(/&$/, '');
      clickoutUrl += params;
    }
    this.clickoutUrl = encodeURIComponent(clickoutUrl);
  }

  domainProfileImageUrl (): string {
    return 'business/domain-profile-image/' + this.domainId + '.png';
  }

  clickoutButtonClass (): string {
    return this.expiredAt ? 'is-secondary' : 'is-primary';
  }

  goToClickout () {
    this.$emit('clickout', { clickoutUrl: this.clickoutUrl, messageId: this.messageId });
  }

  markAsNotInterested () {
    this.$emit('not-interested', this.messageId);
  }

  // Only fire off the viewed stat if the user looks at the message, and only do it the one time per page load
  @Watch('componentViewed', { immediate: true })
  viewedB2CStats () {
    if (this.componentViewed && !this.viewedYet) {
      this.viewedYet = true;
      this.$emit('viewed', this.messageId);
    }
  }
}
</script>
