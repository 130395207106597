<template>
  <div :class="{ 'own-msg': message.author.username === currentUser.username }" class="MChatMessage break-words">
    <div class="message-wrapper">
      <section v-if="reservedUsernamesArray.includes(message.author.username)" class="user-name">
        <a-lightbulb-on-outline-icon v-if="message.author.username === reservedUsernames.ResearchConcierge" size="2.1" :keep-y-zero="true" class="user-image mr-2" />
      </section>
      <a-router-link-profile v-else-if="message.author.username !== currentUser.username" :username="message.author.username"
                             class="user-image">
        <a-user-profile-pic :imageSize="imageSize" :username="message.author.username"/>
      </a-router-link-profile>
      <div class="message-content">
        <div class="msg">
          <section v-if="reservedUsernamesArray.includes(message.author.username)" class="user-name">
            <span class="font-body-s">{{ message.author.firstName }} {{ message.author.lastName }}</span>
          </section>
          <a-router-link-profile
              v-else
              :username="message.author.username"
              class="user-name"
          >
            <span class="font-body-s">
              {{
                (message.author.username !== currentUser.username ?
                        message.author.firstName :
                        $t('dict.you')
                ) | ellipsisString
              }}:
            </span>
          </a-router-link-profile>
          <p class="pl-2 pt-1" v-html="nl2br(formatMessageContent(message.msg))"/>
        </div>
        <span class="msg-created">{{ message.createdAt | formatDate }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.MChatMessage {
  position: relative;
  margin: .8rem 3.5rem .5rem 1rem;

  &.own-msg {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 5.5rem;

    .message-content {
      background-color: var(--darker-bg-color);
    }
  }
}

.message-wrapper {
  display: flex;
}

//.user-name {
//  display: inline-block;
//  margin-bottom: 3px;
//  margin-left: 44px; // image size + spacing
//}

.user-image {
  flex-shrink: 0;
}

.message-content {
  flex-grow: 1;
  padding: .5rem;
  border-radius: var(--default-border-radius);
  box-shadow: 0 3px 6px rgba(#000, .2);
}

.msg-created {
  display: block;
  text-align: right;
}

.msg {
  max-width: 330px;

  @media screen and (max-width: 500px) {
    max-width: 245px;
  }

  @media screen and (max-width: 400px) {
    max-width: 200px;
  }

  ::v-deep a {
    transition: color .2s;

    &:hover {
      color: var(--secondary-color);
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Datum as ChatsDatum } from '@/api/ms-chat/services/interfaces/Chats';
import { format } from 'timeago.js';

import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { AuthenticationStore } from '@/store';
import linkedText from '@/utils/linkedText';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import { formatMentionUserContent } from '@/utils/formatMentionUsersData';
import nl2br from '@/utils/nl2br';
import ellipsisString from '@/utils/ellipsisString';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';

enum ReservedUsernames {
  ResearchConcierge = '##Research.concierge##'
}

@Component({
  components: {
    ALightbulbOnOutlineIcon,
    ARouterLinkProfile,
    AUserProfilePic
  },
  filters: {
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
    ellipsisString
  }
})
export default class MChatMessage extends Vue {
  @Prop()
  message!: ChatsDatum;

  imageSize: Size = Size.The32X32;

  reservedUsernames = ReservedUsernames;
  reservedUsernamesArray = Object.values(ReservedUsernames);

  nl2br = nl2br;

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  formatMessageContent (input: string): string {
    let comment = linkedText(input);

    return formatMentionUserContent(comment);
  }

}
</script>