<template>
  <b-field v-if="!loading" :grouped="isGrouped" class="MChannelCustomField form-group" :addons="false">
    <b-field class="custom-field-name">
      <!-- Name of field -->
      <template #label>
        {{ $t('dict.name') }}
      </template>
      <m-input-with-validation
          v-model="value.label"
          :placeholder="$t('dict.name')"
          name="label"
          rules="required"
          :disabled="disabled"
      />
    </b-field>
    <!-- Data type drop down -->
    <b-field expanded>
      <template #label>
        {{ $t('dict.unit') }}
        <b-field class="float-right">
          <b-switch
              v-model="measurementSystem"
              :true-value="measurementSystems.Metric"
              :false-value="measurementSystems.Imperial"
              type="is-info"
              passive-type="is-warning"
              size="is-small"
          >
            {{ $t('channel.customFields.measurementSystem.' + measurementSystem)|strToLower }}
          </b-switch>
        </b-field>
      </template>
      <b-select
          :placeholder="$t('channel.customFields.selectUnit')"
          class="control default-select"
          v-model="selectModel"
          @input="unitSelected"
      >
        <optgroup v-for="(fieldType, index) in dataTypes" :key="index"
                  :label="$t('channel.customFields.categories.' + fieldType.fieldType)">
          <template v-if="fieldType.fieldType === fieldTypes.Price">
            <option :value="{fieldType: fieldType.fieldType, value: defaultCurrency}">
              {{ $t('channel.customFields.categories.price') }}
            </option>
          </template>
          <template v-else>
            <template v-for="(value, index) in fieldType.values">
              <option
                  v-if="value.measurementSystem === measurementSystems.Na || value.measurementSystem === measurementSystem"
                  :key="index"
                  :value="{fieldType: fieldType.fieldType, value: value.value}"
                  v-html="unitOptionText(fieldType.fieldType, value)"
              />
            </template>
          </template>
        </optgroup>
      </b-select>
    </b-field>

    <!-- Additional optional field appears for certain fields -->
    <template v-if="additionalDataNeeded">
      <!-- Rating -->
      <b-field expanded v-if="value.fieldType === fieldTypes.Rating" class="additional-data-column">
        <template #label>
          <p class="line-clamp-1">{{ $t('channel.customFields.maxRating') }}</p>
        </template>
        <m-input-with-validation
            v-model="value.modifier"
            :placeholder="$t('channel.customFields.maxRating')"
            name="modifier"
            type="number"
            rules="required"
            :min="1"
        />
      </b-field>
      <!-- Price -->
      <b-field expanded v-if="value.fieldType === fieldTypes.Price" :label="$t('channel.customFields.defaultCurrency')"
               class="additional-data-column">
        <b-select
            :placeholder="$t('channel.customFields.defaultCurrency')"
            class="control default-select"
            v-model="value.unit"
        >
          <option v-for="(val, index) in currencies" :key="index" :value="val.value">{{ val.label }}</option>
        </b-select>
      </b-field>
      <!-- Custom select -->
      <m-channel-custom-field-custom-select
          v-if="value.fieldType === fieldTypes.CustomSelect"
          v-model="value.options"
      />
    </template>
    <b-field class="has-text-right border-left pl-2 field-control-container">
      <div class="is-flex is-flex-direction-column field-control">
        <arrow-up-icon v-if="showModeControls" @click="$emit('moveUp')" class="is-clickable"/>
        <arrow-down-icon v-if="showModeControls" @click="$emit('moveDown')" class="is-clickable"/>

        <trash2-icon v-if="isGrouped" size="1.5x" class="mt-5 is-clickable" @click="removeCustomField"/>
        <b-button @click="removeCustomField" class="is-clickable" v-else>
          {{ $t('channel.customFields.removeField') }}
        </b-button>
      </div>
    </b-field>
  </b-field>
</template>

<style scoped lang="scss">
.MChannelCustomField {
  padding: .5rem;

  &:hover {
    background-color: var(--grey-lightest-color);
  }

  .float-right {
    float: right;
  }

  .is-grouped {
    .custom-field-name {
      width: 33%;
    }

    .additional-data-column {
      width: 25% !important;
    }
  }

  @media only screen and (max-width: 710px) {
    .field-control-container {
      border: none !important;
      margin-top: 1.75rem;
    }
    .field-control {
      display: inline-block !important;
    }
  }
}
</style>

<script lang="ts">
import { ArrowDownIcon, ArrowUpIcon, Trash2Icon } from 'vue-feather-icons';
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { FieldType } from '@/api/ms-channel/services/interfaces/Channel';
import { CustomFieldsUnitGroupeds } from '@/api/ms-static-api-data/services/interfaces';
import { CustomFieldEntry } from '@/components/molecules/forms/MChannelCustomFields.vue';
import { MeasurementSystem, Value } from '@/api/ms-static-api-data/services/interfaces/CustomFieldsUnitGroupeds';
import MChannelCustomFieldCustomSelect from '@/components/molecules/forms/MChannelCustomFieldCustomSelect.vue';

interface SelectUnit {
  fieldType?: FieldType,
  value?: string
}

@Component({
  components: {
    MChannelCustomFieldCustomSelect,
    MInputWithValidation,
    ArrowUpIcon,
    ArrowDownIcon,
    Trash2Icon,
  },
  filters: {
    strToLower: (str) => {
      return str.toLowerCase();
    }
  }
})
export default class MChannelCustomField extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: CustomFieldEntry;
  @Prop({ required: false, default: false })
  disabled!: boolean;
  @Prop({ required: true })
  dataTypes!: CustomFieldsUnitGroupeds;
  @Prop({ required: true })
  containerWidth!: number;
  @Prop({ required: true })
  showModeControls!: boolean;

  loading = true;
  isGrouped = true;
  groupedWidthBreakPoint = 500;
  measurementSystem: MeasurementSystem = MeasurementSystem.Metric;
  measurementSystems = MeasurementSystem;
  fieldTypes = FieldType;
  additionalDataNeeded = false;
  defaultCurrency: string = 'GBP';
  currencies: Value[] = [];

  selectModel: SelectUnit = {};

  value: CustomFieldEntry = {
    componentId: 0,
    label: '',
    fieldType: FieldType.Area,
    unit: '',
    options: []
  };

  created () {
    this.value = this.activeValue;
    if (this.value.fieldType && this.value.unit) {
      this.selectModel.fieldType = this.value.fieldType;
      this.selectModel.value = this.value.unit;
      this.determineMeasurementSystem();
    }
    this.setAdditionalDataNeeded();
    this.loading = false;
  }

  mounted () {
    this.storeCurrencies();
  }

  // when loading component with data already entered, figure out which measurement system the value is from
  determineMeasurementSystem () {
    const dataType = this.dataTypes.filter((el) => el.fieldType === this.value.fieldType)[0];
    const dataField = dataType.values.filter((el) => el.value === this.value.unit)[0];
    this.measurementSystem = dataField.measurementSystem === MeasurementSystem.Imperial ? MeasurementSystem.Imperial : MeasurementSystem.Metric;
    // if price set the default currency to what currency was selected
    if (this.value.fieldType === FieldType.Price) {
      this.defaultCurrency = this.value.unit;
    }
  }

  // prices are returned with data types, extract them into a variable for creating the drop down
  storeCurrencies () {
    this.currencies = this.dataTypes.filter((el) => el.fieldType === FieldType.Price)[0].values;
  }

  unitSelected (th: SelectUnit) {
    if (th.fieldType && th.value) {
      this.value.fieldType = th.fieldType;
      this.value.unit = th.value;
    }
  }

  setAdditionalDataNeeded () {
    this.additionalDataNeeded = this.selectModel.fieldType ?
      [FieldType.Price, FieldType.Rating, FieldType.CustomSelect].includes(this.selectModel.fieldType) :
      false;
  }

  // determines whether to display the symbol for the unit next to the name, type NA do not display unless Electrical or Sound
  unitOptionText (fieldType: FieldType, value: Value): string {
    const label = this.$t(`channel.customFields.units.${value.measurementSystem}.${value.key}`) as string;
    return value.measurementSystem === MeasurementSystem.Na && ![FieldType.Electrical, FieldType.Sound].includes(fieldType) ? label : `${label} (${value.value})`;
  }

  removeCustomField () {
    this.$buefy.dialog.confirm({
      title: this.$t('dict.areYouSure') as string,
      message: this.$t('dict.areYouSure') as string,
      onConfirm: () => {
        this.$emit('removeCustomField');
      }
    });
  }

  @Watch('selectModel', { immediate: true })
  handleDataTypeSelection () {
    this.setAdditionalDataNeeded();
  }

  @Watch('value', { deep: true })
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }

  @Watch('containerWidth', { immediate: true })
  setGrouped () {
    this.isGrouped = this.containerWidth > this.groupedWidthBreakPoint;
  }
}
</script>
