export interface RegisterEmailPost {
  allTermsAgreed: Date;
  email: string;
  firstName: string;
  firstPageLoaded?: string;
  inviteCode?: string;
  language?: Language;
  lastName: string;
  password: string;
  passwordConfirm: string;
  registeredForPurpose?: RegisteredForPurpose;
  registeredFromAction?: string;
  registeredFromHref: string;
}

export enum Language {
  DeDE = 'deDE',
  EnGB = 'enGB',
  EsES = 'esES',
  FrFR = 'frFR',
  HrHR = 'hrHR',
  ItIT = 'itIT',
  NlNL = 'nlNL',
  PtBR = 'ptBR',
  RoRO = 'roRO',
}

export enum RegisteredForPurpose {
  Normal = 'normal',
  ProductResearcher = 'productResearcher',
}
