<template>
  <div class="OModalsContainer">
    <!-- Opening welcome modal -->
    <b-modal
        :active.sync="showOpeningWelcomeModal"
        :can-cancel="false"
        aria-role="dialog"
        has-modal-card
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title has-text-centered">
            {{ $t('welcomeGuide.title') }}
          </p>
        </header>
        <section class="modal-card-body">
          <o-welcome-guide v-on:welcomed="() => showOpeningWelcomeModal = false"/>
        </section>
        <div class="language-switcher">
          <m-language-switcher/>
        </div>
      </div>
    </b-modal>

    <!-- Sort and refine items modal -->
    <o-modal-wrapper :show-modal="showItemFiltersModal" v-on:close="() => showItemFiltersModal = false">
      <template slot="title">
        {{ $t('dict.itemFilters') }}
      </template>
      <template slot="body">
        <o-items-filter-form v-on:close="() => showItemFiltersModal = false"/>
      </template>
    </o-modal-wrapper>

    <!-- Sort and refine channels modal -->
    <o-modal-wrapper :show-modal="showChannelsFiltersModal" v-on:close="() => showChannelsFiltersModal = false">
      <template slot="title">
        {{ $t('dict.channelFilters') }}
      </template>
      <template slot="body">
        <o-channels-filter-form
            v-on:close="() => showChannelsFiltersModal = false"/>
      </template>
    </o-modal-wrapper>

    <!-- Report Channel Modal -->
    <o-modal-wrapper :show-modal="showChannelReportModal" v-on:close="() => showChannelReportModal = false">
      <template #title>
        {{ $t('dict.reportChannel') }}
      </template>
      <template #body>
        <o-channel-report-form
            :channel="channel"
            v-on:close="() => showChannelReportModal = false"
        />
      </template>
    </o-modal-wrapper>

    <!-- Channel Event Notifications Modal -->
    <o-modal-wrapper :show-modal="showChannelNotificationsModal"
                     v-on:close="() => showChannelNotificationsModal = false">
      <template #title>
        {{ $t('dict.channelNotifications') }}
      </template>
      <template #body>
        <o-channel-notifications-form
            :channel="channel"
            v-on:close="() => showChannelNotificationsModal = false"
        />
      </template>
    </o-modal-wrapper>

    <!-- Channel Info Modal -->
    <b-modal :active.sync="showChannelInfoModal" scroll="keep" class="channel-info-modal">
      <m-channel-information
          :channel="channel"
          class="card"
          v-on:close-info="showChannelInfoModal = false"
      />
    </b-modal>

    <!-- Archive Channel Modal -->
    <o-modal-wrapper :show-modal="showArchiveChannelToggleModal" v-on:close="showArchiveChannelToggleModal = false">
      <template #body>
        <o-archive-channel
            :channel="archiveChannel.channel"
            :to-archive="archiveChannel.toArchive"
            v-on:child-output="showArchiveChannelToggleModal = false"
        />
      </template>
    </o-modal-wrapper>

    <!-- Report Item Modal -->
    <o-modal-wrapper :show-modal="showItemReportModal" v-on:close="showItemReportModal = false">
      <template #title>
        {{ $t('dict.reportItem') }}
      </template>
      <template #body>
        <o-item-report-content-form
            v-if="item"
            :item="item"
            v-on:child-output="showItemReportModal = false"
        />
      </template>
    </o-modal-wrapper>

    <!-- Report Item Recommendation Modal -->
    <o-modal-wrapper :show-modal="showRecommendationReportModal" v-on:close="showRecommendationReportModal = false">
      <template #title>
        {{ $t('dict.reportRecommendation') }}
      </template>
      <template #body>
        <o-recommendation-report-content-form
            v-if="recommendation"
            :recommendation="recommendation"
            v-on:child-output="showRecommendationReportModal = false"
        />
      </template>
    </o-modal-wrapper>

    <!-- Information Modal (type comment) -->
    <b-modal
        :active.sync="showInfoModal"
        aria-role="dialog"
        has-modal-card
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $t(`modals.info.${showInfoType}.title`) }}
          </p>
          <a-close-modal-button icon-size="2x" v-on:child-output="showInfoModal = false"/>
        </header>
        <section class="modal-card-body">
          <p v-html="$t(`modals.info.${showInfoType}.text1`)"></p>
          <p class="mt-4" v-html="$t(`modals.info.${showInfoType}.text2`)"></p>
        </section>
      </div>
    </b-modal>

    <!-- Watch item modal -->
    <o-modal-wrapper :show-modal="showPriceWatchModal" v-on:close="showPriceWatchModal = false">
      <template #title>
        <activity-icon class="dropdown-item-icon" size=".9x"/>
        {{ $t('modals.itemPriceWatch.title') }}
      </template>
      <template #body>
        <o-price-watch-set-form :item="itemToWatch" v-on:close="showPriceWatchModal = false"/>
      </template>
    </o-modal-wrapper>

    <!-- Delete item modal -->
    <o-modal-wrapper :show-modal="showDeleteModal" v-on:close="showDeleteModal = false">
      <template #title>
        {{ $t('dict.deleteItem') }}
      </template>
      <template #body>
        <m-delete-modal-body
            v-if="deleteItemData"
            :item="deleteItemData"
            v-on:child-output="showDeleteModal = false"
        />
      </template>
    </o-modal-wrapper>

    <!-- Shopping list modal - add -->
    <o-modal-wrapper
        :show-modal="showShoppingListModal"
        v-on:close="showShoppingListModal = false"
        :hide-close="true"
    >
      <template #title>
        <div class="is-flex is-align-content-flex-start">
          <shopping-bag-icon size="1x"/>
          <span class="ml-2">{{ $t('modals.shoppingListAdd.title') }}</span>
        </div>
      </template>
      <template #body>
        <m-modal-shopping-list
            :item="shoppingListData"
            v-on:close-modal="showShoppingListModal = false"
        />
      </template>
    </o-modal-wrapper>

    <!-- Shopping list modal - remove -->
    <b-modal :active.sync="showShoppingListRemoveModal" aria-role="dialog" has-modal-card>
      <div class="modal-card remove-sl-modal">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ $t('dict.removeFromShoppingList') }}
          </p>
          <a-close-modal-button icon-size="2x" v-on:child-output="showShoppingListRemoveModal = false"/>
        </header>
        <m-shopping-list-remove-modal
            v-if="shoppingListRemoveData"
            :get-got-id="shoppingListRemoveData.getGotId"
            :item-name="shoppingListRemoveData.itemName"
            v-on:close-modal="showShoppingListRemoveModal = false"
        />
      </div>
    </b-modal>

    <!-- Select language -->
    <o-modal-wrapper :show-modal="showSelectLanguageModal" v-on:close="showSelectLanguageModal = false">
      <template #title>
        {{ $t('dict.selectLanguage') }}
      </template>
      <template #body>
        <m-select-language-modal v-on:close="showSelectLanguageModal = false"/>
      </template>
    </o-modal-wrapper>

    <!-- Major app update available - non removable modal -->
    <o-modal-wrapper :show-modal="showAppUpdateMajor" :hide-close="true">
      <template #title>
        {{ $t('newReleaseAvailable.app.major.title') }}
      </template>
      <template #body>
        <m-update-app-major :app-versions="appUpdateMajor"/>
      </template>
    </o-modal-wrapper>

    <!-- Book a demo modal-->
    <o-modal-wrapper :show-modal="showBookADemo" v-on:close="showBookADemo=false">
      <template slot="title">
        {{ $t('forBusiness.getInTouch.title') }}
        <p class="font-body-s pl-5">{{ $t('forBusiness.getInTouch.learnMore') }}</p>
      </template>

      <template slot="body">
        <div class="pb-4">
          <o-pricing-contact-form
              style="text-align: left;"
              v-on:cancel="showBookADemo=false"
              v-on:sent="showBookADemo=false"
          />
        </div>
      </template>
    </o-modal-wrapper>

    <!-- Book a demo modal-->
    <o-modal-wrapper :show-modal="inviteByEmail" v-on:close="inviteByEmail=false">
      <template slot="title">
        <p class="has-text-centered">{{ $t('page.invitations.sendingAnInvite') }}</p>
      </template>

      <template slot="body">
        <m-email-invitation-form
            :code="inviteByEmailPayload.code"
            :email="inviteByEmailPayload.email"
            :email-disabled="inviteByEmailPayload.emailDisabled"
            :first-name="inviteByEmailPayload.firstName"
            :last-name="inviteByEmailPayload.lastName"
            :message="inviteByEmailPayload.message"
            :message-disabled="inviteByEmailPayload.messageDisabled"
            :invitation-hooks="inviteByEmailPayload.invitationHooks"
            :include-code-to-copy="!(typeof inviteByEmailPayload.includeCodeToCopy !== 'undefined' && !inviteByEmailPayload.includeCodeToCopy)"
            v-on:close="inviteByEmail=false"
        />
      </template>
    </o-modal-wrapper>

    <!-- Custom modal for the channel swipe items component -->
    <o-channel-swipe-items
        v-if="channelSwipeItemsLoadCount > 0"
        v-model="channelSwipeItems"
        :channel="channelSwipeItemsPayload.channel"
        :starting-unique-item-name="channelSwipeItemsPayload.startingUniqueItemName"
    />
  </div>
</template>

<style lang="scss" scoped>
.language-switcher {
  margin-left: auto;
  padding-right: 1rem;
  padding-bottom: .8rem;
}

.shopping-list-modal {
  max-width: 460px;

  .modal-card-head {
    width: 100%;
  }

  .modal-card-title {
    margin-right: 5px;

    @media screen and (max-width: 500px) {
      flex-shrink: 1;
    }
  }
}

.channel-info-modal {
  z-index: 28 !important; // place this modal behind the channel chat (z-indexed at 29)
}

.remove-sl-modal .modal-card-title {
  @media screen and (max-width: 500px) {
    flex-shrink: 1;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ActivityIcon, ShoppingBagIcon } from 'vue-feather-icons';
import EventBus, { EventBusEvents } from '@/EventBus';
import { Item, Recommendation } from '@/api/ms-item/services/interfaces';
import OItemReportContentForm from '@/components/organisms/forms/OItemReportContentForm.vue';
import MDeleteModalBody from '@/components/molecules/MDeleteModalBody.vue';
import { AuthenticationStore } from '@/store';
import OWelcomeGuide from '@/components/organisms/OWelcomeGuide.vue';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import MPromptBanner from '@/storybook-components/src/stories/molecules/MPromptBanner.vue';
import MModalShoppingList from '@/components/molecules/shoppingList/MModalShoppingList.vue';
import MShoppingListRemoveModal from '@/components/molecules/shoppingList/MShoppingListRemoveModal.vue';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';
import { Channel } from '@/api/ms-channel/services/interfaces';
import OChannelReportForm from '@/components/organisms/forms/OChannelReportForm.vue';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';
import clone from '@/utils/clone';
import OItemsFilterForm from '@/components/organisms/forms/OItemsFilterForm.vue';
import OWelcomeGuideRaffle from '@/components/organisms/OWelcomeGuideRaffle.vue';
import isApp from '@/utils/isApp';
import OArchiveChannel from '@/components/organisms/OArchiveChannel.vue';
import OChannelsFilterForm from '@/components/organisms/forms/OChannelsFilterForm.vue';
import { AppType } from '@/api/ms-version-control/services/interfaces/LatestVersionGetQuery';
import MUpdateAppMajor from '@/components/molecules/MUpdateAppMajor.vue';
import config from '@/config';
import OChannelNotificationsForm from '@/components/organisms/forms/OChannelNotificationsForm.vue';
import OPricingContactForm from '@/components/organisms/forms/OPricingContactForm.vue';
import MSelectLanguageModal from '@/components/molecules/MSelectLanguageModal.vue';
import OPriceWatchSetForm from '@/components/organisms/forms/OPriceWatchSetForm.vue';
import MEmailInvitationForm from '@/components/molecules/forms/MEmailInvitationForm.vue';
import { InvitationHook } from '@/api/ms-authentication/services/interfaces/UserInvitationsAssignPost';
import ORecommendationReportContentForm from '@/components/organisms/forms/ORecommendationReportContentForm.vue';
import MChannelInformation from '@/components/molecules/MChannelInformation.vue';
import OChannelSwipeItems from '@/components/organisms/OChannelSwipeItems.vue';

export interface OModalsContainerReportItemPayload {
  item: Item,
}

export interface OModalsContainerRecommendationPayload {
  recommendation: Recommendation,
}

export interface OModalsContainerDeleteItemPayload {
  uniqueItemName: string;
  title: string;
}

export interface OModalsContainerRemoveShoppingListItemPayload {
  itemName: string;
  getGotId: string;
}

export enum OModalsContainerInfoTypeEnum {
  COMMENT_TYPE = 'commentTypeInfo',
}

export interface OModalsContainerInfoType {
  type: OModalsContainerInfoTypeEnum;
}

export interface OModalsContainerArchiveChannel {
  channel: Channel,
  toArchive?: boolean
}

export interface OModalsContainerChannelAsPayload {
  channel: Channel,
}

export interface OModalsContainerChannelSwipeItems {
  channel: Channel;
  startingUniqueItemName?: string;
}

export interface OModalsContainerAppUpdateAvailable {
  appType: AppType,
  localVersion: string,
  latestVersion: string
}

export interface OModalsContainerInviteByEmail {
  code?: string;
  email?: string;
  emailDisabled?: boolean;
  message?: string;
  messageDisabled?: boolean;
  invitationHooks?: InvitationHook[];
  firstName?: string;
  lastName?: string;
  includeCodeToCopy?: boolean;
}

@Component({
  components: {
    OChannelSwipeItems,
    MChannelInformation,
    ORecommendationReportContentForm,
    MEmailInvitationForm,
    ActivityIcon,
    OPriceWatchSetForm,
    MSelectLanguageModal,
    OPricingContactForm,
    OChannelNotificationsForm,
    MUpdateAppMajor,
    OChannelsFilterForm,
    OArchiveChannel,
    OWelcomeGuideRaffle,
    OItemsFilterForm,
    OModalWrapper,
    OChannelReportForm,
    ACloseModalButton,
    MShoppingListRemoveModal,
    MModalShoppingList,
    MPromptBanner,
    MLanguageSwitcher,
    OWelcomeGuide,
    MDeleteModalBody,
    OItemReportContentForm,
    ShoppingBagIcon
  }
})
export default class OModalsContainer extends Vue {
  channel: Channel | null = null;
  item: Item | null = null;
  recommendation: Recommendation | null = null;
  deleteItemData: OModalsContainerDeleteItemPayload | null = null;
  shoppingListData: Item | null = null;
  shoppingListRemoveData: OModalsContainerRemoveShoppingListItemPayload | null = null;
  showInfoType: OModalsContainerInfoTypeEnum = OModalsContainerInfoTypeEnum.COMMENT_TYPE;
  archiveChannel: OModalsContainerArchiveChannel | {} = {};
  appUpdateMajor: OModalsContainerAppUpdateAvailable | {} = {};
  inviteByEmailPayload: OModalsContainerInviteByEmail = {};
  channelSwipeItemsPayload: OModalsContainerChannelSwipeItems | null = null;
  itemToWatch: Item | {} = {};

  showChannelsFiltersModal: boolean = false;
  showOpeningWelcomeModal: boolean = false;
  showItemReportModal: boolean = false;
  showRecommendationReportModal: boolean = false;
  showChannelReportModal: boolean = false;
  showChannelNotificationsModal: boolean = false;
  showChannelInfoModal: boolean = false;
  showArchiveChannelToggleModal: boolean = false;
  showItemFiltersModal: boolean = false;
  showDeleteModal: boolean = false;
  showShoppingListModal: boolean = false;
  showShoppingListRemoveModal: boolean = false;
  showSelectLanguageModal: boolean = false;
  showInfoModal: boolean = false;
  showAppUpdateMajor: boolean = false;
  showBookADemo: boolean = false;
  inviteByEmail: boolean = false;
  showPriceWatchModal: boolean = false;
  channelSwipeItems: boolean = false;
  // we use a counter on the channel swipe loader so we don't remove from DOM and fire off created every time it closes and opens
  channelSwipeItemsLoadCount: number = 0;

  isApp = isApp();

  created () {
    this.eventListeners();
  }

  // Disabled in preference of new vue tour
  showWelcome () {
    if (AuthenticationStore.getAuthenticated) {
      const { accountSteps } = AuthenticationStore.currentUser;
      if (!accountSteps || !accountSteps.welcomed) {
        this.showOpeningWelcomeModal = true;
      }
    }
  }

  // Not technically a model, but this minor app update available message seems best placed here
  showAppUpdateMinorReleaseMessage (input: OModalsContainerAppUpdateAvailable) {
    this.$buefy.snackbar.open({
      message: this.$t('newReleaseAvailable.app.minor.message') as string,
      indefinite: true,
      cancelText: this.$t('dict.dismiss') as string,
      actionText: this.$t('dict.update') as string,
      onAction: () => {
        location.href = input.appType === AppType.Android ? config.appLinks.android : config.appLinks.apple;
      }
    });
  }

  // eslint-disable-next-line max-lines-per-function
  eventListeners () {
    const callerId = 'OModalsContainer';
    // Disabled in preference of new vue tour
    // EventBus.$on(EventBusEvents.AUTH_LOGIN, callerId, this.showWelcome);

    EventBus.$on(EventBusEvents.CHANNEL_REPORT, callerId, (payload: OModalsContainerChannelAsPayload) => {
      this.channel = clone(payload.channel);
      this.showChannelReportModal = true;
    });

    EventBus.$on(EventBusEvents.CHANNEL_NOTIFICATIONS, callerId, (payload: OModalsContainerChannelAsPayload) => {
      this.channel = clone(payload.channel);
      this.showChannelNotificationsModal = true;
    });

    EventBus.$on(EventBusEvents.CHANNEL_INFO_SHOW, callerId, (payload: OModalsContainerChannelAsPayload) => {
      this.channel = clone(payload.channel);
      this.showChannelInfoModal = true;
    });

    EventBus.$on(EventBusEvents.CHANNEL_ARCHIVE, callerId, (payload: OModalsContainerArchiveChannel) => {
      this.archiveChannel = {
        channel: clone(payload.channel),
        toArchive: (!payload.channel.archivedAt || false)
      };
      this.showArchiveChannelToggleModal = true;
    });

    EventBus.$on(EventBusEvents.ITEM_FILTERS_OPEN, callerId, () => {
      this.showItemFiltersModal = true;
    });

    EventBus.$on(EventBusEvents.CHANNEL_FILTERS_OPEN, callerId, () => {
      this.showChannelsFiltersModal = true;
    });

    EventBus.$on(EventBusEvents.ITEM_REPORT, callerId, (payload: OModalsContainerReportItemPayload) => {
      this.item = { ...payload.item };
      this.showItemReportModal = true;
    });

    EventBus.$on(EventBusEvents.ITEM_RECOMMENDATION_REPORT, callerId, (payload: OModalsContainerRecommendationPayload) => {
      this.recommendation = { ...payload.recommendation };
      this.showRecommendationReportModal = true;
    });

    EventBus.$on(EventBusEvents.ITEM_DELETE, callerId, (payload: OModalsContainerDeleteItemPayload) => {
      this.deleteItemData = { ...payload };
      this.showDeleteModal = true;
    });

    EventBus.$on(EventBusEvents.ITEM_PRICE_WATCH, callerId, (payload: Item) => {
      this.itemToWatch = { ...payload };
      this.showPriceWatchModal = true;
    });

    EventBus.$on(EventBusEvents.SHOPPING_LIST_MODAL, callerId, (payload: Item) => {
      this.shoppingListData = { ...payload };
      this.showShoppingListModal = true;
    });

    EventBus.$on(EventBusEvents.SHOPPING_LIST_REMOVE_MODAL, callerId, (payload: OModalsContainerRemoveShoppingListItemPayload) => {
      this.shoppingListRemoveData = { ...payload };
      this.showShoppingListRemoveModal = true;
    });

    EventBus.$on(EventBusEvents.MODAL_SHOW_INFO, callerId, (payload: OModalsContainerInfoType) => {
      this.showInfoType = payload.type;
      this.showInfoModal = true;
    });

    EventBus.$on(EventBusEvents.SELECT_LANGUAGE, callerId, () => {
      this.showSelectLanguageModal = true;
    });

    EventBus.$on(EventBusEvents.APP_EVENT_UPDATE_MAJOR, callerId, (input: OModalsContainerAppUpdateAvailable) => {
      this.appUpdateMajor = input;
      this.showAppUpdateMajor = true;
    });

    EventBus.$on(EventBusEvents.APP_EVENT_UPDATE_MINOR, callerId, (input: OModalsContainerAppUpdateAvailable) => {
      this.showAppUpdateMinorReleaseMessage(input);
    });

    EventBus.$on(EventBusEvents.BOOK_A_DEMO, callerId, () => {
      this.showBookADemo = true;
    });

    EventBus.$on(EventBusEvents.INVITE_BY_EMAIL, callerId, (input: OModalsContainerInviteByEmail) => {
      this.inviteByEmailPayload = input;
      this.inviteByEmail = true;
    });

    EventBus.$on(EventBusEvents.CHANNEL_SWIPE_ITEMS, callerId, (input: OModalsContainerChannelSwipeItems) => {
      this.channelSwipeItemsPayload = input;
      this.channelSwipeItems = true;
      ++this.channelSwipeItemsLoadCount;
    });

    EventBus.$on(EventBusEvents.CHANNEL_SWIPE_ITEMS_CLEAR, callerId, () => {
      // set load count to 0 to remove it from the DOM so the next load forces a refresh via the created() route
      this.channelSwipeItemsLoadCount = 0;
    });
  }
}
</script>
