<template>


  <svg :style="style" viewBox="0 0 405 405" fill="none" xmlns="http://www.w3.org/2000/svg" @click="click">
    <circle cx="202.227" cy="202.774" r="189.556" stroke="currentColor" stroke-width="25"/>
    <path d="M94.0079 202.773L310.426 202.803" stroke="currentColor" stroke-width="38"/>
    <path d="M202.223 310.984L202.228 94.5545" stroke="currentColor" stroke-width="38"/>
  </svg>
</template>

<style scoped lang="scss">
.AAddItemIcon {
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CHILD_EMIT } from '@/constants/childEmitEventNames';

@Component
export default class AAddItemIcon extends Vue {
  @Prop({required: false, default: 1})
  size!: number|string;
  @Prop({required: false, default: 'rem'})
  sizeUnits!: string;

  get style () {
    const size = typeof this.size === 'number' ? this.size : parseFloat(this.size);
    return `width: ${size}${this.sizeUnits}; height: ${size}${this.sizeUnits};`;
  }

  click () {
    this.$emit(CHILD_EMIT);
  }
}
</script>