<template>
  <div class="AMapOutLink"
       v-if="item.editable.geolocationData"
       @click="clickOutToMap"
  >
    <a-tooltip :tip="$t('dict.openMaps')" :discreet="true" size="is-small" :multilined="false">
      <map-icon
          size="1.25x"
          class="mapPinIconPointer"
      />
    </a-tooltip>
  </div>
</template>

<style scoped lang="scss">
.AMapOutLink {
  display: inline-block;

  @media (hover: hover) {
    &:hover {
      .mapPinIconPointer {
        path {
          fill: var(--primary-color);
        }

        circle {
          color: var(--white-color);
        }
      }
    }
  }

  .mapPinIconPointer {
    cursor: pointer;
  }
}
</style>

<script lang="ts">
import { MapIcon } from 'vue-feather-icons';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces/Item';
import clickOutToMap from '@/utils/clickOutToMap';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';

@Component({
  components: {
    ATooltip,
    MapIcon
  },
  filters: {},
})
export default class AMapOutLink extends Vue {
  @Prop()
  item!: Item;

  @Prop({ default: false })
  dontClickOut!: boolean;

  clickOutToMap () {
    if (!this.dontClickOut) {
      clickOutToMap(this.item);
    }
  }
}
</script>
