import HttpService from '@/services/HttpService';

import { FormsPublicBusinessAccountPricingRequestPost } from './interfaces/FormsPublicBusinessAccountPricingRequestPost';
import { FormsPublicDataCollection } from './interfaces/FormsPublicDataCollection';
import { FormsPublicDataCollectionIdPut } from './interfaces/FormsPublicDataCollectionIdPut';
import { FormsPublicDataCollectionIdPutPath } from './interfaces/FormsPublicDataCollectionIdPutPath';
import { FormsPublicDataCollectionPost } from './interfaces/FormsPublicDataCollectionPost';
import { FormsPublicGeneralContactPost } from './interfaces/FormsPublicGeneralContactPost';
import { FormsPublicResubscribeWelcomePost } from './interfaces/FormsPublicResubscribeWelcomePost';
import { FormsPublicUnsubscribeWelcomePost } from './interfaces/FormsPublicUnsubscribeWelcomePost';

export default class FormsService {
  public static basePath = '/__/ms-notification/';

  /**
   *  Operation ID: formsPublicBusinessAccountPricingRequestPost
   *  Summary: Create a pricing-request, from business-account, from public, from forms
   *  Description:
   */
  public static formsPublicBusinessAccountPricingRequestPost(
    body: FormsPublicBusinessAccountPricingRequestPost
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path:
        FormsService.basePath + 'forms/public/business-account/pricing-request',
      body,
    });
  }

  /**
   *  Operation ID: formsPublicDataCollectionPost
   *  Summary: Create a data-collection, from public, from forms
   *  Description:
   */
  public static formsPublicDataCollectionPost(
    body: FormsPublicDataCollectionPost
  ): Promise<FormsPublicDataCollection> {
    return HttpService.sendRequest({
      method: 'POST',
      path: FormsService.basePath + 'forms/public/data-collection',
      body,
    });
  }

  /**
   *  Operation ID: formsPublicDataCollectionIdPut
   *  Summary: Update a data-collection based on {id}, from public, from forms
   *  Description:
   */
  public static formsPublicDataCollectionIdPut(
    body: FormsPublicDataCollectionIdPut,
    pathParams: FormsPublicDataCollectionIdPutPath
  ): Promise<FormsPublicDataCollection> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: FormsService.basePath + 'forms/public/data-collection/:id',
      body,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: formsPublicGeneralContactPost
   *  Summary: Create a contact, from general, from public, from forms
   *  Description:
   */
  public static formsPublicGeneralContactPost(
    body: FormsPublicGeneralContactPost
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path: FormsService.basePath + 'forms/public/general/contact',
      body,
    });
  }

  /**
   *  Operation ID: formsPublicResubscribeWelcomePost
   *  Summary: Create a welcome, from resubscribe, from public, from forms
   *  Description:
   */
  public static formsPublicResubscribeWelcomePost(
    body: FormsPublicResubscribeWelcomePost
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path: FormsService.basePath + 'forms/public/resubscribe/welcome',
      body,
    });
  }

  /**
   *  Operation ID: formsPublicUnsubscribeWelcomePost
   *  Summary: Create a welcome, from unsubscribe, from public, from forms
   *  Description:
   */
  public static formsPublicUnsubscribeWelcomePost(
    body: FormsPublicUnsubscribeWelcomePost
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'POST',
      path: FormsService.basePath + 'forms/public/unsubscribe/welcome',
      body,
    });
  }
}
