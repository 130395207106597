<template>
  <div class="OChannelsFilterForm">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form
          ref="form"
          class="form"
          novalidate
          @submit.prevent="handleSubmit(applyFilters)"
      >
        <div v-if="forType === availableForTypes.yours" class="mb-4">
          <p class="font-body-l mb-4">{{ $t('dict.filterBy') }}</p>
          <b-field>
            <b-checkbox v-model="form.filterBy.onlyChannelsIOwn">
              {{ $t('containers.channels.filterRefine.onlyChannelsIOwn') }}
            </b-checkbox>
          </b-field>
          <b-field label="Hide channel types">
            <b-select v-model="form.filterBy.hideType">
              <option :value="hideTypes.none">
                {{ $t('filters.allShow') }}
              </option>
              <option :value="hideTypes.public">
                {{ $t('filters.hidePublicChannels') }}
              </option>
              <option :value="hideTypes.private">
                {{ $t('filters.hidePrivateChannels') }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="mb-4">
          <p class="font-body-l mb-4">
            {{ $t('filters.orderBy') }}
          </p>
          <b-field>
            <b-radio v-model="form.sortBy" name="sort" native-value="itemLastAdded">
              {{ $t('filters.orderItems.recentlyAdded') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.sortBy" name="sort" native-value="aToZ">
              {{ $t('filters.orderItems.nameAsc') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.sortBy" name="sort" native-value="zToA">
              {{ $t('filters.orderItems.nameDesc') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.sortBy" name="sort" native-value="newestFirst">
              {{ $t('filters.orderItems.descDate') }}
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="form.sortBy" name="sort" native-value="oldestFirst">
              {{ $t('filters.orderItems.ascDate') }}
            </b-radio>
          </b-field>
        </div>

        <b-button :loading="resetting"
                  class="is-outlined mr-2"
                  type="is-primary"
                  @click="reset">
          {{ $t('filters.allClear') }}
        </b-button>
        <a-button-submit
            :loading="applying">
          {{ $t('filters.applyAndClose') }}
        </a-button-submit>
      </form>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
.OChannelsFilterForm {
  ::v-deep .field {
    padding-left: 1rem;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import { ChannelsStore, SearchSettingsStore } from '@/store';
import { ChannelsGetQuery } from '@/api/ms-channel/services/interfaces';
import { SortBy, SortDir } from '@/api/ms-channel/services/interfaces/ChannelsGetQuery';
import EventBus, { EventBusEvents } from '@/EventBus';
import { ChannelOrPeopleContainerSelectedTab } from '@/enums/ChannelOrPeopleContainerSelectedTab';
import { pause } from 'common-utils/time';
import { clone } from 'common-utils/object';
import channelsSearchQuery from '@/utils/channelsSearchQuery';

export enum HideTypeEnum {
  none = 'none',
  public = 'public',
  private = 'private'
}

export enum sortByEnum {
  aToZ = 'aToZ',
  zToA = 'zToA',
  ItemLastAdded = 'itemLastAdded',
  newestFirst = 'newestFirst',
  oldestFirst = 'oldestFirst',
}

interface OChannelsFilterFormForm {
  filterBy: {
    onlyChannelsIOwn: boolean,
    hideType: HideTypeEnum
  },
  sortBy: sortByEnum
}

const defaultForm: OChannelsFilterFormForm = {
  filterBy: {
    onlyChannelsIOwn: false,
    hideType: HideTypeEnum.none
  },
  sortBy: sortByEnum.aToZ
};

export const channelsFilterSortDefaultsHashMap: ChannelsGetQuery = {
  sortBy: SortBy.Name,
  sortDir: SortDir.Asc,
  hidePrivate: false,
  hidePublic: false,
  onlyChannelsIOwn: false
};

@Component({
  components: {
    AButtonSubmit,
    ValidationObserver
  }
})
export default class OChannelsFilterForm extends Vue {
  availableForTypes = ChannelOrPeopleContainerSelectedTab;

  form: OChannelsFilterFormForm = clone(defaultForm);
  applying = false;
  resetting = false;

  hideTypes = HideTypeEnum;

  get forType (): ChannelOrPeopleContainerSelectedTab {
    return ChannelsStore.getChannelsSelectedTab;
  }

  created () {
    const searchQuery = channelsSearchQuery(this.$route);
    this.form.filterBy.hideType = searchQuery.hidePublic ?
        HideTypeEnum.public :
        searchQuery.hidePrivate ?
            HideTypeEnum.private :
            HideTypeEnum.none;
    this.form.filterBy.onlyChannelsIOwn = searchQuery.onlyChannelsIOwn as boolean;
    if (searchQuery.sortBy) {
      if (searchQuery.sortBy === SortBy.ItemLastAdded) {
        this.form.sortBy = sortByEnum.ItemLastAdded;
      } else if (searchQuery.sortBy === SortBy.CreatedAt) {
        this.form.sortBy = searchQuery.sortDir === SortDir.Desc ? sortByEnum.newestFirst : sortByEnum.oldestFirst;
      } else {
        this.form.sortBy = searchQuery.sortDir === SortDir.Desc ? sortByEnum.zToA : sortByEnum.aToZ;
      }
    }
  }

  async reset () {
    this.resetting = true;
    this.form = clone(defaultForm);
    await this.submit(true);
  }

  async applyFilters () {
    this.applying = true;
    await this.submit();
  }

  // eslint-disable-next-line max-lines-per-function
  async submit (clear = false) {
    const urlParams: ChannelsGetQuery = {};
    const { sortBy, filterBy } = this.form;
    const sortByAge = [sortByEnum.newestFirst, sortByEnum.oldestFirst].includes(sortBy);
    const sortingBy = sortBy === sortByEnum.ItemLastAdded ?
        SortBy.ItemLastAdded :
        sortByAge ?
            SortBy.CreatedAt :
            SortBy.Name;
    const sortingDir = sortBy === sortByEnum.ItemLastAdded ?
        SortDir.Desc :
        sortByAge ?
            ((sortBy === sortByEnum.newestFirst) ? SortDir.Desc : SortDir.Asc) :
            ((sortBy === sortByEnum.aToZ) ? SortDir.Asc : SortDir.Desc);
    urlParams.sortBy = sortingBy;
    urlParams.sortDir = sortingDir;
    if (filterBy && filterBy.hideType) {
      urlParams.hidePrivate = filterBy.hideType === HideTypeEnum.private;
      urlParams.hidePublic = filterBy.hideType === HideTypeEnum.public;
    }
    if (filterBy && typeof filterBy.onlyChannelsIOwn !== 'undefined') {
      urlParams.onlyChannelsIOwn = filterBy.onlyChannelsIOwn;
    }
    // merge the params into the query, then delete any default settings so the URL can be shorter where possible
    const query: any = { ...this.$route.query, ...urlParams };
    if (clear) {
      query.clear = true;
    } else {
      delete query.clear;
    }
    const keys = Object.keys(query);
    for (let i = 0; i < keys.length; i++) {
      if (typeof channelsFilterSortDefaultsHashMap[keys[i]] !== 'undefined' && query[keys[i]] === channelsFilterSortDefaultsHashMap[keys[i]]) {
        delete query[keys[i]];
      }
    }
    // length comparison of old query and new is first method to compare if changes
    let identical = Object.keys(this.$route.query).length === Object.keys(query).length;
    // if length is the same, do a deeper comparison
    if (identical) {
      // after deletions, ensure the query params are not completely identical before pushing (i.e. someone hit save without any changes)
      const filteredKeys = Object.keys(query);
      for (let i = 0; i < filteredKeys.length; i++) {
        if (!this.$route.query[filteredKeys[i]] || this.$route.query[filteredKeys[i]] !== query[filteredKeys[i]]) {
          identical = false;
          break;
        }
      }
    }
    // if differences, push to route and emit out
    if (!identical) {
      await this.$router.push({ query });
      // emit picked up in OChannelsContainer and emitted up to TProfile or TDashboard which will reload the channels
      EventBus.$emit(EventBusEvents.FILTER_CHANNELS);
    }

    // store the sort by and sort dir in the store to be remembered - it's the only thing we remember now after removing most of the search store
    SearchSettingsStore.CHANNEL_SEARCH_SETTINGS_ADD({
      sortBy: sortingBy,
      sortDir: sortingDir
    });

    await pause(250);
    this.$emit('close');
  }
}
</script>
