<template>
  <div class="ADeleteIcon">
    <b-tooltip :label="$t('dict.delete')">
      <span @click="sureShowToggle">
        <trash-2-icon size="16"></trash-2-icon>
      </span>
    </b-tooltip>
    <div v-if="sureShow" class="confirm-wrapper">
      <label>{{ $t('dict.sure') }}</label>
      <b-tooltip :label="$t('dict.yes')">
        <check-icon size="1.3x" class="action-icon" @click="$emit('click')"></check-icon>
      </b-tooltip>

      <b-tooltip :label="$t('dict.cancel')">
        <x-icon size="1.3x" class="action-icon" @click="sureShowToggle"></x-icon>
      </b-tooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ADeleteIcon {
  position: relative;
  cursor: pointer;
}

.confirm-wrapper {
  position: absolute;
  right: 0;
  display: flex;
  gap: .8rem;
  width: fit-content;
  padding: 10px 10px 5px;
  background-color: #ddd;
  font-size: 14px;

  label {
    width: 40px;
  }
}

// safari fix
/* stylelint-disable */
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) {
    .ADeleteIcon {
      margin-left: .8rem;
    }

    .confirm-wrapper {
      width: intrinsic;
    }

    .action-icon {
      margin-left: .8rem;
    }
  }
}

/* stylelint-enable */
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { CheckIcon, Trash2Icon, XIcon } from 'vue-feather-icons';

@Component({
  components: {
    Trash2Icon,
    CheckIcon,
    XIcon
  }
})

export default class ADeleteIcon extends Vue {
  sureShow = false;

  sureShowToggle () {
    this.sureShow = !this.sureShow;
  }
}
</script>
