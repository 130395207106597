<template>
  <div class="MChannelSwipeFooterActions" :class="{disabled}">
    <span class="icon-wrapper SWIPE_LEFT icon-draw-attention" :class="{highlightIcon: highlightWhichIcon === SwipeDirection.SwipeLeft}" :title="$t('dict.dislike')" @click="dislikeItem">
      <a-channel-swipe-item-left :size="footerIconSizeLarge" :fill="highlightWhichIcon === SwipeDirection.SwipeLeft" :disabled="disabled"/>
    </span>
    <span class="smaller-icons">
      <span class="icon-wrapper SWIPE_UP" :title="$t('dict.likeAndPin')" :class="{highlightIcon: highlightWhichIcon === SwipeDirection.SwipeUp}" @click="likeAndPinItem">
        <a-channel-swipe-item-up :size="footerIconSize" :fill="highlightWhichIcon === SwipeDirection.SwipeUp" :disabled="disabled"/>
      </span>
      <span class="icon-wrapper SWIPE_DOWN" :title="$t('dict.skip')" :class="{highlightIcon: highlightWhichIcon === SwipeDirection.SwipeDown}" @click="skipItem">
        <a-channel-swipe-item-down :size="footerIconSize" :fill="highlightWhichIcon === SwipeDirection.SwipeDown" :disabled="disabled"/>
      </span>
    </span>
    <span class="icon-wrapper SWIPE_RIGHT icon-draw-attention" :class="{highlightIcon: highlightWhichIcon === SwipeDirection.SwipeRight}" :title="$t('dict.like')" @click="likeItem">
      <a-channel-swipe-item-right :size="footerIconSizeLarge" :fill="highlightWhichIcon === SwipeDirection.SwipeRight" :disabled="disabled"/>
    </span>
  </div>
</template>

<style scoped lang="scss">
.MChannelSwipeFooterActions {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  span.smaller-icons {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
  }

  &:not(.disabled){
    span.icon-wrapper {
      cursor: pointer;
      transition: all .2s ease;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import AChannelSwipeItemLeft from '@/components/atoms/channel-swipe/AChannelSwipeItemLeft.vue';
import AChannelSwipeItemUp from '@/components/atoms/channel-swipe/AChannelSwipeItemUp.vue';
import AChannelSwipeItemRight from '@/components/atoms/channel-swipe/AChannelSwipeItemRight.vue';
import AChannelSwipeItemDown from '@/components/atoms/channel-swipe/AChannelSwipeItemDown.vue';
import { XIcon } from 'vue-feather-icons';
import { Action } from '@/api/ms-item/services/interfaces/SwipeScoresItemNameUniqueItemNamePut';

@Component({
  components: { AChannelSwipeItemDown, AChannelSwipeItemRight, AChannelSwipeItemUp, AChannelSwipeItemLeft, XIcon }
})
export default class MChannelSwipeFooterActions extends Vue {
  @Prop({ default: false })
  isMoving!: boolean;
  @Prop()
  directionMoving!: Action;
  @Prop()
  iconOpacity!: number;
  @Prop({required: false, default: false})
  disabled!: boolean;

  footerIconSize = '3x';
  footerIconSizeLarge = '4.5x';
  SwipeDirection = Action;

  // if the card is dragged far enough to trigger an action, also highlight the corresponding footer action
  get highlightWhichIcon (): Action|null {
    if(!this.isMoving || this.iconOpacity < 1){
      return null;
    }

    return this.directionMoving;
  }

  emitAction (direction: Action) {
    this.$emit('swipe-action', direction);
  }

  likeItem () {
    this.emitAction(Action.SwipeRight);
  }

  dislikeItem () {
    this.emitAction(Action.SwipeLeft);
  }

  likeAndPinItem () {
    this.emitAction(Action.SwipeUp);
  }

  skipItem () {
    this.emitAction(Action.SwipeDown);
  }
}
</script>