<template>
  <span class="ADecisionMakerIcon">
    <a-router-link-issue-detail :unique-item-name="uniqueItemName" :query="{tab: 'swipe-score'}">
      <b-loading :is-full-page="false" :active.sync="loading"></b-loading>

      <a-decision-maker-icon-svg
          :class="{currentUserSwiped: swipe}"
      />

      <span>{{ swipeScore ? (swipeScore < 0 ? '' : '+') + swipeScore : '' }}</span>
    </a-router-link-issue-detail>
  </span>
</template>

<style scoped lang="scss">
.ADecisionMakerIcon {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  min-height: 1rem;

  &:hover {
    color: var(--primary-color);
    cursor: pointer;
  }

  .currentUserSwiped {
    color: var(--secondary-color);
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import AFireIconSvg from '@/components/atoms/icon/svg/AFireIconSvg.vue';
import ADecisionMakerIconSvg from '@/components/atoms/icon/svg/ADecisionMakerIconSvg.vue';
import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';

@Component({
  components: { ARouterLinkIssueDetail, ADecisionMakerIconSvg, AFireIconSvg }
})
export default class ADecisionMakerIcon extends Vue {
  @Prop({ default: false })
  loading!: boolean | undefined;

  @Prop({ required: true })
  uniqueItemName!: string;

  @Prop({ default: false })
  swipe!: boolean;

  @Prop({ required: true })
  swipeScore!: number;

  @Prop()
  iconSize!: string;

  @Prop()
  disabled!: string;

  @Prop({ default: false })
  numericOnly!: boolean;
}
</script>