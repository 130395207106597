<template>
  <div class="OItemDetailTabs secondary-navigation mt-2">
    <b-tabs
        v-model="selectedTab"
        :animated="false"
        position="is-centered"
        size="is-small"
    >
      <!-- Comments -->
      <b-tab-item :headerClass="tabs.comments" :value="tabs.comments">
        <template #header>
            <span class="tab-item-header">
              <message-square-icon size="1.2x"/>
              <p class="count" v-if="countInfo.comments">
                {{ getTabText(countInfo.comments) }}
              </p>
            </span>
        </template>
        <o-item-comments :show-comment-type-selector="showCommentTypeSelector"/>
      </b-tab-item>

      <!-- Likes -->
      <b-tab-item :headerClass="tabs.likes" :value="tabs.likes">
        <template #header>
            <span class="tab-item-header">
              <thumbs-up-icon size="1.2x"/>
              <p class="text" v-if="countInfo.likes">
                {{ getTabText(countInfo.likes) }}
              </p>
            </span>
        </template>
        <m-button-with-icon
            :loading="likeLoading"
            @click="likeItem"
            class="is-primary is-outlined btn-like is-small"
        >
          <template slot="icon">
            <thumbs-up-icon size="1.4x"/>
          </template>
          <template slot="text">{{ isItemLiked ? $t('dict.unlike') : $t('dict.like') }}</template>
        </m-button-with-icon>

        <o-item-likes/>
      </b-tab-item>

      <!-- Swipe score - if item is not in general "channel" -->
      <b-tab-item :headerClass="tabs.swipeScore" :value="tabs.swipeScore" v-if="item.editable.channel.slug.length > 0">
        <template #header>
            <span class="tab-item-header">
              <a-decision-maker-icon-svg size="1.2"/>
              <p class="text" v-if="item.performanceValues.swipeScore">
                {{ item.performanceValues.swipeScore < 0 ? '' : '+' }}{{ item.performanceValues.swipeScore }}
              </p>
            </span>
        </template>

        <o-item-swipe-score
            :item="item"
            :active-tab="selectedTab"
        />
      </b-tab-item>

      <!-- Shopping lists-->
      <b-tab-item :headerClass="tabs.shoppingList" :value="tabs.shoppingList">
        <template #header>
            <span class="tab-item-header">
              <shopping-bag-icon size="1.2x"/>
              <p class="text" v-if="countInfo.lists">
                {{ getTabText(countInfo.lists) }}
              </p>
            </span>
        </template>
        <a-shopping-list-button
            v-if="selectedTab === tabs.shoppingList"
            :get-got="currentUserGetGot"
            :item="item"
            :is-icon="false"
            classes="btn-list"
        />
        <o-item-lists
            :isNotOnShoppingList="currentUserGetGot === undefined"
            :item="item"
        />
      </b-tab-item>

      <!-- Pins -->
      <b-tab-item :headerClass="tabs.pins" :value="tabs.pins">
        <template #header>
            <span class="tab-item-header">
              <a-pin-svg-icon :size="1.2"/>
              <p class="text" v-if="countInfo.pins">
                {{ getTabText(countInfo.pins) }}
              </p>
            </span>
        </template>

        <m-button-with-icon
            :loading="pinLoading"
            class="is-primary is-outlined btn-like is-small"
            @click="pinItem"
        >
          <template slot="icon">
            <a-pin-svg-icon/>
          </template>
          <template slot="text">{{ isItemPinned ? $t('dict.unpin') : $t('dict.pin') }}</template>
        </m-button-with-icon>

        <o-item-pins :item="item"/>
      </b-tab-item>

      <!-- Location -->
      <b-tab-item :headerClass="tabs.location" :value="tabs.location">
        <template #header>
            <span class="tab-item-header">
              <map-icon size="1.2x"/>
              <p class="text" v-if="item.editable.geolocationData">x1</p>
            </span>
        </template>
        <span v-if="!showMap">{{ $t('dict.loading') }}...</span>
        <m-item-detail-location
            :item="item"
            v-else
        />
      </b-tab-item>

      <!-- B2C Messages -->
      <b-tab-item v-if="b2cMessagesExist" :headerClass="tabs.b2cMessages" :value="tabs.b2cMessages">
        <template #header>
            <span class="tab-item-header">
              <tag-icon size="1.2x"/>
              <p class="text" v-if="b2cMessagesLiveNum">x{{ b2cMessagesLiveNum }}</p>
            </span>
        </template>
        <div v-if="b2cMessagesLiveNum > 0">
          <o-b2-c-message
              v-for="(obj, index) in b2cMessagesLive"
              class="mb-5"
              :key="index"
              :base-url-and-path="baseUrlAndPath"
              :message-id="obj._id"
              :domain-id="obj.domainId"
              :business-name="obj.businessName"
              :message="obj.message"
              :url="obj.urls[0].url"
              :image-size="b2cImageSize"
              :parameters="obj.urls[0].parameters"
              :expired-at="obj.expiredAt"
              :expiry-date="capitaliseFirstLetterOfString(obj.expiryDate)"
              :component-viewed="selectedTab === tabs.b2cMessages"
              :text-elements="{ clickoutButton: $t('item.detailView.b2c.clickoutButton'), notInterested: $t('item.detailView.b2c.notInterested'), expires: $t('dict.expires'), expired: $t('dict.expired'), doesntExpire: $t('dict.doesntExpire') }"
              :locale="{ language: language }"
              v-on:clickout="b2cMessageClickout"
              v-on:viewed="b2cMessageViewed"
              v-on:not-interested="b2cMessageNotInterested"
          />
        </div>
        <div v-else class="is-flex is-justify-content-center is-italic mb-3"><p>
          {{ $t('item.detailView.b2c.noCurrentMessages') }}</p></div>
        <div v-if="b2cMessagesExpiredNum > 0">
          <div v-if="!b2cMessagesExpiredShow" class="is-flex is-justify-content-center">
            <b-button @click="b2cMessagesExpiredShow = true">{{
                $t('item.detailView.b2c.showExpiredMessages')
              }}
            </b-button>
          </div>
          <div v-else>
            <o-b2-c-message
                v-for="(obj, index) in b2cMessagesExpired"
                class="mb-5"
                :key="index"
                :base-url-and-path="baseUrlAndPath"
                :message-id="obj._id"
                :domain-id="obj.domainId"
                :business-name="obj.businessName"
                :message="obj.message"
                :url="obj.urls[0].url"
                :image-size="b2cImageSize"
                :parameters="obj.urls[0].parameters"
                :expired-at="obj.expiredAt"
                :expiry-date="capitaliseFirstLetterOfString(obj.expiryDate)"
                :component-viewed="selectedTab === tabs.b2cMessages"
                :text-elements="{ clickoutButton: $t('item.detailView.b2c.clickoutButton'), notInterested: $t('item.detailView.b2c.notInterested'), expires: $t('dict.expires'), expired: $t('dict.expired'), doesntExpire: $t('dict.doesntExpire') }"
                :locale="{ language: language }"
                v-on:clickout="b2cMessageClickout"
                v-on:viewed="b2cMessageViewed"
                v-on:not-interested="b2cMessageNotInterested"
            />
          </div>
        </div>
      </b-tab-item>

    </b-tabs>
  </div>
</template>

<style lang="scss" scoped>
.OItemDetailTabs {
  @media screen and (max-width: 450px) {
    ::v-deep .tabs li a {
      padding-right: 4px;
      padding-left: 4px;
    }

    .tab-item-header {
      gap: 3px;
    }

    .shoppingList .tab-item-header img {
      height: 11px;
    }
  }
}

::v-deep .tab-content {
  padding-right: 4rem;
  padding-left: 4rem;

  @media screen and (max-width: $tablet) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

::v-deep .item-details-tabs ul {
  justify-content: center;
}

.tab-item-header {
  display: flex;
  gap: 5px;
  place-items: center;
  justify-content: center;
}

.tab-list-icon {
  display: block;
  height: 2rem;
}

// safari fix
/* stylelint-disable */
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) {
    .tab-item-header > svg,
    .tab-item-header > img {
      margin-right: 5px;
    }
  }
}

/* stylelint-enable */
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MapIcon, MessageSquareIcon, ShoppingBagIcon, TagIcon, ThumbsUpIcon } from 'vue-feather-icons';
import { AuthenticationStore, ItemDetailStore, ItemsStore } from '@/store';
import currentUserLikesThisItem from '@/utils/currentUserLikesThisItem';
import currentUserPinnedThisItem from '@/utils/currentUserPinnedThisItem';
import { User } from '@/api/ms-authentication/services/interfaces';
import { Getgot } from '@/api/ms-item/services/interfaces/Item';
import usersGetGotFromItem from '@/utils/usersGetGotFromItem';

import AShoppingListButton from '@/components/atoms/buttons/AShoppingListButton.vue';
import OItemComments from '@/components/organisms/OItemComments.vue';
import OItemLikes from '@/components/organisms/OItemLikes.vue';
import OItemLists from '@/components/organisms/OItemLists.vue';
import OTabsWrapper from '@/components/organisms/OTabsWrapper.vue';
import { RouteNames } from '@/router/RouteNames';
import urlHashParamGet from '@/utils/urlHashParamGet';
import MItemDetailLocation from '@/components/molecules/MItemDetailLocation.vue';
import OItemPins from '@/components/organisms/OItemPins.vue';
import APinSvgIcon from '@/components/atoms/icon/svg/APinSvgIcon.vue';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import MessageService from '@/api/ms-b2c-messaging/services/MessageService';
import { Datum as B2CMessages } from '@/api/ms-b2c-messaging/services/interfaces/B2CMessagesAppDatas';
import OB2CMessage from '@/storybook-components/src/stories/organisms/OB2CMessage.vue';
import formatDateDayjs from '@/utils/formatDateDayjs';
import { translation } from '@/plugins/i18n/Translation';
import config from '@/config';
import { State } from '@/api/ms-b2c-messaging/services/interfaces/MessageMessageIdStatePatchQuery';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { capitaliseFirstLetterOfString } from 'common-utils/string';
import { format } from 'timeago.js';
import ADecisionMakerIconSvg from '@/components/atoms/icon/svg/ADecisionMakerIconSvg.vue';
import OItemSwipeScore from '@/components/organisms/OItemSwipeScore.vue';

export enum itemDetailTabs {
  comments = 'comments',
  likes = 'likes',
  pins = 'pins',
  swipeScore = 'swipe-score',
  shoppingList = 'shoppingList',
  location = 'location',
  b2cMessages = 'b2c'
}

// Overwrite date types to string so we can present them nicely
interface B2CMessagesPretty extends Omit<B2CMessages, 'expiryDate' | 'expiredAt'> {
  expiryDate?: string | Date;
  expiredAt?: string | Date;
}

@Component({
  components: {
    OItemSwipeScore,
    ADecisionMakerIconSvg,
    OB2CMessage,
    MButtonWithIcon,
    APinSvgIcon,
    OItemPins,
    MItemDetailLocation,
    AShoppingListButton,
    OItemLists,
    OItemLikes,
    OItemComments,
    OTabsWrapper,
    MapIcon,
    MessageSquareIcon,
    ShoppingBagIcon,
    TagIcon,
    ThumbsUpIcon
  },
  methods: {
    capitaliseFirstLetterOfString (string: string) {
      return string && typeof string !== 'undefined' && string !== '' ? capitaliseFirstLetterOfString(string) : '';
    }
  }
})
export default class OItemDetailTabs extends Vue {
  @Prop({ required: true })
  countInfo!: { comments: number, likes: number, pins: number, lists: number };

  @Prop()
  showCommentTypeSelector!: boolean;

  tabs = { ...itemDetailTabs };
  selectedTab = this.tabs.comments;
  likeLoading: boolean = false;
  isItemLiked: boolean = false;
  pinLoading: boolean = false;
  isItemPinned: boolean = false;
  currentUserGetGot: Getgot | undefined = undefined;
  showMap = false;

  baseUrlAndPath = config.api.baseUrl;

  b2cMessagesExist: boolean = false;
  b2cMessages: B2CMessagesPretty[] = [];
  b2cMessagesLive: B2CMessagesPretty[] = [];
  b2cMessagesExpired: B2CMessagesPretty[] = [];
  b2cMessagesLiveNum: number = 0;
  b2cMessagesExpiredNum: number = 0;
  b2cMessagesExpiredShow: boolean = false;
  b2cImageSize: Size = Size.The100X100;

  get item () {
    return ItemDetailStore.getItemDetail;
  }

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get language () {
    return translation.currentLanguage;
  }

  async created () {
    this.currentUserGetGot = this.getCurrentUserGetGot();
    this.isItemLiked = currentUserLikesThisItem(this.item.likes, this.currentUser);
    this.isItemPinned = currentUserPinnedThisItem(this.item.pins, this.currentUser);
    //this.getSelectedTab();
    this.loadTabFromQuery();
    await this.getAnyB2CMessages();
  }

  async getAnyB2CMessages () {
    if (this.item.urlCache) {
      const messages = await MessageService.messageUniqueItemNameSearchGet({ uniqueItemName: this.item.uniqueItemName }, { url: this.item.urlCache?.url });
      if (messages.meta.totalResultCount && messages.meta.totalResultCount > 0) {
        this.b2cMessagesExist = true;
        // separate the messages into "live" and "expired" based on the expiredMoreThanTwoWeeks boolean
        this.b2cMessagesLive = messages.data.filter((b2cMessage) => !b2cMessage.expiredMoreThanTwoWeeks);
        this.b2cMessagesLiveNum = this.b2cMessagesLive.length;
        this.b2cMessagesExpired = messages.data.filter((b2cMessage) => b2cMessage.expiredMoreThanTwoWeeks);
        this.b2cMessagesExpiredNum = this.b2cMessagesExpired.length;
        // prettify the dates
        for (let i = 0; i < this.b2cMessagesLiveNum; i++) {
          this.b2cMessagesLive[i].expiredAt = this.b2cMessagesLive[i].expiredAt ? await format(this.b2cMessagesLive[i].expiredAt as Date, this.language) : undefined;
          this.b2cMessagesLive[i].expiryDate = this.b2cMessagesLive[i].expiryDate ? await format(this.b2cMessagesLive[i].expiryDate as Date, this.language) : undefined;
        }
        for (let i = 0; i < this.b2cMessagesExpiredNum; i++) {
          this.b2cMessagesExpired[i].expiredAt = this.b2cMessagesExpired[i].expiredAt ? await format(this.b2cMessagesExpired[i].expiredAt as Date, this.language) : undefined;
          this.b2cMessagesExpired[i].expiryDate = this.b2cMessagesExpired[i].expiryDate ? await format(this.b2cMessagesExpired[i].expiryDate as Date, this.language) : undefined;
        }
        // Auto-select b2c tab if query demands it, i.e. come through from notification
        if (this.$route.query && this.$route.query.tab && this.$route.query.tab === itemDetailTabs.b2cMessages) {
          this.selectedTab = itemDetailTabs.b2cMessages;
        }
      }
    }
  }

  loadTabFromQuery () {
    const tab = this.$route.query.tab;
    if (tab && Object.values(itemDetailTabs).includes(String(tab) as unknown as itemDetailTabs)) {
      this.selectedTab = String(tab) as unknown as itemDetailTabs;
    }
  }

  getSelectedTab () {
    const commentId = urlHashParamGet('commentId');
    const tab = this.$route.name === RouteNames.ROUTE_ITEM_DETAIL_VIEW ?
        commentId ? this.tabs.comments : this.tabs.shoppingList :
        this.$route.name!.split('itemDetailView')[1].toLowerCase();

    return this.selectedTab = this.tabs[tab];
  }

  getCurrentUserGetGot () {
    return usersGetGotFromItem(this.item, this.currentUser.username);
  }

  getTabText (amount: number): string {
    return (amount > 0 ? 'x' + amount : '');
  }

  async likeItem () {
    this.likeLoading = true;
    this.isItemLiked = !this.isItemLiked;

    // like item on detail store and update main items store if it exists there
    const item = await ItemDetailStore.itemLikeToggle({
      newLikeState: this.isItemLiked,
      uniqueItemName: this.item.uniqueItemName,
    });
    ItemsStore.SET_ITEM_DETAIL(item);

    this.likeLoading = false;
  }

  async pinItem () {
    this.pinLoading = true;
    this.isItemPinned = !this.isItemPinned;

    // pin item on detail store and update main items store if it exists there
    const item = await ItemDetailStore.itemPinToggle({
      newPinState: this.isItemPinned,
      uniqueItemName: this.item.uniqueItemName,
    });
    ItemsStore.SET_ITEM_DETAIL(item);

    this.pinLoading = false;
  }

  async b2cMessageViewed (messageId: string) {
    await MessageService.messageMessageIdStatePatch({ messageId: messageId }, {});
  }

  async b2cMessageNotInterested (messageId: string) {
    await MessageService.messageMessageIdFeedbackPatch({ messageId: messageId }, {});
    this.$buefy.dialog.alert({
      title: this.$t('item.detailView.b2c.notInterestedRegisteredTitle') as string,
      message: this.$t('item.detailView.b2c.notInterestedRegisteredMessage') as string
    });
  }

  async b2cMessageClickout (params: { clickoutUrl: string, messageId: string }) {
    let link: string = '';
    link += config.api.baseUrlClickout;
    link += config.api.basePaths.clickout;
    link += '?t=b2c';
    link += '&u=' + params.clickoutUrl;
    link += '&obid=' + params.messageId;
    await MessageService.messageMessageIdStatePatch({ messageId: params.messageId }, { state: State.Clicked });
    window.open(link);
  }

  async formatDate (date): Promise<string> {
    return await formatDateDayjs(date);
  }

  @Watch('item')
  itemValueHandle () {
    this.currentUserGetGot = this.getCurrentUserGetGot();
  }

  @Watch('selectedTab')
  selectedTabHandle () {
    if (this.selectedTab === this.tabs.location) {
      this.showMap = true;
    }
  }
}
</script>
