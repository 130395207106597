<template>
  <div class="MItemEntryMap">
    <p class="return-text" @click="close">
      <arrow-left-icon class="back-icon" size="1.3x"/>
      {{ $t('dict.back') }}
    </p>
    <b-field label="Either, enter an address">
      <div class="autocomplete-container">
        <input ref="autocomplete"
               placeholder="Search"
               class="input"
               type="text"
               v-model="formattedAddress"
        />
        <x-icon
            class="search-icon"
            size="1.5x"
            @click="clearAddressMarker"
        />
      </div>
    </b-field>
    <b-field label="Or, click on the map to select a location">
      <section ref="mapContainer" class="map-container"></section>
    </b-field>
    <p v-if="geolocationBlocked" class="geolocationBlocked mt-1">{{ $t('item.entry.noLocation') }}</p>
  </div>
</template>

<style scoped lang="scss">
@import 'MItemEntryMap';
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import injectGoogleMapScript from '@/utils/injectGoogleMapScript';
import { ArrowLeftIcon, XIcon } from 'vue-feather-icons';
import { GeolocationData } from '@/api/ms-item/services/interfaces/Item';
import { i18n } from '@/plugins/i18n';
import router from '@/router';
import ASaveLocationButton from '@/components/atoms/buttons/ASaveLocationButton.vue';
import googleMapsStoreKeywordsAddress from '@/utils/googleMapsStoreKeywordsAddress';
import googleMapsReverseGepFromGPS from '@/utils/googleMapsReverseGeoFromGPS';

@Component({
  components: {
    ArrowLeftIcon,
    XIcon
  },
})
export default class MItemEntryMap extends Vue {
  @Prop({ required: true })
  geolocationData!: GeolocationData;

  map: any;
  marker: any = [];
  infoWindow: any;
  geolocationBlocked = false;
  formattedAddress: string = '';

  businessDetails?: {
    businessStatus: string,
    name: string
  };

  saveLocationButton?: any = false;
  saveLocationButtonSettings: {
    display: boolean,
    loading: boolean,
    buttonText: string,
    buttonClasses: string
  } = {
    display: true,
    loading: false,
    buttonText: this.$t('item.entry.saveLocation') as string,
    buttonClasses: 'button is-primary mb-5',
  };

  autocomplete!: any;
  $refs!: {
    mapContainer: HTMLElement,
    autocomplete: any
  };

  async mounted () {
    try {
      await this.setup();
    } catch (e) {
      console.error(e);
    }
  }

  async setup () {
    await injectGoogleMapScript();
    this.loadMap();
    this.loadAutocomplete();
  }

  emitLocation () {
    this.$emit('geolocation-data-selected', this.geolocationData);
  }

  close (): void {
    this.$emit('hide-item-entry-map');
  }

  //loads the map, default location and zoom is the entire UK, will attempt to pan to user location if they allow
  loadMap () {
    this.map = new window.google.maps.Map(this.$refs.mapContainer, {
      disableDefaultUI: true,
      mapTypeId: 'terrain',
      center: new window.google.maps.LatLng(this.geolocationData.position.coordinates[1], this.geolocationData.position.coordinates[0]),
      zoom: 5,
      streetViewControl: false,
      zoomControl: true,
      mapTypeControl: true,
      fullscreenControl: true
    });

    this.infoWindow = new window.google.maps.InfoWindow();

    this.tryFindCurrentLocation();

    new window.google.maps.event.addListener(this.map, 'click', (event) => {
      this.geolocationData.position.coordinates[0] = event.latLng.lng();
      this.geolocationData.position.coordinates[1] = event.latLng.lat();

      this.setMarker();

      //prevents the info window from displaying
      if (event.placeId) {
        event.stop();
      }
    });
  }

  //first see if a location has been passed through, if not, try user location, otherwise default location is uk
  tryFindCurrentLocation (): void {
    if (this.geolocationData.formattedAddress) {
      this.setMarker();
    } else {
      const geo = navigator.geolocation;
      if (geo) {
        const success = (position) => {
          this.panMapTo(position.coords.longitude, position.coords.latitude);
        };
        const failure = () => {
          this.geolocationBlocked = true;
        };

        geo.getCurrentPosition(success, failure);
      }
    }
  }

  panMapTo (lng, lat): void {
    this.map.panTo(new window.google.maps.LatLng(lat, lng));
    const currentZoom = this.map.getZoom();
    if (currentZoom < 16) {
      this.map.setZoom(16);
    }
  }

  //place the first marker on screen, or move the existing marker if one already exists
  setMarker (reverseGeocode: boolean = true) {
    if (!this.marker.length) {
      this.marker.push(new window.google.maps.Marker({
        position: {
          lat: this.geolocationData.position.coordinates[1],
          lng: this.geolocationData.position.coordinates[0]
        },
        map: this.map
      }));
      this.showSaveButton();
    } else {
      this.marker[0].setPosition(new window.google.maps.LatLng(this.geolocationData.position.coordinates[1], this.geolocationData.position.coordinates[0]));
    }
    this.panMapTo(this.geolocationData.position.coordinates[0], this.geolocationData.position.coordinates[1]);
    if (reverseGeocode) {
      this.reverseGeocode(this.geolocationData.position.coordinates[1], this.geolocationData.position.coordinates[0]);
    }
  }

  showSaveButton () {
    if (this.saveLocationButton) {
      this.saveLocationButtonSettings.display = true;
      return;
    }
    this.saveLocationButton = document.createElement('div');
    this.map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(this.saveLocationButton);
    const t = document.createElement('template');
    this.saveLocationButton.appendChild(t);
    new Vue({
      i18n,
      router,
      render: (h) => h(ASaveLocationButton, {
        props: {
          display: this.saveLocationButtonSettings.display,
          loading: this.saveLocationButtonSettings.loading,
          buttonText: this.saveLocationButtonSettings.buttonText,
          buttonClasses: this.saveLocationButtonSettings.buttonClasses
        }
      }),
    }).$mount(t);

    this.saveLocationButton.addEventListener('click', () => {
      this.emitLocation();
    });
  }

  // Given lat and lng it will attempt to return an address
  async reverseGeocode (lat: number, lng: number) {
    try {
      const out = await googleMapsReverseGepFromGPS({ lat, lng });
      this.formattedAddress = out.formattedAddress as string;
      this.geolocationData.formattedAddress = out.formattedAddress;
      this.geolocationData.keywordsAddress = out.keywordsAddress;
      this.geolocationData.placeId = out.placeId;
    } catch (e) {
      console.error('googleMapsReverseGepFromGPS failed due to: ' + e);
    }
  }

  clearAddressMarker () {
    this.formattedAddress = '';
    this.geolocationData.formattedAddress = '';
    this.geolocationData.keywordsAddress = '';
    this.geolocationData.placeId = '';
    this.marker[0].setMap(null);
    this.marker.length = 0;
    this.saveLocationButtonSettings.display = false;
    this.$emit('remove-geolocation-data');
  }

  handleAutocompleteChange () {
    const place = this.autocomplete.getPlace();
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const businessDetails = place.business_status ? { businessStatus: place.business_status, name: place.name } : {};

    this.geolocationData.keywordsAddress = googleMapsStoreKeywordsAddress(place.address_components, businessDetails);
    this.geolocationData.position.coordinates[0] = lng;
    this.geolocationData.position.coordinates[1] = lat;
    this.geolocationData.formattedAddress = place.formatted_address;
    this.geolocationData.placeId = place.place_id;
    this.formattedAddress = place.formatted_address;

    this.setMarker(false);
  }

  loadAutocomplete () {
    //if in dev, remove 'fields' key to return every field from google - certain field are counted as extra cost, see conf.
    this.autocomplete = new window.google.maps.places.Autocomplete(
      (this.$refs.autocomplete),
      { fields: ['address_components', 'business_status', 'formatted_address', 'geometry', 'name', 'place_id'] }
    );
    this.autocomplete.bindTo('bounds', this.map);
    this.autocomplete.addListener('place_changed', this.handleAutocompleteChange);

  }
}
</script>
