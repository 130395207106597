import { render, staticRenderFns } from "./OLoginForm.vue?vue&type=template&id=a9fcc428&scoped=true"
import script from "./OLoginForm.vue?vue&type=script&lang=ts"
export * from "./OLoginForm.vue?vue&type=script&lang=ts"
import style0 from "./OLoginForm.vue?vue&type=style&index=0&id=a9fcc428&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9fcc428",
  null
  
)

export default component.exports