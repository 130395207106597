<template>
  <a-default-svg-from-material-design
      :size="size"
      :keep-y-zero="keepYZero"
      path="M22,4A2,2 0 0,1 24,6V16A2,2 0 0,1 22,18H6A2,2 0 0,1 4,16V4A2,2 0 0,1 6,2H12L14,4H22M2,6V20H20V22H2A2,2 0 0,1 0,20V11H0V6H2M6,6V16H22V6H6Z"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ADefaultSvgFromMaterialDesign from '@/components/atoms/icon/svg/ADefaultSvgFromMaterialDesign.vue';

@Component({
  components:{
    ADefaultSvgFromMaterialDesign
  }
})
export default class AFoldersSvgIcon extends Vue {
  @Prop({ default: 1 })
  size!: number;
  @Prop({default: true})
  keepYZero!: boolean;
}
</script>