<template>
  <div class="MChannelSwipeHeaderActions">
    <div class="swipeHeaderBox component-title"><p>{{ $t('dict.swipeScoresName') }}</p></div>
    <div class="swipeHeaderBox progress-bar">{{ $t('dict.progressTracker', {number: swipePosition, total: totalItems}) }}</div>
    <div class="swipeHeaderBox action-icons-container">
      <span class="actionIcon infoIcon" @click="showInfo">
      <a-info-no-circle-icon :size="iconSizeB"/>
      </span>
      <span class="actionIcon sendToOthersIcon" @click="sendToOthers">
        <send-icon :size="iconSize"/>
      </span>
      <span class="actionIcon closeModalIcon" @click="closeModal">
        <x-icon :size="iconSize"/>
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.MChannelSwipeHeaderActions {
  padding: .5rem;
  background-color: var(--primary-color);
  color: var(--white-color);

  .swipeHeaderBox {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .component-title p {
    font-weight: $fontWeightBold;
    padding-left: .5rem;
    margin-right: auto;
  }

  .progress-bar {

  }

  .action-icons-container {
    display: inline-flex;
    margin-left: auto;
    gap: 2rem;
    position: relative;
    justify-content: right;

    @media screen and (max-width: 800px) {
      gap: 1.5rem;
    }

    @media screen and (max-width: 600px) {
      gap: 1rem;
    }

    @media screen and (max-width: 500px) {
      gap: .5rem;
    }

    @media screen and (max-width: 400px) {
      gap: 0;
    }

    .actionIcon {
      position: relative;
      display: flex;
      align-items: center;
      padding: .5rem;
      border: .15rem solid transparent;
      border-radius: 50%;
      cursor: pointer;
      transition: all .2s ease;
      overflow: hidden;

      $opacity: .75;

      &.infoIcon {
        &:before {
          background-color: rgba(var(--infoRBG), 1);
        }

        &:hover {
          color: var(--white-color);
        }
      }

      &.sendToOthersIcon {
        &:before {
          background-color: rgba(var(--goldRGB), .8);
        }

        &:hover {
          color: var(--white-color);
        }
      }

      &.closeModalIcon {
        &:before {
          background-color: rgba(var(--grey-mid-colorRGB), 1);
        }

        &:hover {
          color: var(--white-color);
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        transition: .5s;
        z-index: -1;
      }

      &:hover:before {
        top: 0;
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Channel } from '@/api/ms-channel/services/interfaces';
import { SendIcon, XIcon } from 'vue-feather-icons';
import AInfoNoCircleIcon from '@/components/atoms/icon/svg/AInfoNoCircleIcon.vue';

enum HeaderActionIcons {
  Close = 'close',
  Info = 'info',
  Send = 'send'
}

@Component({
  components: {
    AInfoNoCircleIcon,
    SendIcon,
    XIcon
  }
})
export default class MChannelSwipeHeaderActions extends Vue {
  @Prop()
  channel!: Channel;
  @Prop({ default: 0 })
  swipePosition!: number;
  @Prop({ default: 0 })
  totalItems!: number;

  iconSize: string = '1.5x';
  iconSizeB: number = 1.5;

  ActionIcons = HeaderActionIcons;

  closeModal () {
    this.$emit('close-modal');
  }

  sendToOthers () {
    this.$emit('send-to-others');
  }

  showInfo () {
    this.$emit('show-info');
  }
}
</script>
