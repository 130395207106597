<template>
  <div class="MShoppingListRemoveModal modal-card-body">
    <p class="remove-message">
      {{ message }}
    </p>

    <b-button
        :loading="loading"
        class="is-primary"
        @click="removeAddedItem"
    >
      {{ $t('dict.remove') }}
    </b-button>
  </div>
</template>

<style lang="scss" scoped>
.button {
  margin-top: .7rem;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ShoppingListStore } from '@/store';
import ItemService from '@/api/ms-item/services/ItemService';
import updateItemInStores from '@/utils/updateItemInStores';

@Component
export default class MShoppingListRemoveModal extends Vue {
  @Prop({ required: true })
  itemName!: string;

  @Prop({ required: true })
  getGotId!: string;

  loading = false;
  message = this.$t('dict.removeFromListModalMsg');
  snackBarTime = 5000;

  async removeAddedItem () {
    this.loading = true;

    // the remove from list action, aka undo
    try {
      const newItem = await ItemService.itemNameUniqueItemNameGetgotIdIdDelete({
        id: this.getGotId,
        uniqueItemName: this.itemName
      });

      this.triggerGetGotHeaderUpdate();
      updateItemInStores(newItem);
      ShoppingListStore.DELETE_ONE_ITEM(newItem.uniqueItemName);
      this.loading = false;
      this.$emit('close-modal');
    } catch (e) {
      console.error(e);
      this.loading = false;
      // TODO: catch the error and display the message
      this.$buefy.toast.open({
        message: this.$t('dict.getGotSnackBarMessageRemovedError').toString(),
        queue: false,
        type: 'is-danger',
        duration: this.snackBarTime,
      });
      this.$emit('close-modal');
    }
  }

  triggerGetGotHeaderUpdate () {
    ShoppingListStore.fetchShoppingListToGet();
  }
}
</script>
