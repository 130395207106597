<template>
  <div class="OModalWrapper">
    <b-modal
        :active="showModal"
        aria-role="dialog"
        :full-screen="fullScreen"
        :can-cancel="canCancel"
        has-modal-card
    >
      <div class="modal-card">
        <b-loading :is-full-page="false" :active="loading"/>
        <header v-if="hasTitleSlot" :class="{'has-text-centered': headAlignCenter}" class="modal-card-head">
          <p class="modal-card-title">
            <slot name="title"></slot>
          </p>
          <a-close-modal-button v-if="!hideClose" icon-size="2x" v-on:child-output="$emit('close')"/>
        </header>

        <section v-if="hasBodySlot" class="modal-card-body" :class="{overflowY}">
          <slot name="body"></slot>
        </section>

        <footer v-if="hasFooterSlot" class="modal-card-foot">
          <slot name="footer"></slot>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.OModalWrapper {
  // optional
}

.overflowY {
  overflow-y: scroll;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ACloseModalButton from '../atoms/buttons/ACloseModalButton.vue';

@Component({
  components: { ACloseModalButton }
})
export default class OModalWrapper extends Vue {
  @Prop({ default: false })
  showModal!: boolean;

  @Prop({ default: false })
  headAlignCenter!: boolean;

  @Prop({ default: false })
  hideClose!: boolean;

  @Prop({ default: false })
  overflowY!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({default: false})
  fullScreen!: boolean

  @Prop({default: false})
  canCancel!: boolean;

  get hasTitleSlot () {
    return this.$slots.title !== undefined;
  }

  get hasBodySlot () {
    return this.$slots.body !== undefined;
  }

  get hasFooterSlot () {
    return this.$slots.footer !== undefined;
  }
}
</script>
