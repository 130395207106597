<template>
  <div class="wrapper">
    <b-tooltip :active="isDisabledForm || isResearchConcierge"
               :label="isDisabledForm ? $t('page.channelUpsert.heading.disabledTooltip') : $t('page.channelUpsert.heading.researchConciergeTooltip')"
               position="is-top">
      <div :class="{ 'disabled-form': isDisabledForm || isResearchConcierge }" class="MChannelInvitations">
        <p class="font-body-xl invite-title">{{ $t('page.channelUpsert.heading.invite') }}</p>
        <b-field :label="$t('page.channelUpsert.form.inviteToChannel.label')">
          <b-autocomplete
            v-model="searchString"
            :data="connections"
            :disabled="isDisabledForm"
            :loading="searchLoading"
            :open-on-focus="true"
            :placeholder="$t('page.channelUpsert.form.inviteToChannel.placeholder')"
            class="user-select"
            @select="handleSelection"
            @input="searchInvites"
        >
          <template slot-scope="props">
            <div class="default-options">
              <a-image
                  :alt-text="props.option.firstName"
                  :image-path="`/user/profile-pic/${props.option.username}.png`"
                  :size="userImageSize"
                  class="select-image"
              />
              {{ props.option.firstName }} {{ props.option.lastName }}
            </div>
          </template>
          <template #header>
            <a v-if="showEmailInvite" @click="inviteToChannelByEmail" v-html="$t('page.channelUpsert.form.inviteToChannel.noEmailFound')" />
          </template>
        </b-autocomplete>
        </b-field>

        <p class="font-body-xl invite-title mt-6">{{ $t('page.channelUpsert.heading.existing') }}</p>
        <b-table
            :data="invites"
            :loading="loadingInvites"
            class="mt-1"
            scrollable
            paginated
            hoverable
            backend-pagination
            :total="totalChannelMembers"
            :per-page="perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            backend-sorting
            :default-sort-direction="sortingDirection"
            :default-sort="[sortBy]"
            @sort="onSort"
        >
          <!--Users name field-->
          <b-table-column
              v-slot="props"
              :label="$t('dict.name')">
            <div class="table-cell">
              <a-user-profile-pic :imageSize="userImageSize" :username="props.row.username"></a-user-profile-pic>
              <a-router-link-profile :username="props.row.username">
                <span class="user-full-name">{{ props.row.firstName }} {{ props.row.lastName }}</span>
              </a-router-link-profile>
            </div>
          </b-table-column>

          <!--Can add to the channel-->
          <b-table-column
              v-slot="props"
              :label="$t('dict.canAdd')">
            <div class="table-cell can-add-cell">
              <span v-if="isChannelOwner(props.row.username)">
                {{ $t('dict.channelOwner') }}
              </span>
              <b-tooltip
                  v-else
                  :active="channel.allCanAdd"
                  :label="$t('page.channelUpsert.form.canAddItemsTooltip')"
              >
                <b-checkbox
                    v-model="props.row.canAdd"
                    :disabled="channel.allCanAdd"
                    @input="handleCanAddToggle(props.row)"
                />
              </b-tooltip>
            </div>
          </b-table-column>

          <!--Can admin the channel-->
          <b-table-column
              v-slot="props"
              :label="$t('dict.canManage')">
            <div class="table-cell can-add-cell">
              <b-checkbox
                  v-if="!isChannelOwner(props.row.username)"
                  v-model="props.row.canManage"
                  @input="handleCanManageToggle(props.row)"
              />
            </div>
          </b-table-column>

          <!--Subscription status-->
          <b-table-column
              v-slot="props"
              :label="$t('dict.inviteStatus')"
              field="subscriptionStatus">
            <div class="table-cell status-cell">
              <template v-if="!isChannelOwner(props.row.username)">
                              <span class="invite-state">
                {{ $t('channelInviteState.' + props.row.subscriptionStatus) }}
              </span>

                <div v-if="channel.owner &&
                        channel.owner.username !== props.row.username &&
                        props.row.subscriptionStatus === subscriptionStatus.JoinRequestSent"
                >
                  <m-button-with-icon @click="rejectJoinRequest(props.row)" type="is-danger">
                    <template slot="icon">
                      <x-circle-icon class="remove-invite-icon" size="1.2x"></x-circle-icon>
                    </template>
                    <template slot="text">
                      {{ $t('dict.reject') }}
                    </template>
                  </m-button-with-icon>

                  <m-button-with-icon @click="acceptJoinRequest(props.row)" classes="mt-4">
                    <template slot="icon">
                      <check-icon class="remove-invite-icon" size="1.2x"/>
                    </template>
                    <template slot="text">
                      {{ $t('dict.accept') }}
                    </template>
                  </m-button-with-icon>
                </div>

                <div v-else-if="channel.owner && channel.owner.username !== props.row.username">
                  <m-button-with-icon @click="removeInvite(props.row)" type="is-danger">
                    <template slot="icon">
                      <x-circle-icon class="remove-invite-icon" size="1.2x"></x-circle-icon>
                    </template>
                    <template slot="text">
                      {{ $t('dict.remove') }}
                    </template>
                  </m-button-with-icon>
                </div>
              </template>
            </div>
          </b-table-column>

          <!--Update at timestamp -->
          <b-table-column
              v-slot="props"
              :label="$t('dict.updated')"
              field="updatedAt">
            <div class="table-cell">
              <template v-if="!isChannelOwner(props.row.username)">
                {{ new Date(props.row.updatedAt) | dateFormat('DD.MM.YYYY') }}
              </template>
            </div>
          </b-table-column>
        </b-table>
      </div>
    </b-tooltip>
  </div>
</template>

<style lang="scss" scoped>
@import './MChannelInvitations';
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AImage from '@/components/atoms/AImage.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';
import { Datum as ChannelMemberDatum, SubscriptionStatus } from '@/api/ms-channel/services/interfaces/ChannelMembers';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { CheckIcon, XCircleIcon } from 'vue-feather-icons';
import { ChannelsStore } from '@/store';
import MPersonChannelJoinRequestCard from '@/components/molecules/MPersonChannelJoinRequestCard.vue';
import {
  ChannelMemberSlugGetQuery,
  MemberStatus,
  SortBy,
  SortDir
} from '@/api/ms-channel/services/interfaces/ChannelMemberSlugGetQuery';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import UserService from '@/api/ms-authentication/services/UserService';
import { Datum } from '@/api/ms-authentication/services/interfaces/UserSearchs';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import { ChannelMember } from '@/api/ms-channel/services/interfaces';
import EventBus, { EventBusEvents } from '@/EventBus';
import { InvitationHook, Type } from '@/api/ms-authentication/services/interfaces/UserInvitationsAssignPost';
import { OModalsContainerInviteByEmail } from '@/components/organisms/OModalsContainer.vue';
import { Invitation } from '@/api/ms-authentication/services/interfaces';

@Component({
  components: {
    MButtonWithIcon,
    ARouterLinkProfile,
    MPersonChannelJoinRequestCard,
    AImage,
    AUserProfilePic,
    CheckIcon,
    XCircleIcon
  },
})
export default class MChannelInvitations extends Vue {
  @Prop()
  channelSlug!: string;

  @Prop({ default: false })
  isResearchConcierge!: boolean;

  callerId = 'MChannelInvitations';
  loadingInvites = false;
  loadingJoinRequests = false;

  subscriptionStatus = SubscriptionStatus;
  invites: ChannelMemberDatum[] = [];
  connections: Datum[] = [];
  userImageSize = Size.The32X32;

  joinRequests: ChannelMemberDatum[] = [];
  isDisabledForm: boolean = !this.channelSlug;

  channelMembersQuery: ChannelMemberSlugGetQuery = {};

  // Tabular invite data
  currentPage: number = 1;
  perPage: number = 10;
  totalChannelMembers: number = 0;
  searchLoading: boolean = false;
  searchByEmail: boolean = false;
  searchString: string = '';
  showEmailInvite: boolean = false;
  sortBy: SortBy = SortBy.Username;
  sortingDirection: SortDir = SortDir.Asc;

  get channel () {
    return ChannelsStore.getChannelEditing;
  }

  async created () {
    if (this.channelSlug) {
      this.channelMembersQuery.requestCount = true;
      await this.fetchExistingMembersAndInvites();
    }
    this.bindEvents();
  }

  beforeDestroy () {
    this.unbindEvents();
  }

  bindEvents () {
    EventBus.$on(EventBusEvents.INVITE_BY_EMAIL_SENT, this.callerId, this.inviteSent);
  }

  unbindEvents () {
    EventBus.$remove(EventBusEvents.INVITE_BY_EMAIL_SENT, this.callerId);
  }

  async fetchExistingMembersAndInvites () {
    this.loadingInvites = true;
    this.channelMembersQuery.asOwner = true;
    this.channelMembersQuery.offset = (this.currentPage * this.perPage) - this.perPage;
    const { data, meta } = await ChannelMemberService.channelMemberSlugGet(
        { slug: this.channelSlug, },
        this.channelMembersQuery
    );
    if (meta.total) {
      this.totalChannelMembers = meta.total;
    }
    this.invites = data;
    this.loadingInvites = false;
    if (this.channelMembersQuery.requestCount) {
      //only need the count the first time
      delete this.channelMembersQuery.requestCount;
    }
  }

  onPageChange (page) {
    this.currentPage = page;
    this.fetchExistingMembersAndInvites();
  }

  onSort (field, order) {
    this.channelMembersQuery.sortBy = field;
    this.channelMembersQuery.sortDir = order;
    this.fetchExistingMembersAndInvites();
  }

  async fetchJoinRequests () {
    this.loadingJoinRequests = true;
    // grab the current incoming requests to join
    const { data } = await ChannelMemberService.channelMemberSlugGet(
        { slug: this.channelSlug, },
        { memberStatus: MemberStatus.JoinRequestSent }
    );
    this.joinRequests = data;
    this.loadingJoinRequests = false;
  }

  async searchInvites () {
    if (this.searchString.length >= 2) {
      this.searchLoading = true;
      this.searchByEmail = this.isSearchStringEmail(this.searchString);

      const { data } = await UserService.userSearchGet({ qs: this.searchString, any: true });
      this.showEmailInvite = (this.searchByEmail && !data.length);
      //filter out any connections already in the channel, then store what's left
      this.connections = data.filter((payload) => {
        for (let i = 0; i < this.invites.length; i++) {
          if (this.invites[i].username === payload.username) {
            return false;
          }
        }
        return true;
      }).map((payload) => {
        return payload;
      });

      this.searchLoading = false;
    } else {
      this.searchByEmail = false;
    }
  }

  // very loose check on email, but we don't need anything more complicated
  isSearchStringEmail (str: string): boolean {
    return !!str.match(/^\S+@\S+\.\S+$/);
  }

  async handleSelection (selection) {
    this.loadingInvites = true;

    const newInvite = await ChannelMemberService.channelMemberSlugInvitePost(
        {
          username: selection.username,
          canAdd: false,
        },
        {
          slug: this.channelSlug,
        }
    );
    this.invites.unshift(newInvite);
    this.connections = [];
    this.loadingInvites = false;
  }

  async handleCanAddToggle (channelMember: ChannelMemberDatum) {
    const newDatum: ChannelMember = await ChannelMemberService.channelMemberSlugInviteUpdatePatch(
        {
          canAdd: channelMember.canAdd,
          username: channelMember.username,
        },
        { slug: this.channelSlug, }
    );
    this.canAddOrManageCb(newDatum);
  }

  async handleCanManageToggle (channelMember: ChannelMemberDatum) {
    const newDatum: ChannelMember = await ChannelMemberService.channelMemberSlugInviteUpdatePatch(
        {
          canManage: channelMember.canManage,
          username: channelMember.username,
        },
        { slug: this.channelSlug, }
    );
    this.canAddOrManageCb(newDatum);
  }

  canAddOrManageCb (newDatum) {
    for (let i = 0; i < this.invites.length; ++i) {
      if (this.invites[i].username === newDatum.username) {
        this.invites[i] = newDatum;
        break;
      }
    }
  }

  isChannelOwner (username: string) {
    return this.channel.owner.username === username;
  }

  async removeInvite (channelMember: ChannelMemberDatum) {
    await ChannelMemberService.channelMemberSlugInviteRemoveUsernameDelete({
      slug: this.channelSlug,
      username: channelMember.username,
    });
    await this.fetchExistingMembersAndInvites();
  }

  async acceptJoinRequest (person: ChannelMemberDatum) {
    await ChannelMemberService.channelMemberSlugJoinRequestAcceptPatch({
      username: person.username,
      canAdd: false
    }, {
      slug: this.channelSlug
    });
    await this.fetchExistingMembersAndInvites();
  }

  async rejectJoinRequest (person: ChannelMemberDatum) {
    await ChannelMemberService.channelMemberSlugJoinRequestRejectPatch({
      username: person.username,
      canAdd: false
    }, {
      slug: this.channelSlug
    });
    await this.fetchExistingMembersAndInvites();
  }

  inviteToChannelByEmail () {
    const hook: InvitationHook = {
      type: Type.ChannelAccept,
      uniqueRef: this.channelSlug,
      payload: [{
        key: 'channelName',
        value: this.channel.name
      }]
    };
    const payload: OModalsContainerInviteByEmail = {
      email: this.searchString,
      emailDisabled: true,
      message: this.$t('page.channelUpsert.form.inviteToChannel.message', { channelName: this.channel.name }) as string,
      invitationHooks: [hook],
      includeCodeToCopy: false
    };
    EventBus.$emit(EventBusEvents.INVITE_BY_EMAIL, payload);
  }

  inviteSent (sent: Invitation) {
    if( sent.invitedTo && sent.invitedTo.length > 0 ){
      this.$buefy.dialog.alert({
        title: this.$t('page.invitations.invitationSent.title') as string,
        message: this.$t('page.invitations.invitationSent.message', {
          firstName: sent.invitedTo[0].firstName,
          lastName: sent.invitedTo[0].lastName,
          email: sent.invitedTo[0].email,
        }) as string
      });
    }
  }

  @Watch('channelSlug')
  async loadChannelInvitations () {
    if (this.channelSlug) {
      this.channelMembersQuery.requestCount = true;
      this.isDisabledForm = false;
      await this.fetchExistingMembersAndInvites();
    }
  }
}
</script>
