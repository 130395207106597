<template>
  <div v-if="tourActive">
    <div v-if="tourSettings.vStepSpotlight" class="v-tour__target--highlighted-blocker"></div>
    <v-tour
        name="LifferyTour"
        ref="LifferyTourContainer"
        :steps="vueTour.steps"
        :options="vueTour.options"
        :callbacks="vueTour.callbacks"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
              v-if="tour.steps[tour.currentStep]"
              :key="tour.currentStep"
              :step="tour.steps[tour.currentStep]"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :skip="tour.skip"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
              :class="{
              'v-step-wide': tourSettings.vStepWide,
              'v-step-thin': tourSettings.vStepThin,
              'hide-step': tourSettings.vStepHide,
              'v-tour__target--highlighted': tourSettings.vStepSpotlight
            }"
          >
            <div slot="header">
              <div v-if="!vueTour.tourName" class="v-step__header">
                <div v-html="vueTour.tourName"></div>
              </div>
            </div>
            <!-- If need to disable navigation temporarily, set disable to true to hide buttons -->
            <!-- Please note, we have pulled in the buttons into this slot for customization but in doing so don't have access to isButtonEnabled(), bespoke disabler created in tourSettings.disable[AllButtons/back/etc] -->
            <div slot="actions">
              <div v-if="!selectATour.active" class="v-step__buttons">
                <!-- Skip -->
                <b-button
                    @click.prevent="tour.skip"
                    v-if="!tour.isLast && !tourSettings.hideButtons.all && !tourSettings.hideButtons.skip"
                    :disabled="tourSettings.disableButtons.all || tourSettings.disableButtons.skip"
                    type="is-primary"
                    size="is-small"
                    class="v-step__button v-step__button-skip mr-1 px-3"
                >
                  {{ tour.labels.buttonSkip }}
                </b-button>
                <!-- Back -->
                <b-button
                    @click.prevent="tour.previousStep"
                    @click="() => tourSettings.direction = directions.Backwards"
                    v-if="!tour.isFirst && !tourSettings.hideButtons.all && !tourSettings.hideButtons.back"
                    :disabled="tourSettings.disableButtons.all || tourSettings.disableButtons.back"
                    type="is-primary"
                    size="is-small"
                    class="v-step__button v-step__button-previous mr-1 px-3"
                >
                  {{ tour.labels.buttonPrevious }}
                </b-button>
                <!-- Next -->
                <b-button
                    @click.prevent="tour.nextStep"
                    @click="() => tourSettings.direction = directions.Forwards"
                    v-if="!tour.isLast && !tourSettings.hideButtons.all && !tourSettings.hideButtons.next"
                    :disabled="tourSettings.disableButtons.all || tourSettings.disableButtons.next"
                    type="is-primary"
                    size="is-small"
                    class="v-step__button v-step__button-next mr-1 px-3"
                >
                  {{ tour.labels.buttonNext }}
                </b-button>
                <!-- Finish -->
                <b-button
                    @click.prevent="tour.finish"
                    v-if="tour.isLast && !tourSettings.hideButtons.all && !tourSettings.hideButtons.finish"
                    :disabled="tourSettings.disableButtons.all || tourSettings.disableButtons.finish"
                    type="is-primary"
                    size="is-small"
                    class="v-step__button v-step__button-stop mr-1 px-3"
                >
                  {{ tourSettings.finishIsClose ? $t('dict.close') : tour.labels.buttonStop }}
                </b-button>
              </div>
              <!-- Bespoke buttons for tour selector -->
              <div v-else>
                <!-- Close -->
                <b-button
                    @click.prevent="tour.finish"
                    type="is-primary"
                    size="is-small"
                    class="v-step__button mr-1 px-3"
                >
                  {{ runningTour === availableTours.WelcomeTour ? $t('vueTour.buttonEnd') : $t('dict.close') }}
                </b-button>
                <!-- Back button available if end of welcome tour -->
                <b-button
                    v-if="runningTour === availableTours.WelcomeTour"
                    @click.prevent="tour.previousStep"
                    @click="() => { tourSettings.direction = directions.Backwards; selectATour.active = false }"
                    type="is-primary"
                    size="is-small"
                    class="v-step__button mr-1 px-3"
                >
                  {{ $t('vueTour.buttonPrevious') }}
                </b-button>
                <!-- Launch -->
                <b-button
                    @click="() => selectATour.trigger = true"
                    type="is-primary"
                    size="is-small"
                    class="v-step__button mr-1 px-3"
                >
                  {{ $t('dict.start') }}
                </b-button>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<style lang="scss">
/**
    Source file for css. Copy-pasted to the style tag here for easier modification:
    @import '../../../node_modules/vue-tour/dist/vue-tour.css';
    If vue-tour is updated then update this as appropriate.
 */

.hide-step {
  display: none;
}

body.v-tour--active {
  pointer-events: none;
}

.v-tour {
  pointer-events: auto;
}

/**
 * HIGHLIGHT EVERYTHING - border made massive to highlight whole screen
 */
.v-tour__target--highlighted {
  -webkit-box-shadow: 0 0 0 99999px rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0 0 0 99999px rgba(255, 255, 255, 0.6) !important;
  pointer-events: auto !important;
  z-index: 9999 !important;
}

.v-tour__target--highlighted-blocker {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: auto !important;
  z-index: 9998 !important;
}

.v-tour__target--relative {
  position: relative;
}

.v-step {
  background: var(--grey-darkest-color);
  color: var(--white-color);
  max-width: 320px;
  border-radius: 3px;
  -webkit-box-shadow: transparent 0 0 0 0, transparent 0 0 0 0, rgba(0, 0, 0, .1) 0 6px 9px -1px, rgba(0, 0, 0, .06) 0 4px 6px -1px;
  box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent, 0 6px 9px -1px rgba(0, 0, 0, .1), 0 4px 6px -1px rgba(0, 0, 0, .06);
  padding: 1rem;
  pointer-events: auto;
  text-align: center;
  z-index: 10000;

  a {
    color: var(--grey-lightest-color);
    text-decoration: underline;
  }
}

/**
 * WIDE BOX - Bespoke class to force wide step when needed
 */
.v-step-wide.v-step {
  @media screen and (min-width: 420px) {
    max-width: 400px;
  }
}

/**
 * THIN BOX - As above but to force thin step when needed
 */
.v-step-thin.v-step {
  max-width: 250px;
}

.v-step--sticky {
  position: fixed;
  top: 50%;
  left: 50%;

  /**
   * MIN WIDTH
   */
  min-width: 300px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.v-step--sticky .v-step__arrow {
  display: none;
}

.v-step__arrow,
.v-step__arrow::before {
  position: absolute;
  width: 10px;
  height: 10px;
  background: inherit;
}

.v-step__arrow {
  visibility: hidden;
}

.v-step__arrow--dark::before {
  background: #454d5d;
}

.v-step__arrow::before {
  visibility: visible;
  content: '';
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: -5px;
}

.v-step[data-popper-placement^=top] > .v-step__arrow {
  bottom: -5px;
}

.v-step[data-popper-placement^=bottom] > .v-step__arrow {
  top: -5px;
}

.v-step[data-popper-placement^=right] > .v-step__arrow {
  left: -5px;
}

.v-step[data-popper-placement^=left] > .v-step__arrow {
  right: -5px;
}

.v-step__header {
  margin: -1rem -1rem .5rem;
  padding: .5rem;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.v-step__content {
  margin: 0 0 1rem 0;
}
</style>

<script lang="ts">
import { RouteNames } from '@/router/RouteNames';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { waitTillElementIsVisible } from '@/utils/waitTillElementIsVisible';
import EventBus, { EventBusEvents } from '@/EventBus';
import {
  AvailableTours,
  TourSettings,
  VueTourButtons,
  VueTourDirection,
  VueTourObject,
  VueTourPages,
  VueTourPlacement,
  VueTourStep
} from '@/components/organisms/OVueTour.types';
import printFunctionAsString from '@/storybook-components/src/utils/printFunctionAsString';
import camelCaseToSentenceCase from '@/storybook-components/src/utils/camelCaseToSentenceCase';
import { LifferyTourStore } from '@/store';
import { i18n } from '@/plugins/i18n';
import router from '@/router';
import MVueTourSelector from '@/components/molecules/MVueTourSelector.vue';
import { Item } from '@/api/ms-item/services/interfaces/Item';
import clickOnElement from '@/storybook-components/src/utils/clickOnElement';
import isProd from '@/utils/isProd';
import { pause } from 'common-utils/time';

@Component
export default class OVueTour extends Vue {
  eventCallerId: string = 'OVueTour';

  /**
   * Gets set to true when startTour() is called, if not called then prevents unneeded content being added to DOM
   */
  tourActive: boolean = false;
  runningTour: AvailableTours = AvailableTours.WelcomeTour;
  availableTours = AvailableTours;

  /**
   * Ensure both are false in production.
   *  vueTourDebug - the debug log built into vue-tour
   *  lifferyTourDebug - bespoke debug log built by us, much more verbose than vue-tour
   */
  vueTourDebug: boolean = !isProd();
  lifferyTourDebug: boolean = !isProd();

  $refs!: {
    LifferyTourContainer: HTMLElement
  };

  // Main object holding vue-tour specific settings, steps, callbacks etc
  vueTour: VueTourObject = {
    tourName: AvailableTours.WelcomeTour,
    currentPage: VueTourPages.Welcome,
    currentPageStep: 0,
    steps: [],
    options: {
      // ensure is false in production
      debug: this.vueTourDebug,
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: this.$t('vueTour.buttonSkip') as string,
        buttonPrevious: this.$t('vueTour.buttonPrevious') as string,
        buttonNext: this.$t('vueTour.buttonNext') as string,
        buttonStop: this.$t('vueTour.buttonStop') as string
      },
      highlight: false
    },
    callbacks: {
      onSkip: () => this.runCallback(this.onSkipCallback),
      onStart: () => this.runCallback(this.onStartCallback),
      onStop: () => this.runCallback(this.onStopCallback),
      onFinish: () => this.runCallback(this.onFinishCallback),
      onPreviousStep: () => this.runCallback(this.previousStepCallback),
      onNextStep: () => this.runCallback(this.nextStepCallback)
    },
    tourRouterPlaceholder: 'Navigating...'
  };

  // Extended tour settings that are bespoke to the Liffery implementation of the tour
  directions = VueTourDirection;
  tourSettings: TourSettings = {
    direction: VueTourDirection.Forwards,
    routerActive: false,

    // box states can be edited during the tour depending on placement/screen etc
    vStepWide: false,
    vStepThin: false,

    vStepSpotlight: false,

    // may be necessary to hide while waiting for heavy UI work in a before step, set to true
    vStepHide: false,

    // 320 is default width
    vueTourBoxWidth: 320,

    // if you need to stop navigation for any reason, set to true
    disableButtons: {
      all: false,
      skip: false,
      back: false,
      next: false,
      finish: false
    },

    // set to true to hide all or individual buttons
    hideButtons: {
      all: false,
      skip: false,
      back: false,
      next: false,
      finish: false
    },

    userPressedSkip: false,

    finishIsClose: false
  };

  // Settings for tour selector
  selectATour = {
    active: false,
    trigger: false
  };

  // When adding a new item during the tour, that item is emitted back and stored here to be used for shopping list
  newItem!: Item;

  demoUrlIsInserted = false;

  screenWidth: number = 0;

  // watch the tour setting states above and store a variable of the box width to check against during steps
  @Watch('tourSettings.vStepWide')
  watchVStepWide () {
    this.tourSettings.vueTourBoxWidth = this.tourSettings.vStepWide ? 400 : 320;
  }

  @Watch('tourSettings.vStepThin')
  watchVStepThin () {
    this.tourSettings.vueTourBoxWidth = this.tourSettings.vStepThin ? 250 : 320;
  }

  created () {
    this.bindEvents();
  }

  bindEvents () {
    window.addEventListener('resize', this.setScreenWidth);
    EventBus.$on(EventBusEvents.TOUR_START, this.eventCallerId, this.startTour);
    EventBus.$on(EventBusEvents.TOUR_DEMO_URL_INPUT_FINISHED, this.eventCallerId, () => this.demoUrlIsInserted = true);
    EventBus.$on(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_FORM_NEW_ITEM, this.eventCallerId, (payload) => this.newItem = payload);
  }

  unbindEvents () {
    window.removeEventListener('resize', this.setScreenWidth);
    EventBus.$remove(EventBusEvents.TOUR_START, this.eventCallerId);
    EventBus.$remove(EventBusEvents.TOUR_DEMO_URL_INPUT_FINISHED, this.eventCallerId);
    EventBus.$remove(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_FORM_NEW_ITEM, this.eventCallerId);
  }

  beforeDestroy () {
    this.unbindEvents();
  }

  setScreenWidth () {
    this.screenWidth = window.innerWidth;
  }

  /**
   * Tours can be triggered from any page by emitting to the event bus. Ensure that you have added the tour to the enum
   * of available tours, then iterate through here to route the user to the correct part of Liffery and set the right
   * vueTour.currentPage before starting that tour.
   */
  // eslint-disable-next-line max-lines-per-function
  async startTour (tour: AvailableTours) {
    // Use the router flag to prevent any callbacks chaining up if routing is happening before starting
    this.tourSettings.routerActive = true;

    // If a tour is already active, stop it first before continuing
    if (this.tourActive) {
      await this.stopTour();
    }

    // Ensure we know the tour is running and which tour it is
    this.tourActive = true;
    this.runningTour = tour;

    this.lifferyTourDebugLog('startTour', 'tour activated', tour);

    // Reset tour object in case it's been run before
    this.tourSettings.direction = VueTourDirection.Forwards;
    this.tourSettings.vStepHide = false;
    this.vueTour.currentPageStep = 0;
    this.tourSettings.userPressedSkip = false;
    this.resetTourBoxState();
    this.clearSteps();

    // Any tours which shouldn't change the route at the start, set to true
    let allowRouteChange = true;

    // Dashboard is most used route in the tour so this is default and changed as needed
    let routeName = RouteNames.ROUTE_DASHBOARD;

    // Set the tour accordingly
    switch (tour) {
      case AvailableTours.WelcomeTour:
        this.vueTour.currentPage = VueTourPages.Welcome;
        break;

      case AvailableTours.SelectATour:
        this.vueTour.currentPage = VueTourPages.TourSelector;
        this.selectATour.active = true;
        allowRouteChange = false;
        break;

      case AvailableTours.DashboardTour:
        this.vueTour.currentPage = VueTourPages.Dashboard;
        break;

      case AvailableTours.ProfileTour:
        this.vueTour.currentPage = VueTourPages.Profile;
        routeName = RouteNames.ROUTE_YOUR_PROFILE;
        break;
    }

    // Set the tour name in human-readable case
    this.vueTour.tourName = camelCaseToSentenceCase(tour);

    // If the user is not on the right start page, route them there before starting
    if (this.$route.name !== routeName && allowRouteChange) {
      this.lifferyTourDebugLog('startTour', 'routing to start page', routeName);
      this.$router.push({ name: routeName });
      await pause(250);
    }

    // Start the tour, wrapped in a promise to ensure the steps have finished loading into the array before we press start
    new Promise((resolve, reject) => {
      this.setSteps();
      this.vueTour.steps.length ? resolve(true) : reject();
    }).then(() => {
      this.lifferyTourDebugLog('startTour', 'steps loaded', this.vueTour.steps);
      this.tourSettings.routerActive = false;
      this.$tours['LifferyTour'].start();
    }).catch((e) => {
      console.error('Failed to start tour', e);
    });
  }

  /**
   * Stops a running tour and pauses 250ms to ensure it's finished transitions
   */
  async stopTour () {
    this.$tours['LifferyTour'].stop();
    return pause(250);
  }

  clearSteps () {
    this.vueTour.steps = [];
  }

  /**
   * Using the page name set in vueTour.currentPage will get the relevant steps to show.
   */
  setSteps (): void {
    const page = this.vueTour.currentPage;
    const steps: VueTourStep[] = this.getSteps(page);
    // Any pages need extra...
    switch (page) {
      case VueTourPages.Welcome: {
        // Welcome tour shows welcome message then continues with Dashboard tour and ends with Take the Tour again
        steps.push(...this.getSteps(VueTourPages.Dashboard));
        steps.push(...this.getSteps(VueTourPages.TourSelector));
        break;
      }
      case VueTourPages.Dashboard: {
        steps.push(...this.getSteps(VueTourPages.TakeTourAgain));
        break;
      }
    }

    this.vueTour.steps = steps;
  }

  /**
   * If you create a tour that needs to route between different pages while still naturally showing next/back buttons in
   * the tour box you can do it by adding a routing step at the start and end of each block of steps. On reroute the tour
   * will be stopped, the new steps populated and then the tour started again skipping the routing step.
   * An example routing step:
   *       {
   *          content: this.vueTour.tourRouterPlaceholder,
   *          before: () => new Promise((resolve) =>{
   *            this.tourRouter(VueTourPages.Dashboard);
   *            this.resolveBeforeStep(resolve);
   *          })
   *       }
   */
  // eslint-disable-next-line max-lines-per-function
  getSteps (page: VueTourPages) {
    let steps: VueTourStep[];
    switch (page) {
      /**
       * WELCOME MESSAGE
       *    Only new users to Liffery see this message, will be prepended to the dashboard tour.
       *      1. Welcome message, target liffery icon.
       */
      case VueTourPages.Welcome: {
        steps = [
          {
            target: '.logo-title',
            content: this.$t('vueTour.page.welcome.welcome') as string,
            params: {
              placement: VueTourPlacement.Top,
            },
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              this.tourSettings.vStepSpotlight = true;
              this.resolveBeforeStep(resolve);
            })
          }
        ];
        break;
      }
      /**
       *  DASHBOARD
       *    New users will take this tour after seeing a welcome message. Cover most important points - concept of Liffery
       *    and adding items. Steps as follows:
       *      1. Explain what liffery is, target Items/Channels/People search header
       *      2. Explain concept of shopping bag/list, target shopping bag header
       *      3. Let's add, target add button
       *      4. [Load item entry form and await entry of demo link] Explain item entry form, target textarea
       *      5. Explain item preview, target the preview box or preview button
       *      6. [Submits form] Explain item card, target item card
       *      7. This is how to add to shopping list, target item card shopping bag
       *      8. [Load add to shopping list modal] Demonstrate how adding to list keeps it secret from receiver, target header
       *      9. [Submit adding to shopping list for self] Show that shopping list items collate in header, target shopping bag header
       */
      case VueTourPages.Dashboard: {
        steps = [
          {
            target: '.MSearchBarWithTabs .tabs-container',
            content: this.$t('vueTour.page.dashboard.whatIsLiffery') as string,
            params: {
              placement: VueTourPlacement.Bottom,
              enableScrolling: false
            },
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              this.tourSettings.vStepSpotlight = true;
              LifferyTourStore.increaseDashboardTourCount();
              this.tourSettings.vStepWide = true;
              this.resolveBeforeStep(resolve);
            })
          },
          {
            target: '.MShoppingListHeader svg',
            content: this.$t('vueTour.page.dashboard.shoppingList') as string,
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              this.tourSettings.vStepSpotlight = true;
              this.resolveBeforeStep(resolve);
            })
          },
          {
            target: '.header-add-item-to-liffery svg',
            content: this.$t('vueTour.page.dashboard.letsAdd') as string,
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              this.tourSettings.vStepSpotlight = true;
              // user may have clicked back button, hide the item entry form if it's present
              if (document.querySelector('.modal-entry-item')) {
                EventBus.$emit(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_FORM);
              }
              this.resolveBeforeStep(resolve);
            }),
          },
          {
            target: '.item-entry-form textarea',
            content: this.$t('vueTour.page.dashboard.itemEntry') as string,
            params: {
              placement: VueTourPlacement.Bottom
            },
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              this.tourSettings.vStepSpotlight = false;
              this.tourSettings.vStepHide = true;
              // if user has clicked back, they might also have clicked the preview button on mobile, so ensure the preview is closed
              EventBus.$emit(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_PREVIEW);
              // the user might have clicked the plus icon themselves, if they have just continue, otherwise emit to load the entry form
              if (!document.querySelector('.modal-entry-item')) {
                EventBus.$emit(EventBusEvents.ITEM_ADD);
              }
              waitTillElementIsVisible('.modal-entry-item').then(() => {
                // emit to add demo url, then wait till it's in before resolving
                this.demoUrlIsInserted = false;
                EventBus.$emit(EventBusEvents.TOUR_INSERT_DEMO_URL);
                this.waitTillDemoUrlIsInserted().then(() => {
                  const textarea = document.querySelector('.item-entry-form textarea') as HTMLTextAreaElement;
                  textarea.blur();
                  this.resolveBeforeStep(resolve);
                });
              });
            })
          },
          {
            target: '.url-preview-wrapper',
            content: this.$t('vueTour.page.dashboard.preview') as string,
            params: {
              placement: VueTourPlacement.Left
            },
            before: () => new Promise((resolve) => {
              // mobile view won't have the preview screen showing, so set the target to the preview button on mobile
              const step = this.getCurrentStep() + 1; // +1 as we're in the before
              if (!document.querySelector('.url-preview-wrapper')) {
                this.tourSettings.vStepThin = true;
                this.vueTour.steps[step].target = '.button.is-text span';
                this.setCurrentStepPlacement(VueTourPlacement.Bottom);
              }
              this.resolveBeforeStep(resolve);
            })
          },
          {
            target: '.vue-virtual-collection-container .cell-container .item-card',
            content: this.$t('vueTour.page.dashboard.itemCard') as string,
            params: {
              placement: VueTourPlacement.Right,
              enableScrolling: false,
              disableButtons: {
                back: true
              }
            },
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              this.tourSettings.vStepHide = true;
              // submit the item entry form from the previous step and disable scroll
              EventBus.$emit(EventBusEvents.TOUR_CLOSE_ITEM_ENTRY_FORM_SUBMIT);
              this.waitTillItemCardVisible().then(() => {
                //figure out the width of the screen and set the placement to the top if not wide enough
                if (400 + this.tourSettings.vueTourBoxWidth > this.screenWidth) {
                  this.setCurrentStepPlacement(VueTourPlacement.Top);
                }
                this.resolveBeforeStep(resolve);
              });
            })
          },
          {
            target: '.OItemCardContainer .card-footer .AShoppingListButton svg',
            content: this.$t('vueTour.page.dashboard.shoppingBag') as string,
            params: {
              placement: VueTourPlacement.Top
            },
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              this.waitTillItemCardVisible().then(() => {
                this.resolveBeforeStep(resolve);
              });
            })
          },
          {
            target: '.secrecyLevel',
            content: this.$t('vueTour.page.dashboard.shoppingListDemo') as string,
            params: {
              placement: VueTourPlacement.Bottom
            },
            before: () => new Promise((resolve) => {
              // Emit to load the shopping list modal for the newly added item
              EventBus.$emit(EventBusEvents.SHOPPING_LIST_MODAL, JSON.parse(JSON.stringify(this.newItem)));
              waitTillElementIsVisible('.modal-card-title').then(() => {
                this.resetTourBoxState();
                this.resolveBeforeStep(resolve);
              });
            })
          },
          {
            target: '.MShoppingListHeader svg',
            content: this.$t('vueTour.page.dashboard.shoppingListWithItem') as string,
            params: {
              placement: VueTourPlacement.Bottom
            },
            before: () => new Promise((resolve) => {
              // Emit to add the item to the users shopping list
              EventBus.$emit(EventBusEvents.SHOPPING_LIST_MODAL_SUBMIT);
              this.resolveBeforeStep(resolve);
            })
          }
        ];
        break;
      }
      /**
       *  PROFILE PAGE (Part of Welcome Tour)
       *    An overview of the profile page. Steps as follows:
       *      1. Explain profile page, target profile header
       *      2. Explain items tab, target item tab
       *      3. Explain pinning items, target pinned tab
       *      4. Explain channels, target channel tab
       *      5. Explain connections, target people tab
       *      6. Encourage users to add connections after tour, target find people sub-tab
       */
      case VueTourPages.Profile: {
        steps = [
          {
            target: '.MProfilePageHeader',
            content: this.$t('vueTour.page.profile.overview') as string,
            params: {
              placement: VueTourPlacement.Bottom,
              disableButtons: {
                back: true
              }
            },
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              this.tourSettings.vStepSpotlight = true;
              // emit to store that user has seen tour, after first view it won't trigger automatically
              LifferyTourStore.increaseProfileTourCount();
              // subtract if moving backward to accommodate being in before
              const step = this.getCurrentStep() + this.tourSettings.direction === VueTourDirection.Backwards ? -1 : 0;
              this.waitTillTourStepTargetVisible(step).then(() => {
                this.resolveBeforeStep(resolve);
              });
            })
          },
          {
            target: 'li.items',
            content: this.$t('vueTour.page.profile.items') as string,
            params: {
              placement: VueTourPlacement.Top,
              enableScrolling: false
            },
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              const tab = this.$route.query.tab;
              if (tab !== 'items') {
                clickOnElement('li.items a');
              }
              this.resolveBeforeStep(resolve);
            })
          },
          {
            target: 'li.pinned',
            content: this.$t('vueTour.page.profile.pins') as string,
            params: {
              placement: VueTourPlacement.Top
            },
            before: () => new Promise((resolve) => {
              this.tourSettings.vStepHide = true;
              clickOnElement('li.pinned a');
              waitTillElementIsVisible('.OPinnedItemsContainer').then(() => {
                this.tourSettings.vStepWide = true;
                this.resolveBeforeStep(resolve);
              });
            })
          },
          {
            target: 'li.channels',
            content: this.$t('vueTour.page.profile.channels') as string,
            params: {
              placement: VueTourPlacement.Top
            },
            before: () => new Promise((resolve) => {
              this.tourSettings.vStepHide = true;
              this.tourSettings.vStepWide = true;
              clickOnElement('li.channels a');
              this.resolveBeforeStep(resolve);
            })
          },
          {
            target: 'li.people',
            content: this.$t('vueTour.page.profile.people') as string,
            params: {
              placement: VueTourPlacement.Top
            },
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              const tab = this.$route.query.tab;
              if (tab !== 'people') {
                clickOnElement('li.people a');
              }
              this.resolveBeforeStep(resolve);
            })
          },
          {
            target: '#discover-tab',
            content: this.$t('vueTour.page.profile.findConnections') as string,
            params: {
              placement: VueTourPlacement.Top
            },
            before: () => new Promise((resolve) => {
              const tab = this.$route.query.tab;
              if (tab !== 'people') {
                clickOnElement('li.people a');
              }
              EventBus.$emit(EventBusEvents.TOUR_OPEN_FIND_CONNECTIONS_TAB);
              this.waitTillTourStepTargetVisible().then(() => {
                this.resolveBeforeStep(resolve);
              });
            })
          }
        ];
        break;
      }
      /**
       *  TAKE THE TOUR AGAIN
       *    If a user skips the tour at any point, this is triggered. Steps as follows:
       *      1. (not working, removed for now) [Emit to ensure dropdown is expanded] Point out they can take the tour again, target "take the tour"
       *      1. Point out they can take the tour again, target header drop down
       */
      case VueTourPages.TakeTourAgain: {
        steps = [
          {
            target: '.MUserProfileHeader .dropdown-menu-animation',
            content: this.$t('vueTour.page.takeTourAgain') as string,
            params: {
              placement: VueTourPlacement.Bottom
            },
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              this.tourSettings.vStepSpotlight = true;

              if (this.runningTour === AvailableTours.WelcomeTour) {
                // emit to store that user has seen tour, after first view it won't trigger automatically
                LifferyTourStore.increaseWelcomeTourCount();
              }

              //if the user got here by pressing skip, make sure back button is hidden
              if (this.tourSettings.userPressedSkip) {
                const step = this.getCurrentStep();
                // @ts-ignore
                this.vueTour.steps[step].params.hideButtons = { back: true };
              }

              this.resolveBeforeStep(resolve);
            })
          },
          /**
           * Todo - [BUG] Emit doesn't work, it's unregistered on MUserProfileHeader for some reason between routing steps - resolve the bug and have this as the final step, pointing at the take the tour
           {
            target: '#takeTheTour',
            content: this.$t('vueTour.welcome.page.takeTourAgain') as string,
            params: {
              placement: VueTourPlacement.Top
            },
            before: () => new Promise((resolve) => {
              //todo - [BUG] - Does not trigger, event is not being bound in MUserProfileHeader between routing steps
              //firstly ensure the menu is currently not active in case the user opened it themselves
              EventBus.$emit(EventBusEvents.TOUR_HIDE_PROFILE_HEADER);
              this.waitForProfileHeaderState(false).then(() => {
                EventBus.$emit(EventBusEvents.TOUR_SHOW_PROFILE_HEADER);
                this.waitForProfileHeaderState(true).then(() => {
                  this.logResolve();
                  resolve(true);
                });
              });
            })
          }
           */
        ];
        break;
      }
      /**
       * TOUR SELECTOR
       *
       */
      case VueTourPages.TourSelector: {
        steps = [
          {
            content: '<div id="pickATour">Loading...</div>',
            params: {
              placement: VueTourPlacement.Bottom
            },
            before: () => new Promise((resolve) => {
              this.resetTourBoxState();
              this.tourSettings.vStepSpotlight = true;
              this.selectATour.active = true;

              if (this.runningTour === AvailableTours.WelcomeTour) {
                // emit to store that user has seen tour, after first view it won't trigger automatically
                LifferyTourStore.increaseWelcomeTourCount();
              }

              this.resolveBeforeStep(resolve);
            }),
            after: () => new Promise((resolve) => {
              // Hacky timeout to ensure transition between steps finished before loading component
              setTimeout(() => {
                waitTillElementIsVisible('.v-step__content').then(() => {
                  const div = document.querySelector('.v-step__content') as HTMLElement;
                  div.innerHTML = '';
                  const t = document.createElement('template');
                  div?.appendChild(t);
                  new Vue({
                    i18n,
                    router,
                    render: (h) => h(MVueTourSelector, {
                      props: {
                        tourSelected: this.selectATour.trigger,
                        endOfWelcomeTour: this.runningTour === AvailableTours.WelcomeTour
                      }
                    })
                  }).$mount(t);
                  resolve();
                });
              }, 200);
            })
          }
        ];
        break;
      }
    }

    return steps;
  }

  resolveBeforeStep (resolve: () => void) {
    this.lifferyTourDebugLog('resolveBeforeStep', 'before step resolved');
    resolve();
  }

  /**
   * To route to another page and keep the tour going we:
   *  - stop the tour
   *  - route to new page
   *  - set the next page's steps
   *  - then start the tour again to kick off from the start or end depending on which direction we are going
   */
  // eslint-disable-next-line max-lines-per-function
  async tourRouter (page: VueTourPages, back?: boolean) {
    this.tourSettings.routerActive = true;

    await this.stopTour();

    this.lifferyTourDebugLog('tourRouter', 'ROUTER ACTIVE', page);
    this.lifferyTourDebugLog('tourRouter', 'direction', back ? 'BACKWARDS' : 'FORWARDS');

    this.vueTour.currentPage = page;

    // For the take tour again we don't want to reroute. If they clicked skip then they should remain wherever they are
    let pageChanged = false;
    if (page !== VueTourPages.TakeTourAgain) {
      // Dashboard is most used route in the tour so this is default and changed as needed
      let newRoute = RouteNames.ROUTE_DASHBOARD;
      switch (page) {
        case VueTourPages.Profile:
          newRoute = RouteNames.ROUTE_YOUR_PROFILE;
          break;
      }
      // as user can skip the tour they may already be on the relevant page, this check stops us routing to current page
      if (this.$route.name !== newRoute) {
        this.$router.push({ name: newRoute });
        pageChanged = true;
      }
    }
    this.lifferyTourDebugLog('tourRouter', 'resulted in page change?', pageChanged ? 'TRUE' : 'FALSE');

    // wrap rest into a promise to ensure steps are loaded
    new Promise((resolve, reject) => {
      this.setSteps();
      this.vueTour.steps.length ? resolve(true) : reject();
    }).then(() => {
      // If the first step of the array is a routing step, increase the startStep by 1
      let startStep = this.vueTour.steps[0].content === this.vueTour.tourRouterPlaceholder ? 2 : 1;
      if (back) {
        // we're going backwards, count the number of steps and start at the total minus 1 to skip the forward routing step
        const totalSteps = this.vueTour.steps.length;
        startStep = totalSteps - 1;
      }
      // subtract 1 so we match the array numbering
      --startStep;

      //set this step in the vueTour object
      this.vueTour.currentPageStep = startStep;

      this.lifferyTourLogStep('tourRouter');

      // between routing steps ensure that the box state is reset
      this.resetTourBoxState();

      this.tourSettings.routerActive = false;
      this.$tours['LifferyTour'].start(String(startStep));
    }).catch((e) => {
      console.error('Tour failed wile routing between steps', e);
    });
  }

  getCurrentStep (): number {
    return this.vueTour.currentPageStep;
  }

  /**
   * Use in the before step to reposition the placement of a step according to screen size or whatever.
   * Must be used in before otherwise will target wrong step.
   */
  setCurrentStepPlacement (placement: VueTourPlacement) {
    this.lifferyTourDebugLog('setCurrentStepPlacement', 'changing default placement', placement);
    // If the first step of the steps array is a routing step the array modifier is +2, otherwise it's +1
    let step = this.getCurrentStep() + 1;
    if (this.vueTour.steps[0].content === this.vueTour.tourRouterPlaceholder) {
      ++step;
    }
    // @ts-ignore
    this.vueTour.steps[step].params.placement = placement;
  }

  /**
   * Same concept as waitTillElementIsVisible(), but we're waiting for phantoms and skeletons to disappear
   */
  async waitTillItemCardVisible () {
    this.lifferyTourDebugLog('waitTillItemCardVisible', 'waiting for item card');
    const firstItem = document.querySelector('.vue-virtual-collection-container .cell-container:first-child > div.item-card') as HTMLElement;
    const firstItemFirstDiv = firstItem.firstElementChild as HTMLElement;
    if (firstItemFirstDiv.classList.contains('phantom-card') || firstItemFirstDiv.classList.contains('skeleton-card')) {
      await pause(25);
      return this.waitTillItemCardVisible();
    } else {
      return true;
    }
  }

  /**
   * For the welcome tour, wait till demo URL is successfully inserted before continuing
   */
  async waitTillDemoUrlIsInserted () {
    if (!this.demoUrlIsInserted) {
      await pause(25);
      return this.waitTillDemoUrlIsInserted();
    } else {
      return true;
    }
  }

  /**
   * Generic wait for current step target element to be visible.
   * Pass step number or it will assume being called in a before() moving forward without any routing having taken place
   * and will get current step + 1
   *
   * @param step
   */
  async waitTillTourStepTargetVisible (step?: number) {
    const stepNumber = typeof step === 'number' ? step : this.getCurrentStep() + 1;
    const querySelector = this.vueTour.steps[stepNumber].target as string;
    this.lifferyTourDebugLog('waitTillTourStepTargetVisible', 'waiting for element', '\'' + querySelector + '\'');
    waitTillElementIsVisible(querySelector).then(() => {
      this.lifferyTourDebugLog('waitTillTourStepTargetVisible', 'found element', document.querySelector(querySelector));
      return true;
    });
  }

  /**
   * For the final take the tour again step, we need to be sure the dropdown is active, wait on it.
   * Currently redundant. Emit function broken so this final step changed for now...
   */
  async waitForProfileHeaderState (active: boolean) {
    this.lifferyTourDebugLog('waitForProfileHeaderState', 'waiting for profile header menu to be', active ? 'active' : 'hidden');
    const headerMenu = document.querySelector('.MUserProfileHeader div.dropdown') as HTMLElement;
    const menuIsActive = headerMenu.classList.contains('is-active');

    if ((active && !menuIsActive) || (!active && menuIsActive)) {
      await pause(25);
      return this.waitForProfileHeaderState(active);
    } else {
      return true;
    }
  }

  /**
   * Called before and after every step that changes the tour box state.
   * Also called during startTour()
   */
  resetTourBoxState () {
    this.lifferyTourDebugLog('resetTourBoxState', 'resetting tour box state');
    this.tourSettings.vStepSpotlight = false;
    this.tourSettings.vStepWide = false;
    this.tourSettings.vStepThin = false;
    this.selectATour.active = false;
    this.selectATour.trigger = false;
    const resetButtons: VueTourButtons = {
      all: false,
      skip: false,
      back: false,
      next: false,
      finish: false
    };
    this.tourSettings.disableButtons = resetButtons;
    this.tourSettings.hideButtons = resetButtons;
  }

  /**
   * Wrapper to the callbacks, only runs if we are not currently active in the router
   */
  runCallback (callback: () => void) {
    if (!this.tourSettings.routerActive) {
      callback();
    }
  }

  onSkipCallback () {
    this.lifferyTourDebugLog('onSkipCallback', 'tour skipped');
    this.tourSettings.userPressedSkip = true;
    if ([AvailableTours.WelcomeTour, AvailableTours.DashboardTour].includes(this.runningTour)) {
      this.tourRouter(VueTourPages.TakeTourAgain);
    }
  }

  /**
   * User can stop the tour with the ESC key, however we have disabled keyboard navigation as it's too problematic, this
   * callback therefore doesn't do anything yet.
   */
  onStopCallback () {
    this.resetTourBoxState();
    this.lifferyTourDebugLog('onStopCallback', 'tour stopped, possibly via tour router between pages');
  }

  // Start is a step, ensure the step callbacks are run
  onStartCallback () {
    this.lifferyTourDebugLog('onStartCallback', 'tour started, debug log is active!');
    this.lifferyTourLogStep('onStartCallback');
    this.eachStepCallback();
  }

  onFinishCallback () {
    this.resetTourBoxState();
    EventBus.$emit(EventBusEvents.TOUR_HIDE_PROFILE_HEADER);
    this.lifferyTourDebugLog('onFinishCallback', 'tour finished');
    this.tourSettings.finishIsClose = false;
  }

  previousStepCallback () {
    --this.vueTour.currentPageStep;

    this.lifferyTourLogStep('previousStepCallback');

    this.eachStepCallback();
  }

  nextStepCallback () {
    ++this.vueTour.currentPageStep;

    this.lifferyTourLogStep('nextStepCallback');

    this.eachStepCallback();
  }

  /**
   * Common functions to run at every point a step might be triggered, start, next, previous etc
   */
  eachStepCallback () {
    this.tourSettings.vStepHide = false;
    this.checkDisableHideButtons();
    this.runAnAfter();
  }

  /**
   * Bespoke function checks to see if we are wanting to disable/hide the current step buttons, and does so accordingly
   */
  checkDisableHideButtons () {
    const step = this.vueTour.currentPageStep;
    const disableButtons = this.vueTour.steps[step].params?.disableButtons;
    const hideButtons = this.vueTour.steps[step].params?.hideButtons;
    if (disableButtons) {
      this.lifferyTourDebugLog('checkDisableHideButtons', 'disabling buttons', disableButtons);
      this.tourSettings.disableButtons = {
        ...this.tourSettings.disableButtons,
        ...disableButtons
      };
    }
    if (hideButtons) {
      this.lifferyTourDebugLog('checkDisableHideButtons', 'hiding buttons', hideButtons);
      this.tourSettings.hideButtons = {
        ...this.tourSettings.hideButtons,
        ...hideButtons
      };
    }
  }

  /**
   * Bespoke function checks for the existence of an after callback and runs it if exists. Is triggered by startCallback,
   * nextStepCallback and previousStepCallback
   */
  runAnAfter () {
    const step = this.vueTour.currentPageStep;
    if (this.vueTour.steps[step].after) {
      this.lifferyTourDebugLog('runAnAfter', 'after function being run', {
        'after () => ': printFunctionAsString(this.vueTour.steps[step].after)
      });
      // @ts-ignore
      this.vueTour.steps[step].after();
    }
  }

  /**
   * Used in start, next step and previous step
   */
  lifferyTourLogStep (functionName: string, force?: boolean, stepNumber?: number) {
    if (this.lifferyTourDebug || force) {
      // can pass the step number to log, otherwise it will default to logging the current step
      const step = typeof stepNumber === 'number' ? stepNumber : this.vueTour.currentPageStep;
      this.lifferyTourDebugLog(functionName, 'step number loaded', step.toString(), force);
      this.lifferyTourDebugLog(functionName, 'step object loaded', this.vueTour.steps[step], force);
      if (this.vueTour.steps[step].before) {
        this.lifferyTourDebugLog(functionName, 'before step ran this function', {
          'before () =>': printFunctionAsString(this.vueTour.steps[step].before)
        }, force);
      }
      this.lifferyTourDebugLog(functionName, 'targeted element', document.querySelector(this.vueTour.steps[step].target as string), force);
    }
  }

  /**
   * Bespoke log with far more printed to log than the vue-tour log
   *
   * @param functionName
   * @param logMessage
   * @param additionalContent
   * @param force - If you don't want the whole log showing, set force to true on the log you want to see
   */
  lifferyTourDebugLog (functionName: string, logMessage: string, additionalContent?: any, force?: boolean) {

    if (this.lifferyTourDebug || force) {
      console.log(`[OVueTour] ${functionName}() ${logMessage}`, additionalContent ? '→' : '', additionalContent ? additionalContent : '');
    }
  }

}
</script>
