<template>
  <div class="ADropdownMenuSelector" :class="classes">
    <span>
      {{ selectionText }}
    </span>
    <span v-if="useIcon">
      <chevron-down-icon v-if="!dropdownActive" :size="iconSize" />
      <x-icon v-else :size="iconSize" />
    </span>
  </div>
</template>

<style scoped lang="scss">
.ADropdownMenuSelector {
  cursor: pointer;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChevronDownIcon, XIcon } from 'vue-feather-icons';

@Component({
  components: {
    ChevronDownIcon,
    XIcon
  }
})
export default class AEventRepeats extends Vue {
  @Prop()
  selectionText!: string;

  @Prop()
  dropdownActive!: boolean;

  @Prop({required: false, default: ''})
  classes!: string;

  @Prop({required: false, default: '0.9x'})
  iconSize!: string;

  @Prop({required: false, default: true})
  useIcon!: boolean;
}
</script>