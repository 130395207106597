<template>
  <div ref="container" class="OItemsContainer">
    <!--Items display container -->
    <VirtualCollection
        v-if="!pinned"
        :cellSizeAndPositionGetter="item => { return { width: item.width, height: item.height, x: item.x, y: item.y }}"
        :collection="items.items"
        :container-padding-bottom="25"
        :headerSlotHeight="headerSlotHeight"
        :height="getVScrollDims.boxHeight"
        :scrollToBottomRange="500"
        :width="getVScrollDims.boxWidth"
        :class="{'hide-scroll': disableScroll}"
        v-on:scrolled-to-bottom-range="scrollBottom"
    >
      <template v-slot:header>
        <!-- Search bar -->
        <slot name="search"></slot>

        <div class="show-new-button">
          <m-button-with-icon
              v-if="newItemsCount"
              classes="is-rounded"
              @click="injectLatest"
          >
            <template slot="icon">
              <refresh-cw-icon class="show-new-icon" size="1x"></refresh-cw-icon>
            </template>
            <template slot="text">
              {{ $t('dict.showNewItems') }}
            </template>
          </m-button-with-icon>
        </div>

        <!-- No results -->
        <div v-if="!items.busy && noItems" class="not-found-wrapper">
          <div>
            <template>
              <p class="title-7 not-found-message">
                <!-- No content but no filters or sort applied -->
                <template v-if="!hasSearchParams.content">
                  <!-- DASHBOARD / PROFILE MESSAGE, [SHOW ADD ITEM] -->
                  <template
                      v-if="searchType === SearchType.dashboard || (searchType === SearchType.profile && isOwnProfile())">
                    <m-no-items-empty-search/>
                  </template>
                  <!-- DASHBOARD EXPLORE -->
                  <template v-else-if="searchType === SearchType.dashboardExplore">
                    <m-no-items-empty-explore-search/>
                    <!--                    <span v-html="$t('containers.items.nonFound.exploreNoQs')"/>-->
                  </template>
                  <!-- CHANNEL PAGE-->
                  <template v-else-if="searchType === SearchType.channel">
                    <span v-html="$t('containers.items.nonFound.channel')"/>
                  </template>
                  <!-- OTHER PERSONS PROFILE PAGE-->
                  <template v-else>
                <span
                    v-html="$t('containers.items.nonFound.otherPersonsProfile', {firstName: userOtherMeta.firstName})"/>
                  </template>
                </template>

                <!-- QS found and no filters applied -->
                <template v-else-if="hasSearchParams.qs !== '' && hasSearchParams.filtersCount === 0">
                <span v-html="$t('containers.items.nonFound.fromSearch', {
                    qs: hasSearchParams.qs,
                    sortCount: hasSearchParams.filtersCount
                  })"/>
                </template>
                <template v-else-if="hasSearchParams.qs !== '' && hasSearchParams.filtersCount !== 0">
              <span v-html="$t('containers.items.nonFound.fromSearchAndSort', {
                  qs: hasSearchParams.qs,
                  sortCount: hasSearchParams.filtersCount
                })"/>
                </template>
                <template v-else-if="hasSearchParams.qs === '' && hasSearchParams.filtersCount !== 0">
              <span v-html="$t('containers.items.nonFound.fromSort', {
                sortCount: hasSearchParams.filtersCount
              })"/>
                </template>
              </p>
            </template>
          </div>
        </div>
      </template>

      <template v-slot:header-inner-container>
        <slot name="inner"></slot>
      </template>

      <!-- Item Component slot -->
      <div slot="cell"
           slot-scope="props"
           class="item-card"
      >
        <template v-if="props.data.skeleton">
          <m-item-card-skeleton/>
        </template>
        <template v-else-if="props.data.phantom">
          <div class="phantom-card"></div>
        </template>
        <template v-else>
          <o-item-card
              :key="props.data.item.uniqueItemName"
              :currentUser="currentUser"
              :item="props.data.item"
              :search-type="searchType"
              :show-get-got-control="cardControls.showGetGotControl"
              v-on:item-request-update="(item) => $emit('item-request-update', item)"
          />
        </template>
      </div>
    </VirtualCollection>

    <!--If we are viewing pinned items, pass through to the pinned items container-->
    <o-pinned-items-container
        v-else
        :search-type="searchType"
    >
      <slot name="search"></slot>
      <slot name="inner"></slot>
    </o-pinned-items-container>

  </div>
</template>

<style lang="scss" scoped>
.vue-virtual-collection {
  min-width: 100% !important;
}

.card {
  display: flex;
  width: 100%;
  height: 100%;
}

::v-deep .not-found-wrapper {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
}

.btn-add-item {
  margin-top: 2rem;
}

.show-new-button {
  position: fixed;
  top: 10px;
  left: 50%;
  z-index: 25;
  transform: translateX(-50%) translateY(60%);
  animation: show-btn .5s forwards;
}

@keyframes show-btn {
  to {
    top: 5.625rem;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AuthenticationStore, ItemsStore } from '@/store';
import { SearchType } from '@/store/modules/enums/SearchType';
import { ItemsStoreItemsComponent } from '@/store/modules/interfaces/ItemsModule';
import { User } from '@/api/ms-authentication/services/interfaces';
import { waitTillElementIsVisible } from '@/utils/waitTillElementIsVisible';
import OItemCard from '@/components/organisms/OItemCard.vue';
import MItemCardSkeleton from '@/components/molecules/skeletons/MItemCardSkeleton.vue';
import { RefreshCwIcon, XIcon } from 'vue-feather-icons';
import scrollWindowToElement from '@/utils/scrollWindowToElement';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import MNoItemsEmptySearch from '@/components/molecules/MNoItemsEmptySearch.vue';
import OPinnedItemsContainer from '@/components/organisms/OPinnedItemsContainer.vue';
import htmlBodyClassToggle from '@/utils/htmlBodyClassToggle';
import MNoItemsEmptyExploreSearch from '@/components/molecules/MNoItemsEmptyExploreSearch.vue';
import countFilterSortsUsedInQuery from '@/utils/countFilterSortsUsedInQuery';
import { itemsFilterSortDefaultsHashMap } from './forms/OItemsFilterForm.vue';

@Component({
  components: {
    MNoItemsEmptyExploreSearch,
    OPinnedItemsContainer,
    MNoItemsEmptySearch,
    MButtonWithIcon,
    OItemCard,
    MItemCardSkeleton,
    XIcon,
    RefreshCwIcon
  }
})
export default class OItemsContainer extends Vue {
  @Prop({
    default: () => {
      return {};
    }
  })
  cardControls!: {
    showGetGotControl?: boolean
  };
  @Prop({ required: true })
  searchType!: SearchType;
  @Prop()
  username!: string;
  @Prop({ default: false })
  loading!: boolean;
  @Prop({ default: 297 })
  headerSlotHeight!: number;
  @Prop({ required: false, default: false })
  asAnon!: boolean;
  @Prop({ default: false })
  pinned!: boolean;

  @Watch('pinned', { immediate: true })
  pinnedChangeHandle () {
    this.toggleHTMLScroll();
  }

  @Watch('searchType', { immediate: true })
  searchTypeHandle () {
    this.toggleHTMLScroll();
  }

  search: string = '';
  SearchType = SearchType;

  disableScroll: boolean = false;

  $refs!: {
    container: any
  };

  get hasSearchParams (): {
    content: boolean,
    filtersCount: number,
    qs: string
  } {
    return {
      content: this.getItemFiltersSortCount > 0 || this.search !== '',
      filtersCount: this.getItemFiltersSortCount,
      qs: this.search
    };
  }

  get getItemFiltersSortCount () {
    return countFilterSortsUsedInQuery(this.$route, itemsFilterSortDefaultsHashMap);
  }

  get userOtherMeta () {
    return AuthenticationStore.userMetaOtherData;
  }

  get getVScrollDims () {
    return ItemsStore.getVScrollDims;
  }

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  get newItemsCount (): number {
    return ItemsStore.getNewItemsFetchedCount;
  }

  get items (): ItemsStoreItemsComponent {
    return ItemsStore.getItems;
  }

  get noItems (): boolean {
    if (!this.items.items.length) {
      return true;
    }
    if (this.items.items.length === ItemsStore.getVScrollDims.columnCount) {
      let pCount = 0;
      this.items.items.forEach(item => {
        if (item.data.phantom) {
          ++pCount;
        }
      });
      if (pCount === ItemsStore.getVScrollDims.columnCount) {
        return true;
      }
    }
    return false;
  }

  created () {
    this.setSearch(this.$route.query?.text as string);
  }

  setSearch (searchText: string = '') {
    this.search = searchText;
  }

  async mounted () {
    this.toggleHTMLScroll();
    this.eventsBind();
    const virtualScrollerElement = await waitTillElementIsVisible('.vue-virtual-collection');
    this.scrollDirectionMonitor(virtualScrollerElement);
    this.scrollToOffset(virtualScrollerElement);
    scrollWindowToElement('smooth', this.$refs.container);
  }

  beforeDestroy () {
    this.eventsUnbind();
    document.body.classList.remove('scrolling-down');
  }

  eventsBind () {
    window.addEventListener('resize', this.windowResizeFunction);
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.windowResizeFunction);
  }

  toggleHTMLScroll () {
    htmlBodyClassToggle('hide-scroll', this.pinned);
  }

  scrollToOffset (element) {
    if (this.items.items.length > 0) {
      element.scroll(0, this.items.offset);
    }
  }

  scrollBottom () {
    ItemsStore.paginate({ searchType: this.searchType, asAnon: this.asAnon });
  }

  scrollDirectionMonitor (element) {
    let lastTop = 0;

    // @ts-ignore
    element.onscroll = (arg) => {
      // Store the offset
      ItemsStore.SET_SCROLL_OFFSET({ offset: arg.target.scrollTop });
      // handle the downward scroll
      if (arg.target.scrollTop > lastTop) {
        this.scrollDirectionDownAdd();
      }
      // handle the upward scroll
      else {
        this.scrollDirectionDownRemove();
      }
      lastTop = arg.target.scrollTop;
    };
  }

  /**
   * Must recalculate the image positions on window resize
   */
  windowResizeFunction () {
    ItemsStore.recalculateVirtualScroll();
  }

  scrollDirectionDownAdd () {
    document.body.classList.add('scrolling-down');
  }

  scrollDirectionDownRemove () {
    document.body.classList.remove('scrolling-down');
  }

  isOwnProfile (): boolean {
    //if a username isn't found in the url you can only be looking at your own profile
    if (this.$route.path === '/-/your-profile/') {
      return true;
    }
    return this.currentUser.username === this.$route.params.username;
  }

  injectLatest () {
    scrollWindowToElement('auto');
    ItemsStore.injectLatestFetched().catch(console.error);
  }
}
</script>
