<template>
  <div class="AListIcon" @click="$emit('click')">

    <shopping-bag-icon size=".9x"></shopping-bag-icon>

    <a-badge v-if="count" :count="count" position="top-left"></a-badge>
  </div>
</template>

<style lang="scss" scoped>
.AListIcon {
  position: relative;
  cursor: pointer;

  svg {
    width: 35px;
    height: 35px;
    transition: all .2s;

    @media screen and (max-width: 450px) {
      width: 30px;
      height: 30px;
      margin-top: 0;
    }

    line {
      transition: all .2s;
    }

    path {
      transition: all .2s;
    }

    &:hover,
    &.grow {
      transform: scale(1.2);

      path,
      line {
        stroke: var(--primary-color);
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ABadge from '@/components/atoms/ABadge.vue';
import { ShoppingBagIcon } from 'vue-feather-icons';

@Component({
  components: {
    ABadge,
    ShoppingBagIcon
  }
})
export default class AAddItemIcon extends Vue {
  @Prop({ default: 0 })
  count!: number;
}
</script>
