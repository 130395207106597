<template>
  <b-dropdown-item
      aria-role="listitem"
      @click="listenLoad"
  >
    <headphones-icon class="dropdown-item-icon" size="1.5x"/>
    {{ $t('dict.listenOrRead') }}
  </b-dropdown-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { HeadphonesIcon, } from 'vue-feather-icons';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import { Item } from '@/api/ms-item/services/interfaces';
import { AuthenticationStore } from '@/store';
import { EnumRegisteredFromAction } from '@/store/modules/interfaces/AuthenticationModule';

@Component({
  components: {
    OModalWrapper,
    HeadphonesIcon
  }
})
export default class AListenMenu extends Vue {
  @Prop()
  item!: Item;

  listenLoad () {
    AuthenticationStore.getAuthenticated ?
      EventBus.$emit(EventBusEvents.ITEM_LISTEN, this.item) :
      AuthenticationStore.TOGGLE_PROMPT_SIGNUP({
        state: true,
        metaData: {
          registeredFromAction: EnumRegisteredFromAction.ctaListenToMenu
        }
      });
  }
}
</script>
