<template>
  <router-link class="ARouterLinkChannelView" :to="url">
    <calendar-icon v-if="isChannelEvent" class="mr-1" size="1.1x"/>
    <a-lightbulb-on-outline-icon v-else-if="isResearchConcierge" class="mr-1" size="1.1" />
    <slot v-if="!!customText">
      <span v-html="customText"/>
    </slot>
    <slot v-else>
      <span>{{ url.params.channelSlug1 }}</span>
    </slot>
  </router-link>
</template>

<style scoped lang="scss">
.ARouterLinkChannelView {
  svg {
    vertical-align: middle;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CalendarIcon } from 'vue-feather-icons';
import getChannelRouterObject from '@/utils/getChannelRouterObject';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';

@Component({
  components: { ALightbulbOnOutlineIcon, CalendarIcon }
})
export default class ARouterLinkChannelView extends Vue {
  @Prop({ required: true })
  channelSlug!: string;

  @Prop()
  customText!: string;

  @Prop()
  customClass?: string;

  @Prop({ required: false, default: false })
  isChannelEvent!: boolean;

  @Prop({ required: false, default: false })
  isResearchConcierge!: boolean;

  get url () {
    return getChannelRouterObject(this.channelSlug);
  }
}
</script>
