<template>
  <ValidationProvider
      :vid="vid"
      :name="nameAlt || name"
      :rules="rules"
      v-slot="{ errors, pristine, validated, valid }"
  >
    <b-field
        :label="label"
        :prop="name"
        v-bind="$attrs"
        :auto-complete="autoComplete"
        :type="{ 'is-danger': errors[0] && ((validated && !valid) || !pristine) }"
        :message="((validated && !valid) || !pristine) && errors.length ? errors : ''"
        :class="groupFieldClass"
    >
      <div class="input-wrapper">
        <input
            :name="name"
            :type="showPassword ? 'text' : 'password'"
            v-bind="$attrs"
            :placeholder="placeholder"
            v-model="innerValue"
            novalidate
            class="input password-input"
            :class="{ 'is-danger': errors[0] && ((validated && !valid) || !pristine) }"
        />
        <eye-off-icon
            v-if="showPassword && innerValue"
            size="1.5x"
            class="toggle-password-icon"
            @click="showPassword = !showPassword"
        ></eye-off-icon>
        <eye-icon
            v-else-if="!showPassword && innerValue"
            size="1.5x"
            class="toggle-password-icon"
            @click="showPassword = !showPassword"
        ></eye-icon>
      </div>
    </b-field>
  </ValidationProvider>
</template>

<style lang="scss" scoped>
.form-group {
  position: relative;
}

.input-wrapper {
  position: relative;
}

.password-input {
  width: 100%;
}

.toggle-password-icon {
  position: absolute;
  top: 16px;
  right: 10px;
  z-index: 5;
  cursor: pointer;
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { EyeIcon, EyeOffIcon } from 'vue-feather-icons';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    EyeIcon,
    EyeOffIcon
  }
})
export default class APasswordInputWithValidation extends Vue {
  @Prop({ required: true })
  value!: any;
  @Prop()
  label!: string;
  @Prop({ required: false })
  name!: string;
  @Prop({ default: 'off' })
  autoComplete!: string;
  @Prop()
  nameAlt?: string;
  @Prop()
  placeholder?: string;
  @Prop()
  vid?: string;
  @Prop()
  rules?: string;
  @Prop()
  groupFieldClass?: string;

  innerValue = '';
  showPassword = false;

  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }

  @Watch('innerValue')
  innerValueHandle (newVal) {
    this.$emit('child-output', newVal);
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueValueHandle (newVal) {
    this.innerValue = newVal;
  }
}
</script>
