<template>
  <div :class="{ 'failed-submit': failedSubmit }" class="OItemCommentReplyForm">
    <ValidationObserver ref="form">
      <form novalidate @submit.prevent="onSubmit">
        <m-input-with-validation
            v-model="form.comment"
            :allow-emoji="true"
            :at-mention-fetch="fetchUsersToMention"
            :at-mention-nothing-found="notFoundMessage"
            :is-resizable="true"
            :placeholder="placeholder"
            :prevent-keypress="[13]"
            auto-complete="off"
            emoji-picker-position="top"
            name="comment"
            rules="required"
            type="textarea"
            v-on:enter="onSubmit"
        ></m-input-with-validation>
        <b-button
            :loading="loading"
            class="is-primary is-outlined btn-comment is-pulled-right"
            native-type="submit"
            size="is-small"
        >
          <message-square-icon size="1x"/>
          {{ $t('dict.send') }}
        </b-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .help.is-danger {
  display: none;
}

.OItemCommentReplyForm.failed-submit {
  ::v-deep .help.is-danger {
    display: block;
  }
}

::v-deep .textarea {
  min-height: 3.125rem;
  margin-top: 1.3rem;
  padding-right: 26px;
  resize: vertical;

  @media screen and (max-width: 800px) {
    min-height: 46px;
  }
}

.as-link {
  margin-left: 5px;
}

.btn-comment {
  margin-top: .5rem;

  ::v-deep span {
    display: flex;
    place-items: center;
  }
}

::v-deep .emoji-picker {
  @media screen and (max-width: 800px) {
    margin-left: -10rem;
  }

  @media screen and (max-width: 400px) {
    margin-left: -7rem;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { HelpCircleIcon, MessageSquareIcon } from 'vue-feather-icons';
import { AuthenticationStore, ItemDetailStore, ItemsStore } from '@/store';
import { Item, ItemNameUniqueItemNameCommentCommentIdReplyPost } from '@/api/ms-item/services/interfaces';
import { Comment } from '@/api/ms-item/services/interfaces/Item';
import { Type } from '@/api/ms-item/services/interfaces/ItemNameUniqueItemNameCommentCommentIdReplyPost';
import ConnectionsService from '@/api/ms-authentication/services/ConnectionsService';
import { formatMentionUserComponentData } from '@/utils/formatMentionUsersData';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';

@Component({
  components: {
    MInputWithValidation,
    ValidationObserver,
    HelpCircleIcon,
    MessageSquareIcon
  }
})
export default class OItemCommentReplyForm extends Vue {
  @Prop({ required: true })
  parentComment!: Comment;
  @Prop({ required: true })
  commentId!: string;
  @Prop()
  comment?: string;
  @Prop({ default: true })
  add!: boolean;
  @Prop({ default: false })
  isEdit!: boolean;

  loading: boolean = false;
  failedSubmit: boolean = false;
  placeholder!: string;
  isGeneralChannel: boolean = true;
  notFoundMessage: string = '';

  commentTypes: any[] = [{
    value: Type.General,
    label: this.$t('item.comment.type.general')
  }, {
    value: Type.C2B,
    label: this.$t('item.comment.type.c2b')
  }];

  form: ItemNameUniqueItemNameCommentCommentIdReplyPost = {
    comment: '',
    type: Type.General
  };

  $refs!: {
    form: InstanceType<typeof ValidationObserver>;
  };

  get item (): Item {
    return ItemDetailStore.getItemDetail;
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  mounted () {
    this.$refs.form;
  }

  created () {
    this.placeholder = String(this.add ? this.$t('dict.leaveAReply') : this.$t('dict.editReply'));
    this.form.comment = this.comment || '';
    if (this.parentComment.type === Type.C2B) {
      this.form.type = Type.C2B;
    }

    this.isGeneralChannel = !!this.item.editable.channel!.isDefault;

    if (this.isGeneralChannel) {
      this.notFoundMessage = this.item.actor.username === this.currentUser.username ?
        this.$t('dict.notFoundMessageMentionedConnections').toString() :
        this.$t('dict.notFoundMessageMentionedCommonConnections', { name: this.item.actor.firstName }).toString();
    } else {
      this.notFoundMessage = this.$t('dict.notFoundMessageMentionedChannelMembers').toString();
    }
  }

  async fetchUsersToMention (searchText: string, cb): Promise<void> {
    let resp;

    if (this.isGeneralChannel) {
      if (this.item.actor.username === this.currentUser.username) {
        resp = await ConnectionsService.connectionsGet({
          offset: 0,
          qs: searchText
        });
      } else {
        resp = await ConnectionsService.connectionsUsernameMatchingGet(
          { username: this.item.actor.username },
          { offset: 0, qs: searchText, includeRequestedUsername: true }
        );
      }
    } else {
      resp = await ChannelMemberService.channelMemberSlugGet(
        { slug: this.item.editable.channel!.slug },
        { text: searchText }
      );
    }

    const userObj = formatMentionUserComponentData(resp.data, this.currentUser.username);
    cb(userObj);
  }

  async onSubmit () {
    // https://logaretm.github.io/vee-validate/guide/forms.html#programmatic-access-with-refs
    if (!await this.$refs.form.validate()) {
      this.failedSubmit = true;
      return;
    }
    this.loading = true;

    const item = await ItemDetailStore.itemCommentSubmitNewReply({
      body: this.form,
      commentId: this.commentId
    });

    // update the item in the main items store as well if it exists
    ItemsStore.SET_ITEM_DETAIL(item);

    this.form.comment = '';
    await this.$nextTick(() => {
      this.$refs.form.reset();
    });
    this.failedSubmit = false;
    this.$emit('close-reply');
    this.loading = false;
  }
}
</script>
