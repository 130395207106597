<template>
  <div>
    <div v-if="invitationMessage.length" class="mt-5">
      <p class="mb-1 is-italic">{{ invitationFrom }}</p>
      <b-message :closable="false">
        {{ invitationMessage }}
      </b-message>
    </div>

    <!--    <template v-if="!isIos">-->
    <!--      <m-sso-buttons class="mt-5"/>-->
    <!--      <div class="mt-5 content-divider">{{ $t('dict.orSignupViaEmail') }}</div>-->
    <!--    </template>-->


    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        ref="form"
        class="login-form"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <m-input-with-validation
          v-if="form.inviteCode"
          v-model="form.inviteCode"
          :label="$tc('dict.inviteCode')"
          :placeholder="$tc('dict.inviteCode')"
          :disabled="inviteCodeIsDisabled"
          groupFieldClass="form-group"
          name="Invitation Code"
        />

        <b-field grouped>
          <m-input-with-validation
            v-model="form.firstName"
            :label="$tc('dict.firstName')"
            :placeholder="$tc('dict.firstName')"
            groupFieldClass="form-group"
            auto-complete="off"
            name="first name"
            rules="required|min:2"
            class="mr-2"
          />

          <m-input-with-validation
            v-model="form.lastName"
            :label="$tc('dict.lastName')"
            :placeholder="$tc('dict.lastName')"
            groupFieldClass="form-group"
            name="last name"
            auto-complete="off"
            rules="required|min:2"
          />
        </b-field>


        <m-input-with-validation
          v-model="form.email"
          :label="$tc('dict.email')"
          :placeholder="$tc('dict.email')"
          groupFieldClass="form-group"
          name="email"
          auto-complete="off"
          rules="required|email"
          type="email"
          vid="email"
        />

        <m-input-with-validation
          v-model="emailConfirm"
          :label="$tc('dict.emailConfirm')"
          :placeholder="$tc('dict.emailConfirm')"
          groupFieldClass="form-group"
          name="confirm email"
          auto-complete="off"
          type="email"
          rules="required|email|confirmed:email"
        />

        <a-password-input-with-validation
          v-model="form.password"
          :label="$tc('dict.password')"
          :placeholder="$tc('dict.password')"
          groupFieldClass="form-group"
          auto-complete="off"
          name="password"
          rules="required|min:6"
          vid="newPassword"
        />

        <m-agree-to-tcs-cs
          v-on:agreed="agreedToTerms"
          v-on:disagreed="disagreedToTerms"
        />

        <b-field class="form-group">
          <a-button-submit :disabled="!termsAgreed" :loading="loading">
            {{ $t('dict.signUp') }}
          </a-button-submit>
        </b-field>

        <a-form-server-error
          v-if="errors"
          :errors="errors"
          v-on:child-output="handleMessageClosed"
        />
      </form>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
$beforeAfterBP: 490px;

.content-divider {
  position: relative;
  margin-bottom: 2rem;
  text-align: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    background-color: var(--text-color);
    transform: translateY(-50%);
  }

  &::before {
    right: 70%;
    left: 0;
    @media screen and (max-width: $beforeAfterBP) {
      right: 80%;
    }
  }

  &::after {
    right: 0;
    left: 70%;
    @media screen and (max-width: $beforeAfterBP) {
      left: 80%;
    }
  }
}

.register-sso-divider {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.help {
  margin-top: -.8rem;
}

.toggle-show-more {
  margin-left: 5px;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    opacity: .8;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { RegisterEmailPost } from '@/api/ms-authentication/services/interfaces';
import { AuthenticationStore } from '@/store';

import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import APasswordInputWithValidation from '@/storybook-components/src/stories/atoms/APasswordInputWithValidation.vue';
import AFormServerError from '@/components/atoms/AFormServerError.vue';
import MSsoButtons from '@/components/molecules/MSSOButtons.vue';
import MAgreeToTcsCs from '@/components/molecules/MAgreeToTcsCs.vue';
import { RouteNames } from '@/router/RouteNames';
import config, { BuildType } from '@/config';
import ARafflePrompt from '@/components/atoms/ARafflePrompt.vue';
import UserService from '@/api/ms-authentication/services/UserService';
import isIosApp from '@/utils/isIosApp';
import { translation } from '@/plugins/i18n/Translation';
import { Language } from '@/api/ms-authentication/services/interfaces/RegisterEmailPost';
import Cookie from '@/services/Cookies';
import { CookieNames } from '@/enums/CookieNames';

@Component({
  components: {
    ARafflePrompt,
    MAgreeToTcsCs,
    AFormServerError,
    AButtonSubmit,
    MInputWithValidation,
    ValidationObserver,
    APasswordInputWithValidation,
    MSsoButtons
  },
})
export default class ORegisterForm extends Vue {
  web = (config.buildType === BuildType.WEB);
  termsAgreed: boolean = false;

  isIos = isIosApp();

  loading: boolean = false;
  showAllHelpText: boolean = false;
  emailConfirm: string = '';
  form: RegisterEmailPost = {
    allTermsAgreed: new Date(),
    email: '',
    password: '',
    passwordConfirm: '',
    firstName: '',
    lastName: '',
    registeredFromHref: window.location.href,
    registeredFromAction: AuthenticationStore.getPromptData.metaData.registeredFromAction,
    firstPageLoaded: undefined,
    inviteCode: undefined,
    language: translation.currentLanguage as Language
  };
  inviteCodeIsDisabled = true;
  invitationMessage: string = '';
  invitationFrom: string = '';

  get errors () {
    return AuthenticationStore.errorRegisterContent;
  }

  created () {
    if (this.$route.query.inviteCode) {
      this.form.inviteCode = this.$route.query.inviteCode as string;
      this.loadInviteeDetails();
    }
    Cookie.get(CookieNames.FIRST_EVER_PAGE_LOADED).then((val) => {
      this.form.firstPageLoaded = val;
      console.log(this.form);
    }).catch(console.error);
  }

  async loadInviteeDetails () {
    if (!this.form.inviteCode) {
      return;
    }
    const details = await UserService.userInvitationsInviteeCodeGet({
      code: this.form.inviteCode
    });
    if (details) {
      this.inviteCodeIsDisabled = true;
      this.form.email = details.email;
      this.form.firstName = details.firstName;
      this.form.lastName = details.lastName;
      this.invitationMessage = details.message ? details.message : '';
      this.invitationFrom = this.$t('dict.inviteFrom', { name: details.firstName }) as string;
    }
  }

  agreedToTerms () {
    this.termsAgreed = true;
  }

  disagreedToTerms () {
    this.termsAgreed = false;
  }

  async onSubmit () {
    this.loading = true;

    // Trim the content before sending
    this.form.firstName = this.form.firstName.trim();
    this.form.lastName = this.form.lastName.trim();
    this.form.email = this.form.email.trim();

    // we now request the email to be confirmed and not the password,
    // if the email is wrong, there is no getting back in unlike a
    // password which can be reset
    this.form.passwordConfirm = this.form.password;

    // register
    await AuthenticationStore.register({ register: this.form });

    // success.. then route to the dashboard
    await this.$router.push({ name: RouteNames.ROUTE_DASHBOARD });
    this.loading = false;
  }

  handleMessageClosed () {
    AuthenticationStore.ERRORS_REGISTER_UNSET();
  }
}
</script>
