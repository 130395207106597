<template>
  <div class="card">
    <div>
      <a-router-link-profile :username="person.username">
        <figure class="image is-64x64 user-profile">
          <a-user-profile-pic :username="person.username"></a-user-profile-pic>
        </figure>
      </a-router-link-profile>
    </div>

    <div class="is-relative">

      <p class="font-body-m person-name">
        <span :title="$t('connectionState.' + state)">
          <arrow-up-right-icon v-if="state === states.RequestSent"/>
          <arrow-down-left-icon v-else-if="state === states.RequestReceived"/>
        </span>
        <a-router-link-profile :username="person.username">
          {{ `${person.firstName} ${person.lastName}` | ellipsisString(20) }}
        </a-router-link-profile>
      </p>

    </div>


    <div class="actions-wrapper has-text-centered">
      <span v-if="showConnectIcon">
        <a-connection-icon
            :connection-type="checkConnectionType()"
            :loading="requestLoading"
            :tooltip-text="connectTooltipText"
            class="link-add-connection"
            size="1.6x"
            v-on:cancel-connection-request="cancelConnectionRequest"
            v-on:send-connection-request="sendConnectionRequest"
            v-on:accept-connection-request="sendConnectionRequest"
        >
          <template slot="helper-text">
            <span class="font-body-s is-block">{{ connectTooltipText }}</span>
          </template>
        </a-connection-icon>
      </span>
      <span v-if="showDisconnectIconInnerValue && state !== 'requestSent'">
        <a-connection-icon
            :loading="requestLoading"
            :tooltip-text="disconnectTooltipText"
            class="link-add-connection"
            connection-type="cancelConnectionRequest"
            size="1.6x"
            v-on:cancel-connection-request="cancelConnectionRequest"
        >
          <template slot="helper-text">
            <span class="font-body-s is-block">{{ disconnectTooltipText }}</span>
          </template>
        </a-connection-icon>

      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  display: flex;
  gap: 1rem;
  align-items: center;
  overflow: visible;
  width: 100%;
  padding: .5rem;

  .injected-page-title {
    position: absolute;
    top: -40px;
    left: 0;
  }
}

figure.user-profile {
  display: flex;
  align-items: center;
}

.connections-icon {
  margin-right: 5px;
}

.actions-wrapper {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  margin-left: auto;
}

.link-add-connection {
  cursor: pointer;
  transition: opacity .2s;
}

.request-type-icon {
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import AConnectionIcon from '@/components/atoms/buttons/AConnectionIcon.vue';
import { PeopleStore } from '@/store';
import { Datum, State, } from '@/api/ms-authentication/services/interfaces/Connections';
import { ArrowDownLeftIcon, ArrowUpRightIcon } from 'vue-feather-icons';
import ellipsisString from '@/utils/ellipsisString';

@Component({
  components: {
    AUserProfilePic,
    ARouterLinkProfile,
    AConnectionIcon,
    ArrowDownLeftIcon,
    ArrowUpRightIcon
  },
  filters: {
    ellipsisString
  }
})
export default class MPersonNotConnectedCard extends Vue {
  @Prop(Object)
  person!: Datum;

  @Prop({ default: false })
  showConnectIcon!: boolean;

  @Prop({ default: false })
  showDisconnectIcon!: boolean;
  showDisconnectIconInnerValue = false;
  requestLoading: boolean = false;
  connectionSent = false;
  connectTooltipText = '';
  disconnectTooltipText = '';
  state!: State;
  states = State;

  created () {
    this.showDisconnectIconInnerValue = this.showDisconnectIcon;
    this.state = this.person.state;
    this.setConnectToolTip();
    this.disconnectTooltipText = String(
      this.$t(
        this.state === State.RequestReceived ? 'dict.reject' : 'dict.cancel'
      )
    );
  }

  async sendConnectionRequest () {
    this.requestLoading = true;
    if (!this.state) {
      await PeopleStore.makeConnectionRequest(this.person.username);
    } else {
      try {
        await PeopleStore.connectionRequestUpdate(this.person.username);
        const newPerson = {
          ...this.person,
          state: 'requestReceivedAccepted'
        };
        PeopleStore.REMOVE_CONNECTION_REQUEST({ username: this.person.username });
        PeopleStore.APPEND_CONNECTED_PEOPLE({ connections: newPerson });
        this.showDisconnectIconInnerValue = false;
      } catch (e) {
        // TODO: handle error
        console.error(e);
      }
    }

    this.connectTooltipText = String(this.$t('dict.cancel'));
    this.connectionSent = true;
    this.requestLoading = false;
  }

  async cancelConnectionRequest () {
    this.requestLoading = true;
    await PeopleStore.connectionRequestRemove(this.person.username);
    this.connectTooltipText = String(this.$t('dict.connect'));
    this.connectionSent = false;
    this.requestLoading = false;
  }

  setConnectToolTip () {
    switch (this.checkConnectionType()) {
      case 'cancelConnectionRequest':
        this.connectTooltipText = String(this.$t('dict.cancel'));
        break;
      case 'sendConnectionRequest':
      case 'acceptConnectionRequest':
        this.connectTooltipText = String(this.$t('dict.connect'));
        this.disconnectTooltipText = String(this.$t('dict.reject'));
    }
  }

  checkConnectionType () {
    if (this.state === State.RequestSent || this.connectionSent) {
      return 'cancelConnectionRequest';
    } else if (this.state === State.RequestReceived) {
      return 'acceptConnectionRequest';
    } else {
      return 'sendConnectionRequest';
    }
  }
}
</script>
