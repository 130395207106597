<template>
  <router-link :to="{ name }" class="menu-profile-item">
    <tv-icon size="1x" class="header-menuitem-icon" v-if="hasIcon"></tv-icon>
    {{$t('dict.channelSettings')}}
  </router-link>
</template>

<script lang="ts">
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { RouteNames } from '@/router/RouteNames';
  import { TvIcon } from 'vue-feather-icons';

  @Component({
    components: {
      TvIcon
    }
  })
  export default class ARouterLinkChannelManagement extends Vue {
    @Prop()
    hasIcon!: boolean;
  
    name = RouteNames.ROUTE_CHANNEL_MANAGEMENT;
  }
</script>
