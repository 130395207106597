<template>
  <div class="AToggleChannelView" :class="{'grid': activeView === channelView.grid}">
    <grid-icon
        size="2.3x"
        class="toggle-icon"
        :class="{ 'active': activeView === channelView.grid }"
        @click="$emit('child-output', channelView.grid)"
    />
    <align-justify-icon
        size="2.3x"
        class="toggle-icon"
        :class="{ 'active': activeView === channelView.table }"
        @click="$emit('child-output', channelView.table)"
    />
    <map-icon
        size="2.3x"
        class="toggle-icon"
        :class="{ 'active': activeView === channelView.map }"
        @click="$emit('child-output', channelView.map)"
    />
  </div>
</template>

<style lang="scss" scoped>
.AToggleChannelView {
  position: absolute;
  top: -56px;
  right: 9px;
  display: flex;
  gap: .5rem;
  justify-content: flex-end;
  margin-bottom: 1.2rem;

  &.grid {
    top: -56px;
    right: 9px;
  }
}

.toggle-icon {
  padding: 5px;
  border: 1px solid currentColor;
  border-radius: var(--default-border-radius);
  color: #999;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    opacity: .8;
  }

  &.active {
    color: var(--secondary-color);
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AlignJustifyIcon, GridIcon, MapIcon } from 'vue-feather-icons';
import { AChannelViewEnums } from '@/store/modules/interfaces/ChannelViewModule';

@Component({
  components: {
    GridIcon,
    AlignJustifyIcon,
    MapIcon
  },
})
export default class AToggleChannelView extends Vue {
  @Prop({ required: true })
  activeView!: string;

  channelView = AChannelViewEnums;
}
</script>
