<template>
  <div class="MImageWithLightbox">
    <a-image
        :image-path="imagePath"
        :size="size"
        :class="className + ' is-clickable'"
        @click="showImageModal = true"
    />

    <b-modal
        v-model="showImageModal"
        scroll="keep"
        :width="1280"
    >
      <div class="card">
        <div class="card-image">
          <figure class="image" style="min-height: 180px;">
            <a-image
                :image-path="imagePath"
                :size="sizes.Mx1280"
                :class="className"
                v-on:loaded="imageReady"
            />
          </figure>
        </div>
        <div class="card-content">
          <template v-if="loading">
            <div class="has-text-centered">
              {{$t('dict.loading')}}
            </div>
            <b-progress
                size="is-small"
                type="is-primary"
                show-value
            />
          </template>
          <div class="media">
            <div class="media-left">
              <figure class="image is-64x64">
                <a-user-profile-pic :username="item.actor.username" image-size=""/>
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-6">{{ item.actor.firstName }} {{ item.actor.lastName }}</p>
              <p class="subtitle">
                {{ $t('dict.added') }}:

                <time class="time-ago">
                  {{ item.createdAt | formatDate(currentLanguage) }}
                </time>
              </p>
            </div>
          </div>

          <div v-if="item.editable.text && item.editable.text.length" class="content">
            <b>{{ $t('dict.description') }}:</b>
            <div v-html="linkText(item.editable.text)"/>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
.MImageWithLightbox {
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import AImage from '@/components/atoms/AImage.vue';
import { Item } from '@/api/ms-item/services/interfaces/Item';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import { translation } from '@/plugins/i18n/Translation';
import { format } from 'timeago.js';
import linkedText from '@/utils/linkedText';

@Component({
  components: { AUserProfilePic, AImage },
  filters: {
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    }
  }
})
export default class MImageWithLightbox extends Vue {
  @Prop({ required: true })
  imagePath!: string;

  @Prop({ required: true })
  size!: Size;

  @Prop({ default: '' })
  className!: string;

  @Prop({ required: true })
  item!: Item;

  sizes = Size;
  loaded = false;
  loading = false;

  showImageModal = false;

  get currentLanguage () {
    return translation.currentLanguage;
  }

  mounted () {
    setTimeout(() => {
      if (!this.loaded) {
        this.loading = true;
      }
    }, 1000);
  }

  linkText (input: string): string {
    return linkedText(input, true);
  }

  imageReady () {
    this.loaded = true;
    this.loading = false;
  }
}
</script>
