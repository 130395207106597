<template>
  <router-link :to="{ name }" class="menu-profile-item">
    <smile-icon size="1x" class="header-menuitem-icon" v-if="hasIcon"></smile-icon>
    Start Product Researcher
  </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { SmileIcon } from 'vue-feather-icons';

@Component({
  components: {
    SmileIcon
  }
})
export default class ARouterLinkYourProfile extends Vue {
  @Prop(Boolean)
  hasIcon!: boolean;

  name = RouteNames.ROUTE_PRODUCT_RESEARCH_START;
}
</script>
