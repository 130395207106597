<template>
  <div class="MChannelSwipeInfo">
    <p class="mb-3">{{ $t('item.swipeScore.info.part1') }}</p>
    <section v-for="(action, key) in Actions" :key="key">
      <div class="actionsExplained mb-1" @mouseover="actionIconHighlight[action] = true" @mouseout="actionIconHighlight[action] = false">
        <m-channel-swipe-action-icons :direction="action" :unset-position="true" icon-size="2x" :fill-icons="actionIconHighlight[action]"/>
        <p>{{ $t('item.swipeScore.info.' + action) }}</p>
      </div>
    </section>
    <p class="mt-3">{{ $t('item.swipeScore.info.part2') }}</p>
    <div class="mt-3">
      <span v-if="!showUseCaseIdeas" class="as-link colour-primary" @click="showUseCaseIdeas = true">{{ $t('item.swipeScore.info.showUseCases') }}</span>
      <b-message v-else>
        <p class="mb-3">{{ $t('item.swipeScore.info.useCases.header') }}</p>
        <div v-for="index in 3" :key="index" class="useCaseIdeas mb-2">
          <p>{{ index }}.</p>
          <p>{{ $t('item.swipeScore.info.useCases.use' + index) }}</p>
        </div>
      </b-message>
    </div>
    <div class="has-text-centered">
      <b-button type="is-primary" class="is-outlined mt-3 is-center" @click="closeInfo">
        {{ $t('dict.close') }}
      </b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.MChannelSwipeInfo {
  .actionsExplained {
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .5rem;
  }

  .useCaseIdeas {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .5rem;
  }
}
</style>

<script lang="ts">
import { Action } from '@/api/ms-item/services/interfaces/SwipeScores';
import { Component, Vue } from 'vue-property-decorator';
import MChannelSwipeActionIcons from '@/components/molecules/channel-swipe/MChannelSwipeActionIcons.vue';

@Component({
  components: { MChannelSwipeActionIcons }
})
export default class MChannelSwipeInfo extends Vue {
  Actions: Action[] = [
    Action.SwipeRight,
    Action.SwipeUp,
    Action.SwipeLeft,
    Action.SwipeDown
  ];
  actionIconHighlight: {[key:string]: boolean} = {
    [Action.SwipeRight]: false,
    [Action.SwipeUp]: false,
    [Action.SwipeLeft]: false,
    [Action.SwipeDown]: false
  };

  showUseCaseIdeas: boolean = false;

  closeInfo () {
    this.$emit('close');
  }
}
</script>