<template>
  <span>
    <user-check-icon
        v-if="[notificationTypes.AuthConnectionRequestAccepted, notificationTypes.AuthInvitationAcceptedInvitor, notificationTypes.AuthInvitationAcceptedInvitee].includes(type)"
        :size="size"
        class="not-icon"
    />
    <user-plus-icon
        v-else-if="type === notificationTypes.AuthConnectionRequestIncoming"
        :size="size"
        class="not-icon"
    />

    <check-square-icon
        v-else-if="[notificationTypes.ChannelInviteAccepted, notificationTypes.ChannelJoinAccepted, notificationTypes.ConciergeJobFinishedOwner, notificationTypes.ConciergeJobFinishedConcierge].includes(type)"
        :size="size"
        class="not-icon"
    />

    <a-folders-svg-icon
        v-else-if="[notificationTypes.ChannelCreatedNewUserAuto, notificationTypes.ChannelEmailInviteAccepted, notificationTypes.ChannelJoinPublic].includes(type)"
        :size="sizeB"
        :keep-y-zero="true"
        class="not-icon"
      />


    <a-fire-icon-svg
        v-else-if="[notificationTypes.ChannelSwipeNudge, notificationTypes.ChannelSwipeCompletedOwnerManager].includes(type)"
        :size="sizeB"
        class="not-icon"
      />

    <plus-square-icon
        v-else-if="[notificationTypes.ChannelInvited, notificationTypes.ChannelJoinRequested].includes(type)"
        :size="size"
        class="not-icon"
    />

    <thumbs-up-icon
        v-else-if="[notificationTypes.ItemCommentLike, notificationTypes.ItemLike].includes(type)"
        :size="size"
        class="not-icon"
    />

    <at-sign-icon
        v-else-if="[notificationTypes.ItemCommentMention, notificationTypes.ChatChannelMention].includes(type)"
        :size="size"
        class="not-icon"
    />

    <calendar-icon
        v-else-if="[notificationTypes.ChannelEventDate, notificationTypes.ChannelEventNotification].includes(type)"
        :size="size"
        class="not-icon"
    />

    <a-alarm-clock-icon
      v-else-if="[notificationTypes.ItemReminder].includes(type)"
      :size="sizeB"
      :keep-y-zero="true"
      class="not-icon"
    />

    <edit3-icon
        v-else-if="[notificationTypes.ItemEditedByOther].includes(type)"
        :size="size"
        class="not-icon"
      />

    <x-circle-icon
        v-else-if="[notificationTypes.ItemDeletedByOther].includes(type)"
        :size="size"
        class="not-icon"
    />

    <activity-icon
        v-else-if="[notificationTypes.WatcherPriceChanged].includes(type)"
        :size="size"
        class="not-icon"
    />

    <a-stop-icon
        v-else-if="[notificationTypes.WatcherEnded].includes(type)"
        :size="sizeB"
        :keep-y-zero="true"
        class="not-icon"
    />

    <plus-circle-icon
        v-else-if="[notificationTypes.ChannelNotificationItemAdded].includes(type)"
        :size="size"
        class="not-icon"
      />

    <a-out-of-stock-icon
        v-else-if="[notificationTypes.WatcherItemNoLongerAvailable].includes(type)"
        :size="sizeB"
        :keep-y-zero="true"
        class="not-icon"
    />

    <a-dollar-strikethrough-icon
        v-else-if="[notificationTypes.WatcherPriceNoLongerAvailable].includes(type)"
        :size="sizeB"
        :keep-y-zero="true"
        class="not-icon"
    />

    <a-lightbulb-on-outline-icon
        v-else-if="[
            notificationTypes.ConciergeJobAssignedOwner,
            notificationTypes.ConciergeJobAssignedConcierge,
            notificationTypes.ConciergeJobAlarmJobIncomplete,
            notificationTypes.ConciergeJobItemPosted
          ].includes(type)"
        :size="sizeB"
        :keep-y-zero="true"
        class="not-icon"
    />

    <send-icon
        v-else-if="[notificationTypes.ItemRecommendationNew, notificationTypes.ItemRecommendationAccepted, notificationTypes.ItemRecommendationRejected].includes(type)"
        :size="size"
        class="not-icon"
      />

    <bell-off-icon
        v-else-if="[notificationTypes.ChannelNotificationPrivacyChanged].includes(type)"
        :size="size"
        class="not-icon"
    />

    <!-- Catch all message icon to ensure there is always a comment no matter what -->
    <message-square-icon
        v-else
        :size="size"
        class="not-icon"
    />
  </span>
</template>

<style lang="scss" scoped>
span {
  color: var(--primary-color);
}

.not-icon {
  margin-right: 7px;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Type } from '@/api/ms-notification/services/interfaces/Notifications';

import {
  ActivityIcon,
  AtSignIcon,
  BellOffIcon,
  CalendarIcon,
  CheckSquareIcon,
  Edit3Icon,
  MessageSquareIcon,
  PlusCircleIcon,
  PlusSquareIcon,
  SendIcon,
  ThumbsUpIcon,
  UserCheckIcon,
  UserPlusIcon,
  XCircleIcon
} from 'vue-feather-icons';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';
import AAlarmClockIcon from '@/components/atoms/icon/svg/AAlarmClockIcon.vue';
import ADollarStrikethroughIcon from '@/components/atoms/icon/svg/ADollarStrikethroughIcon.vue';
import AOutOfStockIcon from '@/components/atoms/icon/svg/AOutOfStockIcon.vue';
import AStopIcon from '@/components/atoms/icon/svg/AStopIcon.vue';
import AFoldersSvgIcon from '@/components/atoms/icon/svg/AFoldersSvgIcon.vue';
import AFireIconSvg from '@/components/atoms/icon/svg/AFireIconSvg.vue';

@Component({
  components: {
    AFireIconSvg,
    AFoldersSvgIcon,
    AStopIcon,
    AOutOfStockIcon,
    ADollarStrikethroughIcon,
    AAlarmClockIcon,
    ALightbulbOnOutlineIcon,
    ActivityIcon,
    AtSignIcon,
    BellOffIcon,
    CalendarIcon,
    CheckSquareIcon,
    Edit3Icon,
    MessageSquareIcon,
    PlusCircleIcon,
    PlusSquareIcon,
    SendIcon,
    ThumbsUpIcon,
    UserCheckIcon,
    UserPlusIcon,
    XCircleIcon
  },
})
export default class ANotificationIcon extends Vue {
  @Prop()
  type!: Type;
  @Prop({ default: '1x' })
  size!: string;

  notificationTypes = Type;

  // Removes the x from size so can be used on in-house icons
  get sizeB() {
    return parseInt(this.size);
  }
}
</script>
