<template>
  <div class="card skeleton-card">
    <div class="card-header">
      <div class="image is-48x48">
        <b-skeleton circle width="48px" height="48px"></b-skeleton>
      </div>
      <p class="header">
        <b-skeleton width="120px"></b-skeleton>
        <b-skeleton width="160px" height="12px"></b-skeleton>
      </p>
    </div>

    <div class="card-content">   
      <div class="card-image">
        <div class="image">
          <b-skeleton width="100%" height="285px"></b-skeleton>
        </div>
      </div>

      <p class="item-info">
        <b-skeleton width="90%"></b-skeleton>
        <b-skeleton width="70%" height="12px"></b-skeleton>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  position: relative;
  flex-direction: column;
  // TODO: remove important
  width: 380px !important;
  height: 470px !important;
  margin: 0 auto;

  .card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: .8rem;
    padding: 1.5rem 1.5rem 0;

    .image {
      margin-right: 1rem;
    }
  }
}

.header {
  height: 45px;
}

.item-info {
  margin-top: 1rem;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MItemCardSkeleton extends Vue {
}
</script>
