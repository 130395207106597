<template>
  <a-default-svg-from-material-design
      :size="size"
      path="M23,12V14H18.61C19.61,16.14 19.56,22 12.38,22C4.05,22.05 4.37,15.5 4.37,15.5L8.34,15.55C8.37,18.92 11.5,18.92 12.12,18.88C12.76,18.83 15.15,18.84 15.34,16.5C15.42,15.41 14.32,14.58 13.12,14H1V12H23M19.41,7.89L15.43,7.86C15.43,7.86 15.6,5.09 12.15,5.08C8.7,5.06 9,7.28 9,7.56C9.04,7.84 9.34,9.22 12,9.88H5.71C5.71,9.88 2.22,3.15 10.74,2C19.45,0.8 19.43,7.91 19.41,7.89Z"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ADefaultSvgFromMaterialDesign from '@/components/atoms/icon/svg/ADefaultSvgFromMaterialDesign.vue';

@Component({
  components:{
    ADefaultSvgFromMaterialDesign
  }
})
export default class AStrikethroughIcon extends Vue {
  @Prop({ default: 1 })
  size!: number;
}
</script>