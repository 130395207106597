<template>
  <loader-icon :size="size" class="spin-continuously"/>
</template>

<style scoped lang="scss">
.spin-continuously {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LoaderIcon } from 'vue-feather-icons';

@Component({
  components: {
    LoaderIcon
  }
})
export default class ALoadingIcon extends Vue {
  @Prop({ default: '1x' })
  size?: string;
}
</script>