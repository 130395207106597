<template>
  <div class="OWysiwygTiptap" :class="containerClasses">
    <label v-if="label" class="label">{{ label }}</label>
    <m-wysiwyg-tip-tap-fixed-menu-header v-if="useHeader" :control-overrides="controlOverrides" :editor="editor"/>
    <editor-content
        :editor="editor"
        class="TipTapEditorContainer content"
        :class="editorContainerClasses"
        :style="{ minHeight: minHeight + 'rem', maxHeight: [maxHeight > 0 ? maxHeight + 'rem' : 'none'] }"
        @click.native="focusOnEditable"
    />
  </div>
</template>

<style scoped lang="scss">
.OWysiwygTiptap {
  .TipTapEditorContainer {
    background-color: var(--white-color);
    border: rgba(12, 12, 12, 0.17) solid 1px;
    border-radius: 4px;
    color: var(--grey-darkest-color);

    ::v-deep .ProseMirror {
      padding: var(--input-default-padding);
      min-height: 100%;
      max-height: 100%;

      &:focus {
        outline: none;
      }

      p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: #adb5bd;
        pointer-events: none;
        height: 0;
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import { Placeholder } from '@tiptap/extension-placeholder';
import MWysiwygTipTapFixedMenuHeader, { Control } from '@/components/molecules/MWysiwygTipTapFixedMenuHeader.vue';
import { Highlight } from '@tiptap/extension-highlight';

@Component({
  components: {
    MWysiwygTipTapFixedMenuHeader,
    EditorContent,
  },
})
export default class OWysiwygTiptap extends Vue {
  @Prop({ default: '' })
  value!: string;
  @Prop({ default: '' })
  containerClasses!: string;
  @Prop({ default: '' })
  editorContainerClasses!: string;
  @Prop({ default: 'Write something here...' })
  placeholder!: string;
  @Prop({ default: true })
  useHeader!: boolean;
  @Prop({ default: () => [] })
  controlOverrides!: Control[];
  @Prop({ default: '' })
  label!: string;
  @Prop({ default: 4 })
  minHeight!: number; // rem
  @Prop({ default: 0 })
  maxHeight!: number; // 0 = no max
  @Prop({ default: 0 })
  characterLimit!: number;

  editor: any = null;

  mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        Highlight.configure({
          multicolor: true,
          HTMLAttributes: {
            class: 'tiptap-highlight-element'
          }
        }),
        Placeholder.configure({
          placeholder: this.placeholder
        }),
        StarterKit.configure({
          blockquote: {
            HTMLAttributes: {
              class: 'tiptap-blockquote-element'
            }
          },
          heading: {
            levels: [1, 2, 3]
          }
        })
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
      }
    });
  }

  beforeDestroy () {
    this.editor.destroy();
  }

  focusOnEditable () {
    // If we are not highlighting something set the cursor position to be the anchor position to ensure cursor is in editable area
    const from = this.editor.state.selection.$anchor.pos;
    const to = this.editor.state.selection.$head.pos;
    if( from === to ){
      this.editor.chain().focus().setTextSelection(from).run();
    }
  }

  @Watch('value')
  bindValue (value) {
    if (this.editor.getHTML() !== value) {
      this.editor.commands.setContent(value, false);
    }
  }
}
</script>