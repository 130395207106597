<template>
  <div class="settings-control is-flex pb-4 is-justify-content-end">

    <span class="as-link" @click="showModal=true">
      {{ $t('channel.customFields.tableSettings.linkText') }}
      <settings-icon size="1.2x"/>
    </span>

    <o-modal-wrapper :show-modal="showModal" v-on:close="showModal = false">
      <template slot="title">
        {{ $t('channel.customFields.tableSettings.modalTitle') }}
      </template>
      <template slot="body">
        <b-loading :active="saving" :is-full-page="false"/>
        <div class="columns">
          <!-- Non custom fields-->
          <div class="column">
            <h5 class="m-2">
              {{ $t('channel.customFields.tableSettings.defaultOptions') }}
              <b-button @click="() => emitSingleKeyAndClose('allStandardOff')" size="is-small">
                {{ $t('channel.customFields.tableSettings.allOff') }}
              </b-button>
              <b-button @click="() => emitSingleKeyAndClose('allStandardOn')" size="is-small">
                {{ $t('channel.customFields.tableSettings.allOn') }}
              </b-button>
              <b-button @click="() => emitSingleKeyAndClose('standardReset')" size="is-small">
                {{ $t('channel.customFields.tableSettings.defaults') }}
              </b-button>
            </h5>
            <b-field v-for="(val, i) in nonCustomFields"
                     :key="val.label">
              <b-switch v-model="nonCustomFields[i].show"
                        @input="(show) => $emit('standardFieldToggle', {show, field: val})"
              >
                {{ val.label }}
              </b-switch>
            </b-field>

            <h5 class="m-2">
              {{ $t('channel.customFields.tableSettings.customOptions') }}
              <b-button @click="() => emitSingleKeyAndClose('allCustomOff')" size="is-small">
                {{ $t('channel.customFields.tableSettings.allOff') }}
              </b-button>
              <b-button @click="() => emitSingleKeyAndClose('allCustomOn')" size="is-small">
                {{ $t('channel.customFields.tableSettings.allOn') }}
              </b-button>
            </h5>
            <b-field v-for="(val, i) in customFieldDisplay"
                     :key="val._id">
              <b-switch v-model="customFieldDisplay[i].show"
                        @input="(show) => $emit('customFieldToggle', {show, field: val})">
                <span class="font-sub2">({{ val.unit }})</span> {{ val.label }}
              </b-switch>
            </b-field>
          </div>
        </div>
      </template>
      <template slot="footer">
        <div class="is-flex is-flex-direction-column is-justify-content-end">
          <b-button type="is-primary" @click="showModal = false">
            {{ $t('channel.customFields.tableSettings.saveSettingsAsSession') }}
          </b-button>
          <b-button
              type="is-primary"
              @click="saveAsDefaultSettings"
              class="mt-2 is-outlined"
              :disabled="!currentUserOwnsChannel"
          >
            {{ $t('channel.customFields.tableSettings.saveSettingsAsDefault') }}
          </b-button>
          <small>{{ $t('channel.customFields.tableSettings.saveSettingsDescription') }}</small>
        </div>
      </template>
    </o-modal-wrapper>
  </div>
</template>

<style scoped lang="scss">
.settings-control {

}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomFieldDisplay, NonCustomFields } from '@/store/modules/interfaces/ChannelViewModule';
import { ChevronDownIcon, ChevronUpIcon, MessageSquareIcon, SettingsIcon, ThumbsUpIcon } from 'vue-feather-icons';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';

@Component({
    components: {
      OModalWrapper,
      ChevronDownIcon,
      ChevronUpIcon,
      ThumbsUpIcon,
      MessageSquareIcon,
      SettingsIcon
    },
  }
)
export default class MChannelCustomFieldTableSettings extends Vue {
  @Prop({ required: true })
  customFieldDisplay!: CustomFieldDisplay[];

  @Prop({ required: true })
  nonCustomFields!: NonCustomFields;

  @Prop({ required: true })
  currentUserOwnsChannel!: boolean;

  showModal = false;
  saving = false;

  emitSingleKeyAndClose (eventName: 'allStandardOff' | 'allStandardOn' | 'standardReset' | 'allCustomOff' | 'allCustomOn') {
    this.$emit(eventName);
  }

  saveAsDefaultSettings () {
    this.saving = true;
    setTimeout(() => {
      this.saving = false;
      this.showModal = false;
      this.$emit('saveSettingsAsDefault', {
        customFieldDisplay: this.customFieldDisplay,
        nonCustomFields: this.nonCustomFields
      });
    }, 300);
  }
}
</script>
