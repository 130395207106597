<template>
  <p @click="toggleCheckBox" :class="{'is-clickable': userCanEdit}">
    <b-loading v-model="loading"/>
    <check-square-icon v-if="value === 1" :size="iconSize"/>
    <square-icon v-else :size="iconSize"/>
  </p>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomField as ItemCustomField, FieldType } from '@/api/ms-item/services/interfaces/Item';
import { CustomField as ChannelCustomField } from '@/api/ms-channel/services/interfaces/Channel';
import { CheckSquareIcon, SquareIcon } from 'vue-feather-icons';
import ItemService from '@/api/ms-item/services/ItemService';

@Component({
  components: {
    CheckSquareIcon, SquareIcon
  }
})
export default class ACustomFieldDisplayCheckbox extends Vue {
  @Prop({ required: true })
  field!: ItemCustomField;
  @Prop({ required: true })
  customChannelFieldData!: ChannelCustomField;
  @Prop({ required: true })
  uniqueItemName!: string;
  @Prop({ required: true })
  userCanEdit!: boolean;

  iconSize = '1.2x';
  loading = false;
  value = 0;

  created () {
    if (this.field && this.field.fieldType !== FieldType.Checklist) {
      console.error('Wrong field type. Expects checklist.');
    } else {
      // this custom field is always shown if the channel contains this field, so may be undefined if the item doesn't have a value
      if (this.field && typeof this.field.value !== 'undefined') {
        this.value = Number(this.field.value);
      }
    }
  }

  async toggleCheckBox () {
    if (!this.userCanEdit) {
      return;
    }
    this.loading = true;
    const newVal = this.value === 1 ? '0' : '1';
    await ItemService.itemNameUniqueItemNameCustomFieldCustomFieldIdPatch({
      uniqueItemName: this.uniqueItemName,
      customFieldId: this.customChannelFieldData._id
    }, {
      value: newVal
    });
    // human delay
    setTimeout(() => {
      this.loading = false;
      this.value = Number(newVal);
    }, 300);
  }
}
</script>