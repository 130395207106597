<template>
  <div class="positioner">
    <div class="outer" @click="$emit('click')">
      <div class="play-icon"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.positioner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, .2);
  text-align: center;
  transition: all .2s;

  &:hover {
    background-color: unset;
  }
}

.outer {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-top: 40%;
  padding: 25px 32px 32px 30px;
  border-radius: 40px;
  background-color: #1db954;
  cursor: pointer;
  transition: all .2s;

  &:hover {
    transform: scale(1.1);
  }
}

.play-icon {
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  border-width: 16px 0 16px 26px;
  border-style: solid;
  border-color: transparent transparent transparent #fff;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ASpotifyPlay extends Vue {
}
</script>
