<template>
  <div class="MChannelsRecentlyAddedTo" v-if="getChannelsLastAddedTo.length > 0">
    <p v-if="showLabel" :class="labelClass">{{ $t('dict.channelsRecentlyAddedTo') }}:</p>
    <Flicking :options="{align: 'prev', moveType: ['freeScroll', { stopAtEdge: true }]}">
      <div v-for="(channel, index) in getChannelsLastAddedTo" :key="index" @click="() => selectChannel(channel)">
        <figure :title="figureTitle(channel.name)">
          <a-image
              v-if="channel.imagePath"
              :alt-text="channel.name"
              :image-path="channel.imagePath"
              :size="imageSize"
              class="channel-image"
              :rounded="true"
          />
          <span class="channel-name">
            <calendar-icon v-if="channel.channelType === channelTypes.Event" size="1x" class="mr-1"/>
            <a-lightbulb-on-outline-icon v-if="channel.channelType === channelTypes.ResearchConcierge" size="1"
                                         class="mr-1"/>
            <span v-html="channel.name"/>
          </span>
        </figure>
      </div>
      <template v-if="getChannelsLastAddedTo.length < 3">
        <div v-for="i in (3 - getChannelsLastAddedTo.length)" :key="i" @click="skeletonSelected">
          <figure :title="$t('channel.lastAddedTo.skeletonTitle')">
            <div class="channel-image skeleton">
              <a-folders-svg-icon size="1.2" :keep-y-zero="true" />
            </div>
          </figure>
        </div>
      </template>
    </Flicking>
  </div>
</template>

<style scoped lang="scss">
@import '/node_modules/@egjs/vue-flicking/dist/flicking.css';

.MChannelsRecentlyAddedTo {
  flex-shrink: 3;

  figure {
    display: flex;
    flex-direction: column;
    margin: .25rem .75rem;
    cursor: pointer;

    div.skeleton {
      width: 60px;
      height: 60px;
      border: solid 1px var(--grey-light-color);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .channel-image {
      transition: all .15s ease-in;
    }

    &:hover {
      img,
      div.skeleton {
        -webkit-box-shadow: 0 0 4px 0 #000;
        box-shadow: 0 0 4px 0 #000;
      }

      span {

      }
    }

    span.channel-name {
      max-width: 60px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: small;
      text-align: center;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
// Flicking docs: https://naver.github.io/egjs-flicking/Options
import { Flicking } from '@egjs/vue-flicking';
import { ChannelsStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import AImage from '@/components/atoms/AImage.vue';
import { ChannelType } from '@/api/ms-item/services/interfaces/Item';
import ALightbulbOnOutlineIcon from '@/components/atoms/icon/svg/ALightbulbOnOutlineIcon.vue';
import { CalendarIcon } from 'vue-feather-icons';
import { decode } from 'html-entities';
import { getDifferenceBetweenTwoDates } from 'common-utils/time';
import AFoldersSvgIcon from '@/components/atoms/icon/svg/AFoldersSvgIcon.vue';

@Component({
  components: {
    AFoldersSvgIcon,
    ALightbulbOnOutlineIcon,
    AImage,
    CalendarIcon,
    Flicking,
  }
})
export default class MChannelsRecentlyAddedTo extends Vue {
  @Prop({ default: Size.The60X60 })
  imageSize?: Size;
  @Prop({ default: true })
  showLabel?: boolean;
  @Prop({ default: 'p-1' })
  labelClass?: string;

  channelTypes = ChannelType;

  get getChannelsLastAddedTo () {
    return ChannelsStore.getChannelsLastAddedTo;
  }

  get getChannelsLastAddedToFetched () {
    return ChannelsStore.getChannelsLastAddedToFetched;
  }

  async created () {
    await this.setChannelsLastAddedTo();
  }

  async setChannelsLastAddedTo () {
    const timeBetweenDates = getDifferenceBetweenTwoDates(new Date(), this.getChannelsLastAddedToFetched);
    // if older than 10 minutes, refresh
    if (isNaN(timeBetweenDates.milliseconds) || timeBetweenDates.milliseconds > 10 * 60 * 1000) {
      await ChannelsStore.fetchChannelsLastAddedTo();
    }
  }

  figureTitle (name: string): string {
    return decode(name);
  }

  selectChannel (channel) {
    this.$emit('channel-selected', channel);
  }

  skeletonSelected () {
    this.$emit('skeleton-selected');
  }
}
</script>