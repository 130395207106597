<template>
  <div class="MItemEntryCustomFieldLabel">
    <div class="mr-2 font-caption">
      {{ fieldNumber }} {{ unit ? `(${unit})` : '' }}
    </div>
    <div class="field-label">
      {{ label }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.MItemEntryCustomFieldLabel {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: .3rem;
}
.field-label{
  font-size: smaller;
  text-align: left;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MItemEntryCustomFieldLabel extends Vue {
  @Prop({ required: true })
  label!: string;

  @Prop({ required: true })
  fieldNumber!: number;

  @Prop({ required: true })
  unit?: string;
}
</script>
