<template>
  <div>
    <label class="label">
      <a-tooltip v-if="!channelSlug" tip="After creating the channel you can invite people">
        {{ $t('page.channelUpsert.form.privacyTitle') }}
      </a-tooltip>
      <template v-else>
        {{ $t('page.channelUpsert.form.privacyTitle') }}
      </template>
    </label>
    <div>
      <m-radio-cards
          v-model="value"
          :value="privacyOptions.Private"
          :title="$t('page.channelUpsert.form.privateLabel')"
          :disabled="disabled"
          :recalculate-accordion="recalculateAccordion"
      />
      <m-radio-cards
          v-model="value"
          :value="privacyOptions.FriendsOnly"
          :title="$t('page.channelUpsert.form.friendsOnly')"
          :disabled="disabled"
          :recalculate-accordion="recalculateAccordion"
      />
      <m-radio-cards
          v-model="value"
          :value="privacyOptions.Public"
          :title="$t('page.channelUpsert.form.publicLabel')"
          :disabled="disabled"
          :recalculate-accordion="recalculateAccordion"
      />
    </div>
    <b-message
        v-if="displayPrivacyWarning"
        class="mt-5"
        type="is-danger"
    >
      {{ $t('page.channelUpsert.form.changingPrivacyWarning') }}
    </b-message>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';
import { Privacy } from '@/api/ms-channel/services/interfaces/Channel';
import MRadioCards from '@/storybook-components/src/stories/molecules/MRadioCards.vue';
import NotificationService from '@/api/ms-channel/services/NotificationService';

@Component({
  components: { MRadioCards, ATooltip }
})
export default class MChannelPrivacySettingsPrivacyType extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: Privacy;
  @Prop({ required: false, default: false })
  recalculateAccordion!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;
  @Prop({ required: false })
  channelSlug?: string;
  @Prop({ required: false, default: false })
  editing?: boolean;

  value: Privacy = Privacy.FriendsOnly;
  privacyOptions = Privacy;
  existingPrivacy: Privacy = Privacy.FriendsOnly;

  anyoneWithNotificationsEnabled: boolean = false;

  /**
   * If editing a channel, display privacy warning if the user is about to make the privacy setting more private. Don't
   * display if setting will become less private as that won't affect the notifications
   */
  get displayPrivacyWarning (): boolean {
    if (!this.editing || !this.anyoneWithNotificationsEnabled || this.existingPrivacy === Privacy.Private || this.existingPrivacy === this.value) {
      return false;
    }
    return !(this.existingPrivacy === Privacy.FriendsOnly && this.value === Privacy.Public);


    // existingPrivacy !== privacyOptions.Private && existingPrivacy !== value
  }

  created () {
    this.value = this.activeValue;
    this.existingPrivacy = this.value;
  }

  async mounted () {
    await this.checkForNotificationsEnabled();
  }

  // check if anyone has enabled notifications for this channel and display message if so
  async checkForNotificationsEnabled () {
    // don't bother if the currency privacy setting is "private" as the other types are both less private
    if (this.editing && this.channelSlug && this.existingPrivacy && this.existingPrivacy !== Privacy.Private) {
      const anyEnabled = await NotificationService.notificationSlugAnyEnabledGet({
        slug: this.channelSlug
      });
      this.anyoneWithNotificationsEnabled = anyEnabled.isTrue;
    }
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
</script>