<template>
  <div class="ACommentIcon">
    <a-router-link-issue-detail :go-to-tab="commentsRoute" :unique-item-name="uniqueItemName">
      <message-square-icon size="14"></message-square-icon>
      {{ commentCount ? 'x' + commentCount : '' }}
    </a-router-link-issue-detail>
  </div>
</template>

<style scoped lang="scss">
.ACommentIcon {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  min-height: 1rem;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';
import { MessageSquareIcon } from 'vue-feather-icons';

@Component({
  components: {
    ARouterLinkIssueDetail,
    MessageSquareIcon
  }
})
export default class ACommentIcon extends Vue {
  @Prop({ default: 0, required: true })
  commentCount!: number;

  @Prop({ required: true })
  uniqueItemName!: string;

  commentsRoute = RouteNames.ROUTE_ITEM_DETAIL_VIEW_COMMENTS;
}
</script>
