<template>
  <b-upload
      v-if="!isApp"
      v-model="value"
      :accept="imageTypesAllowed"
      :disabled="maxImageUploadReached"
      multiple
      @input="(fileList) => $emit('input', fileList)"
  >
    <span class="fixed-width-column">
      <camera-icon :disabled="maxImageUploadReached" class="upload-icon" size="1.5x"/>
    </span>
    <span class="is-clickable" v-if="!maxImageUploadReached">
      {{ $t('item.entry.addImage') }}
    </span>
  </b-upload>
  <div v-else v-on:click="deviceCamera">
    <span class="fixed-width-column">
      <camera-icon :disabled="maxImageUploadReached" class="upload-icon" size="1.5x"/>
    </span>
    <span class="additional-data-helper-text" v-if="!maxImageUploadReached">
      {{ $t('item.entry.addImage') }}
    </span>
  </div>
</template>

<script lang="ts">
import isApp from '@/utils/isApp';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { types as imageTypes } from '@/constants/imageTypes';
import { CameraIcon } from 'vue-feather-icons';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component({
  components: {
    CameraIcon
  }
})
export default class MImageUpload extends Vue {
  @Prop({ required: true })
  value!: any;

  @Prop({ required: true })
  maxImageUploadReached!: boolean;

  loading: boolean = false;

  imageTypesAllowed = imageTypes.join(',');

  get isApp (): boolean {
    return isApp();
  }

  created () {
    this.eventsBind();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  eventsBind () {
    EventBus.$on(EventBusEvents.CAPJS_GOT_PIC, 'MImageUpload', async (output: { imageBase64: string }) => {
      this.$emit('input', [output]);
    });
  }

  eventsUnbind () {
    EventBus.$remove(EventBusEvents.CAPJS_GOT_PIC, 'MImageUpload');
  }

  deviceCamera () {
    EventBus.$emit(EventBusEvents.CAPJS_GET_PIC);
  }
}
</script>
