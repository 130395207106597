<template>
  <b-field :label="$t('page.channelUpsert.form.conciergeSettings.qtyItems', {conciergeTypeItems: conciergeTypeItemsTranslation})">
    <b-slider v-model="value" :tooltip="false" indicator :min="1" :max="maxValue" :disabled="disabled">
      <template v-for="val in [1, maxValue]">
        <b-slider-tick :value="val" :key="val">{{ val }}</b-slider-tick>
      </template>
    </b-slider>
  </b-field>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { ConciergeType } from '@/api/ms-channel/services/interfaces/ChannelPost';

@Component
export default class MChannelConciergeSettingsQuantity extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: number;
  @Prop()
  conciergeType!: ConciergeType;
  @Prop({ default: false })
  disabled!: boolean;

  value: number = 0;
  maxValue: number = 10;
  conciergeTypes = ConciergeType;
  conciergeTypeItemsTranslation: string = '';

  created () {
    this.value = this.activeValue;
    this.setMaxValue();
    this.setConciergeTypeItemsTranslation();
  }

  setMaxValue () {
    this.maxValue = this.conciergeType === this.conciergeTypes.SingleItem ? 10 : 5;
  }

  setConciergeTypeItemsTranslation () {
    switch (this.conciergeType) {
      case ConciergeType.SingleItem:
        this.conciergeTypeItemsTranslation = this.$t('page.channelUpsert.form.conciergeSettings.conciergeTypeItems.single') as string;
        break;
      case ConciergeType.Collection:
        this.conciergeTypeItemsTranslation = this.$t('page.channelUpsert.form.conciergeSettings.conciergeTypeItems.collection') as string;
        break;
      case ConciergeType.Travel:
        this.conciergeTypeItemsTranslation = this.$t('page.channelUpsert.form.conciergeSettings.conciergeTypeItems.travel') as string;
        break;
    }
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }

  @Watch('conciergeType')
  updateMaxValueAndLabel () {
    this.setMaxValue();
    this.setConciergeTypeItemsTranslation();
  }
}
</script>