<template>
  <div class="OItemCardContainer MChannelSwipeNoItemsLeft">
    <div class="OItemCard card no-top-border">
      <div class="item-card-title">{{ $t('dict.swipeScoresName') }}</div>
      <div class="card-content">
        <p>{{ $t('item.swipeScore.finished.p1') }}</p>
        <p>{{ $t('item.swipeScore.finished.p2') }}</p>
        <p>{{ $t('item.swipeScore.finished.p3') }}</p>
      </div>
      <div class="card-footer">
        <b-button type="is-primary" class="is-outlined" @click="closeModal"><p>{{ $t('dict.close') }}</p></b-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.MChannelSwipeNoItemsLeft {
  max-width: 395px;

  .card-content {
    p:not(:first-child) {
      margin-top: 1rem;

      &.asterisk-text {
        font-size: smaller;
        font-style: italic;
      }
    }
  }

  .card-footer {
    justify-content: center;
  }
}

// Mostly just mock the item card here...
@import '../../organisms/OItemCard';
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MChannelSwipeNoItemsLeft extends Vue {
  closeModal () {
    this.$emit('close-modal');
  }
}
</script>