<template>
  <div class="MChatDefaultMessage">
    <div class="msg content">
      <p>📫</p>
      <p v-html="$t('dict.chatDefaultMessage')"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.MChatDefaultMessage {
  position: relative;
  margin: 1rem 1rem .5rem 5.5rem;
  padding: .5rem;
  border-radius: var(--default-border-radius);
  background-color: var(--darker-bg-color);
  box-shadow: 0 3px 6px rgba(#000, .2);
}

.msg {
  max-width: 330px;
  overflow-wrap: break-word;

  @media screen and (max-width: 500px) {
    max-width: 245px;
  }

  @media screen and (max-width: 400px) {
    max-width: 200px;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MChatDefaultMessage extends Vue {
}
</script>
