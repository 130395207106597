<template>
  <span class="APinItemIcon" @click="emit">
    <b-loading :is-full-page="false" :active.sync="loading"></b-loading>

    <a-pin-svg-icon
      :pinned="pin"
      />

    <span>{{ pinCount ? 'x' + pinCount : '' }}</span>
  </span>
</template>

<style scoped lang="scss">
.APinItemIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 1rem;

  &:hover {
    color: var(--primary-color);
    cursor: pointer;
  }

  span {
    margin-left: 5px;
    cursor: pointer;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import APinSvgIcon from '@/components/atoms/icon/svg/APinSvgIcon.vue';

export interface APinItemChildOutput {
  pin: boolean,
}

@Component({
  components: { APinSvgIcon }
})
export default class APinItemIcon extends Vue {
  @Prop({ default: false })
  loading!: boolean | undefined;

  @Prop({ default: false })
  pin!: boolean;

  @Prop({ required: true })
  pinCount!: number;

  @Prop()
  iconSize!: string;

  @Prop()
  disabled!: string;

  @Prop({ default: false })
  numericOnly!: boolean;

  emit () {
    const newState = this.pin === undefined ? true : !this.pin;
    this.$emit('child-output', {
      // emit the opposite of the current like value
      pin: newState,
    } as APinItemChildOutput);
  }
}
</script>