<template>
  <div class="ORecommendationReportContentForm">
    <ValidationObserver ref="form">
      <form novalidate @submit.prevent="onSubmit">
        <m-input-with-validation
            v-model="form.reporterComment"
            :is-resizable="true"
            :label="$t('dict.reportItemMsgLabel')"
            :placeholder="$t('dict.message')"
            allow-emoji
            auto-complete="off"
            name="message"
            rules="required|min:4"
            type="textarea"
        />

        <a-button-submit :loading="loading">
          {{ $t('dict.sendMsg') }}
        </a-button-submit>
      </form>

      <a-message
          v-if="error"
          type="is-danger"
          v-on:child-output="handleCloseMessage('error')"
      >
        {{ $t(`dict['${error}']`) }}
      </a-message>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .input {
  margin-bottom: .5rem;
}

.button {
  margin-top: .7rem;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { Recommendation, RecommendNameUniqueItemNameReportPost, } from '@/api/ms-item/services/interfaces';
import { AuthenticationStore } from '@/store';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import AMessage from '@/components/atoms/AMessage.vue';
import { XIcon } from 'vue-feather-icons';
import RecommendService from '@/api/ms-item/services/RecommendService';

@Component({
  components: {
    AButtonSubmit,
    MInputWithValidation,
    ValidationObserver,
    XIcon,
    AMessage
  },
})
export default class ORecommendationReportContentForm extends Vue {
  @Prop({ required: true })
  recommendation!: Recommendation;

  loading = false;
  emptyForm: RecommendNameUniqueItemNameReportPost = {
    reporterComment: ''
  };
  form: RecommendNameUniqueItemNameReportPost = this.emptyForm;
  $refs!: {
    form: InstanceType<typeof ValidationObserver>;
  };
  error: string = '';

  get getAuthenticated () {
    return AuthenticationStore.getAuthenticated;
  }

  mounted () {
    this.$refs.form;
  }

  handleCloseMessage (type: string) {
    this[type] = '';
  }

  async onSubmit () {
    // https://logaretm.github.io/vee-validate/guide/forms.html#programmatic-access-with-refs
    if (!(await this.$refs.form.validate())) {
      return;
    }

    try {
      await RecommendService.recommendNameUniqueItemNameReportPost(this.form, {
        uniqueItemName: this.recommendation.uniqueItemName,
      });

      this.$buefy.snackbar.open({
        message: this.$t('dict.reportItemSuccess').toString(),
        queue: false,
        type: 'is-success',
        position: 'is-top',
      });

      this.$emit('child-output');
      this.form = this.emptyForm;
      await this.$nextTick(() => {
        this.$refs.form.reset();
      });
    } catch (e: any) {
      if (e.response.status === 422) {
        this.error = 'reportItemFailed422';
      } else {
        this.error = 'reportItemFailedGeneral';
      }
    }
  }
}
</script>
