<template>
  <div class="AUserContentDisplay">
    <label class="label">{{ label }}</label>
    <div class="UserContent">
      <div :class="{'is-inline-block': inline}" v-html="content" :style="[minWidth ? {'minWidth': minWidth} : null]" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.AUserContentDisplay {
  .UserContent {
    > div {
      padding: .75rem;
      border-left: .2rem solid var(--primary-color-light);
      background-color: var(--grey-lightest-color);
      border-radius: .2rem;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AUserContentDisplay extends Vue {
  @Prop()
  content!: string;

  @Prop({required:false})
  label?: string;

  @Prop({default: false})
  inline!: boolean;

  @Prop({required: false})
  minWidth?: string;
}
</script>