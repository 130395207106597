<template>
  <div class="MShoppingListHeader navbar-header-icons">
    <b-dropdown
        v-if="authenticated"
        :mobile-modal="false"
        aria-role="list"
        :close-on-click="false"
        class="header-dropdown"
        position="is-bottom-left"
        @active-change="triggerGetGotUpdate"
    >
      <a-list-icon slot="trigger" :count="itemsToGetAvailableCount"/>

      <b-dropdown-item
          v-if="itemsToGet.length === 0"
          aria-role="listitem"
          class="no-items"
      >
        <span class="sl-no-items-msg">{{ $t('dict.shoppingListNoItems') }}</span>
      </b-dropdown-item>

      <b-dropdown-item
          v-for="(item, index) in itemsToGet"
          v-else
          v-bind:key="index"
          aria-role="listitem"
      >
        <m-shopping-list-header-item
            :item="item"
            :dropdown-visible="dropdownVisible"
        />
      </b-dropdown-item>
      <b-dropdown-item v-if="itemsToGet" class="shopping-list-footer" has-link>
        <a-router-link-get-got :count="itemsToGetAvailableCount"/>
      </b-dropdown-item>
    </b-dropdown>

    <div v-else>
      <a-list-icon v-on:click="visible = !visible"/>

      <b-modal :active.sync="visible" :can-cancel="false" aria-role="dialog" has-modal-card>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ $t('dict.welcome') }}</p>
            <a-close-modal-button v-on:child-output="visible = false"/>
          </header>
          <m-authentication-tabs></m-authentication-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.MShoppingListHeader {
  display: flex;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 550px) {
    ::v-deep .is-bottom-left .dropdown-menu {
      right: -8rem;

      &::after {
        right: 8rem;
        left: unset;
      }
    }
  }

  ::v-deep {
    .no-items {
      cursor: default;
    }
  }
}

.dropdown-item {
  padding: .8rem .8rem .6rem;
  border-top: 2px solid #f5f5f5;

  &:first-child {
    border-top-width: 0;
  }
}

.shopping-list-footer {
  margin-top: 7px;
  margin-bottom: -8px;
  background-color: rgba(0, 0, 0, .03);
  text-align: right;

  &.has-link .getgot-link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: .8rem 1.2rem .8rem;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationStore, ShoppingListStore } from '@/store';
import { Item } from '@/api/ms-item/services/interfaces';

import MShoppingListHeaderItem from '@/components/molecules/shoppingList/MShoppingListHeaderItem.vue';
import ARouterLinkGetGot from '@/components/atoms/link/ARouterLinkGetGot.vue';
import MAuthenticationTabs from '@/components/molecules/MAuthenticationTabs.vue';
import AListIcon from '@/components/atoms/icon/AListIcon.vue';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';

@Component({
  components: {
    ACloseModalButton,
    AListIcon,
    ARouterLinkGetGot,
    MShoppingListHeaderItem,
    MAuthenticationTabs,
  }
})
export default class MShoppingListHeader extends Vue {
  visible = false;
  loading = false;
  dropdownVisible = false;

  /**
   * When an item is removed from shopping list it's unique item name is emitted up and stored in this array.
   * When dropdown is closed we iterate through this array and remove all those items from the store.
   */
  listRemovals: string[] = [];

  get itemsToGet (): Item[] {
    return ShoppingListStore.getItemsToGet;
  }

  get itemsToGetAvailableCount (): number {
    return ShoppingListStore.getItemsToGetAvailableCount;
  }

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  async created () {
    this.loading = true;

    if (AuthenticationStore.getAuthenticated) {
      await ShoppingListStore.fetchShoppingListToGet();
    }

    this.loading = false;
  }

  /**
   * When the dropdown is closed, trigger to update the get got states so any marked as bought/removed are removed from
   * list. It also updates the timestamps as a nice side effect.
   */
  triggerGetGotUpdate (active) {
    this.dropdownVisible = active;
    if (!active) {
      ShoppingListStore.fetchShoppingListToGet();
    }
  }
}
</script>
