<template>
  <div class="OChannelUpsertForm">
    <b-loading :active="loading" :is-full-page="false"></b-loading>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form novalidate @submit.prevent="handleSubmit(upsertChannel)">
        <!-- Channel images-->
        <div
            v-if="!simpleForm"
            ref="heroContainer"
            :class="{'has-image': newChannelHeroImage.src || channel.imageHeroPath}"
            class="hero-placeholder">
          <b-field class="field-upload-hero">
            <div v-if="hasChannelHeroImage()" class="channel-hero image-wrapper">
              <a-image
                  :alt-text="channel.name"
                  :image-path="channel.imageHeroPath"
                  :size="heroImageSize"
                  className="channel-edit-hero"
              />
            </div>
            <div>
              <m-upload-image-preview
                  :base64Strings="previewChannelHeroImage"
                  max-height="360"
                  class-delete-button="is-large"
                  class-image="channel-hero"
                  v-on:child-output="clearChannelHero"
              />
            </div>
            <m-upload-or-generate-image
                class="clipper-upload-hero"
                :upload-or-generate="channelHeroUploadOrGenerate"
                :image-types-allowed="imageTypesAllowed"
                :generate-image-binding-string="channel.name"
                :generate-image-binding-min-length="channelNameMinLength"
                :clear-upload-cache="clearCropperHeroImage"
                v-on:child-output="uploadOrGenerateCoverImageHandle"
                v-on:invalid-string="highlightChannelName"
            />
          </b-field>

          <div :class="{ 'has-thumb': newChannelImage.src || channel.imagePath} " class="thumbnail-placeholder">
            <b-field class="field-upload-thumbnail">
              <div v-if="hasChannelImage()" class="image-wrapper channel-thumbnail">
                <a-image
                    v-model="channel.imagePath"
                    :alt-text="channel.name"
                    :image-path="channel.imagePath"
                    :size="imageSize"
                    className="channel-edit-thumbnail"
                />
              </div>
              <div>
                <m-upload-image-preview
                    :base64Strings="previewChannelImage"
                    classImage="channel-thumbnail"
                    v-on:child-output="clearChannelThumb"
                />
              </div>
              <m-upload-or-generate-image
                  class="clipper-upload-thumb"
                  :upload-or-generate="channelImageUploadOrGenerate"
                  :image-types-allowed="imageTypesAllowed"
                  :generate-image-binding-string="channel.name"
                  :generate-image-binding-min-length="channelNameMinLength"
                  :clear-upload-cache="clearCropperThumbImage"
                  v-on:child-output="uploadOrGenerateChannelImageHandle"
                  v-on:invalid-string="highlightChannelName"
              />
            </b-field>
          </div>
        </div>

        <!-- Multi-step form -->
        <div class="input-steps" :class="{'simple-form': simpleForm}">
          <b-steps v-model="activeStep" class="mt-5">
            <!-- Custom buttons template -->
            <template #navigation="{previous, next}">
              <div class="is-flex is-justify-content-center multi-step-button-container pb-3">
                <b-button
                    type="is-danger"
                    class="is-secondary is-outlined"
                    :disabled="previous.disabled"
                    @click.prevent="previous.action">
                  {{ $t('dict.back') }}
                </b-button>
                <b-button
                    v-if="activeStep < 2"
                    class="is-primary"
                    :disabled="next.disabled"
                    @click.prevent="nextStep">
                  {{ $t('dict.next') }}
                </b-button>
                <section v-else>
                  <b-button
                      v-if="!simpleForm && ((!newChannelImage && !channel.imagePath) || (!newChannelHeroImage.src && !channel.imageHeroPath))"
                      class="is-primary btn-save"
                      @click="setModal"
                  >
                    {{
                      !!channel.slug ? $t('page.channelUpsert.form.saveChanges') : $t('page.channelUpsert.form.createChannel')
                    }}
                  </b-button>
                  <a-button-submit
                      v-else
                      :loading="loading"
                      buttonClasses="btn-save"
                  >
                    {{
                      !!channel.slug ? $t('page.channelUpsert.form.saveChanges') : $t('page.channelUpsert.form.createChannel')
                    }}
                  </a-button-submit>
                </section>
              </div>
            </template>

            <!-- Basics - Channel name and channel type -->
            <b-step-item
                step="1"
                :label="$t('dict.basics')"
                :clickable="true"
            >
              <div>
                <!-- Channel name -->
                <m-input-with-validation
                    v-model="channel.name"
                    :label="$t('page.channelUpsert.form.name')"
                    :placeholder="$t('page.channelUpsert.form.namePlaceholder')"
                    groupFieldClass="form-group"
                    name="name"
                    :rules="'required|min:' + channelNameMinLength  + '|max:50'"
                />

                <!-- Channel type -->
                <m-channel-basics-channel-type
                    v-model="channel.channelType"
                    class="channel-type-group form-group"
                />
              </div>
            </b-step-item>

            <!-- Details - Description, event date, meta, concierge settings -->
            <b-step-item
                step="2"
                :label="$t('dict.details')"
            >
              <!-- Channel description -->
              <o-wysiwyg-tiptap
                  v-if="[channelTypeOptions.General, channelTypeOptions.Event].includes(channel.channelType)"
                  v-model="channel.description"
                  :label="$t('page.channelUpsert.form.description')"
                  :placeholder="$t('page.channelUpsert.form.descriptionPlaceholder')"
                  :min-height="6"
                  container-classes="form-group"
              />

              <!-- Datepicker for event channels -->
              <div class="form-group">
                <b-field v-if="channel.channelType === channelTypeOptions.Event"
                         :label="$t('page.channelUpsert.form.channelEventDate')">
                  <div class="datepicker-calendar-wrapper">
                    <date-picker
                        v-model="eventDate"
                        type="date"
                        :disabled="channel.channelType === channelTypeOptions.General"
                        :lang="datePickerLang"
                        :disabled-date="(date) => disabledDate(date)"
                        :format="formatDate()"
                        :partial-update="true"
                        :open.sync="eventDateOpenSync"
                        default-panel="month"
                        value-type="date"
                        prefix-class="lfr"
                        @change="insertDefaultTime"
                    />
                  </div>
                  <div class="datepicker-time-wrapper">
                    <date-picker
                        v-model="eventTime"
                        type="time"
                        :disabled="channel.channelType === channelTypeOptions.General"
                        :lang="datePickerLang"
                        :format="formatTime()"
                        prefix-class="lfr"
                        :minute-step="5"
                        :show-second="false"
                        :open.sync="eventTimeOpenSync"
                        @change="closeOnMinuteSelect"
                    />
                  </div>
                </b-field>
                <m-event-repetition-options
                    v-if="channel.channelType === channelTypeOptions.Event"
                    :event-date="eventDate"
                    :channel-event-type="eventType"
                    v-on:child-output="saveEventType"
                />
              </div>


              <!-- Research concierge settings -->
              <div v-if="isResearchConcierge && activeStep > 0">
                <!-- Concierge type -->
                <m-channel-concierge-settings-concierge-type
                    v-model="channel.conciergeSettings.conciergeType"
                    :recalculate-accordion="activeStep > 0"
                    class="form-group"
                />

                <!-- Quantity of items requested -->
                <m-channel-concierge-settings-quantity
                    v-model="channel.conciergeSettings.qtyItems"
                    :concierge-type="channel.conciergeSettings.conciergeType"
                    class="mb-6"
                />

                <!-- Urgency -->
                <m-channel-concierge-settings-urgency
                    v-model="channel.conciergeSettings.urgency"
                    :recalculate-accordion="activeStep > 0"
                    class="form-group"
                />
              </div>

              <!-- Custom fields -->
              <m-channel-custom-fields
                  v-if="!isResearchConcierge"
                  v-model="channel.customFields"
                  class="form-group"
              />

              <!-- Channel meta fields -->
              <b-field v-if="!isResearchConcierge" class="form-group">
                <b-checkbox v-model="userAddedMeta">{{ $t('page.channelUpsert.form.channelMeta.add') }}</b-checkbox>
              </b-field>
              <div class="channel-meta"
                   v-if="isResearchConcierge || userAddedMeta">
                <!-- Budget -->
                <b-field
                    grouped
                    :label="$t('page.channelUpsert.form.channelMeta.budget') + (isResearchConcierge ? ' *' : '')"
                    class="form-group"
                >
                  <b-select placeholder="Currency" class="default-select is-flex-grow-0"
                            v-model="channel.channelMeta.budget.currency">
                    <option v-for="(value, index) in uniqueCurrencies" v-bind:key="index" :value="value.currency">
                      {{ value.currency }} ({{ value.symbol }})
                    </option>
                  </b-select>
                  <m-input-with-validation
                      v-model="channel.channelMeta.budget.value"
                      :placeholder="$t('page.channelUpsert.form.channelMeta.budgetPlaceholder')"
                      validation-wrapper-class="is-flex-grow-3"
                      type="number"
                      name="budget"
                      :rules="isResearchConcierge ? 'required' : ''"
                  />
                </b-field>

                <!-- Location -->
                <m-geolocation-autocomplete
                    v-model="channel.channelMeta.geolocationData"
                    :is-research-concierge="isResearchConcierge"
                    class="form-group"
                />

                <!-- Requirements, likes and dislikes -->
                <o-wysiwyg-tiptap
                    v-model="channel.channelMeta.requirements"
                    :label="$t('page.channelUpsert.form.channelMeta.requirements') + (isResearchConcierge ? ' *' : '')"
                    :placeholder="$t('page.channelUpsert.form.channelMeta.requirementsPlaceholder')"
                    :min-height="5"
                    :control-overrides="[{name: controlEnum.Heading, on: false}]"
                    container-classes="form-group"
                />
                <o-wysiwyg-tiptap
                    v-model="channel.channelMeta.likes"
                    :label="$t('page.channelUpsert.form.channelMeta.likes')"
                    :placeholder="$t('page.channelUpsert.form.channelMeta.likesPlaceholder')"
                    :min-height="5"
                    :control-overrides="[{name: controlEnum.Heading, on: false}]"
                    container-classes="form-group"
                />
                <o-wysiwyg-tiptap
                    v-model="channel.channelMeta.dislikes"
                    :label="$t('page.channelUpsert.form.channelMeta.dislikes')"
                    :placeholder="$t('page.channelUpsert.form.channelMeta.dislikesPlaceholder')"
                    :min-height="5"
                    :control-overrides="[{name: controlEnum.Heading, on: false}]"
                    container-classes="form-group"
                />
              </div>

              <!-- Custom fields position for research concierge channels -->
              <m-channel-custom-fields
                  v-if="isResearchConcierge"
                  v-model="channel.customFields"
                  class="form-group"
              />
            </b-step-item>

            <!-- Privacy Settings - Privacy, who can add, shareable link, chat -->
            <b-step-item
                step="3"
                label="Privacy Settings"
            >
              <p class="form-group is-italic" v-if="isResearchConcierge">
                {{ $t('page.channelUpsert.form.conciergeSettings.privacyExplanation') }}</p>

              <div class="privacy-contribution-group" v-if="activeStep > 1">
                <!-- Privacy type-->
                <m-channel-privacy-settings-privacy-type
                    v-model="channel.privacy"
                    :recalculate-accordion="activeStep > 1"
                    class="form-group"
                    :disabled="isResearchConcierge"
                    :channel-slug="channelSlug"
                />

                <!-- Who can add-->
                <m-channel-privacy-settings-who-can-add
                    v-model="channel.allCanAdd"
                    :recalculate-accordion="activeStep > 1"
                    class="form-group"
                    :disabled="channel.privacy === privacyOptions.FriendsOnly || isResearchConcierge"
                />

                <!-- Allow secret share link-->
                <div class="form-group">
                  <label class="label">
                    <a-tooltip :tip="$t('page.channelUpsert.form.secretLinkTooltip')">
                      {{ $t('page.channelUpsert.form.secretLinkEnable') }}
                    </a-tooltip>
                  </label>
                  <b-field class="channel-contribution">
                    <b-switch v-model="channel.shareLinkEnabled" :disabled="isResearchConcierge">
                      {{ channel.shareLinkEnabled ? $t('dict.yes') : $t('dict.no') }}
                    </b-switch>
                  </b-field>
                </div>

                <!-- Chat -->
                <div class="form-group">
                  <label class="label">
                    <a-tooltip :tip="$t('page.channelUpsert.form.chatEnabledTooltip')">
                      {{ $t('page.channelUpsert.form.chatEnabled') }}
                    </a-tooltip>
                  </label>
                  <b-field class="channel-contribution">
                    <b-switch v-model="channel.chatEnabled" :disabled="isResearchConcierge">
                      {{ channel.chatEnabled ? $t('dict.yes') : $t('dict.no') }}
                    </b-switch>
                  </b-field>
                </div>
              </div>
            </b-step-item>
          </b-steps>
        </div>

        <!-- No images selected, we'll provide them modal -->
        <o-modal-wrapper
            :head-align-center="true"
            :show-modal="visible"
            class="modal-no-image"
            @close="visible = false"
        >
          <template #title>
            {{ $t('page.channelUpsert.form.modalTextFirstLine') }}
          </template>
          <template #body>
            <p v-html="$t('page.channelUpsert.form.modalTextSecondLine')"/>
            <p class="is-italic">
              ({{
                !previewChannelImage.length && !previewChannelHeroImage.length ?
                    $t('page.channelUpsert.form.noThumbAndHeroTitle') :
                    previewChannelImage.length ?
                        $t('page.channelUpsert.form.noThumbTitle') :
                        $t('page.channelUpsert.form.noHeroTitle')
              }})
            </p>
          </template>
          <template #footer>
            <b-button class="is-text" @click="visible = false">
              {{ $t('page.channelUpsert.form.cancelPublishText') }}
            </b-button>
            <a-button-submit :loading="loading">
              {{ $t('page.channelUpsert.form.confirmPublishText') }}
            </a-button-submit>
          </template>
        </o-modal-wrapper>
      </form>
    </ValidationObserver>

    <!-- Image modals -->
    <o-image-cropper-modal
        :visible="showCropperThumbModal"
        :file="newChannelImage"
        :loading="loading"
        v-on:clip-image="(image) => clipImage(image, channelImageTypes.thumbnail)"
        @close="clearChannelThumb"
    />

    <o-image-cropper-modal
        :visible="showCropperHeroModal"
        :file="newChannelHeroImage"
        :loading="loading"
        :message="$t('dict.sizeChannelHero')"
        :aspect-ratio="2.5"
        :is-round="false"
        v-on:clip-image="(image) => clipImage(image, channelImageTypes.hero)"
        @close="clearChannelHero"
    />
  </div>
</template>

<style lang="scss" scoped>
@import './OChannelUpsertForm';
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

// Types / interfaces / enums
import { ChannelType, EventType, Privacy, RepetitionSettings } from '@/api/ms-channel/services/interfaces/ChannelPost';
import { CHILD_EMIT } from '@/constants/childEmitEventNames';
import { ControlEnum } from '@/components/molecules/MWysiwygTipTapFixedMenuHeader.vue';
import { Datum as Currency } from '@/api/ms-static-api-data/services/interfaces/Currenciess';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { types as imageTypes } from '@/constants/imageTypes';
import {
  OChannelUpsertFormInterfacesChildOutput
} from '@/components/molecules/itemEntry/MItemEntryChannelSelector.vue';

// 1st party components
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import ACloseModalButton from '@/storybook-components/src/stories/atoms/buttons/ACloseModalButton.vue';
import AImage from '@/components/atoms/AImage.vue';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';
import MChannelConciergeSettingsQuantity from '@/components/molecules/forms/MChannelConciergeSettingsQuantity.vue';
import MChannelConciergeSettingsUrgency from '@/components/molecules/forms/MChannelConciergeSettingsUrgency.vue';
import MChannelConciergeSettingsConciergeType
  from '@/components/molecules/forms/MChannelConciergeSettingsConciergeType.vue';
import MChannelInvitations from '@/components/molecules/MChannelInvitations.vue';
import MChannelPrivacySettingsPrivacyType from '@/components/molecules/forms/MChannelPrivacySettingsPrivacyType.vue';
import MChannelPrivacySettingsWhoCanAdd from '@/components/molecules/forms/MChannelPrivacySettingsWhoCanAdd.vue';
import MChannelBasicsChannelType from '@/components/molecules/forms/MChannelBasicsChannelType.vue';
import MEventRepetitionOptions from '@/components/molecules/MEventRepetitionOptions.vue';
import MGeolocationAutocomplete from '@/components/molecules/forms/MGeolocationAutocomplete.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import MRadioCards from '@/storybook-components/src/stories/molecules/MRadioCards.vue';
import MUploadImagePreview, { MUploadImagePreviewImageItem } from '@/components/molecules/MUploadImagePreview.vue';
import MUploadOrGenerateImage, { UploadOrGenerateType } from '@/components/molecules/MUploadOrGenerateImage.vue';
import OImageCropperModal from '@/components/organisms/OImageCropperModal.vue';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';
import OWysiwygTiptap from '@/components/organisms/OWysiwygTiptap.vue';

// 3rd party components
import { CameraIcon } from 'vue-feather-icons';
import DatePicker from 'vue2-datepicker';

// Methods
import ChannelService from '@/api/ms-channel/services/ChannelService';
import CurrenciesService from '@/api/ms-static-api-data/services/CurrenciesService';
import convertBase64ToBlob from '@/utils/convertBase64ToBlob';
import fileReaderToBase64Preview from '@/utils/fileReaderToBase64Preview';
import getBase64ImageFromUrl from '@/utils/getBase64ImageFromUrl';
import seoInjector from '@/services/SeoInjector';
import { DatePickerLang, getLang } from '@/utils/vue2DatepickerLocale';
import { camelCaseToSentenceCase, titleCase } from 'common-utils/string';

// Vuex
import { ChannelsStore } from '@/store';
import { translation } from '@/plugins/i18n/Translation';

// Other
import MChannelCustomFields from '@/components/molecules/forms/MChannelCustomFields.vue';
import { FieldType } from '@/api/ms-channel/services/interfaces/Channel';
import ChannelHttpDataService, { EventSettings } from '@/services/ChannelHttpDataService';
import { ImageGenerationProvider } from '@/api/ms-channel/services/interfaces/ChannelSlugSlugImageGeneratedPutQuery';
import {
  AUploadImageOutput,
  clearImageOutput
} from '@/storybook-components/src/stories/atoms/buttons/AUploadImage.vue';

@Component({
  components: {
    MChannelCustomFields,
    AButtonSubmit,
    ACloseModalButton,
    AImage,
    ATooltip,
    MChannelBasicsChannelType,
    MChannelConciergeSettingsConciergeType,
    MChannelConciergeSettingsQuantity,
    MChannelConciergeSettingsUrgency,
    MChannelInvitations,
    MChannelPrivacySettingsPrivacyType,
    MChannelPrivacySettingsWhoCanAdd,
    MEventRepetitionOptions,
    MGeolocationAutocomplete,
    MInputWithValidation,
    MRadioCards,
    MUploadImagePreview,
    MUploadOrGenerateImage,
    OImageCropperModal,
    OModalWrapper,
    OWysiwygTiptap,
    CameraIcon,
    DatePicker,
    ValidationObserver,
  },
  filters: {
    titleCase: titleCase,
    camelCaseToSentenceCase: camelCaseToSentenceCase
  },
  methods: {
    camelCaseToSentenceCase: camelCaseToSentenceCase
  }
})
export default class OChannelUpsertFormStepped extends Vue {
  @Prop(String)
  channelSlug?: string;

  @Prop({ default: false })
  simpleForm!: boolean;

  activeStep = 0;

  datePickerLang: DatePickerLang['lang'] = 'en';
  eventDate: Date | null = null;
  eventDateOpenSync: boolean = false;
  eventTime: Date | null = null;
  eventTimeOpenSync: boolean = false;
  eventType: EventType = EventType.OneOff;
  eventRepetition: RepetitionSettings = {
    day: 0
  };
  editing: boolean = false;
  channelNameMinLength: number = 4;
  visible: boolean = false;
  privacyOptions = Privacy;
  channelTypeOptions = ChannelType;
  newChannelImage: AUploadImageOutput = clearImageOutput;
  newChannelHeroImage: AUploadImageOutput = clearImageOutput;
  previewChannelHeroImage: MUploadImagePreviewImageItem[] = [];
  previewChannelImage: MUploadImagePreviewImageItem[] = [];
  loading: boolean = false;
  imageSize = Size.The100X100;
  heroImageSize = Size.The950X;
  imageTypesAllowed = imageTypes;
  addAnimationTo: string[] = [];
  showCropperHeroModal: boolean = false;
  showCropperThumbModal: boolean = false;
  clearCropperHeroImage: number = 0;
  clearCropperThumbImage: number = 0;
  userAddedMeta: boolean = false;
  controlEnum = ControlEnum;

  uniqueCurrencies: Currency[] = [];

  channelImageUploadOrGenerate: UploadOrGenerateType | null = null;
  channelHeroUploadOrGenerate: UploadOrGenerateType | null = null;

  channelImageTypes = {
    thumbnail: 'thumbnail',
    hero: 'hero',
  };

  $refs!: {
    heroContainer: HTMLElement,
    btnUploadHero: HTMLElement,
    btnUploadThumb: HTMLElement
  };

  get channel () {
    return ChannelsStore.getChannelEditing as any;
  }

  get language () {
    return translation.currentLanguage;
  }

  get isResearchConcierge () {
    return this.channel.channelType === this.channelTypeOptions.ResearchConcierge;
  }

  getLocale () {
    this.datePickerLang = getLang(this.language);
  }

  formatDate () {
    return 'YYYY-MM-DD';
  }

  formatTime () {
    return 'HH:mm';
  }

  // Date must be in the future, tomorrow is the earliest date
  disabledDate (date) {
    // return false;
    return date < new Date();
  }

  // If no time is selected, insert 6am as the default
  insertDefaultTime () {
    if (!this.eventTime) {
      this.eventTime = new Date();
      this.eventTime.setHours(6);
      this.eventTime.setMinutes(0);
    }
  }

  // When user selects a minute, close the popup
  closeOnMinuteSelect (value, type) {
    if (type === 'minute') {
      this.eventTimeOpenSync = false;
    }
  }

  saveEventType (input) {
    this.eventType = input.eventType;
    this.eventRepetition = input.repetitions;
  }

  canSubmit () {
    let returnVal = true;
    const channelName = this.channel.name.trim();
    if (channelName === '' || channelName.length < 4) {
      this.highlightChannelName();
      returnVal = false;
    }
    if (this.channel.channelType === ChannelType.Event && (typeof this.eventDate === 'undefined' || this.eventDate === null)) {
      this.highlightEventDate();
      returnVal = false;
    }
    return returnVal;
  }

  async created () {
    this.getLocale();
    this.uniqueCurrencies = (await CurrenciesService.currenciesUniqueGet()).data;
  }

  mounted () {
    if (this.channelSlug) {
      this.editing = true;
      setTimeout(() => {
        // highlight relevant button for upload or generate
        this.channelImageUploadOrGenerate = this.channel.imagePathCredit ? UploadOrGenerateType.Generate : UploadOrGenerateType.Upload;
        this.channelHeroUploadOrGenerate = this.channel.imageHeroPathCredit ? UploadOrGenerateType.Generate : UploadOrGenerateType.Upload;
        // Initiate the date settings for event channels, but only if it's in the future, if an event has passed and is being edited a new date must be set
        if (this.channel.channelType === this.channelTypeOptions.Event && new Date(this.channel.event.eventDate) > new Date()) {
          this.eventDate = new Date(this.channel.event.eventDate);
          this.eventTime = new Date(this.channel.event.eventDate);
          this.eventType = this.channel.event.eventType;
        }
      }, 500);
    }
  }

  beforeDestroy () {
    seoInjector.clearPageTitle();
  }

  // eslint-disable-next-line max-lines-per-function
  async upsertChannel () {
    if (!this.canSubmit()) {
      return;
    }
    this.loading = true;
    const action = this.channelSlug ? 'updated' : 'created';

    const eventChannelSettings: EventSettings = {
      eventDate: this.eventDate,
      eventTime: this.eventTime,
      eventType: this.eventType,
      eventRepetition: this.eventRepetition
    };

    const savedChannel = this.channelSlug
      ? await ChannelService.channelSlugSlugPut(ChannelHttpDataService.PUT(this.channel, eventChannelSettings), { slug: this.channelSlug, })
      : await ChannelService.channelPost(ChannelHttpDataService.POST(this.channel, eventChannelSettings));

    if (this.previewChannelImage.length) {
      const block = this.previewChannelImage[0].preview.split(';');
      const contentType = block[0].split(':')[1];

      this.channelImageUploadOrGenerate === UploadOrGenerateType.Upload ?
        await ChannelService.channelSlugSlugImagePut(
          { image: convertBase64ToBlob(this.previewChannelImage[0].preview, contentType) },
          { slug: savedChannel.slug }
        ) :
        await ChannelService.channelSlugSlugImageGeneratedPut(
          { slug: savedChannel.slug },
          {
            imageGenerationUrl: this.previewChannelImage[0].generationUrl,
            imageGenerationCredits: this.previewChannelImage[0].credits,
            imageGenerationProvider: this.previewChannelImage[0].provider as unknown as ImageGenerationProvider
          }
        );
    }

    if (this.previewChannelHeroImage.length) {
      const block = this.previewChannelHeroImage[0].preview.split(';');
      const contentType = block[0].split(':')[1];

      this.channelHeroUploadOrGenerate === UploadOrGenerateType.Upload ?
        await ChannelService.channelSlugSlugImageHeroPut(
          { image: convertBase64ToBlob(this.previewChannelHeroImage[0].preview, contentType) },
          { slug: savedChannel.slug }
        ) :
        await ChannelService.channelSlugSlugImageHeroGeneratedPut(
          { slug: savedChannel.slug },
          {
            imageGenerationUrl: this.previewChannelHeroImage[0].generationUrl,
            imageGenerationCredits: this.previewChannelHeroImage[0].credits,
            imageGenerationProvider: this.previewChannelHeroImage[0].provider as unknown as ImageGenerationProvider
          }
        );
    }
    this.$emit(CHILD_EMIT, { channel: savedChannel, action } as OChannelUpsertFormInterfacesChildOutput);
    await ChannelsStore.fetchChannelsManagerOf();
    this.loading = false;
  }

  setModal () {
    if (!this.canSubmit()) {
      return;
    }
    this.visible = true;
    if (!this.previewChannelImage.length && !this.previewChannelHeroImage.length) {
      this.addAnimationTo = ['btnUploadHero', 'btnUploadThumb'];
    } else if (!this.previewChannelImage.length) {
      this.addAnimationTo = ['btnUploadThumb'];
    } else if (!this.previewChannelHeroImage.length) {
      this.addAnimationTo = ['btnUploadHero'];
    }
  }

  hideModal () {
    this.addAnimationTo.forEach(ref => this.$refs[ref].classList.remove('shake-animation'));
    this.visible = false;

    this.$refs.heroContainer.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });

    this.addAnimationTo.forEach(ref => this.$refs[ref].classList.add('shake-animation'));
  }

  clipImage (image: string, type: string): void {
    if (type === this.channelImageTypes.thumbnail) {
      this.previewChannelImage = [{
        preview: image
      }];
      this.showCropperThumbModal = false;
    } else {
      this.previewChannelHeroImage = [{
        preview: image
      }];
      this.showCropperHeroModal = false;
    }
  }

  async handleUploadThumbImage (file: Blob): Promise<void> {
    this.previewChannelImage = [{
      preview: await fileReaderToBase64Preview(file)
    }];
  }

  clearChannelThumb (): void {
    ++this.clearCropperThumbImage;
    this.showCropperThumbModal = false;
    this.newChannelImage = clearImageOutput;
    this.previewChannelImage = [];
    this.channelImageUploadOrGenerate = null;
  }

  clearChannelHero (): void {
    ++this.clearCropperHeroImage;
    this.showCropperHeroModal = false;
    this.newChannelHeroImage = clearImageOutput;
    this.previewChannelHeroImage = [];
    this.channelHeroUploadOrGenerate = null;
  }

  hasChannelImage (): boolean {
    return !this.newChannelImage.src && this.channel.imagePath;
  }

  hasChannelHeroImage (): boolean {
    return !this.newChannelHeroImage.src && this.channel.imageHeroPath;
  }

  uploadOrGenerateChannelImageHandle (input: { type: UploadOrGenerateType, image?: AUploadImageOutput }) {
    this.channelImageUploadOrGenerate = input.type;
    if (input.type === UploadOrGenerateType.Upload && input.image) {
      this.newChannelImage = input.image;
      this.showCropperThumbModal = true;
    } else {
      this.getStoreGeneratedImage(false).catch(console.error);
    }
  }

  async uploadOrGenerateCoverImageHandle (input: { type: UploadOrGenerateType, image?: AUploadImageOutput }) {
    console.log('New image', input);
    this.channelHeroUploadOrGenerate = input.type;
    if (input.type === UploadOrGenerateType.Upload && input.image) {
      this.newChannelHeroImage = input.image;
      this.showCropperHeroModal = true;
    } else {
      this.getStoreGeneratedImage(true).catch(console.error);
    }
  }

  async getStoreGeneratedImage (channelHero: boolean) {
    this.loading = true;
    const gen = await ChannelService.channelGenerateImagePexelsGet({ text: this.channel.name });
    getBase64ImageFromUrl(gen.url, true).then((image) => {
      const previewImage: MUploadImagePreviewImageItem = {
        preview: image,
        userFriendlyFilename: gen.credits ? gen.credits : 'preview',
        generationUrl: gen.url,
        credits: gen.credits,
        provider: gen.provider
      };

      if (channelHero) {
        this.newChannelHeroImage = image;
        this.previewChannelHeroImage = [previewImage];
      } else {
        this.newChannelImage = image;
        this.previewChannelImage = [previewImage];
      }

      this.loading = false;
    }).catch(() => {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Error generating image, try again',
        position: 'is-bottom',
        type: 'is-danger'
      });
    });
  }

  highlightChannelName () {
    this.$buefy.toast.open({
      duration: 5000,
      message: 'Please provide a channel name of at least 4 characters',
      position: 'is-bottom',
      type: 'is-danger',
      queue: true
    });
  }

  highlightEventDate () {
    this.$buefy.toast.open({
      duration: 5000,
      message: 'Please set an event date',
      position: 'is-bottom',
      type: 'is-danger',
      queue: true
    });
  }

  highlightMetaBudget () {
    this.buefyErrorToast('Please set your approximate budget');
  }

  highlightMetaRequirements () {
    this.buefyErrorToast('Please list some requirements for the concierge to use');
  }

  highlightCustomFieldsRequirements () {
    this.buefyErrorToast('Please ensure any custom fields being used have names and units added');
  }

  buefyErrorToast (msg: string) {
    this.$buefy.toast.open({
      duration: 5000,
      message: msg,
      position: 'is-bottom',
      type: 'is-danger',
      queue: true
    });
  }

  nextStep () {
    switch (this.activeStep) {
      case 0:
        this.validateStepBasics();
        break;

      case 1:
        this.validateStepDetails();
        break;

      case 2:
        this.validateStepPrivacy();
        break;
    }
  }

  validateStepBasics () {
    let err = false;
    if (!this.channel.name || !this.channel.name.length || this.channel.name.length < 4) {
      err = true;
      this.highlightChannelName();
    }
    if (!err) {
      ++this.activeStep;
      this.$emit('create-channel-header-details', {
        channelName: this.channel.name,
        channelType: this.channel.channelType
      });
    }
  }

  validateStepDetails () {
    let err = false;
    if (this.channel.channelType === this.channelTypeOptions.Event) {
      if (!this.eventDate) {
        err = true;
        this.highlightEventDate();
      }
    } else if (this.isResearchConcierge) {
      if (!this.channel.channelMeta.budget || !this.channel.channelMeta.budget.currency || !this.channel.channelMeta.budget.value) {
        err = true;
        this.highlightMetaBudget();
      }
      if (!this.channel.channelMeta.requirements) {
        err = true;
        this.highlightMetaRequirements();
      }
      if (!err) {
        // set the default privacy options
        this.channel.privacy = Privacy.Private;
        this.channel.allCanAdd = false;
      }
    }
    // if custom fields added, ensure they are filled out
    if (this.channel.customFields && this.channel.customFields.length) {
      for (let i = 0; i < this.channel.customFields.length; i++) {
        if (this.channel.customFields[i].label === '' ||
          typeof this.channel.customFields[i].fieldType === 'undefined' ||
          this.channel.customFields[i].unit === '' ||
          (this.channel.customFields[i].fieldType === FieldType.Rating && typeof this.channel.customFields[i].modifier === 'undefined')
        ) {
          err = true;
          this.highlightCustomFieldsRequirements();
          break;
        }
      }
    }
    if (!err) {
      ++this.activeStep;
    }
  }

  validateStepPrivacy () {

  }

  @Watch('channel', { deep: true, immediate: true })
  channelHandle (newVal) {
    ChannelsStore.SET_CHANNEL_EDITING({ channel: newVal });
    let titleStr = this.channelSlug ? 'Edit Channel' : 'Create Channel';
    if (this.channel.name.length > 0) {
      titleStr += ' - ' + this.channel.name;
    }
    seoInjector.setPageTitle(titleStr);
  }

  @Watch('activeStep')
  emitStep () {
    this.$emit('active-step', this.activeStep);
  }
}
</script>
