<template>
  <b-button
      :class="classes"
      :size="size"
      :type="type"
      class="btn-create-channel"
      @click="goToAddChannel"
  >
    <plus-icon size="1.5x"/>
    {{ $t('dict.newChannel') }}
  </b-button>
</template>

<style lang="scss" scoped>
::v-deep {
  span {
    display: flex;
    align-items: center;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { User } from '@/api/ms-authentication/services/interfaces/Login';
import { AuthenticationStore } from '@/store';
import { PlusIcon } from 'vue-feather-icons';

@Component({
  components: {
    PlusIcon
  },
})
export default class AGoToAddChannel extends Vue {
  @Prop({ default: 'is-primary' })
  type!: string;

  @Prop({ default: 'is-medium' })
  size!: string;

  @Prop({ default: '' })
  classes!: string;

  get currentUser (): User {
    return AuthenticationStore.currentUser;
  }

  goToAddChannel () {
    this.$emit('click');
    this.$router.push({
      name: RouteNames.ROUTE_CHANNEL_CREATE, params: {
        username: this.currentUser.username,
      }
    });
  }
}
</script>
