<template>
  <span :class="classes">
    <a-tooltip v-if="tooltipTitle" :tip="tooltipTitle" :discreet="true">
      {{ formattedDate }}
    </a-tooltip>
    <span v-else>{{ formattedDate }}</span>
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import formatDateDayjs from '@/utils/formatDateDayjs';
import { capitaliseFirstLetterOfString } from 'common-utils/string';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';
@Component({
  components: { ATooltip }
})
export default class ASpanFormatDateDayJs extends Vue {
  @Prop()
  date!: Date;
  @Prop()
  locale!: string;
  @Prop({default: ''})
  classes!: string;
  @Prop({default: ''})
  tooltipTitle!: string;
  @Prop({default: true})
  capitalise!: boolean;

  formattedDate: string = '';

  async mounted () {
    const format = await formatDateDayjs(this.date, this.locale);
    this.formattedDate = this.capitalise ? capitaliseFirstLetterOfString(format) : format;
  }
}
</script>