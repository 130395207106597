<template>
  <div class="MNoItemsEmptySearch has-text-centered">
    <b-steps
        v-model="activeStep"
        :animated="true"
        :has-navigation="false"
        label-position="bottom">
      <b-step-item step="0">
        <div class="card p-4">
          <p class="font-body-l">
            Hey there 👋
          </p>
          <p> You’re new (yay!), so there’s nothing here yet.</p>
          <p> Not to worry though...</p>
          <p>Liffery is yours to fill whenever you are ready.</p>
          <p>
            <b-button class="is-outlined" type="is-primary" @click="++activeStep">Next</b-button>
          </p>
        </div>
      </b-step-item>
      <b-step-item step="1">
        <div class="card p-4">
          <p class="font-body-l">
            What now?
          </p>
          <p>
            Unless you already have an <b>Item</b> you want to save, start by creating a <b>Channel</b>.
          </p>
          <p>
            <a-go-to-add-channel/>
          </p>

          <p>Already have an Item to add?</p>
          <p>
            <a-add-new-item :is-outlined="true"/>
          </p>
        </div>
      </b-step-item>
    </b-steps>
  </div>
</template>

<style lang="scss" scoped>
.MNoItemsEmptySearch {
  max-width: 400px;
  margin: auto;
  text-align: left;

  ::v-deep .step-items {
    display: none;
  }

  p {
    margin-bottom: 1.5rem;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AAddNewItem from '@/components/atoms/buttons/AAddNewItem.vue';
import AGoToAddChannel from '@/components/atoms/buttons/AGoToAddChannel.vue';

@Component({
  components: { AGoToAddChannel, AAddNewItem }
})
export default class MNoItemsEmptySearch extends Vue {
  activeStep = 0;
}
</script>
