<template>
  <div class="AFetchFromLocationButton">
    <b-tooltip :active="!loading" :label="tooltipLabel"
               position="is-bottom"
               square>
      <a-button-submit
          type="is-primary"
          :class="buttonClasses"
          :disabled="disabled"
          :size="size"
          :loading="loading"
          @click="$emit('click')"
          native-type="submit"
      >{{ $t('item.mapView.fetchFromLocationButton.text') }}
      </a-button-submit>
    </b-tooltip>

  </div>
</template>

<style scoped lang="scss">
  .AFetchFromLocationButton {
  }
</style>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';

  @Component({
    components: {
      AButtonSubmit
    }
  })
  export default class AFetchFromLocationButton extends Vue {
    @Prop({ default: true })
    disabled!: boolean;

    @Prop({ default: '' })
    size!: string;

    @Prop({ default: false })
    loading!: boolean;

    @Prop({ default: 'Fetch' })
    buttonText!: string;

    @Prop()
    buttonClasses!: string;

    @Prop()
    moreResultsToLoad!: boolean;

    @Prop()
    tooltipLabel!: string;
  }
</script>