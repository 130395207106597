<template>
  <svg viewBox="0 0 42 42" :style="style" fill="none" xmlns="http://www.w3.org/2000/svg" @mouseover="mouseover = true"
       @mouseout="mouseover = false">

    <circle cx="21.0415" cy="21.3016" r="19.806" :fill="fillColour" :stroke="circleStrokeColour" stroke-width="1.5"/>

    <path
        d="M18.165 26.6594V30.6594C18.165 31.4551 18.481 32.2181 19.0437 32.7807C19.6063 33.3433 20.3693 33.6594 21.165 33.6594L25.165 24.6594V13.6594H13.885C13.4026 13.654 12.9346 13.823 12.5671 14.1354C12.1995 14.4478 11.9573 14.8825 11.885 15.3594L10.505 24.3594C10.4615 24.6461 10.4808 24.9387 10.5616 25.2172C10.6425 25.4956 10.7829 25.7531 10.9731 25.9719C11.1634 26.1907 11.3989 26.3655 11.6634 26.4842C11.9279 26.6029 12.2151 26.6627 12.505 26.6594H18.165ZM25.165 13.6594H27.835C28.4009 13.6494 28.9509 13.8476 29.3804 14.2162C29.8099 14.5849 30.0891 15.0985 30.165 15.6594V22.6594C30.0891 23.2204 29.8099 23.7339 29.3804 24.1026C28.9509 24.4713 28.4009 24.6694 27.835 24.6594H25.165"
        :fill="fillColour"/>

    <path
        d="M25.165 24.6594L21.165 33.6594C20.3693 33.6594 19.6063 33.3433 19.0437 32.7807C18.481 32.2181 18.165 31.4551 18.165 30.6594V26.6594H12.505C12.2151 26.6627 11.9279 26.6029 11.6634 26.4842C11.3989 26.3655 11.1634 26.1907 10.9731 25.9719C10.7829 25.7531 10.6425 25.4956 10.5616 25.2172C10.4808 24.9387 10.4615 24.6461 10.505 24.3594L11.885 15.3594C11.9573 14.8825 12.1995 14.4478 12.5671 14.1354C12.9346 13.823 13.4026 13.654 13.885 13.6594H25.165M25.165 24.6594V13.6594M25.165 24.6594H27.835C28.4009 24.6694 28.9509 24.4713 29.3804 24.1026C29.8099 23.7339 30.0891 23.2204 30.165 22.6594V15.6594C30.0891 15.0985 29.8099 14.5849 29.3804 14.2162C28.9509 13.8476 28.4009 13.6494 27.835 13.6594H25.165"
        :stroke="strokeColour" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>

  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ThumbsDownIcon } from 'vue-feather-icons';

@Component({
  components: { ThumbsDownIcon }
})
export default class AChannelSwipeItemLeft extends Vue {
  @Prop({ default: '1.5x' })
  size!: string;
  @Prop({ required: false, default: false })
  fill!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;

  mouseover = false;

  colours: {
    disabled: string,
    swipeLeft: string,
    white: string
  } = {
    disabled: 'var(--grey-mid-color)',
    swipeLeft: 'rgb(var(--channel-swipe-left))',
    white: 'var(--white-color)'
  };

  get style () {
    const size = parseFloat(this.size);
    return `width: ${size}rem; height: ${size}rem;`;
  }

  get fillColour () {
    if (this.disabled) {
      return this.colours.white;
    }

    return (this.mouseover || this.fill) ? this.colours.swipeLeft : this.colours.white;
  }

  get strokeColour () {
    if (this.disabled) {
      return this.colours.disabled;
    }

    return (!this.mouseover && !this.fill) ? this.colours.swipeLeft : this.colours.white;
  }

  get circleStrokeColour () {
    return this.disabled ? this.colours.disabled : this.colours.swipeLeft;
  }
}
</script>