<template>
  <footer class="MFooterGeneral footer">
    <div class="content">
      <div class="logo">
        <span v-on:click="scrollToTop">
          Liffery
        </span>
      </div>

      <div :class="{'is-justify-content-flex-end': footerInMenu}" class="links">
        <template v-if="footerInMenu">
          <p class="footer-menu is-flex is-align-items-center" @click="open = true">
            <span class="mr-2">Navigation</span>
            <menu-icon/>
          </p>
          <b-sidebar
              v-model="open"
              :fullheight="true"
              :fullwidth="false"
              :overlay="false"
              :right="true"
              class="zindex-100 is-footer-color"
              type="is-light"
          >
            <m-footer-general-sidebar :footer-links="footerLinks" v-on:close="closeSidebar"/>
          </b-sidebar>
        </template>
        <template v-else>
          <template v-for="(link, index) in footerLinks">
            <a-link v-if="link.link"
                    :link="link.link"
                    :key="index"
            >{{ $t(link.textKey) }}
            </a-link>

            <router-link
                v-else
                :key="index"
                :to="{ name: link.name }">
              <span
                  :class="{'link-active font-bold': link.activeAlsoWith.concat([link.name]).includes($route.name)}">
                {{ $t(link.textKey) }}
              </span>
            </router-link>
          </template>
          <m-language-switcher/>
        </template>

      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  z-index: 25;
  width: 100%;
  padding: .6rem 7rem;
  border-top: 1px solid #a8a7a7;
  background-color: var(--bg-color);
  transition: all .4s;

  @media screen and (max-width: 1400px) {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  @media screen and (max-width: 800px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  ::v-deep a {
    color: rgb(74, 74, 74) !important;
  }

  ::v-deep .b-sidebar {
    background-color: red !important;
  }
}

.scrolling-down {
  .footer {
    bottom: -50px;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link-active {
  text-decoration: underline;
}

.logo {
  margin-right: 10px;
  cursor: pointer;
}

.links {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  *:not(:first-child) {
    margin-left: 4rem;
  }
}

.footer-menu {
  text-align: right;
}

@media screen and (max-width: 1250px) {
  .logo {
    display: none;
  }

  .links {
    width: 100%;

    *:not(:first-child) {
      margin-left: unset;
    }
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import { RouteNames } from '@/router/RouteNames';
import scrollWindowToElement from '@/utils/scrollWindowToElement';
import MFooterGeneralSidebar from '@/components/molecules/MFooterGeneralSidebar.vue';
import { MenuIcon } from 'vue-feather-icons';
import ALink from '@/components/atoms/link/ALink.vue';

@Component({
  components: {
    ALink,
    MFooterGeneralSidebar,
    MLanguageSwitcher,
    MenuIcon
  }
})
export default class MFooterGeneral extends Vue {
  routeName = RouteNames;
  open = false;
  footerInMenu = false;

  footerLinks: {
    name?: RouteNames,
    link?: string,
    textKey: string,
    activeAlsoWith: RouteNames[]
  }[] = [{
    name: RouteNames.ROUTE_INFO_WHAT_IS,
    activeAlsoWith: [RouteNames.ROUTE_DASHBOARD],
    textKey: 'footer.forPeople'
  }, {
    name: RouteNames.ROUTE_INFO_BUSINESS,
    activeAlsoWith: [],
    textKey: 'footer.forBusiness'
  }, {
    name: RouteNames.ROUTE_INFO_CONTACT,
    activeAlsoWith: [],
    textKey: 'footer.contact'
  }, {
    link: 'https://blog.liffery.com',
    activeAlsoWith: [],
    textKey: 'footer.blog'
  }, {
    name: RouteNames.ROUTE_INFO_CAREERS,
    activeAlsoWith: [],
    textKey: 'footer.careers'
  }, {
    link: 'https://help.liffery.com',
    activeAlsoWith: [],
    textKey: 'footer.help'
  }];

  created () {
    this.hideShow();
    this.eventsBind();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  closeSidebar () {
    this.open = false;
  }

  scrollToTop () {
    scrollWindowToElement();
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.hideShow);
  }

  eventsBind () {
    window.addEventListener('resize', this.hideShow);
  }

  hideShow () {
    this.footerInMenu = window.innerWidth <= 640;
  }
}
</script>
