<template>
  <div v-if="isValid" class="MYouTube">
    <iframe
        :src="getIframeSrc"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="video"
        frameborder="0"
    />
  </div>
</template>

<style lang="scss" scoped>
.MYouTube {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 56.25%;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import getYoutubeId from '@/utils/getYoutubeId';

@Component
export default class MYouTube extends Vue {
  @Prop({ required: false })
  url!: string;

  @Prop({ required: false })
  vidId!: string;

  @Prop({ default: 0 })
  autoPlay!: number;

  @Prop({ default: 0 })
  captions!: number;

  @Prop({ default: 0 })
  mute!: number;

  get isValid () {
    return (this.vidId || getYoutubeId(this.url));
  }

  get getIframeSrc (): string | undefined {
    return `https://www.youtube.com/embed/${(this.vidId || getYoutubeId(this.url))}?autoplay=${this.autoPlay}`;
  }
}
</script>
