<template>
  <div class="dot-flashing" :title="title"></div>
</template>

<style scoped lang="scss">
/**
 * ==============================================
 * Dot Flashing: https://codepen.io/nzbin/pen/GGrXbp
 * ==============================================
 */
.dot-flashing {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 3px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -12px;
  width: 7px;
  height: 7px;
  border-radius: 3px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 12px;
  width: 7px;
  height: 7px;
  border-radius: 3px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--primary-color);
  }
  50%, 100% {
    background-color: $primaryRGBA15;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class A3Dots extends Vue {
  @Prop({ required: true })
  title!: '';
}
</script>
