<template>
  <div class="MEmailInvitationForm">
    <p class="mb-5">{{ invitationOpener }}</p>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
          :model="inviteForm"
          class="login-form"
          ref="form"
          @submit.prevent="handleSubmit(submitInviteForm)"
          novalidate
      >
        <m-input-with-validation
            :placeholder="$t('dict.email')"
            :disabled="emailDisabled"
            auto-complete="off"
            name="email"
            type="email"
            v-model="inviteForm.email"
            rules="required|email"
            group-field-class="form-group"
        />

        <div class="name-fields-wrapper">
          <m-input-with-validation
              :placeholder="$t('dict.firstName')"
              auto-complete="off"
              name="firstName"
              type="text"
              v-model="inviteForm.firstName"
              rules="required"
              group-field-class="form-group"
          />
          <m-input-with-validation
              :placeholder="$t('dict.lastName')"
              auto-complete="off"
              name="lastName"
              type="text"
              v-model="inviteForm.lastName"
              rules="required"
              group-field-class="form-group"
          />
        </div>

        <m-input-with-validation
            :placeholder="$t('dict.message')"
            auto-complete="off"
            name="message"
            type="textarea"
            textarea-rows="3"
            v-model="inviteForm.message"
            rules="required"
            group-field-class="form-group"
            :allowEmoji="true"
        />

        <div class="has-text-centered mt-5">
          <b-button
              type="is-secondary"
              class="mr-4"
              :loading="loading"
              @click="$emit('close')"
          >
            {{ $t('dict.close') }}
          </b-button>
          <a-button-submit :loading="loading">
            {{ $t('dict.send') }}
          </a-button-submit>
        </div>
      </form>
    </ValidationObserver>

    <template v-if="includeCodeToCopy">
      <p class="divider">- {{ $t('dict.or') }} -</p>
      <p>{{ $t('page.invitations.sendingAnInviteOrShare') }}</p>
      <p class="mt-1">
        <i>https://www.liffery.com?inviteCode={{ inviteForm.code }}</i>
      </p>
    </template>
  </div>
</template>

<style scoped lang="scss">
.MEmailInvitationForm {
  @media screen and (max-width: 500px) {
    position: relative;
    width: 100%;

    .name-fields-wrapper {
      flex-direction: column;
    }
  }

  .name-fields-wrapper {
    display: flex;

    ::v-deep > span {
      width: 100%;

      &:first-child {
        margin-right: 1rem;
      }
    }
  }

  .divider {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    text-align: center;
  }
}

::v-deep .form-group {
  margin: .5rem 0;

  input:disabled {
    border: #DDD 1px solid;
    background-color: #EFEFEF;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Invitation, UserInvitationsAssignPost } from '@/api/ms-authentication/services/interfaces';
import UserService from '@/api/ms-authentication/services/UserService';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { ValidationObserver } from 'vee-validate';
import { InvitationHook } from '@/api/ms-authentication/services/interfaces/UserInvitationsAssignPost';
import EventBus, { EventBusEvents } from '@/EventBus';
import { Type as HookType } from '@/api/ms-authentication/services/interfaces/Invitation';

@Component({
  components: {
    AButtonSubmit,
    MInputWithValidation,
    ValidationObserver
  }
})
export default class MEmailInvitationForm extends Vue {
  @Prop({ required: false, default: '' })
  code!: string;
  @Prop({ required: false, default: '' })
  email!: string;
  @Prop({ required: false, default: false })
  emailDisabled!: boolean;
  @Prop({ required: false, default: '' })
  firstName!: string;
  @Prop({ required: false, default: '' })
  lastName!: string;
  @Prop({ required: false, default: '' })
  message!: string;
  @Prop({ required: false, default: false })
  messageDisabled!: boolean;
  @Prop({ required: false, default: () => [] })
  invitationHooks!: InvitationHook[];
  @Prop({ required: false, default: true })
  includeCodeToCopy!: boolean;

  loading: boolean = false;

  inviteForm: UserInvitationsAssignPost = {
    lastName: '',
    firstName: '',
    email: '',
    code: '',
    message: '',
    invitationHooks: []
  };

  get invitationOpener (): string {
    let opener = this.$t('page.invitations.sendingAnInviteOpener') as string;
    if (this.invitationHooks && this.invitationHooks.length > 0) {
      switch (this.invitationHooks[0].type) {
        case HookType.ChannelAccept:
          if (this.invitationHooks[0].payload) {
            const channelNameIndex = this.invitationHooks[0].payload.findIndex((el) => el.key === 'channelName');
            const channelName = this.invitationHooks[0].payload[channelNameIndex].value;
            opener = this.$t('page.invitations.sendingAnInviteToChannelOpener', { channelName }) as string;
          }
          break;
      }
    }
    return opener;
  }

  mounted () {
    this.inviteForm = {
      lastName: this.lastName,
      firstName: this.firstName,
      email: this.email,
      code: this.code,
      message: this.message, //this.$t('page.invitations.formMessage').toString()
      invitationHooks: this.invitationHooks
    };
  }

  async submitInviteForm () {
    this.loading = true;
    // send the form and then emit the result via the event bus to be picked up by whatever triggered this form
    const sent: Invitation = await UserService.userInvitationsAssignPost(this.inviteForm);
    EventBus.$emit(EventBusEvents.INVITE_BY_EMAIL_SENT, sent);
    this.$emit('close');
    this.loading = false;
  }
}
</script>