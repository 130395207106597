<template>
  <div class="MNoItemsEmptySearch has-text-centered">
    <b-steps
        v-model="activeStep"
        :animated="true"
        :has-navigation="false"
        label-position="bottom">
      <b-step-item step="0">
        <div class="card p-4">
          <p class="font-body-l">
            Explore
          </p>
          <p>
            {{ $t('containers.items.nonFound.exploreNoQs.one') }}
          </p>
          <p>
            {{ $t('containers.items.nonFound.exploreNoQs.two') }}
          </p>
          <p>
            <b-button class="is-outlined" type="is-primary" @click="focusSearch">{{$t('dict.start')}}</b-button>
          </p>
        </div>
      </b-step-item>
    </b-steps>
  </div>
</template>

<style lang="scss" scoped>
.MNoItemsEmptySearch {
  max-width: 400px;
  margin: auto;
  text-align: left;

  ::v-deep .step-items {
    display: none;
  }

  p {
    margin-bottom: 1.5rem;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AAddNewItem from '@/components/atoms/buttons/AAddNewItem.vue';
import AGoToAddChannel from '@/components/atoms/buttons/AGoToAddChannel.vue';

@Component({
  components: { AGoToAddChannel, AAddNewItem }
})
export default class MNoItemsEmptyExploreSearch extends Vue {
  activeStep = 0;

  focusSearch () {
    const input = document.querySelector('.search-wrapper input') as any;
    if (input) {
      input.focus();
    }
  }
}
</script>
