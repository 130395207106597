<template>
  <div class="MSpotify">
    <iframe
        :height="height"
        :src="url"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowtransparency="true"
        frameBorder="0"
        width="100%"/>
  </div>
</template>

<style lang="scss" scoped>
.MSpotify {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { calculateImageHeight } from '@/utils/calculateItemCardHeight';

@Component
export default class MYouTube extends Vue {
  @Prop({ required: false })
  url!: string;

  @Prop()
  imagePreviewUrl?: string;

  @Prop()
  cardWidth?: number;

  get height (): number {
    if (this.cardWidth && this.imagePreviewUrl) {
      return calculateImageHeight(this.cardWidth, this.imagePreviewUrl);
    } else {
      return 450;
    }
  }
}
</script>
