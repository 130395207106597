<template>
  <div :class="{ios: isIos}" class="MBreadcrumbs">

    <template v-if="breadcrumbs.length > 0">
      <span class="breadcrumb-item ios-back is-clickable" @click="browserBack">
        <chevron-left-icon size="2x"/>
        <span>Back</span>
      </span>

      <span class="breadcrumb-item ios-back-spacer"></span>
    </template>

    <template v-else>
      <span v-for="(breadcrumb, index) in breadcrumbs" :key="index" class="breadcrumb-item">
        <router-link
            v-if="breadcrumb.name"
            :to="{
              name: breadcrumb.name,
              params: breadcrumb.params
            }"
            class="breadcrumb-item-link"
        >
          <home-icon v-if="breadcrumb.name === homeKey" :size="isIos ? '1.5x' : '1.2x'" class="home-icon"></home-icon>
          <span v-else :class="{ 'active': index === breadcrumbs.length - 1 }" class="as-link">
            {{ getName(breadcrumb.name) }}
          </span>
          <chevron-right-icon v-if="index !== breadcrumbs.length - 1" class="chevron-icon"
                              size="1x"></chevron-right-icon>
        </router-link>
      </span>
    </template>

  </div>
</template>

<style lang="scss" scoped>
.ios-back {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-left: -24px;
  padding-right: 1rem;
  padding-left: 1rem;
}

.ios-back-spacer {
  padding-right: 1rem;
}

.MBreadcrumbs {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  margin-top: -4rem;
  margin-bottom: 3rem;
  margin-left: -2.2rem;

  @media screen and (max-width: 800px) {
    margin-left: -.5rem;
  }

  @media screen and (max-width: 600px) {
    margin-left: .2rem;
  }

  &.ios {
    font-size: larger !important;
  }
}

.TChannelCreate,
.TChannelEdit,
.main-container.full-width {
  .MBreadcrumbs {
    margin-bottom: -3rem;
    margin-left: -.2rem;
    padding-top: 6rem;
    padding-left: calc(var(--header-v-padding) + 1rem);
  }
}

.breadcrumb-item-link {
  display: flex;
  align-items: center;
  color: rgba(61, 61, 61, .8);
}

.active {
  border-bottom: 1px solid currentColor;
}

.chevron-icon {
  margin-right: 3px;
  margin-left: 3px;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { NavigationStore } from '@/store';

import { Breadcrumb } from '@/services/BreadcrumbMaker';
import { ChevronLeftIcon, ChevronRightIcon, HomeIcon } from 'vue-feather-icons';
import isIosApp from '@/utils/isIosApp';

@Component({
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    HomeIcon
  }
})
export default class MBreadcrumbs extends Vue {
  homeKey: string = 'dashboard';
  routeName!: string;

  isIos = isIosApp();

  get breadcrumbs (): Breadcrumb[] {
    return NavigationStore.getBreadCrumbs;
  }

  created () {
    NavigationStore.recalculateBreadcrumbs(this.$route);
    this.routeName = this.$route.name || '';
  }

  getName (name): string {
    return this.$t(`breadcrumbs['${name}']`).toString();
  }

  browserBack () {
    this.$router.go(-1);
  }
}
</script>