<template>
  <router-link :to="{ name }" class="menu-profile-item">
    <settings-icon v-if="hasIcon" class="header-menuitem-icon" size="1x"/>
    {{ $t('dict.editProfile') }}
  </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { SettingsIcon } from 'vue-feather-icons';

@Component({
  components: {
    SettingsIcon
  }
})
export default class ARouterLinkSettings extends Vue {
  @Prop(Boolean)
  hasIcon!: boolean;

  name = RouteNames.ROUTE_SETTINGS_GENERAL;
}
</script>
