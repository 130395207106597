<template>
  <div class="OTabsWrapper">
    <span class="line"></span>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .tabs {
  width: fit-content;
  margin: 0 auto;
}

.line {
  display: inline-block;
  height: 1px;
  margin-top: -1rem;
  margin-bottom: 1rem;
  background-color: currentColor;
  opacity: 0;
  transition: all .3s, opacity .2s 250ms;
  transform-origin: top left;
}
</style>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
export default class OTabsWrapper extends Vue {
  @Prop({ required: true })
  selectedTab!: string;

  @Prop({ required: true })
  yPosition!: string;

  setLineAttributes (): void {
    setTimeout(() => {
      const tabsContainer: any = document.querySelector('.b-tabs');
      const activeItem: any = document.querySelector(`.b-tabs .${this.selectedTab}`);
      const line: any = document.querySelector('.line');

      const tabContainerRect = tabsContainer.getBoundingClientRect();
      const activeItemRect = activeItem.getBoundingClientRect();
      const activeTabStarting = activeItemRect.left - tabContainerRect.left + 12;

      line.style.width = (activeItem.clientWidth - 22) + 'px';
      line.style.transform = `translate(${activeTabStarting + 'px'}, ${this.yPosition})`;
      line.style.opacity = 1;
    }, 0);
  }

  mounted () {
    this.setLineAttributes();
    window.addEventListener('resize', this.setLineAttributes);
  }

  destroyed () {
    window.removeEventListener('resize', this.setLineAttributes);
  }

  @Watch('selectedTab')
  selectedTabValueHandle () {
    this.setLineAttributes();
  }
}
</script>
