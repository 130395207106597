<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
          ref="form"
          class="login-form"
          @submit.prevent="handleSubmit(onSubmit)"
      >
        <m-input-with-validation
            v-model="form.email"
            :placeholder="$t('dict.email')"
            groupFieldClass="form-group"
            name="email"
            novalidate
            rules="required|email"
            type="email"
        ></m-input-with-validation>

        <div>
          <b-button :loading="loading" native-type="submit" type="is-primary">
            {{ $t('dict.requestPasswordReset') }}
          </b-button>

          <b-button class="is-text is-pulled-right" @click.prevent="setShowLogin">{{ $t('dict.login') }}</b-button>
        </div>

        <a-message
            v-if="success"
            type="is-success"
            v-on:child-output="handleCloseMessage('success')"
        >
          {{ success }}
        </a-message>
        <a-message
            v-if="error"
            type="is-danger"
            v-on:child-output="handleCloseMessage('error')"
        >
          {{ error }}
        </a-message>
      </form>
    </ValidationObserver>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { PasswordResetRequestPost } from '@/api/ms-authentication/services/interfaces';
import PasswordService from '@/api/ms-authentication/services/PasswordService';
import { ValidationObserver } from 'vee-validate';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import AMessage from '@/components/atoms/AMessage.vue';
import { FromOrigin } from '@/api/ms-authentication/services/interfaces/PasswordResetRequestPost';

@Component({
  components: {
    MInputWithValidation,
    ValidationObserver,
    AMessage
  },
})
export default class OPasswordForgotRequestForm extends Vue {
  private loading: boolean = false;
  private error: string = '';
  private success: string = '';
  private form: PasswordResetRequestPost = {
    email: '',
    fromOrigin: FromOrigin.UserApp
  };

  handleCloseMessage (type: string) {
    this[type] = '';
  }

  setShowLogin () {
    this.$emit('child-output', false);
  }

  async onSubmit () {
    this.loading = true;
    try {
      await PasswordService.passwordResetRequestPost(this.form);
      this.success = String(this.$i18n.tc('success.resetLinkSent'));
    } catch (e) {
      this.error = String(this.$i18n.tc('errors.somethingWentWrong'));
    }
    this.loading = false;
  }
}
</script>
