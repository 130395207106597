<template>
  <div>
    <label class="label">
      {{ $t('page.channelUpsert.form.conciergeSettings.urgency') }}
    </label>
    <div class="content">
      <m-radio-cards
          v-model="value"
          :value="urgencyTypes.ThreeDays"
          :title="$t('page.channelUpsert.form.conciergeSettings.urgencyOptions.' + urgencyTypes.ThreeDays)"
          :disabled="disabled"
          :recalculate-accordion="recalculateAccordion"
      />
      <m-radio-cards
          v-model="value"
          :value="urgencyTypes.OneWeek"
          :title="$t('page.channelUpsert.form.conciergeSettings.urgencyOptions.' + urgencyTypes.OneWeek)"
          :disabled="disabled"
          :recalculate-accordion="recalculateAccordion"
      />
      <m-radio-cards
          v-model="value"
          :value="urgencyTypes.MoreThanOneWeek"
          :title="$t('page.channelUpsert.form.conciergeSettings.urgencyOptions.' + urgencyTypes.MoreThanOneWeek)"
          :disabled="disabled"
          :recalculate-accordion="recalculateAccordion"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { Urgency } from '@/api/ms-channel/services/interfaces/Channel';
import MRadioCards from '@/storybook-components/src/stories/molecules/MRadioCards.vue';
import { camelCaseToSentenceCase } from 'common-utils/string';

@Component({
  components: { MRadioCards },
  methods: { camelCaseToSentenceCase }
})
export default class MChannelConciergeSettingsUrgency extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: Urgency;
  @Prop({required: false, default: false})
  recalculateAccordion!: boolean;
  @Prop({required: false, default: false})
  disabled!: boolean;

  value: Urgency = Urgency.OneWeek;
  urgencyTypes = Urgency;

  created () {
    this.value = this.activeValue;
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
</script>