<template>
  <router-link :to="{ name }" class="menu-profile-item">
    <user-plus-icon size="1x" class="header-menuitem-icon"></user-plus-icon>
    <span>
      {{ $t('dict.invitationCodes') }}
    </span>
  </router-link>
</template>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { RouteNames } from '@/router/RouteNames';
  import { UserPlusIcon } from 'vue-feather-icons';

  @Component({
    components: {
      UserPlusIcon
    }
  })
  export default class ARouterLinkInvitations extends Vue {
    @Prop(Boolean)
    hasIcon!: boolean;

    name = RouteNames.ROUTE_SETTINGS_INVITATIONS;
  }
</script>
