<template>
  <div class="OCaptchaForm">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
          ref="form"
          novalidate
          @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-loading
            :active.sync="loadingSVG"
            :can-cancel="false"
            :is-full-page="false"
        ></b-loading>

        <p><strong>{{ $t('dict.accountCaptchaLocked') }}</strong></p>
        <div v-if="!loadingSVG" class="wrapper">
          <div class="captchaSVG" v-html="captchaSVG"></div>

          <m-input-with-validation
              v-model="form.answer"
              :placeholder="$t('dict.answer')"
              class="input-captcha"
              groupFieldClass="form-group"
              name="answer"
              rules="required|numeric"
              type="number"
          />
        </div>

        <b-button v-if="!loadingSVG" :loading="loading" class="form-group" native-type="submit" type="is-primary">
          {{ $t('dict.unlock') }}
        </b-button>

        <a-form-server-error v-if="errors" :errors="errors"
                             v-on:child-output="handleMessageClosed"></a-form-server-error>
      </form>
    </ValidationObserver>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
}

.input-captcha {
  flex-grow: 1;
  margin-left: 2rem;
}

.captchaSVG {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .wrapper {
    flex-direction: column;
  }

  .input-captcha {
    margin-left: 0;
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { CaptchaPost } from '@/api/ms-authentication/services/interfaces';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import AFormServerError from '@/components/atoms/AFormServerError.vue';
import { AuthenticationStore } from '@/store';

@Component({
  components: {
    AFormServerError,
    MInputWithValidation,
    ValidationObserver,
  },
})
export default class OCaptchaForm extends Vue {
  private loading: boolean = false;
  private loadingSVG: boolean = false;
  private captchaSVG: string = '';
  private form: CaptchaPost = {
    email: this.lastEmailLoginAttempt,
    hash: '',
    answer: '',
  };

  get errors () {
    return AuthenticationStore.errorCaptchaContent;
  }

  get lastEmailLoginAttempt () {
    return AuthenticationStore.lastEmailLoginAttempt;
  }

  handleMessageClosed () {
    AuthenticationStore.ERRORS_CAPTCHA_UNSET();
  }

  async onSubmit () {
    this.loading = true;
    // NB: The svg and hash are not persisted to the store to reduce stale content on reload
    if (!(await AuthenticationStore.unlockCaptcha(this.form))) {
      await this.fetchCaptcha();
    }
    this.loading = false;
  }

  private async mounted () {
    await this.fetchCaptcha();
  }

  private async fetchCaptcha () {
    this.form.hash = '';
    this.form.answer = '';
    this.captchaSVG = '<i class="el-icon-loading"></i>';
    this.loadingSVG = true;
    const captcha = await AuthenticationStore.getCaptcha(
      this.lastEmailLoginAttempt
    );
    this.loadingSVG = false;
    this.form.hash = captcha.hash;
    this.captchaSVG = captcha.data.replace('\\', '');
  }
}
</script>
