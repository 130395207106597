<template>
  <div class="MDropdownButton">
    <b-button class="is-primary main-action" :native-type="String(mainActionType)">
      {{ mainActionLabel }}
    </b-button>
    <b-dropdown position="is-top-left" class="dropdown" :mobile-modal="false">
      <template #trigger="{ active }">
        <b-button type="is-primary">
          <chevron-down-icon v-if="!active"/>
          <chevron-up-icon v-else/>
        </b-button>
      </template>
      <b-dropdown-item v-for="option in dropdownOptions" :key="option.value" @click="() => emitOption(option.value)">
        <component v-if="option.icon" :is="option.icon" size="1x" class="dropdown-item-icon"/>
        {{ option.label }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<style scoped lang="scss">
.MDropdownButton {
  display: flex;

  .main-action {
    display: flex;
    align-items: center;
    vertical-align: middle;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ::v-deep .dropdown {
    margin-left: 1px;

    .dropdown-trigger {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      button, svg {
        padding: 0;
        margin: 0 !important;
      }

      svg {
        vertical-align: middle;
      }
    }

    .dropdown-item {
      .dropdown-item-icon {
        vertical-align: middle;
        margin-right: .25rem;
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChevronDownIcon, ChevronUpIcon, SendIcon } from 'vue-feather-icons';
import { NativeButtonTypes } from './MDropdownButtonInterfaces';
import { DropdownOptions } from './MDropdownButtonInterfaces';



@Component({
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    SendIcon
  }
})
export default class MDropdownButton extends Vue {
  @Prop()
  mainActionLabel!: string;
  @Prop({ default: NativeButtonTypes.Submit })
  mainActionType?: NativeButtonTypes;
  @Prop()
  dropdownOptions!: DropdownOptions[];

  emitOption (value: string) {
    this.$emit('option-selected', value);
  }

  created () {
    if (this.dropdownOptions.length === 0) {
      console.error('Error: No options provided to component');
    }
  }
}
</script>
