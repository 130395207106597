<template>
  <router-link :to="{ name }" class="menu-profile-item">
    <img src="@/assets/svg/icon-list.svg" class="header-menuitem-icon list-icon" v-if="hasIcon" />

    {{ $t('route.shoppingList') }}
  </router-link>
</template>

<style lang="scss" scoped>
.list-icon {
  width: 1rem;
  height: 1rem;
}
</style>

<script lang="ts">
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { RouteNames } from '@/router/RouteNames';
import { ShoppingBagIcon } from 'vue-feather-icons';

  @Component({
    components: {
      ShoppingBagIcon
    }
  })
  export default class ARouterLinkShoppingList extends Vue {
    @Prop(Boolean)
    hasIcon!: boolean;

    name = RouteNames.ROUTE_GETGOT;
  }
</script>
