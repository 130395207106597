<template>
  <div class="MDeleteModalBody modal-card-body">
    <p>
      {{ message }}
    </p>

    <b-button
        :loading="loading"
        class="is-primary"
        @click="deleteItem"
    >
      {{ $t('dict.deleteItem') }}
    </b-button>
  </div>
</template>

<style lang="scss" scoped>
.button {
  margin-top: .7rem;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ItemDetailStore, ItemsStore, NavigationStore } from '@/store';
import { OModalsContainerDeleteItemPayload } from '@/components/organisms/OModalsContainer.vue';
import { RouteNames } from '@/router/RouteNames';

@Component
export default class MDeleteModalBody extends Vue {
  @Prop({ required: true })
  item!: OModalsContainerDeleteItemPayload;

  loading = false;
  message = this.$t('dict.deleteItemMessage', { title: this.item.title });

  async deleteItem () {
    this.loading = true;

    await ItemDetailStore.deleteItem({ uniqueItemName: this.item.uniqueItemName });
    await ItemsStore.recalculateVirtualScroll();

    this.loading = false;
    this.$emit('child-output');
    const history = NavigationStore.getLastRouterHistory;
    await this.$router.push({
      name: history?.name || RouteNames.ROUTE_DASHBOARD,
      params: history?.params || {},
      query: history?.query || {},
      hash: history?.hash || '',
    });
  }
}
</script>
