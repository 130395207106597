<template>
  <div class="AUserDeletedProfilePic">
    <slash-icon
        :width="dims.width + 'px'"
        :height="dims.height + 'px'"
        :stroke-width="strokeWidth"
    />
  </div>
</template>

<style scoped lang="scss">
.AUserDeletedProfilePic {
  margin-right: .5rem;

  img {
    border-radius: 50%;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SlashIcon } from 'vue-feather-icons';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import imageSizeToPixel, { returnDims } from '@/utils/imageSizeToPixel';

@Component({
  components: {
    SlashIcon
  }
})
export default class AUserDeletedProfilePic extends Vue {
  @Prop(String)
  imageSize!: Size;

  @Prop({ default: 0.5 })
  strokeWidth!: number;

  dims!: returnDims;

  created () {
    this.dims = imageSizeToPixel(this.imageSize);
  }
}
</script>