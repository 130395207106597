<template>
  <div
      v-if="urlPreview.editable && urlPreview.userPhotos"
      :class="{ 'full-height-preview': urlPreview.editable.text === '' && !urlPreview.userPhotos.length }"
      class="url-preview">

    <h5 class="preview-title">
      <b-tooltip label="This is how your item will look when added"
                 position="is-bottom">
        {{ $t('dict.preview') }}
      </b-tooltip>
    </h5>

    <hr class="divider"/>

    <o-item-card
        :currentUser="currentUser"
        :item="urlPreview"
        :show-get-got-control="true"
        :preview="previewSettings"
    />

    <b-button v-if="showSaveButton" class="is-primary save-button" @click="$emit('child-output')">
      {{ $t('dict.save') }}
    </b-button>
  </div>
</template>

<style lang="scss" scoped>
.preview-title {
  margin-bottom: 1rem;
  text-align: center;
  cursor: default;
}

hr.divider {
  height: 1px;
  background-color: $primary;
}

.url-preview {
  position: relative;
  max-width: 500px;

  ::v-deep .OItemCard {
    width: 380px;

    @media screen and (max-width: 400px) {
      width: 340px;
    }
  }

  &.full-height-preview {
    ::v-deep .card-content {
      min-height: 370px;
    }
  }
}

.save-button {
  display: flex;
  margin-top: 2rem;
  margin-right: 10px;
  margin-left: auto;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import AImage from '@/components/atoms/AImage.vue';
import OItemCard, { ItemPreview } from '@/components/organisms/OItemCard.vue';
import { User } from '@/api/ms-authentication/services/interfaces';

@Component({
  components: { OItemCard, AImage },
})
export default class MUrlPreview extends Vue {
  @Prop()
  urlPreview!: any;

  @Prop(Object)
  currentUser!: User;

  @Prop()
  classes?: string;

  @Prop()
  previewSettings?: ItemPreview;

  @Prop()
  showSaveButton!: boolean;
}
</script>
