<template>
  <b-tooltip :label="$t('dict.edit')">
    <span class="AEditIcon" @click="$emit('click')">
      <edit2-icon size="16"></edit2-icon>
    </span>
  </b-tooltip>
</template>

<style lang="scss" scoped>
.AEditIcon {
  cursor: pointer;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Edit2Icon } from 'vue-feather-icons';

@Component({
  components: {
    Edit2Icon
  }
})
export default class AEditIcon extends Vue {
}
</script>
