export default (paramToRemove: string, addToHistory = false): string => {
  const currentParams = new URLSearchParams(window.location.search);
  currentParams.delete(paramToRemove);

  const newSearch = currentParams.toString();
  const newUrl = `${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;

  if (addToHistory) {
    window.history.pushState({}, '', newUrl);
  } else {
    window.history.replaceState({}, '', newUrl);
  }

  return newUrl;
};
