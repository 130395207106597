<template>
  <div class="ABusinessProfileImage">
    <img
        :alt="altText"
        :class="className"
        :src="fullHref"
        @click="$emit('click')"
        onerror="this.src='https://www.liffery.com/img/icons/logo-128x128.png'"
    />
  </div>
</template>

<style scoped lang="scss">
.ABusinessProfileImage {
  margin-right: .5rem;

  img {
    border-radius: 50%;
  }
}
</style>

<script lang="ts">
import calculateFullImageHref from '../../utils/calculateFullImageHref';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ABusinessProfileImage extends Vue {
  @Prop({required: true})
  baseUrlAndPath!: string;

  @Prop({required: true})
  imagePath!: string;

  @Prop({required: true})
  altText!: string;

  @Prop({required: true})
  size!: string;

  @Prop({ default: '' })
  className!: string;

  @Prop({ required: false })
  imageBase64?: string;

  get fullHref (): string {
    if (this.imagePath) {
      return calculateFullImageHref(this.baseUrlAndPath, this.size, this.imagePath);
    } else if (this.imageBase64) {
      return this.imageBase64;
    } else {
      return '';
    }
  }
}
</script>
