<template>
  <router-link :to="{ name }" class="menu-profile-item">
    <mail-icon v-if="hasIcon" size="1x" class="header-menuitem-icon" />
    <span>
      {{ $t('dict.emailNotifications') }}
    </span>
  </router-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { MailIcon } from 'vue-feather-icons';

@Component({
  components: {
    MailIcon
  }
})
export default class ARouterLinkEmailNotifications extends Vue {
  @Prop({default: true})
  hasIcon!: boolean;

  name = RouteNames.ROUTE_SETTINGS_NOTIFICATIONS;
}
</script>
