<template>
  <router-link :to="{ name }" class="menu-profile-item">
    <home-icon size="1x" class="header-menuitem-icon" v-if="hasIcon"/>
    {{$t('dict.dashboard')}}
  </router-link>
</template>

<script lang="ts">
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { RouteNames } from '@/router/RouteNames';
  import { HomeIcon } from 'vue-feather-icons';

  @Component({
    components: {
      HomeIcon
    }
  })
  export default class ARouterLinkDashboard extends Vue {
    @Prop()
    hasIcon!: boolean;
  
    name = RouteNames.ROUTE_DASHBOARD;
  }
</script>
