import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@/constants/translation';

export default (browserPreferred: string = navigator.language): string => {
  const twoCharLangKeys = SUPPORTED_LANGUAGES.map((lng) => {
    return {
      short: lng.substring(0, 2),
      original: lng
    };
  });
  const found = twoCharLangKeys.find((key) => key.short === browserPreferred.substring(0, 2));
  if (found) {
    return found.original;
  } else {
    return DEFAULT_LANGUAGE;
  }
};
