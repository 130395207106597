<template>
  <div class="AShoppingListButton">

    <m-button-with-icon
        v-if="!isIcon"
        :loading="loading"
        :class="classes"
        class="shopping-list-icon"
        @click="addOrRemoveHandle"
    >
      <template slot="icon">
        <shopping-bag-icon size="14"/>
      </template>
      <template slot="text">
        <slot>
          {{ getGot ? $t('dict.removeGetGotList') : $t('dict.addGetGotList') }}
        </slot>
      </template>
    </m-button-with-icon>

    <template v-else>
      <shopping-bag-icon
          size="14"
          :loading="loading"
          @click="addOrRemoveHandle"
          :class="{'item-in-list':getGot}"
      />
      <span @click="addOrRemoveHandle">
        {{ item.getgot.length > 0 ? 'x' + item.getgot.length : '' }}
      </span>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.AShoppingListButton {
  display: flex;
  align-items: center;
  min-height: 1rem;
  cursor: pointer;
  gap: 5px;
  grid-gap: 5px;

  &:hover {
    color: var(--secondary-color);
  }

  .item-in-list {
    color: var(--secondary-color);
  }
}

.shopping-list-icon {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  min-height: 1rem;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getgot, Item } from '@/api/ms-item/services/interfaces/Item';
import EventBus, { EventBusEvents } from '@/EventBus';
import { ShoppingBagIcon } from 'vue-feather-icons';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';

@Component({
  components: {
    MButtonWithIcon,
    ShoppingBagIcon
  }
})
export default class AShoppingListButton extends Vue {
  @Prop({ default: true })
  isIcon!: boolean;
  @Prop()
  classes!: string;
  @Prop()
  username!: string;
  @Prop({ required: true })
  getGot!: Getgot | undefined;
  @Prop({ required: true })
  item!: Item;

  loading = false;

  addOrRemoveHandle () {
    if (this.getGot) {
      // we assume we remove from the current users shopping list
      this.openShoppingListRemoveModal();
    } else {
      this.openShoppingListModal();
    }
  }

  openShoppingListModal () {
    EventBus.$emit(EventBusEvents.SHOPPING_LIST_MODAL, JSON.parse(
      JSON.stringify(this.item)
    ));
  }

  openShoppingListRemoveModal () {
    EventBus.$emit(EventBusEvents.SHOPPING_LIST_REMOVE_MODAL,
      { itemName: this.item.uniqueItemName, getGotId: this.getGot?._id }
    );
  }
}
</script>
