<template>
  <button class="btn-sso btn-sso-google font-button font-caption"
          :class="{'app-sso-coming': isApp || disabled}"
          @click="signIn"
  >
    <a-google-icon/>
    {{ message }}
  </button>
</template>

<style lang="scss" scoped>
// _base.scss contains shared .btn-sso styles
.btn-sso-google {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: #4484f4;

  &:hover {
    background-color: darken(#4484f4, 10);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;

    svg {
      filter: grayscale(.9);
    }
  }

  &.app-sso-coming {
    background-color: var(--grey-mid-color);
    position: relative;

    &:after {
      content: '(Coming soon)';
      display: block;
      position: absolute;
      bottom: -18px;
      width: 100%;
      color: var(--grey-dark-color)
    }
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AGoogleIcon from '@/components/atoms/icon/AGoogleIcon.vue';
import { SSOGoogle } from '@/utils/SSOGoogle';
import { AuthenticationStore } from '@/store';
import { RouteNames } from '@/router/RouteNames';
import isApp from '@/utils/isApp';
import EventBus, { EventBusEvents } from '@/EventBus';
// import { User } from '@codetrix-studio/capacitor-google-auth';
import { LoginGooglePost } from '@/api/ms-authentication/services/interfaces';

@Component({
  components: {
    AGoogleIcon
  }
})
export default class MGoogleButton extends Vue {
  originalMsg = this.$t('sso.google');
  message = this.originalMsg;

  isApp = isApp();

  disabled = true;

  async signIn () {
    if (this.isApp) {
      return this.inAppNotice();
    } else {
      await this.signInWeb();
    }
  }

  inAppNotice () {
    this.$buefy.dialog.alert({
      title: 'Coming soon',
      message: 'Google login will be available soon. For the time being please use either email login or Facebook login. If you have not yet set a password, please use forgot password.'
    });
  }

  async signInApp () {

    this.message = this.$t('dict.loading') + '...';

    EventBus.$emit(EventBusEvents.CAPJS_SSO_GOOG);
    EventBus.$on(EventBusEvents.CAPJS_SSO_GOOG_SUCCESS, 'MGoogleButton', async (user: any) => {
      const googleLoginData: LoginGooglePost = {
        accessToken: user.authentication.accessToken,
        inviteCode: this.$route.query.inviteCode ? this.$route.query.inviteCode as string : undefined,
      };
      await AuthenticationStore.loginWithGoogle(googleLoginData);
      await this.$router.push({ name: RouteNames.ROUTE_DASHBOARD });
    });

    EventBus.$on(EventBusEvents.CAPJS_SSO_GOOG_ERROR, 'MGoogleButton', async (err: any) => {
      console.error(err);
      this.$buefy.dialog.alert({
        title: 'Something went wrong',
        message: 'There was an error trying to login with the Google Login button. Please try again or try another login method.',
        onConfirm: () => {
          this.message = this.originalMsg;
        }
      });
    });
  }

  async signInWeb () {
    try {
      this.message = this.$t('dict.loading') + '...';

      const resp = await SSOGoogle();
      if (this.$route.query.inviteCode) {
        resp.inviteCode = this.$route.query.inviteCode as string;
      }
      await AuthenticationStore.loginWithGoogle(resp);
      await this.$router.push({ name: RouteNames.ROUTE_DASHBOARD });
    } catch (e) {
      this.message = this.originalMsg;
    }
  }
}
</script>
