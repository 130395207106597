<template>
  <div>
    <template v-if="!loginCaptchaRequired && !showForgotPassword">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form
            ref="form"
            class="login-form"
            novalidate
            @submit.prevent="handleSubmit(onSubmit)"
        >
          <m-input-with-validation
              v-model="form.email"
              :placeholder="$t('dict.email')"
              auto-complete="on"
              groupFieldClass="form-group"
              name="email"
              rules="required|email"
              type="email"
          ></m-input-with-validation>

          <a-password-input-with-validation
              v-model="form.password"
              :placeholder="$t('dict.password')"
              groupFieldClass="form-group"
              name="password"
              rules="required|min:6"
          ></a-password-input-with-validation>

          <b-field class="form-group login-buttons-group">
            <a-button-submit :loading="loading">
              {{ $t('dict.signIn') }}
            </a-button-submit>
            <b-button
                class="is-text"
                @click.prevent="setShowForgotPassword(true)"
            >
              {{ $t('dict.passwordForgot') }}
            </b-button>
          </b-field>

          <a-form-server-error
              v-if="loginErrors"
              :errors="loginErrors"
              v-on:child-output="handleMessageClosed"
          ></a-form-server-error>
        </form>
      </ValidationObserver>

<!--      <template v-if="!isIos">-->
<!--        <p class="login-sso-divider">- {{ $t('dict.or') }} -</p>-->
<!--        <m-sso-buttons/>-->
<!--      </template>-->
    </template>

    <o-captcha-form v-if="loginCaptchaRequired"/>

    <o-password-forgot-request-form
        v-else-if="showForgotPassword"
        v-on:child-output="setShowForgotPassword(false)"
    />
  </div>
</template>

<style lang="scss" scoped>
.login-buttons-group .field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-sso-divider {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { LoginEmailPost } from '@/api/ms-authentication/services/interfaces';
import { AuthenticationStore } from '@/store';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import APasswordInputWithValidation from '@/storybook-components/src/stories/atoms/APasswordInputWithValidation.vue';
import AFormServerError from '@/components/atoms/AFormServerError.vue';
import MSsoButtons from '@/components/molecules/MSSOButtons.vue';
import OCaptchaForm from '@/components/organisms/forms/OCaptchaForm.vue';
import OPasswordForgotRequestForm from '@/components/organisms/forms/OPasswordForgotRequestForm.vue';
import { RouteNames } from '@/router/RouteNames';
import ARafflePrompt from '@/components/atoms/ARafflePrompt.vue';
import isIosApp from '@/utils/isIosApp';

@Component({
  components: {
    ARafflePrompt,
    AFormServerError,
    MInputWithValidation,
    AButtonSubmit,
    OCaptchaForm,
    OPasswordForgotRequestForm,
    ValidationObserver,
    APasswordInputWithValidation,
    MSsoButtons
  },
})
export default class OLoginForm extends Vue {
  @Prop()
  showForgotPassword!: boolean;

  loading: boolean = false;
  form: LoginEmailPost = {
    email: '',
    password: '',
  };

  isIos = isIosApp();

  get loginErrors () {
    return AuthenticationStore.errorLoginContent;
  }

  get loginCaptchaRequired () {
    return AuthenticationStore.captchaRequired;
  }

  handleMessageClosed () {
    AuthenticationStore.ERRORS_LOGIN_UNSET();
  }

  setShowForgotPassword (value: boolean) {
    this.$emit('child-output', value);
  }

  async onSubmit () {
    this.loading = true;
    try {
      await AuthenticationStore.login(this.form);
      await this.$router.push({ name: RouteNames.ROUTE_DASHBOARD });
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.loading = false;
    }
  }

  beforeDestroy () {
    AuthenticationStore.clearAuthErrors();
  }
}
</script>
