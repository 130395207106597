<template>
  <b-field class="flex-centered">
    <b-radio-button
        v-model="uploadedOrGenerated"
        :native-value="type.Upload"
        type="is-success is-light"
        @click.native="(e) => clickCamera(e)"
    >
      <a-upload-image
          v-if="!clearDom"
          v-model="imageUpload"
          @input="imageUploaded"
          :image-types-allowed="imageUploadParameters.imageTypesAllowed"
          :file-size-limit="imageUploadParameters.fileSizeLimit"
          v-on:image-upload-error="imgUploadError"
          class="clipper-upload"
      >
        <camera-icon class="icon-upload" size="1.2x"></camera-icon>
        {{ capitaliseFirstLetter(type.Upload) }}
      </a-upload-image>
    </b-radio-button>
    <b-radio-button
        v-model="uploadedOrGenerated"
        :native-value="type.Generate"
        type="is-success is-light"
        @click.native="generateImage"
    >
      <a-pexels-svg-icon fill-colour="#000"/>
      {{ capitaliseFirstLetter(type.Generate) }}
    </b-radio-button>
  </b-field>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { CameraIcon } from 'vue-feather-icons';
import { capitaliseFirstLetterOfString } from 'common-utils/string';
import APexelsSvgIcon from '@/components/atoms/icon/svg/APexelsSvgIcon.vue';
import { CHILD_EMIT } from '@/constants/childEmitEventNames';
import AUploadImage, {
  AUploadImageOutput,
  clearImageOutput,
  ImageUploadError
} from '@/storybook-components/src/stories/atoms/buttons/AUploadImage.vue';
import imageUploadError, { ImageUploadParameters } from '@/utils/imageUploadError';
import { clone } from 'common-utils/object';


export enum UploadOrGenerateType {
  Upload = 'upload',
  Generate = 'fetch'
}

@Component({
  components: {
    AUploadImage,
    APexelsSvgIcon,
    CameraIcon
  },
  methods: {
    capitaliseFirstLetter: capitaliseFirstLetterOfString
  }
})
export default class MUploadOrGenerateImage extends Vue {
  @Prop({ default: null })
  uploadOrGenerate!: UploadOrGenerateType | null;
  @Prop()
  imageTypesAllowed!: any;
  @Prop({default: 10}) // MB
  fileSizeLimit!: number;
  @Prop()
  generateImageBindingString!: string;
  @Prop()
  generateImageBindingMinLength!: number;
  @Prop({required: false, default: 0})
  clearUploadCache!: number;

  uploadedOrGenerated: UploadOrGenerateType | null = null;
  type = UploadOrGenerateType;
  imageUpload: AUploadImageOutput = clearImageOutput;
  clearDom = false;

  imageUploadParameters: ImageUploadParameters = {
    dimensionRequirements: 'none',
    fileSizeLimit: this.fileSizeLimit,
    imageTypesAllowed: this.imageTypesAllowed
  };

  $refs!: {
    clipperUpload: HTMLElement
  };

  @Watch('uploadOrGenerate', {immediate: true})
  setUploadedOrGenerated() {
    this.uploadedOrGenerated = this.uploadOrGenerate;
  }

  // Runs on a timeout of 25ms to overcome race condition with mouseup
  clearRadioSelection () {
    setTimeout(() => {
      this.uploadedOrGenerated = null;
    }, 25);
  }

  clickCamera (e) {
    if(!e.isTrusted) {
      e.preventDefault();
    }
  }

  generateImage () {
    if (this.generateImageBindingString.length >= this.generateImageBindingMinLength) {
      this.$emit(CHILD_EMIT, {
        type: UploadOrGenerateType.Generate
      });
    } else {
      this.$emit('invalid-string');
      this.clearRadioSelection();
    }
  }

  imgUploadError (error: ImageUploadError) {
    imageUploadError(error, this.imageUploadParameters);
  }

  imageUploaded () {
    this.uploadedOrGenerated = UploadOrGenerateType.Upload;
    const emitImage = clone(this.imageUpload);
    this.imageUpload = clearImageOutput;
    this.$emit(CHILD_EMIT, {
      type: this.type.Upload,
      image: emitImage
    });
  }

  /**
   * Hacky solution(!) to the following problem. When a user selects an image to upload, if they then cancel at the crop
   * stage and then try to upload the exact same photo again nothing happens for some reason. After enough failed attempts
   * to fix in the AUploadImage component the workaround for now is to clear the component from the DOM and reload it.
   */
  @Watch('clearUploadCache')
  clearUploadDOM () {
    this.clearDom = true;
    setTimeout(() => this.clearDom = false, 10);
  }
}
</script>
