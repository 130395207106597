<template>
  <div class="OItemCardContainer" ref="itemCard">
    <div :class="{
      'disabled-item': preview.isPreview,
      'has-multiple-indicator': item.countInGroup && item.countInGroup > 1,
      'isMapView': isMapView,
      'notMapView': !isMapView,
      'OItemCard card': !isMapView,
      'no-top-border': itemCardTitle && itemCardTitle.isShown
    }">
      <!-- Manual item card title, e.g. on decision maker -->
      <div v-if="itemCardTitle && itemCardTitle.isShown" class="item-card-title">{{ itemCardTitle.title }}</div>
      <!-- +x in this channel -->
      <template v-if="!item.anonymous && item.countInGroup && item.countInGroup > 1">
        <a-router-link-channel-view
            v-if="item.editable.channel.name !== ''"
            :channel-slug="item.editable.channel.slug"
            :customText="(item.editable.channel.isDefault ? $t('dict.general') + ': ' : '') + item.editable.channel.name"
            class="multiple-indicator"
            :is-channel-event="isChannelEvent"
            :is-research-concierge="isResearchConcierge"
        >
          <sup>({{
              $t('item.card.viewMoreByUser', {
                count: item.countInGroup - 1,
                channelName: item.editable.channel.name,
                firstName: ellipsisString(item.actor.firstName)
              })
            }})
          </sup>
        </a-router-link-channel-view>

        <a-router-link-profile
            v-else
            :username="item.actor.username"
            class="multiple-indicator"
        >
          <sup>
            ({{
              $t('item.card.viewMoreByUser', {
                count: item.countInGroup - 1,
                channelName: $t('dict.general'),
                firstName: ellipsisString(item.actor.firstName, 25)
              })
            }})
          </sup>
        </a-router-link-profile>
      </template>

      <!-- Header - User picture and name, time ago, channel, dropdown menu -->
      <div class="card-header" v-if="!hideHeader()">
        <div class="header-details">
          <template v-if="item.actor">
            <figure class="image is-48x48">
              <a-router-link-profile :username="item.actor.username">
                <a-user-profile-pic
                    :username="item.actor.username"
                ></a-user-profile-pic>
              </a-router-link-profile>
            </figure>
            <div>
              <p class="font-body-m line-clamp-1">
                <a-router-link-profile :username="item.actor.username">
                  {{ `${item.actor.firstName} ${item.actor.lastName}` | ellipsisString }}
                </a-router-link-profile>
              </p>
              <p class="font-body-s line-clamp-1">
                <time class="time-ago">
                  {{ item.createdAt | formatDate(currentLanguage) }}
                </time>

                {{ $t('dict.in') }}

                <a-router-link-channel-view
                    v-if="item.editable.channel.name !== '' && item.editable.channel.slug !== ''"
                    :channel-slug="item.editable.channel.slug"
                    :customText="(item.editable.channel.isDefault && item.editable.channel.name === '' ? $t('dict.general') + ': ' : '') + item.editable.channel.name"
                    class="link-channel-name"
                    :is-channel-event="isChannelEvent"
                    :is-research-concierge="isResearchConcierge"
                />
                <template v-else>
                  {{ $t('dict.general') }}
                </template>
              </p>
            </div>
          </template>
          <!-- If no actor the item is anonymised, show an add button instead -->
          <template v-else>
            <a-add-item-button-link v-on:child-output="showCopyItem" />
          </template>
        </div>
        <div class="card-actions font-body-s">
          <a-item-reminder :item="item" tooltip-position-force="is-left"/>
          <a-router-link-setting-watchers v-if="isAlreadyPriceWatched" :has-icon="true" :has-text="false"/>
          <a-map-out-link class="ml-3" :item="item"/>
          <m-item-actions-dropdown
              :item="item"
              :current-user="currentUser"
              :include-item-detail-link="true"
              :card-height="cardHeight"
              class="ml-2"
          />
        </div>
      </div>

      <!-- Card content - user description, picture, url cache preview -->
      <div class="card-content">
        <div
            v-if="hasUserDescription() || !hasItemUrl()"
            :class="{'user-desc-centered': !hasItemImage() && !hasUserImage() && !isInProgress() && !preview.loading}"
            class="user-desc"
        >
          <div v-if="preview.isPreview">
            <div :class="'line-clamp-' + getUserDescLineClamp()">
              <m-item-card-text :text="item.editable.text"/>
            </div>
          </div>
          <a-router-link-issue-detail
              v-else-if="item.editable.text.length > 0"
              :unique-item-name="item.uniqueItemName"
          >
            <blockquote :class="'line-clamp-' + getUserDescLineClamp()"
                        class="is-italic font-body-m">
              <m-item-card-text :text="item.editable.text"/>
            </blockquote>
          </a-router-link-issue-detail>
        </div>

        <div class="item-info">
          <!-- Item with url fetch still in progress -->
          <div v-if="isInProgress()">
            <div class="card-image">
              <a-router-link-issue-detail :unique-item-name="item.uniqueItemName">
                <div class="still-fetching-image">
                  <p class="still-fetching-msg">
                    {{ $t('dict.itemCardStillFetchingMsg') }}
                    <b-button
                        class="mt-4 is-primary is-outlined"
                        @click="handleCheckUpdateClick"
                    >
                      {{
                        checkingForUrlCacheUpdate ? $t('dict.itemCardCheckingForUpdate') : $t('dict.itemCardCheckForUpdate')
                      }}
                    </b-button>
                  </p>
                </div>
              </a-router-link-issue-detail>
            </div>
          </div>
          <!-- Skeletons while loading preview during item entry -->
          <div v-else-if="preview.isPreview && preview.loading">
            <div class="card-image">
              <b-skeleton height="250px" :animated="false"/>
              <div class="card-image-preview-message">
                <p>{{ preview.message }}</p>
              </div>
            </div>
            <div class="item-content">
              <b-skeleton size="is-large" width="60%"/>
              <b-skeleton/>
              <b-skeleton width="50%" position="is-right"/>
            </div>
          </div>

          <!-- Regular url item preview -->
          <div v-else-if="item.urlCache || (preview.isPreview && !preview.loading)">
            <div v-if="hasItemImage()" class="card-image">
              <template v-if="getYouTubeId">
                <div
                    class="link-detail">
                  <m-you-tube
                      v-if="showYouTube"
                      :vid-id="getYouTubeId"
                      auto-play="1"
                  />
                  <a-image
                      v-else
                      :alt-text="item.urlCache.meta && item.urlCache.meta.title"
                      :image-path="item.urlCache.image.href"
                      v-on:loaded="loadedImage"
                      class="item-image"
                      size="400x"
                  />
                  <a-youtube-play v-if="!showYouTube" @click="toggleShowYouTube"/>
                </div>
              </template>
              <template v-else-if="getSpotifyEmbed">
                <div class="link-detail">
                  <m-spotify-player
                      v-if="showSpotify"
                      :card-width="itemWidth"
                      :image-preview-url="item.urlCache.image.href"
                      :url="getSpotifyEmbed"
                  />
                  <a-image
                      v-else
                      :alt-text="item.urlCache.meta && item.urlCache.meta.title"
                      :image-path="item.urlCache.image.href"
                      v-on:loaded="loadedImage"
                      class="item-image"
                      size="400x"
                  />
                  <a-spotify-play v-if="!showSpotify" @click="showSpotify = true"/>
                </div>
              </template>
              <a-router-link-issue-detail
                  v-else
                  :unique-item-name="item.uniqueItemName"
              >
                <a-image
                    :alt-text="item.urlCache.meta && item.urlCache.meta.title"
                    :image-path="item.urlCache.image.href"
                    v-on:loaded="loadedImage"
                    class="item-image"
                    size="400x"
                    :style="imageMaxHeight ? 'max-height:' + imageMaxHeight + 'px;' : ''"
                />
              </a-router-link-issue-detail>
              <div v-if="preview && preview.backgroundRescrapeInProgress" class="background-fetch-in-progress">
                <span :title="$t('item.entry.updatingCacheTitle')"><a-loading-icon /> {{ $t('dict.updatingCache') }}</span>
              </div>
            </div>

            <!-- Clickout region for URL links with meta -->
            <a-router-link-item-url
                v-if="hasItemUrlMeta()"
                :url="item.urlCache.url"
                :item="item"
                classes="link-with-image flex-wrap font-body-s"
            >
              <template name="default">
                <div class="item-content">
                  <p class="line-clamp-1 item-title font-body-s">
                    <b>
                      <i class="colour-danger" v-if="item.urlCache.url404">({{ $t('dict.noLongerAvailable') }})</i>
                      {{ item.urlCache.meta && item.urlCache.meta.title }}
                    </b>
                  </p>
                  <p
                      v-if="hasItemDescription()"
                      class="item-description line-clamp-1 font-body-s"
                  >
                    {{ item.urlCache.meta.description }}
                  </p>
                </div>
              </template>
              <template slot="price">
                <a-item-price :item="item" />
              </template>
            </a-router-link-item-url>
          </div>

          <div v-if="hasUserImage()">
            <div class="card-image">
              <a-router-link-issue-detail :unique-item-name="item.uniqueItemName">
                <a-image
                    v-if="item.userPhotos[0].filePath"
                    :image-path="item.userPhotos[0].filePath"
                    v-on:loaded="loadedImage"
                    class="item-image"
                    size="400x"
                    :style="imageMaxHeight ? 'max-height:' + imageMaxHeight + 'px;' : ''"
                />
                <a-image
                    v-else
                    :image-base64="item.userPhotos[0].preview"
                    v-on:loaded="loadedImage"
                    class="item-image"
                    size="400x"
                />
              </a-router-link-issue-detail>
            </div>
          </div>

          <a-router-link-item-url
              v-if="hasUserImage() && hasItemUrl() || isInProgress()"
              :url="item.urlCache.url"
              classes="link-with-image mt-2 font-body-s"
          />
          <a-router-link-item-url
              v-else-if="!hasItemImage() && !hasItemUrlMeta() && hasItemUrl()"
              :url="item.urlCache.url"
              classes="link-without-image font-body-s"
          />
        </div>

        <!-- When the card is used inside the google maps, this is shown -->
        <div v-if="isMapView && isApp" class="is-flex is-justify-content-flex-end mt-4 mb-4">
          <m-button-with-icon @click="clickOutToMap" :icon-rhs="true">
            <template slot="text">
              <div class="mr-2">
                {{ $t('item.card.openInMaps') }}
              </div>
            </template>
            <template slot="icon">
              <external-link-icon size="1.0x"/>
            </template>
          </m-button-with-icon>
        </div>

      </div>

      <!-- Item card footer - interaction icons -->
      <div v-if="!hideFooter()" class="card-footer font-body-s" :class="{itemIsInChannel: itemIsInChannel}">

        <b-tooltip :label="$t('itemActions.comments')" :active="!isTouchScreen">
          <a-comment-icon :unique-item-name="item.uniqueItemName" :comment-count="itemTotalCommentCount"/>
        </b-tooltip>

        <b-tooltip :label="theCurrentUserLikesThisItem ? $t('itemActions.unlikeIt') : $t('itemActions.likeIt')"
                   :active="!isTouchScreen">
          <a-like-icon
              :like="theCurrentUserLikesThisItem"
              :like-count="itemTotalLikeCount"
              :loading="likeLoading"
              iconSize="14"
              v-on:child-output="likeItem"
          />
        </b-tooltip>


        <b-tooltip :label="theCurrentUserPinnedThisItem ? $t('itemActions.unpinIt') : $t('itemActions.pinIt')"
                   :active="!isTouchScreen">
          <a-pin-item-icon
              :pin="theCurrentUserPinnedThisItem"
              :pin-count="itemTotalPinCount"
              :loading="pinLoading"
              :numeric-only="smallWindow"
              iconSize="14"
              v-on:child-output="pinItem"
          />
        </b-tooltip>

        <b-tooltip v-if="itemIsInChannel"
                   :label="$t('itemActions.swipeScore')"
                   :active="!isTouchScreen">
          <a-decision-maker-icon
              :unique-item-name="item.uniqueItemName"
              :swipe-score="item.performanceValues ? item.performanceValues.swipeScore : 0"
              :loading="pinLoading"
              :numeric-only="smallWindow"
              v-on:child-output="pinItem"
          />
        </b-tooltip>

        <b-tooltip :label="currentUserGetGot() ? $t('dict.removeGetGotList') : $t('dict.addGetGotList')"
                   :active="!isTouchScreen">
          <a-shopping-list-button
              :getGot="currentUserGetGot()"
              :isIcon="true"
              :item="item"
              :username="currentUser.username"
              style="margin-top: 1px;"
          />
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './OItemCard';
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import { DatumLike, Pin } from '@/api/ms-item/services/interfaces/Items';
import { User } from '@/api/ms-authentication/services/interfaces';
import { translation } from '@/plugins/i18n/Translation';
import { AuthenticationStore, ItemsStore, WatchersStore } from '@/store';
import { format } from 'timeago.js';
import { ChannelType, FetchStatus, Getgot } from '@/api/ms-item/services/interfaces/Item';
import usersGetGotFromItem from '@/utils/usersGetGotFromItem';
import remove1stUrlFromString from '@/utils/remove1stUrlFromString';

import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';
import AImage from '@/components/atoms/AImage.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkChannelView from '@/components/atoms/link/ARouterLinkChannelView.vue';
import ARouterLinkItemUrl from '@/components/atoms/link/ARouterLinkItemUrl.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import ALikeIcon, { ALikeChildOutput } from '@/components/atoms/icon/ALikeIcon.vue';
import AShoppingListButton from '@/components/atoms/buttons/AShoppingListButton.vue';
import {
  AlertCircleIcon,
  CopyIcon,
  Edit2Icon,
  ExternalLinkIcon,
  MessageSquareIcon,
  MoreVerticalIcon,
  MoveIcon,
  RepeatIcon,
  Share2Icon,
  ShareIcon,
  ThumbsUpIcon,
  XIcon
} from 'vue-feather-icons';
import MYouTube from '@/components/molecules/video/MYouTube.vue';
import getYoutubeId from '@/utils/getYoutubeId';
import AYoutubePlay from '@/storybook-components/src/stories/atoms/AYoutubePlay.vue';
import ellipsisString from '@/utils/ellipsisString';
import isItemUrlCacheEmpty from '@/utils/isItemUrlCacheEmpty';
import getSpotifyEmbed from '@/utils/getSpotifyEmbed';
import MSpotifyPlayer from '@/components/molecules/video/MSpotifyPlayer.vue';
import ASpotifyPlay from '@/storybook-components/src/stories/atoms/ASpotifyPlay.vue';
import updateItemInStores from '@/utils/updateItemInStores';
import AMapOutLink from '@/components/atoms/link/AMapOutLink.vue';
import APinItemIcon, { APinItemChildOutput } from '@/components/atoms/icon/APinItemIcon.vue';
import AListenMenu from '@/components/atoms/AListenMenu.vue';
import ACommentIcon from '@/components/atoms/icon/ACommentIcon.vue';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';
import isTouchScreen from '@/utils/isTouchScreen';
import currentUserPinnedThisItem from '@/utils/currentUserPinnedThisItem';
import currentUserLikesThisItem from '@/utils/currentUserLikesThisItem';
import itemTotalCommentCount from '@/utils/itemTotalCommentCount';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import clickOutToMap from '@/utils/clickOutToMap';
import isApp from '@/utils/isApp';
import { extractHashtags } from 'common-utils/regex';
import MItemCardText from '@/components/molecules/MItemCardText.vue';
import MItemActionsDropdown from '@/components/molecules/MItemActionsDropdown.vue';
import AItemReminder from '@/components/atoms/AItemReminder.vue';
import AItemPrice from '@/components/atoms/AItemPrice.vue';
import ARouterLinkSettingWatchers from '@/components/atoms/link/ARouterLinkSettingWatchers.vue';
import ADecisionMakerIcon from '@/components/atoms/icon/ADecisionMakerIcon.vue';
import AAddItemIcon from '@/components/atoms/icon/AAddItemIcon.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import AAddItemButtonLink from '@/components/atoms/buttons/AAddItemButtonLink.vue';
import ALoadingIcon from '@/storybook-components/src/stories/atoms/icons/svg/ALoadingIcon.vue';
import { EnumRegisteredFromAction } from '@/store/modules/interfaces/AuthenticationModule';

export interface ItemPreview {
  isPreview: boolean;
  loading?: boolean;
  message?: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
  customHeader?: string;
  backgroundRescrapeInProgress?: boolean;
}

@Component({
  components: {
    ALoadingIcon,
    AAddItemButtonLink,
    AAddItemIcon,
    ADecisionMakerIcon,
    ARouterLinkSettingWatchers,
    AItemPrice,
    AItemReminder,
    MItemActionsDropdown,
    MItemCardText,
    MButtonWithIcon,
    ATooltip,
    ACommentIcon,
    APinItemIcon,
    AListenMenu,
    AMapOutLink,
    ASpotifyPlay,
    MSpotifyPlayer,
    AYoutubePlay,
    MYouTube,
    ARouterLinkChannelView,
    AUserProfilePic,
    AImage,
    ALikeIcon,
    ARouterLinkIssueDetail,
    ARouterLinkItemUrl,
    ARouterLinkProfile,
    AShoppingListButton,
    AlertCircleIcon,
    CopyIcon,
    Edit2Icon,
    ExternalLinkIcon,
    RepeatIcon,
    MessageSquareIcon,
    MoveIcon,
    MoreVerticalIcon,
    ShareIcon,
    Share2Icon,
    ThumbsUpIcon,
    XIcon
  },
  filters: {
    formatHashTag: (inputString: string): string => {
      const tags = extractHashtags(inputString, true);
      tags.forEach((tag) => {
        inputString = inputString.replace(tag, '<a>' + tag + '</a>');
      });
      return inputString;
    },
    removeUrl: remove1stUrlFromString,
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
    ellipsisString
  },
})
export default class OItemCard extends Vue {
  @Prop({ default: false })
  showGetGotControl!: boolean;

  @Prop(Object)
  item!: Item & {
    anonymous?: boolean,
    count?: number
  };

  @Prop(Object)
  currentUser!: User;

  @Prop({ default: false })
  isMapView!: boolean;

  @Prop({ default: false })
  smallWindow!: boolean;

  @Prop({required: false})
  imageMaxHeight?: number;

  @Prop({
    required: false,
    type: Object,
    default: () => ({
      isPreview: false,
      loading: false,
      message: 'Fetching preview...',
      hideFooter: false,
      hideHeader: false,
      customHeader: ''
    })
  })
  preview!: ItemPreview;

  @Prop({
    required: false,
    type: Object,
    default: () => ({
      isShown: false,
      title: 'Title'
    })
  })
  itemCardTitle!: any;

  $refs!: {
    itemCard: HTMLElement
  };

  cardHeight: number = 200;
  checkingForUrlCacheUpdate = false;
  likeLoading = false;
  pinLoading = false;
  isTouchScreen = isTouchScreen();
  showSpotify = false;
  showYouTube = false;
  ellipsisString = ellipsisString;

  get isAlreadyPriceWatched () {
    return WatchersStore.getWatchers.find((watcher) => watcher.itemUniqueName === this.item.uniqueItemName);
  }

  get itemWidth () {
    return ItemsStore.vScrollDims.cardWidth;
  }

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  get itemTotalCommentCount () {
    return itemTotalCommentCount(this.item, this.currentUser);
  }

  get theCurrentUserLikesThisItem () {
    return currentUserLikesThisItem(this.item.likes, this.currentUser);
  }

  get itemTotalLikeCount () {
    const likes = this.item.likes?.filter((like: DatumLike) => {
      return like.like;
    });
    return likes ? likes.length : 0;
  }

  get theCurrentUserPinnedThisItem () {
    return currentUserPinnedThisItem(this.item.pins, this.currentUser);
  }

  get itemTotalPinCount () {
    const pins = this.item.pins?.filter((pin: Pin) => {
      return pin.pin;
    });
    return pins ? pins.length : 0;
  }

  get getYouTubeId (): string | null {
    return getYoutubeId(this.item.urlCache?.url || '');
  }

  get getSpotifyEmbed () {
    return getSpotifyEmbed(this.item.urlCache?.url);
  }

  get itemIsInChannel () {
    return !!(this.item.editable && this.item.editable.channel && this.item.editable.channel.slug);
  }

  get isChannelEvent () {
    return this.item.editable.channel?.channelType === ChannelType.Event;
  }

  get isResearchConcierge () {
    return this.item.editable.channel?.channelType === ChannelType.ResearchConcierge;
  }

  get isApp () {
    return isApp();
  }

  created () {}

  loadedImage () {
    this.cardHeight = this.$refs.itemCard.offsetHeight;
  }

  /**
   * Returns the getgot item for the current username, or undefined
   */
  currentUserGetGot (): Getgot | undefined {
    return usersGetGotFromItem(this.item, this.currentUser.username);
  }

  async handleCheckUpdateClick (e: Event) {
    e.preventDefault();
    this.checkingForUrlCacheUpdate = true;
    this.$emit('item-request-update', this.item);
    setTimeout(() => this.checkingForUrlCacheUpdate = false, 1000);
  }

  toggleShowYouTube () {
    this.showYouTube = !this.showYouTube;
  }

  async likeItem (input: ALikeChildOutput) {
    this.likeLoading = true;
    const response = await ItemsStore.itemLikeToggle({
      item: this.item,
      newLikeState: input.like,
      uniqueItemName: this.item.uniqueItemName,
    });
    this.likeLoading = false;

    updateItemInStores(response);
  }

  async pinItem (input: APinItemChildOutput) {
    this.pinLoading = true;
    const response = await ItemsStore.itemPinToggle({
      item: this.item,
      newPinState: input.pin,
      uniqueItemName: this.item.uniqueItemName,
    });
    this.pinLoading = false;

    updateItemInStores(response);
  }

  isItemAnon () {
    return !!this.item.anonymous;
  }

  hideFooter () {
    return this.isItemAnon() || this.preview.hideFooter;
  }

  hideHeader () {
    return this.preview.hideHeader;
  }

  clickOutToMap () {
    clickOutToMap(this.item);
  }

  getUserDescLineClamp (): number {
    return this.hasItemImage() || this.hasUserImage() || this.preview.loading ? 1 : 3;
  }

  hasUserDescription (): boolean {
    if (!this.item.urlCache?.url && this.item.editable.text.length > 0) {
      return true;
    }
    return (
        this.item.editable &&
        this.$options.filters?.removeUrl(this.item.editable.text).length > 0
    );
  }

  hasItemImage (): boolean {
    return !!this.item?.urlCache?.image?.href;
  }

  hasUserImage (): boolean | undefined {
    return this.item?.userPhotos && this.item.userPhotos.length > 0;
  }

  hasItemUrl (): boolean {
    return !isItemUrlCacheEmpty(this.item) || !!this.item.urlCache?.url;
  }

  hasItemUrlMeta (): boolean {
    return !!(this.item.urlCache && this.item.urlCache.meta && (this.item.urlCache.meta.title || this.item.urlCache.meta.description));
  }

  hasItemDescription (): boolean {
    return !!this.item?.urlCache?.meta?.description;
  }

  isInProgress (): boolean {
    return this.item?.urlCache?.fetchStatus === FetchStatus.InProgress;
  }

  showCopyItem () {
    if (!this.authenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.ITEM_COPY, {
      uniqueItemName: this.item.uniqueItemName
    });
  }

  showLogin () {
    AuthenticationStore.TOGGLE_PROMPT_SIGNUP({
      state: true,
      metaData: {
        registeredFromAction: EnumRegisteredFromAction.ctaItemCard
      }
    });
  }
}
</script>
