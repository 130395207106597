<template>
  <div class="MHeader">
    <div class="header-left header-item">
      <slot name="left"></slot>
    </div>

    <div class="header-right header-item">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.MHeader {
  position: fixed;
  top: 0;
  z-index: 99;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: .3rem 1rem;

  background-color: var(--white-color);
  box-shadow: 0 3px 13px rgba(0, 0, 0, .08);

  @media screen and (min-width: $desktop) {
    padding: .3rem 7rem;
  }

  .header {
    &-right {
      display: inline-flex;
      gap: 2.5rem;
      align-items: center;
      justify-content: flex-end;

      @media screen and (max-width: 540px) {
        gap: 1.5rem;
      }

      @media screen and (max-width: 500px) {
        gap: 1.4rem;
      }

      @media screen and (max-width: 370px) {
        gap: 1rem;
      }

      @media screen and (max-width: 320px) {
        gap: 1.5rem;
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MHeader extends Vue {}
</script>
