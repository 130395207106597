import { i18n } from '@/plugins/i18n';
import TMainLayout from '@/components/templates/TMainLayout.vue';
import { RouteNames } from '@/router/RouteNames';
import nonSEOChildren from '@/router/routes/nonSEOChildren';
import TMainLayoutSEO from '@/components/templates/TMainLayoutSEO.vue';

const routes = [
  // Underscore pages are designed to limit the quantity of top level URL segments used for base app usage and
  // allows the public channels to take nicer looking links.. eg www.liffery.com/nike
  // Ordering is important here as the vue router checks top down which routes to match
  {
    path: '/-',
    component: TMainLayout,
    children: nonSEOChildren
  },

  // Intended for unauthenticated paths
  {
    path: '/',
    component: TMainLayoutSEO,
    meta: { landingPage: true, ownSEOInj: true },
    children: [
      {
        name: RouteNames.ROUTE_INFO_WHAT_IS,
        meta: { landingPage: true, ownSEOInj: true },
        path: '',
        component: () => import(/* webpackChunkName: "TInfoWhatIsLiffery" */ '@/components/templates/TInfoWhatIsLifferyNew.vue'),
      },
      {
        name: RouteNames.ROUTE_INFO_WHAT_IS_AUTHED,
        meta: {
          auth: true,
          landingPage: true,
          ownSEOInj: true
        },
        path: 'liffery/what',
        component: () => import(/* webpackChunkName: "TInfoWhatIsLifferyAuthd" */ '@/components/templates/TInfoWhatIsLifferyNew.vue'),
      },
      {
        name: RouteNames.ROUTE_INFO_ABOUT_US,
        path: 'liffery/about-us',
        redirect: {
          name: RouteNames.ROUTE_INFO_OUR_STORY
        }
      },
      {
        name: RouteNames.ROUTE_INFO_OUR_STORY,
        meta: { landingPage: true, ownSEOInj: true },
        path: 'liffery/our-story',
        component: () => import(/* webpackChunkName: "TInfoAboutUs" */ '@/components/templates/TInfoAboutUs.vue'),
      },
      {
        name: RouteNames.ROUTE_INFO_BUSINESS,
        meta: {
          landingPage: true,
          ownSEOInj: true,
          seo: {
            title: 'Liffery - For Business'
          }
        },
        path: 'liffery/for-business',
        component: () => import(/* webpackChunkName: "TInfoForBusiness" */ '@/components/templates/TInfoForBusinessNew.vue'),
      },
      {
        name: RouteNames.ROUTE_INFO_CONTACT,
        meta: {
          landingPage: true,
          ownSEOInj: true,
          seo: {
            title: 'Liffery - Contact Us',
            // @ts-ignore
            description: Object.values(i18n.t('page.contact.intro')).join(' ')
          }
        },
        path: 'liffery/contact',
        component: () => import(/* webpackChunkName: "TInfoContactFind" */ '@/components/templates/TInfoContactFindNew.vue'),
      },
      {
        name: RouteNames.ROUTE_INFO_CAREERS,
        meta: {
          landingPage: true,
          ownSEOInj: true,
          seo: {
            title: 'Liffery - Careers',
            description: i18n.t('page.career.landingPageP.1')
          }
        },
        path: 'liffery/careers',
        component: () => import(/* webpackChunkName: "TInfoCareers" */ '@/components/templates/TInfoCareersNew.vue'),
      },
      {
        name: RouteNames.ROUTE_INFO_CAREERS_DEV_FE,
        meta: {
          landingPage: true,
          ownSEOInj: true,
          seo: {
            title: 'Liffery - Career: Frontend Guru'
          },
          description: i18n.t('page.career.jobs.frontendDev.summary')
        },
        path: 'liffery/careers/developer-frontend',
        component: () => import(/* webpackChunkName: "TInfoCareersDevFe" */ '@/components/templates/TInfoCareerDetailPage.vue'),
      },
      {
        name: RouteNames.ROUTE_INFO_CAREERS_DEV_FS,
        meta: {
          landingPage: true,
          ownSEOInj: true,
          seo: {
            title: 'Liffery - Career: Fullstack Typescript',
            description: i18n.t('page.career.jobs.fullStack.summary')
          }
        },
        path: 'liffery/careers/developer-fullstack-typescript',
        component: () => import(/* webpackChunkName: "TInfoCareersDevFs" */ '@/components/templates/TInfoCareerDetailPage.vue'),
      },
      {
        name: RouteNames.ROUTE_INFO_CAREERS_SALES,
        meta: {
          landingPage: true,
          ownSEOInj: true,
          seo: {
            title: 'Liffery - Careers Sales',
            description: i18n.t('page.career.jobs.sales.summary')
          }
        },
        path: 'liffery/careers/sales',
        component: () => import(/* webpackChunkName: "TInfoCareersSales" */ '@/components/templates/TInfoCareerDetailPage.vue'),
      },
      {
        name: RouteNames.ROUTE_INFO_HELP,
        meta: {
          landingPage: true,
          ownSEOInj: true,
          seo: {
            title: 'Liffery - Help',
            description: i18n.t('footer.help')
          }
        },
        path: 'liffery/help',
        component: () => import(/* webpackChunkName: "TInfoHelp.vue" */ '@/components/templates/TInfoHelp.vue'),
      },
      {
        name: RouteNames.ROUTE_INFO_LANDING_PAGES_PERSONAL_SHOPPER_CONSUMER,
        meta: {
          landingPage: true,
          landingFunnelPage: true,
          ownSEOInj: true,
          seo: {
            title: 'Liffery - Personal Shoppers',
            description: 'Your personal (online) shoppers'
          }
        },
        path: 'liffery/landing/personal-shoppers',
        component: () => import(/* webpackChunkName: "personal_shopper_consumer_TVersion1.vue" */ '@/components/templates/landing-pages/personal-shopper/consumer/TVersion1.vue'),
      }
    ],
  },

  // catch for pwa
  {
    path: '/index.html',
    component: TMainLayout,
    children: [
      {
        name: RouteNames.ROUTE_DASHBOARD,
        path: '',
        component: () => import(/* webpackChunkName: "TDashboard" */ '@/components/templates/TDashboard.vue'),
      }
    ],
  },

  // A channel page
  {
    path: '/channel/:channelSlug0/:channelSlug1?/:tab?',
    component: TMainLayout,
    meta: {
      ownSEOInj: true
    },
    children: [
      {
        name: RouteNames.ROUTE_CHANNEL_VIEW,
        path: '',
        component: () => import(/* webpackChunkName: "TChannel" */ '@/components/templates/TChannel.vue'),
      }
    ]
  },

  // An item detail page
  {
    path: '/item/:uniqueItemName',
    component: TMainLayout,
    meta: {
      ownSEOInj: true
    },
    children: [
      {
        name: RouteNames.ROUTE_ITEM_DETAIL_VIEW,
        path: '',
        component: () => import(/* webpackChunkName: "TItemDetail" */ '@/components/templates/TItemDetail.vue'),
      },
      {
        name: RouteNames.ROUTE_ITEM_DETAIL_VIEW_COMMENTS,
        path: '',
        component: () => import(/* webpackChunkName: "TItemDetail" */ '@/components/templates/TItemDetail.vue'),
      },
      {
        name: RouteNames.ROUTE_ITEM_DETAIL_VIEW_LIKES,
        path: '',
        component: () => import(/* webpackChunkName: "TItemDetail" */ '@/components/templates/TItemDetail.vue'),
      }
    ]
  },

  // An item recommendation detail page
  {
    path: '/item/recommendation/:uniqueItemName',
    component: TMainLayout,
    meta: {
      ownSEOInj: false
    },
    children: [
      {
        name: RouteNames.ROUTE_ITEM_RECOMMENDATION_DETAIL_VIEW,
        path: '',
        component: () => import(/* webpackChunkName: "TRecommendationDetail" */ '@/components/templates/TRecommendationDetail.vue'),
      }
    ]
  },

  // Another users profile page
  {
    path: '/profile/:username',
    component: TMainLayout,
    meta: { auth: true },
    children: [
      {
        name: RouteNames.ROUTE_PROFILE,
        path: '',
        component: () => import(/* webpackChunkName: "TProfile" */ '@/components/templates/TProfile.vue'),
      }
    ],
  },

  // Catchall for anything else not matched.. 404
  {
    path: '*',
    component: TMainLayout,
    children: [
      {
        name: RouteNames.ROUTE_LOST,
        path: '',
        component: () => import(/* webpackChunkName: "T404" */ '@/components/templates/T404.vue'),
      }
    ],
  },
];

export default routes;
