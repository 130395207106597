<template>
  <div class="OChannelItemsTable">
    <b-loading :active="loading" :is-full-page="false"/>
    <b-table
        v-if="items.length"
        :data="items"
        :loading="loading"
        scrollable
        paginated
        hoverable
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        backend-sorting
        :default-sort-direction="sortingDirection"
        :default-sort="[sortBy]"
        @sort="onSort"
    >
      <!-- Date added -->
      <b-table-column
          :label="$t('dict.dateAdded')"
          :field="sortByOptions.CreatedAt"
          v-slot="props"
          sortable
          cell-class="item-date-cell"
      >
        {{ new Date(props.row.createdAt).toLocaleDateString() }}
      </b-table-column>
      <!-- Image -->
      <b-table-column :label="$t('dict.image')" v-slot="props" cell-class="item-image-cell">
        <a-router-link-issue-detail :unique-item-name="props.row.uniqueItemName">
          <a-image
              v-if="props.row.urlCache && props.row.urlCache.image && props.row.urlCache.image.href"
              :alt-text="props.row.urlCache.title"
              :image-path="props.row.urlCache.image.href"
              :size="imageSize"
              class="item-image"
          />
          <a-image
              v-else-if="props.row.userPhotos.length"
              :image-path="props.row.userPhotos[0].filePath"
              :size="imageSize"
              class="item-image"
          />
          <span v-else></span>
        </a-router-link-issue-detail>
      </b-table-column>
      <!-- Title, Description and URL -->
      <b-table-column
          :label="$t('dict.item')"
          :field="sortByOptions.UrlCacheurl"
          v-slot="props"
          sortable
          cell-class="item-desc-cell"
      >
        <a-router-link-issue-detail :unique-item-name="props.row.uniqueItemName">
          <p v-if="hasUserDescription(props.row.editable.text)" class="line-clamp-2 item-user-desc">
            <q>{{ props.row.editable.text | removeUrl }}</q>
          </p>
        </a-router-link-issue-detail>
        <template v-if="props.row.urlCache">
          <template v-if="props.row.urlCache.meta">
            <a-router-link-issue-detail :unique-item-name="props.row.uniqueItemName">
              <p v-if="props.row.urlCache.meta.title" class="line-clamp-2 item-title">
                <strong>{{ props.row.urlCache.meta.title }}</strong>
              </p>
            </a-router-link-issue-detail>
            <p v-if="props.row.urlCache.meta.description" class="line-clamp-2 item-desc">
              {{ props.row.urlCache.meta.description }}
            </p>
            <a-router-link-item-url
                v-if="props.row.urlCache.url"
                :url="props.row.urlCache.url"
                classes="item-link"
            />
          </template>
        </template>
      </b-table-column>
      <!-- Number of comments -->
      <b-table-column
          v-if="isLoggedIn"
          :label="$t('dict.comments')"
          :field="sortByOptions.CommentCount"
          v-slot="props"
          sortable
          cell-class="item-comments-cell">
        <span>
          {{ props.row.performanceValues.commentsAndReplies }}
          <message-square-icon size="1x" class="comment-icon"/>
        </span>
      </b-table-column>
      <!-- Number of likes -->
      <b-table-column
          v-if="isLoggedIn"
          :label="$t('dict.likes')"
          :field="sortByOptions.LikeCount"
          v-slot="props"
          sortable
          cell-class="item-likes-cell">
        <span>
          {{ props.row.performanceValues.likes }}
          <thumbs-up-icon size="1x" class="like-icon"/>
        </span>
      </b-table-column>
      <!-- Decision score -->
      <b-table-column
          v-if="isLoggedIn"
          :label="$t('dict.swipeScore')"
          :field="sortByOptions.SwipeScore"
          v-slot="props"
          sortable
          cell-class="item-swipe-score-cell">
        {{ props.row.performanceValues.swipeScore }}
        <a-decision-maker-icon-svg :size="1" class="decision-maker-icon" />
      </b-table-column>
      <!-- Price -->
      <b-table-column
          :label="$t('dict.price')"
          :field="sortByOptions.UrlCachePricePrice"
          v-slot="props"
          sortable
          cell-class="item-price-cell"
      >
        <p v-if="props.row.urlCache && props.row.urlCache.price && props.row.urlCache.price.price">
          <a-item-price :item="props.row" :force-decimal="true" />
        </p>
        <div v-else>
          <!--    TODO: add input     -->
        </div>
      </b-table-column>
    </b-table>

    <div v-else-if="!loading" class="not-found-wrapper">
      <template>
        <p class="title-7 not-found-message">
          {{ $t('containers.items.nonFound.channel') }}
        </p>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep .item-image-cell {
  max-width: 220px;
}

.item-image {
  object-fit: cover;
  max-width: 200px;
  max-height: 200px;
  border-radius: var(--default-border-radius);
}

::v-deep .item-desc-cell {
  min-width: 300px;
  max-width: 400px;
  word-break: break-all;
}

.item-link {
  display: inline-block;
  transition: color .2s;

  &:hover {
    color: var(--secondary-color);
  }
}

.item-user-desc,
.item-title,
.item-desc {
  margin-bottom: 1rem;
}

.like-icon,
.comment-icon {
  margin-left: 4px;
}

::v-deep {
  .item-date-cell,
  .item-price-cell {
    min-width: 120px;
  }

  .item-comments-cell,
  .item-likes-cell {
    min-width: 85px;
    padding-left: 1.5rem;
  }
}

.not-found-wrapper {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-width: 1150px) {
  .item-image {
    max-width: 100px;
    max-height: 100px;
  }
}

@media screen and (max-width: 768px) {
  ::v-deep {
    .item-image-cell,
    .item-desc-cell {
      max-width: 100% !important;

      &::before {
        content: unset !important;
      }
    }

    .item-desc-cell {
      flex-direction: column;
      align-items: flex-start !important;
      text-align: left !important;
    }

    .item-comments-cell,
    .item-likes-cell {
      padding: .5em .75em; // buefy default padding
    }
  }

  .item-image {
    max-width: 50px;
    max-height: 50px;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import AImage from '@/components/atoms/AImage.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import remove1stUrlFromString from '@/utils/remove1stUrlFromString';
import ARouterLinkItemUrl from '@/components/atoms/link/ARouterLinkItemUrl.vue';
import { SortBy, SortDir } from '@/api/ms-item/services/interfaces/ItemsGetQuery';
import { MessageSquareIcon, ThumbsUpIcon } from 'vue-feather-icons';
import ARouterLinkIssueDetail from '@/components/atoms/link/ARouterLinkIssueDetail.vue';
import scrollWindowToElement from '@/utils/scrollWindowToElement';
import { AuthenticationStore, ItemsStore } from '@/store';
import AItemPrice from '@/components/atoms/AItemPrice.vue';
import ADecisionMakerIconSvg from '@/components/atoms/icon/svg/ADecisionMakerIconSvg.vue';

@Component({
  components: {
    ADecisionMakerIconSvg,
    AItemPrice,
    ARouterLinkIssueDetail,
    ARouterLinkItemUrl,
    AImage,
    ThumbsUpIcon,
    MessageSquareIcon
  },
  filters: {
    removeUrl: remove1stUrlFromString,
  },
})
export default class OChannelItemsTable extends Vue {
  @Prop()
  loading!: boolean;

  @Prop({ required: true })
  items!: Item[];

  @Prop({ required: true })
  total!: number;

  @Prop({ default: 20 })
  perPage!: number;

  @Prop({ required: true })
  sortingDirection!: SortDir;

  @Prop({ required: true })
  sortBy!: SortBy;

  imageSize: Size = Size.The200X;
  sortByOptions = SortBy;
  refetchItemsTimeout: any = {};

  get isLoggedIn () {
    return AuthenticationStore.getAuthenticated;
  }

  get noItemsInChannel () {
    return ItemsStore.getItems.items.length;
  }

  mounted () {
    scrollWindowToElement('smooth', '.OChannelItemsTable', true);
    //if using browser back no items will have been loaded yet, emit to fetch the first page of results.
    if (!this.items.length) {
      this.$emit('page-change', 1);
    }
  }

  onPageChange (page: number): void {
    this.$emit('page-change', page);
  }

  onSort (sortBy: SortBy, sortDir: SortDir): void {
    this.$emit('sort-change', { sortBy, sortDir });
  }

  hasUserDescription (text): boolean {
    return this.$options.filters?.removeUrl(text).length > 0;
  }

  @Watch('noItemsInChannel')
  itemsStoreUpdated () {
    clearTimeout(this.refetchItemsTimeout);
    this.refetchItemsTimeout = setTimeout(() => this.$emit('refetch-items'), 350);
  }
}
</script>
