<template>
  <div class="card">
    <div>
      <a-router-link-profile :username="person.username">
        <figure class="image is-64x64">
          <a-user-profile-pic :username="person.username"></a-user-profile-pic>
        </figure>
      </a-router-link-profile>
    </div>

    <div>
      <a-router-link-profile :username="person.username">
        <p class="font-body-l">{{ `${person.firstName} ${person.lastName}` }}</p>
      </a-router-link-profile>
    </div>
    <div class="actions-wrapper">
      <!-- Reject -->
      <a-connection-icon
          :size="'1.6x'"
          connection-type="removeConnection"
          :tooltip-text="$t('dict.reject')"
          v-on:remove-connection-request="$emit('reject-join-request', person)"
          class="link-add-connection"
      />
      <!-- Accept -->
      <a-connection-icon
          :size="'1.6x'"
          connection-type="acceptConnectionRequest"
          :tooltip-text="$t('dict.accept')"
          v-on:accept-connection-request="$emit('accept-join-request', person)"
          class="link-add-connection"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding: 1rem;
}

.connections-icon {
  margin-right: 5px;
}

.actions-wrapper {
  margin-left: auto;
}

.link-add-connection {
  cursor: pointer;
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import AConnectionIcon from '@/components/atoms/buttons/AConnectionIcon.vue';
import { Datum, } from '@/api/ms-authentication/services/interfaces/Connections';

@Component({
  components: {
    AUserProfilePic,
    ARouterLinkProfile,
    AConnectionIcon,
  },
})
export default class MPersonChannelJoinRequestCard extends Vue {
  @Prop(Object)
  person!: Datum;
}
</script>
