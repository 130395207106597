<template>
  <button
      :class="{'app-sso-coming': isIos}"
      class="btn-sso btn-sso-facebook font-button font-caption"
      @click="signIn">
    <a-facebook-icon/>
    {{ message }}
  </button>
</template>

<style lang="scss" scoped>
// _base.scss contains shared .btn-sso styles
.btn-sso-facebook {
  background-color: #4267b2;

  &:hover {
    background-color: darken(#4267b2, 10);
  }

  svg {
    width: 25px;
    height: 25px;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &.app-sso-coming {
    background-color: var(--grey-mid-color);
    position: relative;

    &:after {
      content: '(Coming soon)';
      display: block;
      position: absolute;
      bottom: -18px;
      width: 100%;
      color: var(--grey-dark-color)
    }
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { SSOFb } from '@/utils/SSOFb';
import { AuthenticationStore } from '@/store';

import AFacebookIcon from '@/components/atoms/icon/AFacebookIcon.vue';
import { RouteNames } from '@/router/RouteNames';
import isApp from '@/utils/isApp';
import { LoginFacebookPost } from '@/api/ms-authentication/services/interfaces';
import EventBus, { EventBusEvents } from '@/EventBus';
import { FacebookLoginResponse } from '@capacitor-community/facebook-login';
import isIosApp from '@/utils/isIosApp';

@Component({
  components: {
    AFacebookIcon
  }
})
export default class AFacebookButton extends Vue {
  originalMsg = this.$t('sso.facebook');
  message = this.originalMsg;

  isIos = isIosApp();

  async signIn () {
    if (this.isIos) {
      return this.inAppNotice();
    }
    if (isApp()) {
      await this.signInApp();
    } else {
      await this.signInWeb();
    }
  }

  inAppNotice () {
    this.$buefy.dialog.alert({
      title: 'Coming soon',
      message: 'In-app Facebook login will be available soon. For the time being please use email login. Please use forgot password if you have not already set a password.'
    });
  }

  async signInApp () {
    this.message = this.$t('dict.loading') + '...';

    EventBus.$emit(EventBusEvents.CAPJS_SSO_FB);
    EventBus.$on(EventBusEvents.CAPJS_SSO_FB_SUCCESS, 'MFacebookButton', async (result: FacebookLoginResponse) => {
      const facebookLoginData: LoginFacebookPost = {
        authResponse: {
          accessToken: result.accessToken?.token,
        },
        inviteCode: this.$route.query.inviteCode ? this.$route.query.inviteCode as string : undefined,
      };
      await AuthenticationStore.loginWithFacebook(facebookLoginData);
      await this.$router.push({ name: RouteNames.ROUTE_DASHBOARD });
    });

    EventBus.$on(EventBusEvents.CAPJS_SSO_FB_ERROR, 'MFacebookButton', async (err: any) => {
      console.error(err);
      this.$buefy.dialog.alert({
        title: 'Something went wrong',
        message: 'There was an error trying to login with the Facebook Login button. Please try again or try another login method.',
        onConfirm: () => {
          this.message = this.originalMsg;
        }
      });
    });
  }

  async signInWeb () {
    try {
      this.message = this.$t('dict.loading') + '...';
      const resp = await SSOFb();
      if (this.$route.query.inviteCode) {
        resp.inviteCode = this.$route.query.inviteCode as string;
      }
      await AuthenticationStore.loginWithFacebook(resp);
      await this.$router.push({ name: RouteNames.ROUTE_DASHBOARD });
    } catch (e) {
      this.message = this.originalMsg;
    }
  }
}
</script>
