<template>
  <b-dropdown
      ref="dropdownMenu"
      :mobile-modal="false"
      aria-role="menu"
  >
    <span slot="trigger">
      <span class="is-flex is-align-items-center">
        <chevron-down-icon size="1.2x"/> <span>{{ $t('dict.more') }}</span>
      </span>
    </span>

    <b-dropdown-item
        aria-role="menuitem"
        @click="eventEmitter(EventsToEmit.ChannelInfo)"
    >
      <span>
        <info-icon size="1x"/>
        <span> {{ $t('channel.header.info') }}</span>
      </span>
    </b-dropdown-item>

    <b-dropdown-item
        v-if="currentUserJoined"
        aria-role="menuitem"
        @click="eventEmitter(EventsToEmit.SwipeItems)"
    >
      <span>
        <a-decision-maker-icon-svg />
        <span> {{ $t('dict.swipeScoresName') }}</span>
      </span>
    </b-dropdown-item>

    <b-dropdown-item
        v-if="!channel.archived"
        aria-role="menuitem"
        @click="eventEmitter(EventsToEmit.ShuffleItems)"
    >
      <span>
        <img src="../../assets/svg/shuffle-svg.svg" alt="Wheel of fortune"
             style="height: 1rem;"
             class="is-inline-block"
        />
        <span> {{ $t('dict.shuffleItems') }}</span>
      </span>
    </b-dropdown-item>

    <b-dropdown-item
        aria-role="menuitem"
        @click="eventEmitter(EventsToEmit.ShowSecretShareLink)"
    >
      <span>
        <key-icon size="1x"/>
        <span> {{ $t('dict.secretShareLink') }}</span>
      </span>
    </b-dropdown-item>

    <b-dropdown-item
        aria-role="menuitem"
        @click="eventEmitter(EventsToEmit.ShareChannel)"
        v-if="!channel.archived"
    >
      <span>
        <share2-icon size="1x"/>
        <span> {{ $t('dict.share') }}</span>
      </span>
    </b-dropdown-item>

    <b-dropdown-item
        v-if="!channel.archived"
        :disabled="!currentUserCanEditChannel"
        aria-role="menuitem"
        @click="() => currentUserCanEditChannel && eventEmitter(EventsToEmit.NavigateToEdit)"
    >
      <span>
        <edit-icon class="edit-icon" size="1x"/>
        <span> {{ $t('dict.edit') }}</span>
      </span>
    </b-dropdown-item>

    <b-dropdown-item
        v-if="!channel.archived"
        aria-role="menuitem"
        @click="eventEmitter(EventsToEmit.ChannelNotifications)"
    >
      <span>
        <bell-icon v-if="channelNotificationsEnabled" size="1x"/>
        <bell-off-icon v-else size="1x" />
        {{ $t('dict.notifications') }}
      </span>
    </b-dropdown-item>

    <b-dropdown-item
        aria-role="menuitem"
        @click="eventEmitter(EventsToEmit.ReportChannel)"
    >
      <span>
        <alert-circle-icon size="1x"/>
        {{ $t('dict.reportChannel') }}
      </span>
    </b-dropdown-item>

    <b-dropdown-item
        aria-role="menuitem"
        @click="eventEmitter(EventsToEmit.ArchiveChannel)"
        v-if="currentUserOwnsChannel"
        :disabled="isResearchConcierge"
    >
      <span :title="isResearchConcierge ? $t('page.channel.archived.cannotArchiveConciergeChannel') : ''">
        <archive-icon size="1x"/>
        <template v-if="!channel.archived"> {{ $t('page.channel.archived.archiveChannel') }} </template>
        <template v-else> {{ $t('page.channel.archived.unArchiveChannel') }} </template>
      </span>
    </b-dropdown-item>

    <b-dropdown-item
        :disabled="!currentUserJoined || currentUserOwnsChannel"
        aria-role="menuitem"
        @click="() => currentUserJoined && !currentUserOwnsChannel && eventEmitter(EventsToEmit.LeaveChannel)"
    >
      <span
          :title="$t(currentUserOwnsChannel ? 'dict.leavingChannelCannotWhenOwner': '')">
        <user-x-icon size="1x"/>
        {{ $t('dict.leaveThisChannel') }}
      </span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<style scoped lang="scss">
::v-deep .dropdown-item > span {
  display: flex;
  align-items: center;

  > svg,
  img {
    margin-right: .4rem;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChannelWithRelationalMetaVue } from '@/store/modules/interfaces/ChannelModule';
import {
  AlertCircleIcon,
  ArchiveIcon,
  BellIcon,
  BellOffIcon,
  ChevronDownIcon,
  EditIcon,
  InfoIcon,
  KeyIcon,
  Share2Icon,
  UserXIcon
} from 'vue-feather-icons';
import { ChannelStore } from '@/store';
import ADecisionMakerIconSvg from '@/components/atoms/icon/svg/ADecisionMakerIconSvg.vue';

export enum EventsToEmit {
  ArchiveChannel = 'archiveChannel',
  ChannelInfo = 'channelInfo',
  ChannelNotifications = 'channelNotifications',
  LeaveChannel = 'leaveChannel',
  NavigateToEdit = 'navigateToEdit',
  ReportChannel = 'reportChannel',
  ShareChannel = 'shareChannel',
  ShuffleItems = 'shuffleItems',
  ShowSecretShareLink = 'showSecretShareLink',
  SwipeItems = 'swipeItems'
}

@Component({
  components: {
    ADecisionMakerIconSvg,
    AlertCircleIcon,
    ArchiveIcon,
    BellIcon,
    BellOffIcon,
    ChevronDownIcon,
    EditIcon,
    InfoIcon,
    KeyIcon,
    Share2Icon,
    UserXIcon
  }
})
export default class MChannelHeaderDropdownMenu extends Vue {
  @Prop({required: true})
  channel!: ChannelWithRelationalMetaVue;
  @Prop({required: true})
  currentUserCanEditChannel!: boolean;
  @Prop({required: true})
  isChannelEvent!: boolean;
  @Prop({required: true})
  currentUserOwnsChannel!: boolean;
  @Prop({required: true})
  isResearchConcierge!: boolean;
  @Prop({required: true})
  currentUserJoined!: boolean;

  EventsToEmit = EventsToEmit;

  get channelNotificationsEnabled () {
    return ChannelStore.getCurrentChannelNotificationsEnabled;
  }

  eventEmitter (event: EventsToEmit) {
    this.$emit('dropdown-action', event);
  }
}
</script>