<template>
  <div
      ref="itemCardContainer"
      class="MChannelSwipeItemDraggable item-card"
      :class="itemClassString"
      :style="{
        transform: transformString,
        opacity: nextItemOpacity
      }"
  >
    <o-item-card
        class="swipeItemCard"
        :item="item"
        :current-user="user"
        :preview="static.previewSettings"
        :item-card-title="{isShown: true, title: 'Swipe to decide' }"
        :image-max-height="imageMaxHeight"
    />
    <m-channel-swipe-action-icons
        class="swipe-action-icon"
        v-if="isMoving"
        :direction="directionMoving"
        :style="{opacity: iconOpacity}"
    />
  </div>
</template>

<style scoped lang="scss">
@import 'MChannelSwipeItemDraggable';
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import interact from 'interactjs';
import { XIcon } from 'vue-feather-icons';
import { pause } from 'common-utils/time';
import { Datum as Item } from '@/api/ms-item/services/interfaces/Items';
import OItemCard, { ItemPreview } from '@/components/organisms/OItemCard.vue';
import { Action } from '@/api/ms-item/services/interfaces/SwipeScoresItemNameUniqueItemNamePut';
import MChannelSwipeFooterActions from '@/components/molecules/channel-swipe/MChannelSwipeFooterActions.vue';
import MChannelSwipeActionIcons from '@/components/molecules/channel-swipe/MChannelSwipeActionIcons.vue';
import AChannelSwipeItemUp from '@/components/atoms/channel-swipe/AChannelSwipeItemUp.vue';
import AChannelSwipeItemLeft from '@/components/atoms/channel-swipe/AChannelSwipeItemLeft.vue';
import AChannelSwipeItemRight from '@/components/atoms/channel-swipe/AChannelSwipeItemRight.vue';
import { User } from '@/api/ms-authentication/services/interfaces';
import { ActiveCardProps, Coordinates } from '@/components/organisms/OChannelSwipeItems.vue';
import SwipeScoresService from '@/api/ms-item/services/SwipeScoresService';
import { Channel } from '@/api/ms-channel/services/interfaces';

interface ChannelSwipeStatics {
  interactMaxRotation: number,
  interactXThreshold: number,
  interactYThreshold: number,
  interactIconsAppearsAtPercent: number,
  inertiaSettings: {
    resistance: number,
    minSpeed: number,
    endSpeed: number,
  },
  previewSettings: ItemPreview,
  footerActionAnimateSpeed: number
}

/**
 * Note. "Tinder-like" interactivity of this component built using the following as references:
 *    - https://css-tricks.com/swipeable-card-stack-using-vue-js-and-interact-js/
 *    - https://codesandbox.io/s/z36k32znvp?file=/src/components/Draggable.vue
 */
@Component({
  components: {
    AChannelSwipeItemUp,
    AChannelSwipeItemLeft,
    AChannelSwipeItemRight,
    MChannelSwipeItemDraggable,
    MChannelSwipeFooterActions,
    MChannelSwipeActionIcons,
    OItemCard,
    XIcon
  }
})
export default class MChannelSwipeItemDraggable extends Vue {
  @Prop({ required: true })
  item!: Item;
  @Prop({ required: true })
  channel!: Channel;
  @Prop({ required: true })
  user!: User;
  @Prop({ required: true })
  activeIndex!: number;
  @Prop({ required: true })
  activeCardProps!: ActiveCardProps;
  @Prop({ required: true, default: null })
  footerActionClick!: Action | null;
  @Prop({required: true})
  imageMaxHeight!: number;

  static: ChannelSwipeStatics = {
    interactMaxRotation: 15,
    interactXThreshold: 200,
    interactYThreshold: 300,
    interactIconsAppearsAtPercent: 0.3,
    inertiaSettings: {
      resistance: 20,
      minSpeed: 200,
      endSpeed: 100
    },
    previewSettings: {
      isPreview: true,
      hideFooter: true,
      hideHeader: true
    },
    footerActionAnimateSpeed: 200
  };

  interactPosition: Coordinates = { x: 0, y: 0, rotation: 0 };
  isInteractAnimating: boolean = true;
  swipeActionProcessing: boolean = false;
  actionApplied: boolean = false;
  actionAppliedAndFaded: boolean = false;
  fadeSpeed: number = 250;

  isMoving: boolean = false;
  directionMoving: Action = Action.SwipeRight;
  iconOpacity: number = 0;

  $refs!: {
    itemCardContainer: HTMLDivElement
  };

  get transformString (): string {
    const { x, y, rotation } = this.interactPosition;
    return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
  }

  get itemClassString (): string {
    const classes: string[] = [];
    if (this.isInteractAnimating) {
      classes.push('isAnimating');
    }
    if (!this.isCurrentItem) {
      classes.push('fix-item');
    }
    if (this.isItemHidden) {
      classes.push('hidden');
    }
    if (this.actionApplied) {
      classes.push('fadeOut');
      classes.push('duration-' + this.fadeSpeed);
    }

    return classes.join(' ');
  }

  get isCurrentItem (): boolean {
    return this.activeIndex === this.$vnode.key;
  }

  get isNextItem (): boolean {
    const key = this.$vnode.key as number;
    return this.activeIndex === key - 1;
  }

  get isItemHidden (): boolean {
    const key = this.$vnode.key as number;
    return (key < this.activeIndex && this.actionAppliedAndFaded) || key > this.activeIndex + 1;
  }

  get nextItemOpacity (): number {
    if (!this.isNextItem) {
      return 1;
    }

    return this.activeCardProps.isMoving ? this.activeCardProps.iconOpacity : 0;
  }

  beforeDestroy () {
    interact(this.$refs.itemCardContainer).unset();
  }

  mounted () {
    this.activeIndexWatcher();
  }

  initWatch: () => void = () => {};

  /**
   * Watches the active index. When the active index matches the key for this iteration of the channel swipe
   * draggable we initialise interact.js on this component, and then remove the watcher.
   */
  activeIndexWatcher () {
    this.initWatch = this.$watch('activeIndex', function (newValue) {
      if (newValue === this.$vnode.key) {
        const draggable = this.$refs.itemCardContainer;
        this.initInteract(draggable);
        // invoking the function that stores the watcher removes the watcher
        this.initWatch();
      }
    }, { immediate: true });
  }

  setInteractPosition (coordinates: Coordinates) {
    const { x, y, rotation } = coordinates;
    this.interactPosition = { x, y, rotation };
  }

  resetInteractPosition () {
    this.setInteractPosition({ x: 0, y: 0, rotation: 0 });
    this.setIsMoving();
    this.isMoving = false;
  }

  initInteract (selector: HTMLDivElement) {
    interact(selector).draggable({
      inertia: this.static.inertiaSettings,
      onmove: this.interactOnMove,
      onend: this.interactOnEnd,
    });
  }

  interactOnMove (event) {
    this.isInteractAnimating = false;

    const x = this.interactPosition.x + event.dx;
    const y = this.interactPosition.y + event.dy;
    const rotation = this.setCardRotation(x);

    this.setInteractPosition({ x, y, rotation });
    this.setIsMoving();
  }

  setCardRotation (x: number) {
    const {
      interactMaxRotation,
      interactXThreshold
    } = this.static;

    let rotation = interactMaxRotation * (x / interactXThreshold);
    if (rotation > interactMaxRotation) {
      rotation = interactMaxRotation;
    } else if (rotation < -interactMaxRotation) {
      rotation = -interactMaxRotation;
    }

    return rotation;
  }

  /**
   * Determine the direction that the user is swiping this item, so we can highlight the relevant icon
   */
  setIsMoving () {
    const { x, y } = this.interactPosition;
    const { interactXThreshold, interactYThreshold, interactIconsAppearsAtPercent } = this.static;
    // start displaying the icon halfway to threshold
    const interactXIconAppears = interactXThreshold * interactIconsAppearsAtPercent;
    const interactYIconAppears = interactYThreshold * interactIconsAppearsAtPercent;
    const withinXAxis = (y < interactYThreshold && y > -interactYThreshold);
    const withinYAxis = (x < interactXThreshold && x > -interactXThreshold);
    if (x > interactXIconAppears && withinXAxis) {
      this.isMoving = true;
      this.directionMoving = Action.SwipeRight;
      this.setIconOpacityPercentage(x, interactXIconAppears, interactXThreshold);
    } else if (x < -interactXIconAppears && withinXAxis) {
      this.isMoving = true;
      this.directionMoving = Action.SwipeLeft;
      this.setIconOpacityPercentage(x, -interactXIconAppears, interactXThreshold);
    } else if (y < -interactYIconAppears && withinYAxis) {
      this.isMoving = true;
      this.directionMoving = Action.SwipeUp;
      this.setIconOpacityPercentage(y, -interactYIconAppears, interactYThreshold);
    } else if (y > interactYIconAppears && withinYAxis) {
      this.isMoving = true;
      this.directionMoving = Action.SwipeDown;
      this.setIconOpacityPercentage(y, interactYIconAppears, interactYThreshold);
    } else {
      this.isMoving = false;
      this.iconOpacity = 0;
    }
    const emitProps: ActiveCardProps = {
      isMoving: this.isMoving,
      directionMoving: this.directionMoving,
      iconOpacity: this.iconOpacity,
      coordinates: this.interactPosition
    };
    this.$emit('card-moving', emitProps);
  }

  /**
   * Icon at appears at the halfway mark, but only gets to full opacity at the threshold point.
   */
  setIconOpacityPercentage (movingValue: number, iconAppearsAt: number, iconFullOpacityAt: number) {
    const moving = Math.abs(movingValue);
    const appearsAt = Math.abs(iconAppearsAt);
    const fullOpacity = Math.abs(iconFullOpacityAt);
    if (moving > fullOpacity) {
      this.iconOpacity = 1;
    } else if (moving < appearsAt) {
      this.iconOpacity = 0;
    } else {
      // subtract the appearsAt value from the moving and fullOpacity value, then use those numbers to create a percentage
      const movingBased = moving - appearsAt;
      const fullOpacityBased = fullOpacity - appearsAt;
      this.iconOpacity = (movingBased / fullOpacityBased);
    }
  }

  // check if we have crossed the threshold for any actions, trigger it, or if not reset to centre
  interactOnEnd () {
    this.isInteractAnimating = true;
    const { x, y } = this.interactPosition;
    const { interactXThreshold, interactYThreshold } = this.static;
    const withinXAxis = (y < interactYThreshold && y > -interactYThreshold);
    const withinYAxis = (x < interactXThreshold && x > -interactXThreshold);
    if (x > interactXThreshold && withinXAxis) {
      this.swipeComplete(Action.SwipeRight);
    } else if (x < -interactXThreshold && withinXAxis) {
      this.swipeComplete(Action.SwipeLeft);
    } else if (y < -interactYThreshold && withinYAxis) {
      this.swipeComplete(Action.SwipeUp);
    } else if (y > interactYThreshold && withinYAxis) {
      this.swipeComplete(Action.SwipeDown);
    } else {
      this.resetInteractPosition();
    }
  }

  async swipeComplete (direction: Action) {
    this.swipeActionProcessing = true;
    // void to not wait on the response, let it happen in the background and user can rush onto next card
    void SwipeScoresService.swipeScoresItemNameUniqueItemNamePut({
      channelSlug: this.channel.slug,
      action: direction
    }, {
      uniqueItemName: this.item.uniqueItemName
    });
    this.actionApplied = true;
    // after fade sets display: none and unsets the interact.js to remove all event listeners
    setTimeout(() => {
      interact(this.$refs.itemCardContainer).unset();
      this.actionAppliedAndFaded = true;
    }, this.fadeSpeed);
    this.$emit('next-card', direction);
  }

  // move the card then trigger interact on end
  async footerActionClicked (direction: Action) {
    this.isInteractAnimating = true;
    await this.animateCard(direction);
    this.interactOnEnd();
  }

  // after clicking a footer action we animate the card to move in the relevant direction
  async animateCard (direction: Action) {
    const { interactXThreshold, interactYThreshold } = this.static;
    const slowdownX = interactXThreshold / 50;
    const slowdownY = interactYThreshold / 50;
    let thresholdCrossed = false;
    let x = 0, y = 0;
    while (!thresholdCrossed) {
      switch (direction) {
        case Action.SwipeRight:
          ++x;
          thresholdCrossed = x > interactXThreshold;
          break;
        case Action.SwipeLeft:
          --x;
          thresholdCrossed = x < -interactXThreshold;
          break;
        case Action.SwipeUp:
          --y;
          thresholdCrossed = y < -interactYThreshold;
          break;
        case Action.SwipeDown:
          ++y;
          thresholdCrossed = y > interactYThreshold;
          break;
      }
      const rotation = this.setCardRotation(x);
      this.setInteractPosition({ x, y, rotation });
      this.setIsMoving();
      // slows it down to a more human level
      if ([Action.SwipeRight, Action.SwipeLeft].includes(direction)) {
        if (x % slowdownX === 0) {
          await pause(1);
        }
      } else {
        if (y % slowdownY === 0) {
          await pause(1);
        }
      }
    }
  }

  @Watch('footerActionClick')
  userClickedFooter () {
    if (this.footerActionClick !== null && this.isCurrentItem) {
      this.footerActionClicked(this.footerActionClick);
    }
  }
}
</script>