<template>
  <a-default-svg-from-material-design
      :size="size"
      path="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ADefaultSvgFromMaterialDesign from '@/components/atoms/icon/svg/ADefaultSvgFromMaterialDesign.vue';

@Component({
  components:{
    ADefaultSvgFromMaterialDesign
  }
})
export default class AFormatBlockQuoteIcon extends Vue {
  @Prop({ default: 1 })
  size!: number;
}
</script>