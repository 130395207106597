<template>
  <div class="MWysiwygFixedMenuHeader">
    <span v-for="(control, index) in controls" v-bind:key="index">
      <button
          v-if="!control.maxRange"
          class="menu-item"
          :class="{ 'is-active': control.command.state() }"
          :title="camelCaseToSentenceCase(control.name)"
          @click.prevent="control.command.toggle()"
      >
        <component v-if="control.icon.feather" :is="control.icon.name + 'Icon'" :size="iconSizeFeather"/>
        <component v-else :is="control.icon.name" :size="iconSizeMaterialDesign"/>
      </button>
      <span v-else>
        <button
            v-for="index in control.maxRange"
            class="menu-item"
            v-bind:key="index"
            :class="{ 'is-active': control.command.state(index) }"
            :title="camelCaseToSentenceCase(control.name)"
            @click.prevent="control.command.toggle(index)"
        >
          <component v-if="control.icon.feather" :is="control.icon.name + 'Icon'" :size="iconSizeFeather"/>
          <component v-else :is="control.icon.name" :size="iconSizeMaterialDesign" :value="index - 1"/>
        </button>
      </span>
    </span>
  </div>
</template>

<style scoped lang="scss">
.MWysiwygFixedMenuHeader {
  .menu-item {
    width: 1.75rem;
    height: 1.75rem;
    color: #0d0d0d;
    border: none;
    background-color: transparent;
    border-radius: 0.4rem;
    padding: 0.25rem;
    cursor: pointer;
    vertical-align: middle;

    &.is-active,
    &:hover {
      background-color: var(--grey-darkest-color);
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  BoldIcon,
  CodeIcon,
  CornerDownLeftIcon,
  DropletIcon,
  HelpCircleIcon,
  ItalicIcon,
  ListIcon,
  MinusIcon,
  RotateCcwIcon,
  RotateCwIcon
} from 'vue-feather-icons';
import AFormatBlockQuoteIcon from '@/components/atoms/icon/svg/AFormatBlockQuoteIcon.vue';
import AFormatCodeBlockIcon from '@/components/atoms/icon/svg/AFormatCodeBlockIcon.vue';
import AFormatClearIcon from '@/components/atoms/icon/svg/AFormatClearIcon.vue';
import AFormatHeaderIcon from '@/components/atoms/icon/svg/AFormatHeaderIcon.vue';
import AFormatNumberedListIcon from '@/components/atoms/icon/svg/AFormatNumberedListIcon.vue';
import AFormatParagraphIcon from '@/components/atoms/icon/svg/AFormatParagraphIcon.vue';
import AHighlightIcon from '@/components/atoms/icon/svg/AHighlightIcon.vue';
import AStrikethroughIcon from '@/components/atoms/icon/svg/AStrikethroughIcon.vue';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';
import { camelCaseToSentenceCase } from 'common-utils/string';

export enum ControlEnum {
  Bold = 'bold',
  Italic = 'italic',
  Strike = 'strike',
  Code = 'code',
  Highlight = 'highlight',
  Colour = 'colour',
  Heading = 'heading',
  Paragraph = 'paragraph',
  BulletList = 'bulletList',
  NumberedList = 'numberedList',
  TaskList = 'taskList',
  CodeBlock = 'codeBlock',
  Blockquote = 'blockquote',
  HorizontalRule = 'horizontalRule',
  HardBreak = 'hardBreak',
  ClearFormat = 'clearFormat',
  Undo = 'undo',
  Redo = 'redo',
  QuickHelp = 'quickHelp'
}

interface ControlIcon {
  feather: boolean;
  name: string;
}

export interface Control {
  name: ControlEnum;
  on: boolean;
}

interface ControlDetails extends Control {
  maxRange?: number; // E.g. for heading, h1, h2, etc
  icon: ControlIcon;
  command?: Commands;
}

interface Commands {
  toggle: Function;
  state: Function;
}

@Component({
  components: {
    ATooltip,
    BoldIcon,
    CodeIcon,
    CornerDownLeftIcon,
    DropletIcon,
    HelpCircleIcon,
    ItalicIcon,
    ListIcon,
    MinusIcon,
    RotateCcwIcon,
    RotateCwIcon,
    AFormatBlockQuoteIcon,
    AFormatCodeBlockIcon,
    AFormatClearIcon,
    AFormatHeaderIcon,
    AFormatNumberedListIcon,
    AFormatParagraphIcon,
    AHighlightIcon,
    AStrikethroughIcon
  },
  methods: {
    camelCaseToSentenceCase: camelCaseToSentenceCase
  }
})
export default class MWysiwygTipTapFixedMenuHeader extends Vue {
  @Prop()
  editor!: any;

  @Prop({ default: [] })
  controlOverrides!: Control[];

  iconSizeFeather = '1x';
  iconSizeMaterialDesign = '1';

  /**
   * Control commands. Toggle for applying the command, state tells us if active
   */
  bold = {
    toggle: () => this.editor.commands.toggleBold(),
    state: () => this.settingIsActive('bold')
  };
  italic = {
    toggle: () => this.editor.commands.toggleItalic(),
    state: () => this.settingIsActive('italic')
  };
  strike = {
    toggle: () => this.editor.commands.toggleStrike(),
    state: () => this.settingIsActive('strike')
  };
  code = {
    toggle: () => this.editor.commands.toggleCode(),
    state: () => this.settingIsActive('code')
  };
  highlight = {
    toggle: () => this.editor.commands.toggleHighlight(),
    state: () => this.settingIsActive('highlight')
  };
  heading = {
    toggle: (level) => this.editor.commands.toggleHeading({ level: level }),
    state: (level) => this.settingIsActive('heading', { level: level })
  };
  paragraph = {
    toggle: () => this.editor.commands.setParagraph(),
    state: () => this.settingIsActive('paragraph')
  };
  bulletList = {
    toggle: () => this.editor.commands.toggleBulletList(),
    state: () => this.settingIsActive('bulletList')
  };
  numberedList = {
    toggle: () => this.editor.commands.toggleOrderedList(),
    state: () => this.settingIsActive('orderedList')
  };
  codeBlock = {
    toggle: () => this.editor.commands.toggleCodeBlock(),
    state: () => this.settingIsActive('codeBlock')
  };
  blockquote = {
    toggle: () => this.editor.commands.toggleBlockquote(),
    state: () => this.settingIsActive('blockquote')
  };
  horizontalRule = {
    toggle: () => this.editor.commands.setHorizontalRule(),
    state: () => this.settingIsActive('horizontalRule')
  };
  hardBreak = {
    toggle: () => this.editor.commands.setHardBreak(),
    state: () => false
  };
  clearFormatting = {
    toggle: () => {
      this.editor.commands.clearNodes();
      this.editor.commands.unsetAllMarks();
    },
    state: () => false
  };
  undo = {
    toggle: () => this.editor.commands.undo(),
    state: () => false
  };
  redo = {
    toggle: () => this.editor.commands.redo(),
    state: () => false
  };
  quickHelp = {
    toggle: () => this.quickHelpMessage(),
    state: () => false
  };

  // Gets called before editor is loaded so simple if defined check to prevent errors
  settingIsActive (setting: string, params?: any) {
    if( this.editor ){
      return this.editor.isActive(setting, params);
    }
  }

  /**
   * Default controls on:
   *  Bold, Italic, Strikethrough, Highlight, Heading 1-2, Bullet List, Numbered List, Block Quote, Horizontal Rule,
   *  Hard Break, Clear Format, Undo and Redo
   */
  controls: ControlDetails[] = [
    { name: ControlEnum.Bold, on: true, icon: { feather: true, name: 'Bold' }, command: this.bold },
    { name: ControlEnum.Italic, on: true, icon: { feather: true, name: 'Italic' }, command: this.italic },
    { name: ControlEnum.Strike, on: true, icon: { feather: false, name: 'AStrikethroughIcon' }, command: this.strike },
    { name: ControlEnum.Code, on: false, icon: { feather: true, name: 'Code' }, command: this.code },
    {
      name: ControlEnum.Highlight,
      on: true,
      icon: { feather: false, name: 'AHighlightIcon' },
      command: this.highlight
    },
    {
      name: ControlEnum.Heading,
      on: true,
      maxRange: 2,
      icon: { feather: false, name: 'AFormatHeaderIcon' },
      command: this.heading
    },
    {
      name: ControlEnum.Paragraph,
      on: false,
      icon: { feather: false, name: 'AFormatParagraphIcon' },
      command: this.paragraph
    },
    { name: ControlEnum.BulletList, on: true, icon: { feather: true, name: 'List' }, command: this.bulletList },
    {
      name: ControlEnum.NumberedList,
      on: true,
      icon: { feather: false, name: 'AFormatNumberedListIcon' },
      command: this.numberedList
    },
    {
      name: ControlEnum.CodeBlock,
      on: false,
      icon: { feather: false, name: 'AFormatCodeBlockIcon' },
      command: this.codeBlock
    },
    {
      name: ControlEnum.Blockquote,
      on: true,
      icon: { feather: false, name: 'AFormatBlockQuoteIcon' },
      command: this.blockquote
    },
    {
      name: ControlEnum.HorizontalRule,
      on: true,
      icon: { feather: true, name: 'Minus' },
      command: this.horizontalRule
    },
    { name: ControlEnum.HardBreak, on: true, icon: { feather: true, name: 'CornerDownLeft' }, command: this.hardBreak },
    {
      name: ControlEnum.ClearFormat,
      on: true,
      icon: { feather: false, name: 'AFormatClearIcon' },
      command: this.clearFormatting
    },
    { name: ControlEnum.Undo, on: true, icon: { feather: true, name: 'RotateCcw' }, command: this.undo },
    { name: ControlEnum.Redo, on: true, icon: { feather: true, name: 'RotateCw' }, command: this.redo },
    { name: ControlEnum.QuickHelp, on: true, icon: { feather: true, name: 'HelpCircle' }, command: this.quickHelp }
  ];

  mounted () {
    // apply any control overrides passed to the component
    for (let i = 0; i < this.controlOverrides.length; i++) {
      this.controls = this.controls.map((control) => control.name === this.controlOverrides[i].name ? {
        ...control,
        on: this.controlOverrides[i].on
      } : control);
    }

    // remove all controls not in use
    this.controls = this.controls.filter((control) => control.on);
  }

  quickHelpMessage () {
    let message = '';
    for (let i = 0; i < this.controls.length; i++) {
      if (this.controls[i].on) {
        const translationMapping = 'wysiwygEditor.shortcuts.' + this.controls[i].name;
        const translation = this.$t(translationMapping) as string;
        if (translation.length && translation !== translationMapping) {
          const controlName = camelCaseToSentenceCase(this.controls[i].name);
          message += `<p class="has-text-weight-bold">${controlName}</p><p class="mb-3">${translation}</p>`;
        }
      }
    }
    this.$buefy.dialog.alert({
      title: this.$t('wysiwygEditor.quickHelp.title') as string,
      message: message
    });
  }
}
</script>