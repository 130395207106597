<template>
  <div class="is-italic has-text-centered pt-4 pb-2 mb-6 font-bold">
    {{ $t('raffle.launch21.prompt') }}
    <a class="colour-primary" target="_blank" href="https://blog.liffery.com/2021/12/11/free-to-join-250-launch-raffle/">{{
        $t('raffle.launch21.promptLinkTxt')
      }}</a>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ARafflePrompt extends Vue {
}
</script>
