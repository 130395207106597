<template>
  <div class="OWelcomeGuide">
    <b-steps
        v-model="activeStep"
        :animated="true"
        :has-navigation="false"
        :mobile-mode="'compact'"
        :rounded="true">
      <b-step-item :clickable="isStepsClickable" :label="$t('raffle.welcome.step1.step')" step="1">
        <div class="columns">
          <div class="column is-one-third pt-6">
            <img
                :alt="$t('whatIsLiffery.addItemsTitle')"
                class="section-image"
                src="@/assets/svg/what-is-add-items.svg"
            />
          </div>
          <div class="column is-flex is-align-items-center welcome-text pt-6" v-html="$t('raffle.welcome.step1.copy')"/>
        </div>

        <p class="has-text-centered">
          <b-button class="is-primary is-outlined" @click="() => ++activeStep">{{ $t('dict.next') }}</b-button>
        </p>
      </b-step-item>

      <b-step-item :clickable="isStepsClickable" :label="$t('raffle.welcome.step2.step')" step="2">
        <div class="columns pt-6">
          <div class="column is-align-items-center welcome-text" v-html="$t('raffle.welcome.step2.copy')"/>
        </div>
        <p class="has-text-centered">
          <b-button class="is-primary is-outlined" @click="() => --activeStep">{{ $t('dict.back') }}</b-button>
          <b-button class="is-primary is-outlined ml-4" @click="() => ++activeStep">{{ $t('dict.next') }}</b-button>
        </p>
      </b-step-item>

      <b-step-item :clickable="isStepsClickable" :label="$t('raffle.welcome.step3.step')" step="3">
        <div class="columns pt-6 is-centered">
          <div class="column is-flex is-two-thirds is-align-items-center welcome-text" v-html="$t('raffle.welcome.step3.copy')"/>
        </div>

        <p class="has-text-centered">
          <b-button class="is-primary is-outlined" @click="() => --activeStep">{{ $t('dict.back') }}</b-button>
          <b-button class="is-primary is-outlined ml-4" @click="closeModal">{{ $t('dict.start') }}</b-button>
        </p>
      </b-step-item>
    </b-steps>
  </div>
</template>

<style lang="scss" scoped>
.section-image {
  padding-top: 15%;

  @media screen and (max-width: 770px) {
    display: block;
    max-height: 12rem;
    margin: 0 auto;
    padding-top: 1rem;
  }
}

.welcome-text {
  line-height: 150%;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';

@Component({
  components: { MLanguageSwitcher }
})
export default class OWelcomeGuide extends Vue {
  isStepsClickable = false;
  activeStep = 0;

  async closeModal () {
    await AuthenticationStore.updateUserWelcomed(true);
    EventBus.$emit(EventBusEvents.ITEM_ADD_PULSE);
    this.$emit('close');
  }
}
</script>
