import Cookie from '@/services/Cookies';
import { CookieNames } from '@/enums/CookieNames';
import { AuthenticationStore } from '@/store';
import { getDateInXMonths } from 'common-utils/time';

export default async () => {
  if (!AuthenticationStore.getAuthenticated) {
    const firstPageEverLoaded = await Cookie.get(CookieNames.FIRST_EVER_PAGE_LOADED) || window.location.href;
    await Cookie.set(
      CookieNames.FIRST_EVER_PAGE_LOADED,
      firstPageEverLoaded,
      { expires: getDateInXMonths(18) }
    );
  }
};

export const get1stPageLoaded = async (): Promise<string> => {
  return await Cookie.get(CookieNames.FIRST_EVER_PAGE_LOADED) || window.location.href;
};
