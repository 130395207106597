<template>
  <div class="MKeyboardDirectionPad">
    <a-menu-up-svg class="keyboard-direction direction-up"
                   :class="{'highlight-key': this.pressedKeys.ArrowUp }" :size="iconSize"/>
    <a-menu-left-svg class="keyboard-direction direction-left"
                     :class="{'highlight-key': this.pressedKeys.ArrowLeft}" :size="iconSize"/>
    <a-menu-down-svg class="keyboard-direction direction-down"
                     :class="{'highlight-key': this.pressedKeys.ArrowDown}" :size="iconSize"/>
    <a-menu-right-svg class="keyboard-direction direction-right"
                      :class="{'highlight-key': this.pressedKeys.ArrowRight}"
                      :size="iconSize"/>
  </div>
</template>

<style scoped lang="scss">
.MKeyboardDirectionPad {
  display: inline-grid;
  gap: .1rem .1rem;
  place-items: center;
  padding: .5rem;

  .keyboard-direction {
    border: solid 1px var(--grey-dark-color);
    border-radius: 10%;

    &.direction {
      &-up {
        grid-column: 2;
        grid-row: 1;
      }

      &-left {
        grid-column: 1;
        grid-row: 2;
      }

      &-down {
        grid-column: 2;
        grid-row: 2;
      }

      &-right {
        grid-column: 3;
        grid-row: 2;
      }
    }

    &.highlight-key {
      background-color: grey;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import AMenuUpSvg from '@/components/atoms/icon/svg/AMenuUpSvg.vue';
import AMenuLeftSvg from '@/components/atoms/icon/svg/AMenuLeftSvg.vue';
import AMenuDownSvg from '@/components/atoms/icon/svg/AMenuDownSvg.vue';
import AMenuRightSvg from '@/components/atoms/icon/svg/AMenuRightSvg.vue';
import { KeyboardNavigationKeys } from '@/enums/KeyboardNavigationKeys';

@Component({
  components: { AMenuRightSvg, AMenuDownSvg, AMenuLeftSvg, AMenuUpSvg }
})
export default class MKeyboardDirectionPad extends Vue {
  @Prop({ required: false, default: false })
  initKeyboardEventListener!: boolean;

  iconSize: number = 1.25;

  pressedKeys: {
    ArrowUp: boolean,
    ArrowLeft: boolean,
    ArrowDown: boolean,
    ArrowRight: boolean,
  } = {
    ArrowUp: false,
    ArrowLeft: false,
    ArrowDown: false,
    ArrowRight: false
  };
  ArrowKeys: KeyboardNavigationKeys[] = [KeyboardNavigationKeys.ArrowUp, KeyboardNavigationKeys.ArrowLeft, KeyboardNavigationKeys.ArrowDown, KeyboardNavigationKeys.ArrowRight];

  created () {
    if (this.initKeyboardEventListener) {
      this.eventsBind();
    }
  }

  beforeDestroy () {
    if (this.initKeyboardEventListener) {
      this.eventsUnbind();
    }
  }

  eventsBind () {
    window.addEventListener('keydown', this.keyboardNavigationDownListener);
    window.addEventListener('keyup', this.keyboardNavigationUpListener);
  }

  eventsUnbind () {
    window.removeEventListener('keydown', this.keyboardNavigationDownListener);
    window.removeEventListener('keyup', this.keyboardNavigationUpListener);
  }

  keyboardNavigationDownListener (e) {
    if (this.ArrowKeys.includes(e.key)) {
      this.pressedKeys[e.key] = true;
      this.$emit('keydown', e.key);
    }
  }

  keyboardNavigationUpListener (e) {
    if (this.ArrowKeys.includes(e.key)) {
      this.pressedKeys[e.key] = false;
      // emit keyup, and if no other key is currently being pressed emit solo keyup
      this.$emit('keyup', e.key);
      if (!Object.values(this.pressedKeys).includes(true)) {
        this.$emit('solo-keyup', e.key);
      }
    }
  }
}
</script>