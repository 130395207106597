<template>
  <div class="OItemLikes">
    <div class="likes-wrapper">
      <div v-for="(like, index) in likes" :key="index" class="user-like">
        <b-tooltip v-if="like.like" :label="like.actor.firstName + ' ' + like.actor.lastName">
          <a-router-link-profile :username="like.actor.username">
            <a-user-profile-pic :imageSize="userImageSize" :username="like.actor.username"></a-user-profile-pic>
          </a-router-link-profile>
        </b-tooltip>
      </div>
      <div v-if="!likes.length">
        {{ $t('dict.noLikesYet') }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.OItemLikes {
  padding: .5rem 0 .5rem;
}

.likes-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.user-like {
  flex-shrink: 0;
}

</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ItemDetailStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';

import OItemCommentForm from '@/components/organisms/forms/OItemCommentForm.vue';
import MItemCommentList from '@/components/molecules/comment/MItemCommentList.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';
import { ThumbsUpIcon } from 'vue-feather-icons';

@Component({
  components: {
    ARouterLinkProfile,
    AUserProfilePic,
    MItemCommentList,
    OItemCommentForm,
    ThumbsUpIcon
  }
})
export default class OItemLikes extends Vue {
  userImageSize = Size.The60X60;
  loading: boolean = false;

  get likes () {
    return ItemDetailStore.getItemLikes;
  }
}
</script>
