<template>
  <b-dropdown
      :mobile-modal="false"
      :scrollable="scrollable"
      :max-height="maxHeight"
      aria-role="list"
      class="card-dropdown"
      :position="dropdownPosition"
      :append-to-body="appendToBody"
  >
    <more-vertical-icon
        slot="trigger"
        class="card-actions-icon"
        size="1.5x"
    />

    <!-- Item detail link -->
    <b-dropdown-item
        v-if="includeItemDetailLink"
        aria-role="listitem"
        @click="goToItemDetail"
    >
      <chevron-right-icon class="dropdown-item-icon" :size="featherIconSize"/>
      {{ $t('itemMenu.itemDetail') }}
    </b-dropdown-item>

    <!-- Watch item -->
    <b-dropdown-item
        v-if="authenticated"
        aria-role="listitem"
        @click="priceWatchItem"
        :disabled="!!(!itemHasPrice || isAlreadyPriceWatched)"
    >
      <activity-icon class="dropdown-item-icon" :size="featherIconSize"/>
      {{ isAlreadyPriceWatched ? $t('itemMenu.priceAlreadyWatched') : itemHasPrice ? $t('itemMenu.priceWatch') : $t('itemMenu.priceWatchNotAvailable') }}
    </b-dropdown-item>

    <!-- Go to map -->
    <b-dropdown-item
        v-if="item.editable.geolocationData"
        aria-role="listitem"
        @click="clickOutToMap"
    >
      <a-map-out-link
          :item="item"
          :label-show="true"
          :dont-click-out="true"
      />
      {{ $t('itemMenu.openInMaps') }}
    </b-dropdown-item>

    <!-- Share item -->
    <b-dropdown-item
        aria-role="listitem"
        @click="shareItem"
    >
      <share-icon v-if="isIos" class="dropdown-item-icon" :size="featherIconSize"/>
      <share2-icon v-else class="dropdown-item-icon" :size="featherIconSize"/>
      {{ $t('itemMenu.share') }}
    </b-dropdown-item>

    <!-- Remind item -->
    <b-dropdown-item
        v-if="
                authenticated &&
                item.actor &&
                (currentUser.username === item.actor.username || isUserManagerOfChannel)
              "
        aria-role="listitem"
        @click="editItem"
    >
      <a-alarm-clock-icon class="dropdown-item-icon" :size="lifferyIconSize" :keep-y-zero="true" />
      {{ $t('itemMenu.reminder') }}
    </b-dropdown-item>

    <!-- Edit item -->
    <b-dropdown-item
        v-if="
                authenticated &&
                item.actor &&
                (currentUser.username === item.actor.username || isUserManagerOfChannel)
              "
        aria-role="listitem"
        @click="editItem"
    >
      <edit2-icon class="dropdown-item-icon" :size="featherIconSize"/>
      {{ $t('itemMenu.edit') }}
    </b-dropdown-item>

    <!-- Add item to channel (Copy item) -->
    <b-dropdown-item
        v-if="authenticated"
        aria-role="listitem"
        @click="openCopyItem"
    >
      <a-add-item-icon class="dropdown-item-icon" :size="lifferyIconSize" size-units="em" />
      {{ $t('dict.addToChannel') }}
    </b-dropdown-item>

    <!-- Move item -->
    <b-dropdown-item
        v-if="
                authenticated &&
                item.actor &&
                currentUser.username === item.actor.username
              "
        aria-role="listitem"
        @click="moveItem"
    >
      <move-icon class="dropdown-item-icon" :size="featherIconSize"/>
      {{ $t('itemMenu.moveTo') }}
    </b-dropdown-item>

    <!-- Remove from shopping list - this was on item detail page only, and using old icon. Todo - Commented out for now. Can uncomment, make conditional, or delete... -->
    <!--    <b-dropdown-item-->
    <!--        v-if="isAuthenticated && currentUserGetGot"-->
    <!--        aria-role="listitem"-->
    <!--        @click="openShoppingListRemoveModal"-->
    <!--    >-->
    <!--      &lt;!&ndash; todo - icon needs updating to current shopping list bag icon &ndash;&gt;-->
    <!--      <img class="dropdown-item-icon shopping-list-icon" src="@/assets/svg/icon-list.svg"/>-->
    <!--      {{ $t('dict.removeFromShoppingList') }}-->
    <!--    </b-dropdown-item>-->

    <!-- Delete item -->
    <b-dropdown-item
        v-if="item.actor && (currentUser.username === item.actor.username || isUserManagerOfChannel)"
        aria-role="listitem"
        @click="goToDeleteItem"
    >
      <x-icon class="dropdown-item-icon" :size="featherIconSize"/>
      {{ $t('itemMenu.delete') }}
    </b-dropdown-item>

    <!-- Report item -->
    <b-dropdown-item
        v-if="displayReportItem()"
        aria-role="listitem"
        @click="openReportItem"
    >
      <alert-circle-icon class="dropdown-item-icon" :size="featherIconSize"/>
      {{ $t('dict.reportItem') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<style scoped lang="scss">
.card-dropdown {
  .card-actions-icon {
    //margin-left: 1rem;
    cursor: pointer;
    transition: color .2s;

    &:hover {
      color: var(--secondary-color);
    }
  }

  .dropdown-item {
    display: flex;
    gap: .8rem;
    align-items: center;
  }

  .shopping-list-icon {
    height: 16px;
  }
}

// safari fix
/* stylelint-disable */
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) {
    .dropdown-item-icon {
      margin-right: .5rem;
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import AMapOutLink from '@/components/atoms/link/AMapOutLink.vue';
import { RouteNames } from '@/router/RouteNames';
import clickOutToMap from '@/utils/clickOutToMap';
import shareOrCopyItem from '@/utils/shareOrCopyItem';
import isIosApp from '@/utils/isIosApp';
import { AuthenticationStore, ChannelsStore, WatchersStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';
import { User } from '@/api/ms-authentication/services/interfaces';
import {
  ActivityIcon,
  AlertCircleIcon,
  ChevronRightIcon,
  Edit2Icon,
  MoreVerticalIcon,
  MoveIcon,
  Share2Icon,
  ShareIcon,
  XIcon
} from 'vue-feather-icons';
import itemHasPrice from '@/utils/itemHasPrice';
import AAlarmClockIcon from '@/components/atoms/icon/svg/AAlarmClockIcon.vue';
import AAddItemIcon from '@/components/atoms/icon/AAddItemIcon.vue';
import { EnumRegisteredFromAction } from '@/store/modules/interfaces/AuthenticationModule';

@Component({
  components: {
    AAddItemIcon,
    AAlarmClockIcon,
    AMapOutLink,
    ActivityIcon,
    AlertCircleIcon,
    ChevronRightIcon,
    Edit2Icon,
    MoreVerticalIcon,
    MoveIcon,
    ShareIcon,
    Share2Icon,
    XIcon
  }
})
export default class MItemActionsDropdown extends Vue {
  @Prop()
  item!: Item;
  @Prop()
  currentUser!: User;
  @Prop({ required: false, default: false })
  loadedFromItemDetail!: boolean;
  @Prop({ required: false, default: 'is-bottom-left' })
  dropdownPosition!: string;
  @Prop({ required: false, default: false })
  includeItemDetailLink!: boolean;
  // true on table view, otherwise is clipped by table edges
  @Prop({ required: false, default: false })
  appendToBody!: boolean;
  @Prop()
  cardHeight!: number;

  isIos = isIosApp();
  featherIconSize = '1.5x';
  lifferyIconSize = 1.5;

  get scrollable (): boolean {
    return this.maxHeight < 234;
  }

  get maxHeight (): number {
    return this.cardHeight - 70 + 25;
  }

  get authenticated () {
    return AuthenticationStore.authenticated;
  }

  get isUserManagerOfChannel (): boolean {
    return !!ChannelsStore.getChannelsManagerOf.find((channel) => channel.slug === this.item.editable.channel?.slug);
  }

  get itemHasPrice (): boolean {
    return itemHasPrice(this.item);
  }

  get isAlreadyPriceWatched () {
    return WatchersStore.getWatchers.find((watcher) => watcher.itemUniqueName === this.item.uniqueItemName);
  }

  showLogin () {
    AuthenticationStore.TOGGLE_PROMPT_SIGNUP({
      state: true,
      metaData: {
        registeredFromAction: EnumRegisteredFromAction.ctaItemCard
      }
    });
  }

  goToItemDetail () {
    this.$router.push({
      name: RouteNames.ROUTE_ITEM_DETAIL_VIEW, params: {
        uniqueItemName: this.item.uniqueItemName,
      }
    });
  }

  clickOutToMap () {
    clickOutToMap(this.item);
  }

  priceWatchItem () {
    EventBus.$emit(EventBusEvents.ITEM_PRICE_WATCH, this.item);
  }

  shareItem () {
    const fullPathOfItem = this.$router.resolve({
      name: RouteNames.ROUTE_ITEM_DETAIL_VIEW,
      params: {
        uniqueItemName: this.item.uniqueItemName
      }
    });
    shareOrCopyItem(this.item, fullPathOfItem.href);
  }

  openCopyItem () {
    if (!this.authenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.ITEM_COPY, {
      uniqueItemName: this.item.uniqueItemName
    });
  }

  moveItem () {
    if (!this.authenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.ITEM_EDIT, {
      uniqueItemName: this.item.uniqueItemName,
      isMovingItem: true
    });
  }

  editItem () {
    if (!this.authenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.ITEM_EDIT, {
      uniqueItemName: this.item.uniqueItemName
    });
  }

  async goToDeleteItem () {
    if (!this.loadedFromItemDetail) {
      await this.$router.push({
        name: RouteNames.ROUTE_ITEM_DETAIL_VIEW, params: {
          uniqueItemName: this.item.uniqueItemName,
        }
      });
    }

    EventBus.$emit(EventBusEvents.ITEM_DELETE, {
      uniqueItemName: this.item.uniqueItemName,
      title: this.item.urlCache?.meta?.title || this.$t('dict.thisItem')
    });
  }

  openReportItem () {
    if (!this.authenticated) {
      return this.showLogin();
    }
    EventBus.$emit(EventBusEvents.ITEM_REPORT, {
      item: JSON.parse(JSON.stringify(this.item)),
    });
  }

  displayReportItem (): boolean {
    if (!this.authenticated) {
      return true;
    } else {
      return this.currentUser?.username !== this.item?.actor?.username;
    }
  }
}
</script>
