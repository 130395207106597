import { render, staticRenderFns } from "./OItemLikes.vue?vue&type=template&id=649b200b&scoped=true"
import script from "./OItemLikes.vue?vue&type=script&lang=ts"
export * from "./OItemLikes.vue?vue&type=script&lang=ts"
import style0 from "./OItemLikes.vue?vue&type=style&index=0&id=649b200b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "649b200b",
  null
  
)

export default component.exports