import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Auth from '@/services/AuthenticationService';
import AuthenticationService from '@/services/AuthenticationService';
import {
  CaptchaPost,
  Generic422,
  JwtRenewAccessOrRenewalPost,
  Login,
  LoginEmailPost,
  LoginFacebookPost,
  LoginGooglePost,
  RegisterEmailPost,
  User,
  UserEmailPut,
  UserMeta,
  UserPasswordPut,
  UserPut,
} from '@/api/ms-authentication/services/interfaces';
import { TAB_LOGIN, TAB_PASSWORD_RESET, TAB_REGISTER } from '@/constants/tabs';
import EventBus, { EventBusEvents } from '@/EventBus';
import LoginService from '@/api/ms-authentication/services/LoginService';
import CaptchaService from '@/api/ms-authentication/services/CaptchaService';
import LogoutService from '@/api/ms-authentication/services/LogoutService';
import TokenService from '@/api/ms-authentication/services/TokenService';
import RegisterService from '@/api/ms-authentication/services/RegisterService';
import UserService from '@/api/ms-authentication/services/UserService';
import WebSocket from '@/socket/WebSocket';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import {
  IAuthenticationModule,
  InitialGenericError,
  InitialUserMetaState,
  InitialUserState,
  ISignUpMetData
} from '@/store/modules/interfaces/AuthenticationModule';
import config, { BuildType, BuildTypeFor } from '@/config';
import { AuthenticationStore, ChannelsStore, LifferyTourStore, ShoppingListStore } from '@/store';
import { Language } from '@/api/ms-authentication/services/interfaces/User';
import setLanguage from '@/utils/setLanguage';
import { AvailableTours } from '@/components/organisms/OVueTour.types';
import UserAppSettingsHydrate from '@/services/UserAppSettingsHydrate';
import { Location as RouteLocation, Route } from 'vue-router';
import { FromAppType } from '@/api/ms-authentication/services/interfaces/UserGetQuery';
import isIosApp from '@/utils/isIosApp';
import isAndroidApp from '@/utils/isAndroidApp';
import { RegisteredForPurpose } from '@/api/ms-authentication/services/interfaces/RegisterEmailPost';

@Module({
  name: StoreNames.AUTHENTICATION_STORE,
  namespaced: true,
})
export default class AuthenticationModule extends VuexModule implements IAuthenticationModule {
  loginCaptchaRequired = false;
  lastEmailLoginAttempt = '';
  authenticated = false;
  errorCaptcha = InitialGenericError;
  errorRegister = InitialGenericError;
  errorLogin = InitialGenericError;
  prompt: { login: boolean, loginActiveTab: number, metaData: ISignUpMetData } = {
    login: false,
    loginActiveTab: 0,
    metaData: {}
  };
  user: User = InitialUserState;
  userMeta: UserMeta = InitialUserMetaState;
  userMetaOther: UserMeta = InitialUserMetaState;
  accessToken: string = '';
  renewalToken: string = '';
  loginForwardingLocation: RouteLocation | null = null;

  get getTokens (): { accessToken: string, renewalToken: string } {
    return {
      accessToken: this.accessToken,
      renewalToken: this.renewalToken,
    };
  }

  get getAuthenticated () {
    return this.authenticated;
  }

  get currentUser () {
    return this.user;
  }

  get userMetaData () {
    return this.userMeta;
  }

  get userMetaOtherData () {
    return this.userMetaOther;
  }

  get errorCaptchaContent () {
    return this.errorCaptcha.errors;
  }

  get errorLoginContent () {
    return this.errorLogin.errors;
  }

  get errorRegisterContent () {
    return this.errorRegister.errors;
  }

  get captchaRequired () {
    return this.loginCaptchaRequired;
  }

  get forwardingLocation () {
    return this.loginForwardingLocation;
  }

  get getPromptData () {
    return this.prompt;
  }

  @Mutation
  RESET () {
    this.lastEmailLoginAttempt = '';
    this.loginCaptchaRequired = false;
    this.authenticated = false;
    this.errorCaptcha = {};
    this.errorRegister = {};
    this.errorLogin = {};
    this.prompt = {
      login: false,
      loginActiveTab: 0,
      metaData: {}
    };
    this.user = InitialUserState;
    this.userMeta = InitialUserMetaState;
    this.userMetaOther = InitialUserMetaState;
    this.accessToken = '';
    this.renewalToken = '';
  }

  @Action
  clearAuthErrors () {
    this.ERRORS_CLEAR();
    this.ERRORS_CAPTCHA_UNSET();
    this.ERRORS_REGISTER_UNSET();
    this.ERRORS_LOGIN_UNSET();
  }

  /**
   * Common callback used by all 3 login and register methods
   */
  @Action
  async loginCallback (input: { resp: Login, nextAction?: RegisteredForPurpose }) {
    const { resp } = input;
    await Auth.injectAccessJWT(resp.tokenAccess.value);
    await Auth.injectRenewalJWT(resp.tokenRenewal.value);
    this.USER_SET(resp.user);
    // do the initial load of all the channels the user is a manager of
    await ChannelsStore.fetchChannelsManagerOf();
    if (resp.user.language) {
      await setLanguage(resp.user.language, true);
    }
    this.ERRORS_CLEAR();
    this.ERRORS_REGISTER_UNSET();
    await AuthenticationStore.getCurrentUserMetaData();
    await ShoppingListStore.fetchShoppingListToGet();
    WebSocket.connect(config.api.baseWss, resp.tokenAccess.value);
    // if the user has not ever seen the welcome tour, emit to start this immediately
    await UserAppSettingsHydrate.fetchThenHydrate();
    if (input.nextAction === RegisteredForPurpose.Normal) {
      const tours = LifferyTourStore.getToursSeen;
      if (!tours.welcome?.takeTheTour) {
        EventBus.$emit(EventBusEvents.TOUR_START, AvailableTours.WelcomeTour);
      }
    }

    EventBus.$emitMany([EventBusEvents.AUTH_LOGIN, EventBusEvents.AUTH_CHANGE]);
  }

  @Action
  async register (input: { register: RegisterEmailPost, nextAction?: RegisteredForPurpose }) {
    try {
      const data = await RegisterService.registerEmailPost(input.register);
      await this.loginCallback({ resp: data, nextAction: input.nextAction });
    } catch (e: any) {
      if (e.request.status === 422 || e.request.status === 406) {
        this.ERRORS_REGISTER_SET(e.response.data);
      }
    }
  }

  @Action
  async login (data: LoginEmailPost) {
    try {
      const resp = await LoginService.loginEmailPost(data);
      await this.loginCallback({ resp });
    } catch (e: any) {
      if (e.request && e.request.status && e.request.status === 423) {
        this.CAPTCHA_SET_REQUIRED(data.email);
        this.ERRORS_LOGIN_UNSET();
      } else {
        this.ERRORS_LOGIN_SET();
      }
    }
  }

  @Action
  async loginWithFacebook (data: LoginFacebookPost) {
    try {
      const resp = await LoginService.loginFacebookPost({
        ...data,
        registeredFromHref: window.location.href
      });
      await this.loginCallback({ resp });
    } catch (e: any) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async loginWithGoogle (data: LoginGooglePost) {
    try {
      const resp = await LoginService.loginGooglePost({
        ...data,
        registeredFromHref: window.location.href
      });
      await this.loginCallback({ resp });
    } catch (e: any) {
      console.error(e);
    }
  }

  @Action
  async getCaptcha (email: string) {
    return CaptchaService.captchaGet({ email });
  }

  @Action
  async unlockCaptcha (data: CaptchaPost) {
    try {
      await CaptchaService.captchaPost(data);
      this.ERRORS_CAPTCHA_UNSET();
      this.CAPTCHA_SET_NOT_REQUIRED();
      return true;
    } catch (e: any) {
      if (e.request.status === 422) {
        this.ERRORS_CAPTCHA_SET(e.response.data);
      }
      return false;
    }
  }

  @Action({ rawError: true })
  async logout (bypassApiCall?: boolean) {
    try {
      const jwt = await Auth.getRenewalJWT();
      if (typeof jwt === 'string' && !bypassApiCall) {
        await LogoutService.logoutGet();
      }
      await Auth.ejectAccessJWT();
      await Auth.ejectRenewalJWT();
      WebSocket.disconnect();
      this.RESET();
      EventBus.$emitMany([EventBusEvents.AUTH_LOGOUT, EventBusEvents.AUTH_CHANGE]);
    } catch (e) {
      console.error(e);
      // Force full redirect
      window.location.href = '/';
    }
  }

  @Action({ rawError: true })
  async renewAccessToken (renewalToken: JwtRenewAccessOrRenewalPost) {
    // Remove the cookie
    await AuthenticationService.ejectAccessJWT();
    // Now fetch a new one
    const data = await TokenService.tokenRenewAccessPost(renewalToken);
    await Auth.injectAccessJWT(data.value);
    return data.value;
  }

  @Action({ rawError: true })
  async renewRenewalToken (renewalToken: JwtRenewAccessOrRenewalPost) {
    const data = await TokenService.tokenRenewRenewalPost(renewalToken);
    await Auth.injectRenewalJWT(data.tokenRenewal.value);
  }

  @Action
  async updateProfileGeneral (data: UserPut) {
    this.USER_SET(await UserService.userPut(data));
  }

  @Action
  async updateUserLanguage (language: Language) {
    this.USER_SET(await UserService.userPut({ language }));
  }

  @Action
  async updateUserWelcomed (welcomed: boolean) {
    this.USER_SET(await UserService.userPut({ accountSteps: { welcomed } }));
  }

  @Action
  async updateProfileEmail (data: UserEmailPut) {
    this.USER_SET(await UserService.userEmailPut(data));
  }

  @Action
  async updateProfilePassword (data: UserPasswordPut) {
    await UserService.userPasswordPut(data);
  }

  @Action
  async reFetchCurrentUser () {
    let fromAppType: FromAppType | undefined = undefined;
    if (config.buildType === BuildType.WEB) {
      fromAppType = FromAppType.Web;
    } else if (config.buildType === BuildType.BROWSER_EXT && config.buildTypeFor === BuildTypeFor.chrome) {
      fromAppType = FromAppType.ChromeExt;
    } else if (isIosApp()) {
      fromAppType = FromAppType.Ios;
    } else if (isAndroidApp()) {
      fromAppType = FromAppType.Android;
    }
    const user = await UserService.userGet({ fromAppType });
    this.USER_SET(user);
  }

  @Action
  async getCurrentUserMetaData () {
    const userMeta = await UserService.userUsernameGet({ username: this.user.username });
    this.CURRENT_USER_META_SET(userMeta);
  }

  @Action
  async getCurrentUserMetaDataOther (username) {
    const userMeta = await UserService.userUsernameGet({ username });
    this.OTHER_USER_META_SET(userMeta);
  }

  @Action
  setCurrentRouteAsForwardingLocation ($route: Route) {
    const currentRoute: RouteLocation = {};
    if ($route.name) {
      currentRoute.name = $route.name;
    }
    if ($route.params) {
      currentRoute.params = $route.params;
    }
    if ($route.query) {
      currentRoute.query = $route.query;
    }
    if ($route.path) {
      currentRoute.path = $route.path;
    }
    if ($route.hash) {
      currentRoute.hash = $route.hash;
    }

    this.SET_FORWARDING_LOCATION(currentRoute);
  }

  @Mutation
  TOGGLE_PROMPT_LOGIN (input: { state: boolean, metaData?: ISignUpMetData }) {
    this.prompt.login = input.state;
    this.prompt.loginActiveTab = TAB_LOGIN;
    this.prompt.metaData = input.metaData || {};
  }

  @Mutation
  TOGGLE_PROMPT_SIGNUP (input: { state: boolean, metaData?: ISignUpMetData }) {
    this.prompt.login = input.state;
    this.prompt.loginActiveTab = TAB_REGISTER;
    this.prompt.metaData = input.metaData || {};
  }

  @Mutation
  TOGGLE_PROMPT_FORGOT_PASSWORD (state: boolean) {
    this.prompt.login = state;
    this.prompt.loginActiveTab = TAB_PASSWORD_RESET;
  }

  @Mutation
  SET_AUTH_ACTIVE_TAB (tab: number) {
    this.prompt.loginActiveTab = tab;
  }

  @Mutation
  CAPTCHA_SET_REQUIRED (email: string) {
    this.loginCaptchaRequired = true;
    this.lastEmailLoginAttempt = email;
  }

  @Mutation
  CAPTCHA_SET_NOT_REQUIRED () {
    this.loginCaptchaRequired = false;
  }

  @Mutation
  ERRORS_CLEAR () {
    this.errorCaptcha = this.errorLogin = this.errorRegister = {};
  }

  @Mutation
  ERRORS_CAPTCHA_SET (error: Generic422) {
    this.errorCaptcha = error;
  }

  @Mutation
  ERRORS_CAPTCHA_UNSET () {
    this.errorCaptcha = {};
  }

  @Mutation
  ERRORS_REGISTER_SET (error: Generic422) {
    this.errorRegister = error;
  }

  @Mutation
  ERRORS_REGISTER_UNSET () {
    this.errorRegister = {};
  }

  @Mutation
  ERRORS_LOGIN_SET () {
    this.errorLogin = {
      errors: ['errors.loginDetailsIncorrect'],
    };
  }

  @Mutation
  ERRORS_LOGIN_UNSET () {
    this.errorLogin = {};
  }

  //todo - delete user settings before setting
  @Mutation
  USER_SET (user: User) {
    this.authenticated = true;
    this.prompt.login = false;
    this.user = { ...this.user, ...user };
  }

  @Mutation
  USER_UNSET () {
    this.authenticated = false;
    this.user = {
      email: '',
      username: '',
      firstName: '',
      lastName: '',
      captchaLocked: false,
      verified: false,
      language: Language.EnGB
    };
  }

  @Mutation
  CURRENT_USER_META_SET (userMeta: UserMeta) {
    this.userMeta = userMeta;
  }

  @Mutation
  OTHER_USER_META_SET (userMeta: UserMeta) {
    this.userMetaOther = userMeta;
  }

  @Mutation
  SET_ACCESS_TOKEN (accessToken: string) {
    this.accessToken = accessToken;
  }

  @Mutation
  SET_RENEWAL_TOKEN (renewalToken: string) {
    this.renewalToken = renewalToken;
  }

  @Mutation
  SET_FORWARDING_LOCATION (input: RouteLocation | null) {
    this.loginForwardingLocation = input;
  }

  @Mutation
  CLEAR_FORWARDING_LOCATION () {
    this.loginForwardingLocation = null;
  }
}
