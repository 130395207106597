<style lang="scss">
.v-tribute {
  width: 100%;
}

.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  display: block;
  overflow: auto;
  min-width: 170px;
  max-width: 450px;
  height: auto;
  max-height: 300px;
  margin-top: 6px;
  border-radius: var(--default-border-radius);
  box-shadow: 0 3px 6px rgba(#000, .2);

  ul {
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: #fff;
    list-style: none;
  }

  li {
    padding: 5px .5rem;
    cursor: pointer;

    &.highlight-item {
      background: var(--darker-bg-color);
    }

    &.no-match {
      cursor: default;
    }

    span {
      font-weight: bold;
    }
  }

  .menu-highlighted {
    font-weight: bold;
  }
}

.mentioned-not-found {
  padding: 5px 10px;
  font-size: .875rem;

  &.hashtag {
    display: none;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Tribute, { TributeOptions } from 'tributejs';

@Component
export default class AMentionUser extends Vue {
  @Prop({ required: true })
  options!: TributeOptions<any>[];

  @Prop({ default: 'input' })
  elementType!: string;

  tributes: Tribute<any>[] = [];

  $target!: HTMLElement;

  mounted () {
    if (typeof Tribute === 'undefined') {
      throw new Error('Cannot locate tributejs!');
    }
    if (!this.$slots?.default?.length) {
      throw new Error('Cannot locate base element for tributejs to target!');
    } else {
      this.$target = this.$slots.default[0].elm as HTMLElement;
    }

    // bind each tribute option object to the target
    this.options.forEach((option) => {
      const newTribute = new Tribute(option);
      newTribute.attach(this.$target);

      this.tributes.push(newTribute);
    });

    this.$target.addEventListener('tribute-replaced', e => {
      // @ts-ignore
      e.target.dispatchEvent(new Event(this.elementType, { bubbles: true }));
    });
  }

  beforeDestroy () {
    // detach all tributes
    this.tributes.forEach((tribute) => {
      tribute.detach(this.$target);
    });
  }

  render (h) {
    return h(
      'div',
      {
        staticClass: 'v-tribute'
      },
      this.$slots.default
    );
  }
}
</script>
