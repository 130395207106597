<template>
  <span class="ALike" @click="emit">
    <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
    <thumbs-up-icon :size="iconSize" class="like-icon" :class="{ 'liked-item': like }"></thumbs-up-icon>
    <span>{{ getLikeInfo() }}</span>
  </span>
</template>

<style lang="scss" scoped>
.ALike {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 1rem;

  &.disabled {
    pointer-events: none;
  }

  span {
    margin-left: 5px;
    cursor: pointer;
  }
}

.like-icon {
  transition: all .2s;
}

.ALike:hover .like-icon,
.liked-item {
  color: var(--secondary-color);
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ThumbsUpIcon } from 'vue-feather-icons';

export interface ALikeChildOutput {
  like: boolean,
}

@Component({
  components: {
    ThumbsUpIcon
  }
})
export default class ALike extends Vue {
  @Prop({ default: false })
  loading!: boolean | undefined;

  @Prop({ default: false })
  like!: boolean;

  @Prop({ required: true })
  likeCount!: number;

  @Prop()
  iconSize!: string;

  @Prop({ default: false })
  numericOnly!: boolean;

  getLikeInfo (): string {
    if (!this.likeCount) {
      return '';
    }
    return `x${this.likeCount}`;
  }

  emit () {
    const newState = this.like === undefined ? true : !this.like;
    this.$emit('child-output', {
      // emit the opposite of the current like value
      like: newState,
    } as ALikeChildOutput);
  }
}
</script>
