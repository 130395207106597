<template>
  <div class="OItemSwipeScore">
    <m-button-with-icon class="is-primary is-outlined btn-like is-small mb-5" @click="decisionMakerFromThisItem" v-if="currentUserJoinedItemChannel">
      <template slot="icon">
        <a-decision-maker-icon-svg :size="1.4" />
      </template>
      <template slot="text">{{ thisUserScore ? $t('dict.edit') : $t('dict.swipeScoresName') }}</template>
    </m-button-with-icon>

    <m-item-swipe-score-user
        v-if="thisUserScore"
        class="mb-3"
        :score="thisUserScore"
    />

    <div v-if="tabLoaded">
      <GChart
          v-if="data.length > 0"
          type="LineChart"
          :data="dataForChart"
          :options="chartOptions"
          :resizeDebounce="500"
      />

      <p v-else>{{ $t('dict.notFoundMessageSwipeScoreTab' + (this.currentUserJoinedItemChannel ? '' : 'NotMember')) }}</p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.OItemSwipeScore {
  .hidden {
    display: none;
    visibility: hidden;
  }
}

::v-deep {
  .google-visualization-tooltip {
    white-space: nowrap;
    padding: .5rem;
    width: unset !important;
    height: unset !important;

    .tooltip-container {
      display: flex;
      justify-content: flex-start;

      .profile-pic {
        margin-right: .5rem;
        align-self: center;
        border-radius: 50%;
      }

      .swipe-data {
        .data-line1 {
          display: flex;
          justify-content: space-between;
          font-weight: $fontWeightBold;
        }
      }
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import SwipeScoresService from '@/api/ms-item/services/SwipeScoresService';
// vue2 uses this legacy folder which shows an error in the code, when we upgrade to vue3 update ref and remove this ts-ignore
// @ts-ignore
import { GChart } from 'vue-google-charts/legacy';
import { GoogleChartOptions } from 'vue-google-charts/dist/types';
import { Datum } from '@/api/ms-item/services/interfaces/SwipeScoress';
import { AuthenticationStore } from '@/store';
import MItemSwipeScoreUser from '@/components/molecules/MItemSwipeScoreUser.vue';
import { itemDetailTabs } from '@/components/organisms/OItemDetailTabs.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import calculateFullImageHref from '@/storybook-components/src/utils/calculateFullImageHref';
import config from '@/config';
import { format } from 'timeago.js';
import { translation } from '@/plugins/i18n/Translation';
import MButtonWithIcon from '@/storybook-components/src/stories/molecules/MButtonWithIcon.vue';
import ADecisionMakerIconSvg from '@/components/atoms/icon/svg/ADecisionMakerIconSvg.vue';
import { Item } from '@/api/ms-item/services/interfaces';
import OChannelSwipeItems from '@/components/organisms/OChannelSwipeItems.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import { OModalsContainerChannelSwipeItems } from '@/components/organisms/OModalsContainer.vue';
import { Channel } from '@/api/ms-channel/services/interfaces';
import ChannelService from '@/api/ms-channel/services/ChannelService';
import ChannelMemberService from '@/api/ms-channel/services/ChannelMemberService';

interface ChartData extends Datum {
  cumulativeScore: number;
}

@Component({
  components: {
    OChannelSwipeItems,
    ADecisionMakerIconSvg,
    MButtonWithIcon,
    MItemSwipeScoreUser,
    GChart
  }
})
export default class OItemSwipeScore extends Vue {
  @Prop()
  item!: Item;
  @Prop()
  activeTab?: itemDetailTabs;

  data: ChartData[] = [];
  tabLoaded = false;
  profilePicSize: Size = Size.The32X32;

  channel: Channel | null = null;
  currentUserJoinedItemChannel: boolean = false;

  chartOptions: GoogleChartOptions = {
    tooltip: { isHtml: true, trigger: 'both' },
    legend: { position: 'bottom' },
    hAxis: { title: this.$t('dict.time') as string },
    vAxis: { minValue: 0 },
    trendlines: { 0: {} },
    pointSize: 5
  };

  get dataForChart () {
    if (this.data.length === 0) {
      return [];
    }
    const headers: [any[]] = [[
      this.$t('dict.date') as string,
      this.$t('item.swipeScore.chart.tooltipLabel') as string,
      {
        type: 'string',
        role: 'tooltip',
        'p': { 'html': true }
      }
    ]];

    return headers.concat(this.data.map((element) => [element.updatedAt, element.cumulativeScore, this.prettyTooltip(element)]));
  }

  get currentUser () {
    return AuthenticationStore.currentUser;
  }

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get thisUserScore (): ChartData {
    return this.data.filter((score) => score.username === this.currentUser.username)[0];
  }

  get channelSlug (): string {
    if( this.item.editable.channel && this.item.editable.channel.slug.length > 0){
      return this.item.editable.channel.slug;
    } else {
      return '';
    }
  }

  created () {
    this.activeTabListener();
  }

  async mounted () {
    await this.getData();
  }

  beforeDestroy () {
    EventBus.$emit(EventBusEvents.CHANNEL_SWIPE_ITEMS_CLEAR);
  }

  /**
   * Create the pretty tooltip.
   *
   * Note - Cannot figure out how to mount a component and load it into a variable as a string instead of mounting
   *        directly onto the page. Would have preferred to directly pull the component AUserProfilePic into this,
   *        but instead have manually created the image element instead.
   */
  // eslint-disable-next-line max-lines-per-function
  prettyTooltip (element: ChartData): string {
    const container = document.createElement('div');
    container.classList.add('tooltip-container');

    const img = document.createElement('img');
    img.src = calculateFullImageHref(
        config.api.baseUrl + config.api.basePaths.imageRead,
        this.profilePicSize,
        'user/profile-pic/' + element.username + '.png'
    );
    img.alt = element.username;
    img.classList.add('profile-pic');
    container.appendChild(img);

    const swipeData = document.createElement('div');
    swipeData.classList.add('swipe-data');
    container.appendChild(swipeData);

    const line1 = document.createElement('div');
    line1.classList.add('data-line1');
    swipeData.appendChild(line1);

    const line1a = document.createElement('p');
    line1a.innerText = element.updatedAt ? format(element.updatedAt, this.currentLanguage) : '';
    line1.appendChild(line1a);

    const scorePrefix = element.scoreApplied < 1 ? '' : '+';
    const line1b = document.createElement('p');
    line1b.innerText = `(${scorePrefix}${element.scoreApplied})`;
    line1.appendChild(line1b);

    const line2 = document.createElement('p');
    line2.classList.add('data-line2');
    line2.innerText = this.$t('item.swipeScore.chart.tooltipLabel') + ': ' + element.cumulativeScore;
    swipeData.appendChild(line2);

    return container.outerHTML;
  }

  async getData (offset = 0) {
    // load the points
    const { data } = await SwipeScoresService.swipeScoresItemNameUniqueItemNameGet({
      uniqueItemName: this.item.uniqueItemName
    }, { offset });

    this.data = this.mapData(data);
  }

  /**
   * Figure out the cumulative score and map it onto the object
   */
  mapData (data: Datum[]): ChartData[] {
    // if results already here, get the last cumulative as the starting point, otherwise it's 0
    const startingPoint = this.data.length > 0 ? this.data[this.data.length - 1].cumulativeScore : 0;
    const c: { umulative: number } = { umulative: 0 };
    const l: { owestValue: number } = { owestValue: 0 };
    const h: { ighestValue: number } = { ighestValue: 0 };
    const mapped: ChartData[] = data.map((element: Datum, index: number) => {
      const cumulative = index > 0 && data[index - 1] ? c.umulative : startingPoint;
      c.umulative = cumulative + element.scoreApplied;
      // we use the lowest value to ensure the v-axis is set correctly
      if (c.umulative < l.owestValue) {
        l.owestValue = c.umulative;
      }
      if (c.umulative > h.ighestValue) {
        h.ighestValue = c.umulative;
      }
      return {
        ...element,
        ...{
          cumulativeScore: c.umulative
        }
      };
    });

    this.chartOptions.vAxis = this.chartOptions.vAxis || {};
    this.chartOptions.vAxis.minValue = l.owestValue;
    this.chartOptions.vAxis.maxValue = h.ighestValue;

    return mapped;
  }

  async decisionMakerFromThisItem () {
    if(!this.item.editable || !this.item.editable.channel){
      return;
    }
    // load the channel if it's not loaded yet, and fire off the channel swipe event passing this unique item name along
    if( !this.channel){
      this.channel = await ChannelService.channelSlugSlugGet({slug: this.item.editable.channel.slug});
    }
    const payload: OModalsContainerChannelSwipeItems = {
      channel: this.channel,
      startingUniqueItemName: this.item.uniqueItemName
    };
    EventBus.$emit(EventBusEvents.CHANNEL_SWIPE_ITEMS, payload);
  }

  /**
   * Todo - Idea is to set an event listener on mouseover of the <m-item-swipe-score-user> component and select the relevant
   *        tooltip in the graph to display. Time-box ran out, complete in future release...
   */
      // onChartReady (chart, google) {
      //   google.visualization.events.addListener(chart, 'select', (el) => {
      //     console.log('clicked innit', el);
      //     console.log(chart.getSelection());
      //     chart.setSelection(chart.getSelection());
      //   });
      // }

  tabLoadedInit () {
    this.tabLoaded = true;
    ChannelMemberService.channelMemberSlugIsMemberGet({
      slug: this.channelSlug
    }).then(() => this.currentUserJoinedItemChannel = true).catch(() => this.currentUserJoinedItemChannel = false);
  }

  initWatch: () => void = () => {};

  activeTabListener () {
    this.initWatch = this.$watch('activeTab', function (newValue) {
      if (newValue === itemDetailTabs.swipeScore) {
        this.tabLoadedInit();
        this.initWatch();
      }
    }, { immediate: true });
  }
}
</script>
