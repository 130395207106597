<template>
  <svg viewBox="0 0 42 42" :style="style" fill="none" xmlns="http://www.w3.org/2000/svg" @mouseover="mouseover = true" @mouseout="mouseover = false">

    <circle cx="20.8932" cy="20.944" r="19.806" :fill="fillColour" :stroke="circleStrokeColour" stroke-width="1.5"/>

    <path d="M23.0519 15.9439V11.9439C23.0519 11.1482 22.7358 10.3852 22.1732 9.82254C21.6106 9.25993 20.8475 8.94386 20.0519 8.94386L16.0519 17.9439V23.4439V28.9439H27.3319C27.8142 28.9493 28.2823 28.7803 28.6498 28.4679C29.0173 28.1555 29.2596 27.7208 29.3319 27.2439L30.7119 18.2439C30.7554 17.9572 30.7361 17.6645 30.6552 17.3861C30.5744 17.1077 30.434 16.8502 30.2438 16.6314C30.0535 16.4126 29.818 16.2378 29.5535 16.1191C29.289 16.0004 29.0018 15.9406 28.7119 15.9439H23.0519ZM16.0519 28.9439H13.0519C12.5215 28.9439 12.0128 28.7331 11.6377 28.3581C11.2626 27.983 11.0519 27.4743 11.0519 26.9439V19.9439C11.0519 19.4134 11.2626 18.9047 11.6377 18.5296C12.0128 18.1546 12.5215 17.9439 13.0519 17.9439H16.0519" :fill="fillColour"/>

    <path d="M16.0519 17.9439L20.0519 8.94386C20.8475 8.94386 21.6106 9.25993 22.1732 9.82254C22.7358 10.3852 23.0519 11.1482 23.0519 11.9439V15.9439H28.7119C29.0018 15.9406 29.289 16.0004 29.5535 16.1191C29.818 16.2378 30.0535 16.4126 30.2438 16.6314C30.434 16.8502 30.5744 17.1077 30.6552 17.3861C30.7361 17.6645 30.7554 17.9572 30.7119 18.2439L29.3319 27.2439C29.2596 27.7208 29.0173 28.1555 28.6498 28.4679C28.2823 28.7803 27.8142 28.9493 27.3319 28.9439H16.0519M16.0519 17.9439H13.0519C12.5215 17.9439 12.0128 18.1546 11.6377 18.5296C11.2626 18.9047 11.0519 19.4134 11.0519 19.9439V26.9439C11.0519 27.4743 11.2626 27.983 11.6377 28.3581C12.0128 28.7331 12.5215 28.9439 13.0519 28.9439H16.0519M16.0519 17.9439V23.4439V28.9439" :stroke="strokeColour" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>

  </svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AChannelSwipeItemRight extends Vue {
  @Prop({default: '1x'})
  size!: string;
  @Prop({required: false, default: false})
  fill!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;

  mouseover = false;

  colours: {
    disabled: string,
    swipeRight: string,
    white: string
  } = {
    disabled: 'var(--grey-mid-color)',
    swipeRight: 'rgb(var(--channel-swipe-right))',
    white: 'var(--white-color)'
  };

  get style () {
    const size = parseFloat(this.size);
    return `width: ${size}rem; height: ${size}rem;`;
  }

  get fillColour () {
    if (this.disabled) {
      return this.colours.white;
    }

    return (this.mouseover || this.fill) ? this.colours.swipeRight : this.colours.white;
  }

  get strokeColour () {
    if (this.disabled) {
      return this.colours.disabled;
    }

    return (!this.mouseover && !this.fill) ? this.colours.swipeRight : this.colours.white;
  }

  get circleStrokeColour () {
    return this.disabled ? this.colours.disabled : this.colours.swipeRight;
  }
}
</script>