<template>
  <a :class="classes" :href="getHref(url)" rel="noopener" target="_blank">
    <slot></slot>
    <link-icon v-if="hasIcon" :size="sizeIcon" class="icon"></link-icon>
    <div class="url">
      <slot name="price"></slot>
      <span class="line-clamp-1" style="float: right;">
          {{ url | formatUrl }}
      </span>
    </div>
  </a>
</template>

<style lang="scss" scoped>
.link-without-image,
.link-with-image {
  display: flex;
  align-items: center;
  padding: 0 .5rem;
  padding-bottom: 5px;

  &.flex-wrap {
    flex-wrap: wrap;
  }

  .url {
    width: 100%;
  }
}

.link-with-image {
  &.big-link {
    bottom: 1rem;
    padding: 5px .8rem;
  }
}

.icon {
  flex-shrink: 0;
  width: 14px;
  margin-right: 5px;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { LinkIcon } from 'vue-feather-icons';
import formatUrl from '@/utils/formatUrl';
import clickoutUrl from '@/utils/clickoutUrl';
import AItemPrice from '@/components/atoms/AItemPrice.vue';

@Component({
  components: {
    AItemPrice,
    LinkIcon
  },
  filters: {
    formatUrl: formatUrl
  }
})
export default class ARouterLinkItemUrl extends Vue {
  @Prop({ required: true })
  url!: string;

  @Prop()
  classes!: string;

  @Prop()
  isBig!: boolean;

  @Prop()
  hasIcon!: boolean;

  sizeIcon = this.isBig ? '2x' : '14';

  getHref (url: string) {
    return clickoutUrl(url);
  }
}
</script>
