<template>
  <section class="modal-card-body">
    <b-tabs v-model="activeTab" class="modal-tabs">
      <b-tab-item :label="showForgotPassword ? $t('dict.passwordForgot') : $t('dict.login')" :name="TAB_LOGIN">
        <o-login-form
            :showForgotPassword="showForgotPassword"
            v-on:child-output="setShowForgotPassword"
        />
      </b-tab-item>
      <b-tab-item :label="$t('dict.signUp')" :name="TAB_REGISTER">
        <o-register-form/>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<style lang="scss" scoped>
::v-deep .tab-content {
  padding: 0;
}

::v-deep nav {
  margin-bottom: .6rem;
  margin-left: -1rem;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { TAB_LOGIN, TAB_REGISTER } from '@/constants/tabs';
import OLoginForm from '@/components/organisms/forms/OLoginForm.vue';
import ORegisterForm from '@/components/organisms/forms/ORegisterForm.vue';
import { AuthenticationStore } from '@/store';

@Component({
  components: {
    OLoginForm,
    ORegisterForm,
  },
})
export default class MAuthenticationTabs extends Vue {
  TAB_REGISTER = TAB_REGISTER;
  TAB_LOGIN = TAB_LOGIN;

  showForgotPassword: boolean = false;

  get activeTab () {
    return AuthenticationStore.prompt.loginActiveTab;
  }

  set activeTab (tab: number) {
    AuthenticationStore.SET_AUTH_ACTIVE_TAB(tab);
  }

  setShowForgotPassword (value: boolean) {
    this.showForgotPassword = value;
  }
}
</script>
