<template>
  <div class="MNotificationBell" @click="$emit('click')">
    <svg
        class="navbar-header-item-icon"
        viewBox="0 0 24 24"
        @click="() => $emit('click')"
    >
      <path
          d="M10 21H14C14 22.1 13.1 23 12 23S10 22.1 10 21M21 19V20H3V19L5 17V11C5 7.9 7 5.2 10 4.3V4C10 2.9 10.9 2 12 2S14 2.9 14 4V4.3C17 5.2 19 7.9 19 11V17L21 19M17 11C17 8.2 14.8 6 12 6S7 8.2 7 11V18H17V11Z"
          fill="currentColor"/>
    </svg>
    <a-badge v-if="count" :count="count" position="bottom-right"></a-badge>
  </div>
</template>

<style lang="scss" scoped>
.MNotificationBell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.navbar-header-item-icon {
  height: 40px;
  transition: all .2s;

  @media screen and (max-width: 450px) {
    height: 30px;
  }

  path {
    transition: all .2s;
  }

  &:hover,
  &.grow {
    transform: scale(1.2);

    path {
      fill: var(--primary-color);
    }
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ABadge from '../../atoms/ABadge.vue';

@Component({
  components: {
    ABadge,
  },
})

export default class MNotificationBell extends Vue {
  @Prop({ default: 0 })
  count!: number;
}
</script>
