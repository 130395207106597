<template>
  <div class="AUserProfilePic">
    <a-image
        v-if="isOnline"
        :image-path="profilePicUrl()"
        :size="imageSize || '100x100'"
        class="image user-profile-image"
    />
  </div>
</template>

<style lang="scss">
.AUserProfilePic {
  margin-right: .5rem;

  img {
    border-radius: 50%;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import AImage from '@/components/atoms/AImage.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import InternetStatus from '@/services/InternetStatus';

@Component({
  components: {
    AImage
  }
})
export default class AUserProfilePic extends Vue {
  @Prop(String)
  username?: string;

  @Prop(String)
  imageSize?: Size;

  get isOnline () {
    return InternetStatus.isOnline;
  }

  profilePicUrl () {
    return 'user/profile-pic/' + this.username + '.png';
  }
}
</script>
