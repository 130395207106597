<template>
  <div :class="[{ small: count <= 9}, position, type]"
       class="badge font-sub2"
  >
    <span class="badge-number">{{ count > 9 ? '9+' : count }}</span>
  </div>
</template>

<style lang="scss" scoped>
.badge {
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: .25em;
  border-radius: 4px;
  color: var(--white-color);

  &.top-left {
    top: -5px;
    left: -14px;
  }

  &.bottom-right {
    right: -7px;
    bottom: -4px;
  }

  &.small {
    padding: .5em;
  }

  &.primary {
    background-color: var(--secondary-color);
  }

  &.secondary {
    background-color: var(--danger-color);
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
}

.badge-number {
  overflow: visible; // set the height to 0 and overflow to visible to not interfere with the square styles
  height: 0;
  margin-top: -1em; // vertically center text since the height is set to 0
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ABadge extends Vue {
  @Prop({ default: 0 })
  count!: number;

  @Prop({default: 'top-left'})
  position!: 'top-left' | 'bottom-right';

  @Prop({ default: 'primary' })
  type!: 'primary' | 'secondary';
}
</script>