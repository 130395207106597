<template>
  <div class="channel-header">
    
    <div class="channel-info">
      <div class="image is-96x96">
      <b-skeleton circle width="100px" height="100px"></b-skeleton>
    </div>

      <div>
        <b-skeleton width="18rem" height="26px"></b-skeleton>

        <div class="channel-details">
          <b-skeleton width="8rem"></b-skeleton>
          <b-skeleton width="7.5rem"></b-skeleton>
        </div>
      </div>
      <div class="btn-add-to-channel">
        <b-skeleton width="12rem" height="3.45rem"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../organisms/OChannelHeader';

.channel-header {
  background-color: #f0f0f0;
}

.channel-details {
  margin-top: 1rem;

  .b-skeleton {
    margin-top: 0;
  }
}

.channel-info {
  background: none;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MChannelHeaderSkeleton extends Vue {
}
</script>
