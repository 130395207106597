<template>
  <div class="custom-field-container">
    <div v-if="[fieldTypes.Checklist, fieldTypes.Compass, fieldTypes.CustomSelect, fieldTypes.Rating, fieldTypes.Price].includes(channelCustomFieldData.fieldType)
                && 'compassDegrees' !== channelCustomFieldData.unit">
      <b-field horizontal>
        <template slot="label">
          <m-item-entry-custom-field-label
              :label="channelCustomFieldData.label"
              :field-number="fieldNumber"
              :unit="channelUnitDisplay"
          />
        </template>

        <!-- Star Ratings-->
        <template
            v-if="fieldTypes.Rating === channelCustomFieldData.fieldType && 'stars' === channelCustomFieldData.unit">
          <b-rate
              v-if="channelCustomFieldData.modifier <= maxRatingDisplay"
              icon="star"
              icon-pack="vfi"
              v-model="value.value"
              :max="channelCustomFieldData.modifier"
              :rtl="false"
              :disabled="false"
              :show-score="true"
              :custom-text="` / ${channelCustomFieldData.modifier}`"
          />
          <section v-else>
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <div style="width: 75%;">
                <m-input-with-validation
                    v-model="value.value"
                    name="rating"
                    type="number"
                    :min="1"
                    :max="channelCustomFieldData.modifier"
                />
              </div>
              <div class="is-flex is-align-items-center is-justify-content-end ml-1" style="width: 25%;">
                <span class="mr-1">/ {{ channelCustomFieldData.modifier }}</span>
                <b-rate
                    icon="star"
                    icon-pack="vfi"
                    :value="1"
                    :max="1"
                    :rtl="false"
                    :disabled="true"
                />
              </div>
            </div>
          </section>
        </template>

        <!-- Custom select options -->
        <template v-else-if="fieldTypes.CustomSelect === channelCustomFieldData.fieldType">
          <b-select
              v-model="value.value"
              class="default-select"
              :placeholder="channelUnitData.label"
          >
            <option
                v-for="(val, i) in channelCustomFieldData.options"
                :key="i"
                :value="val"
            >
              {{ val }}
            </option>
          </b-select>
        </template>

        <!-- Pricing options -->
        <template v-else-if="fieldTypes.Price === channelCustomFieldData.fieldType">
          <b-input
              v-model="value.value"
              :placeholder="channelUnitData.label"
              style="max-width: 150px"
              type="number"
              min="0"
              step="0.01"
          />
          <b-select
              v-model="value.unit"
              class="default-select"
              :placeholder="channelUnitData.label"
          >
            <option
                v-for="(val, i) in currenciesUnique"
                :key="i"
                :value="val.currency"
            >
              {{ val.currency }}
              {{ val.currency !== val.symbol ? `(${val.symbol})` : '' }}
            </option>
          </b-select>
        </template>

        <!-- Compass options-->
        <template
            v-else-if="fieldTypes.Compass === channelCustomFieldData.fieldType && 'compassDegrees' !== channelCustomFieldData.unit">
          <b-select
              class="default-select"
              v-model="value.value"
              :placeholder="channelUnitData.label"
          >
            <option
                v-for="(val, i) in getCompassDirections"
                :key="i"
                :value="val.value"
            >
              {{ val.label }}
            </option>
          </b-select>
        </template>

        <!-- Checkbox -->
        <template v-else-if="fieldTypes.Checklist === channelCustomFieldData.fieldType">
          <b-checkbox
              v-model="value.value"
              :true-value="1"
              :false-value="0"
              class="is-align-self-center custom-field-checkbox"
          >
            <span v-if="value.value === 1">{{ $t('dict.yes') }}</span>
            <span v-else>{{ $t('dict.no') }}</span>
          </b-checkbox>
        </template>
      </b-field>
    </div>

    <div v-else>
      <m-input-with-validation
          v-model="value.value"
          :name="channelUnitData.label"
          :type="getInputType"
          :horizontal="true"
          :is-decimal-number="getInputDecimal"
          :placeholder="channelUnitData.label"
          :min="getMinValue"
          :max="getMaxValue"
      >
        <template slot="label">
          <m-item-entry-custom-field-label
              :label="channelCustomFieldData.label"
              :field-number="fieldNumber"
              :unit="channelUnitDisplay"
          />
        </template>
      </m-input-with-validation>
    </div>
  </div>
</template>

<style scoped lang="scss">
.custom-field-container {
  margin-top: 1rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: $white;
  }

  .custom-field-checkbox {
    height: 100%;
    width: 100%;
  }
}

::v-deep .field-label {
  margin-right: .5rem !important;
}

::v-deep .field-body {
  @media screen and (min-width: 951px) {
    flex-grow: 2;
  }
}
</style>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import { CustomField as ItemCustomField } from '@/api/ms-item/services/interfaces/ItemPost';
import { CustomField as ChannelCustomField, FieldType } from '@/api/ms-channel/services/interfaces/Channel';
import { CustomFieldsUnitGroupeds } from '@/api/ms-static-api-data/services/interfaces';
import { CustomFieldsUnitGrouped } from '@/api/ms-static-api-data/services/interfaces/CustomFieldsUnitGroupeds';
import MItemEntryCustomFieldLabel from '@/components/molecules/itemEntry/MItemEntryCustomFieldLabel.vue';
import { StarIcon } from 'vue-feather-icons';
import StaticApiDataService from '@/services/StaticApiDataService';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { Datum as Currency } from '@/api/ms-static-api-data/services/interfaces/Currenciess';
import CustomFields from '@/services/CustomFields';

@Component({
  components: {
    MItemEntryCustomFieldLabel,
    MInputWithValidation,
    DatePicker,
    StarIcon
  }
})
export default class MItemEntryCustomFields extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: ItemCustomField;

  @Prop({ required: true })
  channelCustomFieldData!: ChannelCustomField;

  @Prop({ required: true })
  currenciesUnique!: Currency[];

  @Prop({ required: true })
  fieldNumber!: number;

  @Prop({ required: true })
  allChannelUnitData!: CustomFieldsUnitGroupeds;

  fieldTypes = FieldType;
  maxRatingDisplay = 10;
  value: ItemCustomField = {
    _id: '',
    label: '',
    fieldType: FieldType.Area,
    unit: '',
    value: ''
  };

  get channelUnitDisplay (): string | undefined {
    return CustomFields.channelUnitDisplay(this.channelCustomFieldData);
  }

  get channelUnitData () {
    let unitData;
    this.allChannelUnitData.forEach((allChannelUnitData: CustomFieldsUnitGrouped) => {
      if (allChannelUnitData.fieldType === this.value.fieldType) {
        unitData = allChannelUnitData.values.find((value) => {
          return value.value === this.value.unit;
        });
      }
    });
    return unitData;
  }

  get getInputType (): string {
    if (this.channelUnitData.dataType === 'string') {
      return 'text';
    } else if (this.channelUnitData.dataType === 'date' && this.channelUnitData.value === 'year') {
      return 'number';
    } else {
      return this.channelUnitData.dataType;
    }
  }

  get getInputDecimal (): boolean {
    return !(this.channelUnitData.dataType === 'date' && this.channelUnitData.value === 'year');
  }

  get getMinValue () {
    return this.channelCustomFieldData.unit === 'compassDegrees' ? 0 : '';
  }

  get getMaxValue () {
    return this.channelCustomFieldData.unit === 'compassDegrees' ? 360 : '';
  }

  get getCompassDirections (): { label: string, value: number }[] {
    return StaticApiDataService.compassData[this.channelCustomFieldData.unit];
  }

  created () {
    this.setValue();
  }

  /**
   * The values need to be cast for different input types
   * Specifically date inputs.
   */
  setValue () {
    this.value = this.activeValue;
    if (!this.value.value || this.value.value === '') {
      if (this.channelCustomFieldData.fieldType !== 'rating') {
        return;
      } else {
        // @ts-ignore
        this.value.value = 0;
      }
    } else if (this.channelUnitData.dataType === 'date' && this.channelUnitData.value === 'year') {
      // @ts-ignore
      this.value.value = new Date(this.value.value).getFullYear();
    } else if (this.channelUnitData.dataType === 'date') {
      this.value.value = new Date(this.value.value).toISOString().substring(0, 10);
    } else if (this.channelUnitData.dataType === 'number') {
      // @ts-ignore
      this.value.value = Number(this.value.value);
    }
  }

  @Watch('value', { deep: true })
  updateValue () {
    const valueToEmit = JSON.parse(JSON.stringify(this.value)) as ItemCustomField;
    valueToEmit.value = String(valueToEmit.value);
    this.$emit('updateModelValue', valueToEmit);
  }
}
</script>
