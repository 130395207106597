<template>
  <a-default-svg-from-material-design
      :size="size"
      :keep-y-zero="keepYZero"
      :fill-colour="fillColour"
      path="M14,7L9,12L14,17V7Z"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ADefaultSvgFromMaterialDesign from '@/components/atoms/icon/svg/ADefaultSvgFromMaterialDesign.vue';

@Component({
  components: { ADefaultSvgFromMaterialDesign }
})
export default class AMenuLeftSvg extends Vue {
  @Prop({ default: 1 })
  size!: number;
  @Prop({ default: true })
  keepYZero!: boolean;
  @Prop()
  fillColour?: string;
}
</script>