<template>
  <router-link v-if="myItem" :to="{ name, params: { username: currentUser.username }, hash: `#${item.uniqueItemName}`}">
    <div class="content">
      <div class="left">
        <a-image
            v-if="hasItemImage()"
            :alt-text="item.urlCache.meta && item.urlCache.meta.title"
            :image-path="item.urlCache.image.href"
            :size="size"
            class="item-image"
        />
        <a-image
            v-else-if="hasUserImage()"
            :image-path="item.userPhotos[0].filePath"
            :size="size"
            class="item-image"
        />
        <a-user-profile-pic
            v-else
            :imageSize="size"
            :username="item.actor.username"
        />
      </div>
      <div class="right">
        <p v-if="itemRemoved !== item.uniqueItemName" class="item-title">
          <span v-if="item.urlCache && item.urlCache.meta" class="line-clamp-1">
            {{ item.urlCache.meta.title }}
          </span>
          <span v-else-if="hasUserDescription()" class="line-clamp-1">
            {{ this.item.editable.text | removeUrl }}
          </span>
          <span class="created-at">{{ myItem.createdAt | formatDate }}</span>
        </p>
        <p v-else class="item-title">
          <span class="line-clamp-1">{{ $t('dict.removedFromShoppingList') }}</span>
        </p>

        <div v-if="itemRemoved !== item.uniqueItemName" class="bottom-info">
          <span class="user-name">
            {{
              myItem.forActor.username === currentUser.username ?
                  $t('modals.shoppingListAdd.forMyself') :
                  `${myItem.forActor.firstName} ${myItem.forActor.lastName}`
            }}
          </span>
          <div class="shopping-list-controls" :class="{'isGot': isGot}" @click.prevent>
            <b-tooltip :label="$t('dict.gotIt')" :delay="1000" :active="displayShoppingListControlTooltips">
              <check-square-icon
                  class="control mark-as-bought"
                  size="20"
                  @click="(e) => toggleGotItState(e)"
              />
            </b-tooltip>
            <b-tooltip :label="$t('dict.removeGetGotList')" :delay="1000" :active="displayShoppingListControlTooltips">
              <trash2-icon
                  class="control remove-from-list"
                  size="20"
                  @click="(e) => removeFromList(e)"
              />
            </b-tooltip>
          </div>
        </div>
        <div v-else class="bottom-info" @click.prevent>
          <span></span>
          <b-button
              class="button is-small"
              @click="(e) => removeFromListUndo(e)"
          >
            {{ $t('dict.undo') }}
          </b-button>
        </div>
      </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: space-between;
  width: 21rem;

  @media screen and (max-width: 550px) {
    width: 18rem;
  }

  .item-title,
  .bottom-info {
    display: flex;
    justify-content: space-between;

    .created-at {
      padding-left: .5rem;
    }
  }

  .bottom-info {
    .shopping-list-controls {
      display: flex;
      justify-content: space-between;
      width: 3rem;

      @media screen and (max-width: 550px) {
        width: 3.5rem;
      }

      &:not(.isGot) {
        svg {
          opacity: .4;

          &:hover {
            opacity: 1;
          }
        }
      }

      &.isGot {
        .mark-as-bought {
          color: var(--primary-color);
        }

        .remove-from-list {
          opacity: .4;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  p {
    margin: 0 0 14px;
  }
}

::v-deep .AUserProfilePic img,
.content img {
  max-height: unset;
}

::v-deep .AUserProfilePic {
  margin-right: 0;
}

.left {
  width: 17%;
}

.right {
  width: 80%;
}

.item-title {
  .line-clamp-1 {
    display: block;
    text-overflow: ellipsis;
  }
}

p.bottom-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.user-name {
  display: inline-block;
  padding: 2px 6px;
  background-color: rgba(0, 0, 0, .1);
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Item } from '@/api/ms-item/services/interfaces';
import { format } from 'timeago.js';
import { Getgot, LatestStage } from '@/api/ms-item/services/interfaces/Item';
import { User } from '@/api/ms-authentication/services/interfaces/Login';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import { AuthenticationStore } from '@/store';
import remove1stUrlFromString from '@/utils/remove1stUrlFromString';
import { RouteNames } from '@/router/RouteNames';

import AImage from '@/components/atoms/AImage.vue';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ADeleteIcon from '@/components/atoms/icon/ADeleteIcon.vue';
import { CheckSquareIcon, Trash2Icon } from 'vue-feather-icons';
import toggleGetGotState from '@/utils/toggleGetGotState';
import ItemService from '@/api/ms-item/services/ItemService';
import updateItemInStores from '@/utils/updateItemInStores';
import isTouchScreen from '@/utils/isTouchScreen';

@Component({
  components: {
    ADeleteIcon,
    AImage,
    AUserProfilePic,
    CheckSquareIcon,
    Trash2Icon
  },
  filters: {
    formatDate: function (date: Date, currentLanguage) {
      return format(date, currentLanguage);
    },
    removeUrl: remove1stUrlFromString,
  },
})
export default class MShoppingListHeaderItem extends Vue {
  @Prop({ default: false })
  item!: Item;

  @Prop()
  dropdownVisible!: boolean;

  name = RouteNames.ROUTE_GETGOT;
  size = Size.The60X60;
  currentUser: User = AuthenticationStore.currentUser;
  stageNames = LatestStage;
  itemGot: string = '';
  itemRemoved: string = '';
  displayShoppingListControlTooltips: boolean = true;

  /**
   * The component isn't destroyed when the dropdown visibility changes, this is the workaround to reset the individual
   * header item states between dropdown visibility. E.g. If something is got or removed from the list.
   */
  @Watch('dropdownVisible')
  resetStatesOnClose () {
    if (!this.dropdownVisible) {
      this.itemGot = '';
      this.itemRemoved = '';
    }
  }

  get isGot () {
    return (this.itemGot === this.item.uniqueItemName && String(this.myItem?.latestStage) === this.stageNames.StageGot);
  }

  // Shopping list state can be edited in the header, myItem placed into getter to keep up to date
  get myItem (): Getgot | null {
    return this.item.getgot.filter(item => item.actor.username === this.currentUser.username)[0];
  }

  created () {
    this.displayShoppingListControlTooltips = !isTouchScreen();
  }

  hasItemImage (): boolean {
    return !!this.item?.urlCache?.image?.href;
  }

  hasUserDescription (): boolean {
    return this.item.editable && this.$options.filters?.removeUrl(this.item.editable.text).length > 0;
  }

  hasUserImage (): boolean {
    return !!this.item?.userPhotos && this.item.userPhotos.length > 0;
  }

  async toggleGotItState (e) {
    e.preventDefault();
    if (this.myItem) {
      const newState = await toggleGetGotState(this.item.uniqueItemName, this.myItem);
      // Change the state of this item just for this component depending on what state it changed to
      this.myItem.latestStage = newState as unknown as LatestStage;
      this.itemGot = this.myItem.latestStage === this.stageNames.StageGot ? this.item.uniqueItemName : '';
    }
  }

  async removeFromList (e) {
    e.preventDefault();
    if (this.myItem && this.myItem._id) {
      const newItem = await ItemService.itemNameUniqueItemNameGetgotIdIdDelete({
        id: this.myItem._id,
        uniqueItemName: this.item.uniqueItemName
      });
      updateItemInStores(newItem);

      // Change state on a timeout otherwise race condition happens between this and mouseup and it closes the dropdown
      setTimeout(() => {
        this.itemRemoved = this.item.uniqueItemName;
      }, 50);
    }
  }

  async removeFromListUndo (e) {
    e.preventDefault();
    if (this.myItem && this.myItem._id) {
      const newItem = await ItemService.itemNameUniqueItemNameGetgotIdIdUndoDeletePut({
        id: this.myItem._id,
        uniqueItemName: this.item.uniqueItemName
      });
      updateItemInStores(newItem);

      setTimeout(() => {
        this.itemRemoved = '';
      }, 50);
    }
  }
}
</script>
