<template>
  <select class="ContentCategoryDropdown" v-model="value">
    <option v-for="(group, key) in groups" :key="key" :value="group">
      {{ $t('searchPlaceholder.contentCategoryGroups.' + group) }}
    </option>
  </select>
</template>

<style scoped lang="scss">
.ContentCategoryDropdown {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  padding: 0 3px;
  padding-left: 1rem;
  width: 5.5rem;
  cursor: pointer;
  border: unset;
  border-radius: var(--radius-big);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-style: solid;
  border-right-width: 2px;
  border-right-color: var(--grey-light-color);
  -webkit-box-shadow: 0 2px 6px rgb(0 0 0 / 11%);
  box-shadow: 0 2px 6px rgb(0 0 0 / 11%);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--black-color);
  background-color: var(--grey-light-color) !important;

  @media screen and (max-width: 450px) {
    width: 3.5rem;
  }
}
</style>

<script lang="ts">
import { Component, Model, Vue, Watch } from 'vue-property-decorator';
import { ChannelType } from '@/api/ms-channel/services/interfaces/Channel';
import StaticApiDataService from '@/services/StaticApiDataService';
import { GroupName } from '@/api/ms-static-api-data/services/interfaces/ContentCategoriesGroups';

@Component
export default class AContentCategoryDropdown extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: ChannelType;

  value = 'all';
  groups = ['all'];

  // this should be handled on the backend, would be easier to update...
  // the content of these categories is not good enough to release on live, they can be seen using lifferyDeveloperMode
  hiddenCategories = [
    GroupName.Baby,
    GroupName.Books,
    GroupName.Camera,
    GroupName.Christmas,
    GroupName.DiyAndTools,
    GroupName.HealthAndPersonalCare,
    GroupName.Hobbies,
    GroupName.Luggage,
    GroupName.Music,
    GroupName.Nature,
    GroupName.OfficeAndStationary,
    GroupName.PropertyAndRealEstate,
    GroupName.ScienceAndTechnology,
    GroupName.TravelAndLeisure,
    GroupName.Wedding
  ];

  async created () {
    this.value = this.activeValue ? this.activeValue : this.value;
    await this.loadCategories();
  }

  async loadCategories () {
    const cats = await StaticApiDataService.contentCategoriesGet();
    // remove the hidden categories unless we are in developer mode
    const catsFiltered = window.lifferyDeveloperMode ? cats : cats.filter((cat) => !this.hiddenCategories.includes(cat.groupName));
    this.groups = [
      ...this.groups,
      ...catsFiltered.map(group => group.groupName)
    ];
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
</script>
