import UserSettingsService from '@/api/ms-authentication/services/UserSettingsService';
import { ItemEntryStore, LifferyTourStore, SearchSettingsStore } from '@/store';
import { UserSettingsAppSettings } from '@/api/ms-authentication/services/interfaces';
import { ItemPost } from '@/api/ms-item/services/interfaces';

class UserAppSettingsHydrate {
  async fetchThenHydrate () {
    const settings = await UserSettingsService.userSettingsGet();
    this.hydrate(settings);
  }

  /**
   * App settings are persisted to the user settings via the vuex persist plugin.
   * It will not send to the API is the state is matches the last object sent.
   */
  hydrate (settings: UserSettingsAppSettings): void {
    if (!settings) {
      return;
    }
    if (settings.itemEntry) {
      ItemEntryStore.UPDATE_ITEM_ENTRY(settings.itemEntry as ItemPost);
    }
    if (settings.lastChannelAddedTo) {
      ItemEntryStore.UPDATE_LAST_CHANNEL_USED(settings.lastChannelAddedTo as any);
    }
    if (settings.searchSettings) {
      SearchSettingsStore.REHYDRATE(settings.searchSettings);
    }
    if (settings.toursSeen) {
      LifferyTourStore.REHYDRATE(settings.toursSeen);
    }
  }
}

export default new UserAppSettingsHydrate();
