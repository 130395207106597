<template>
  <div class="OItemPins">
    <div class="pins-wrapper">
      <div v-for="(pin, index) in pins" :key="index" class="user-pin">
        <b-tooltip v-if="pin.pin" :label="pin.actor.firstName + ' ' + pin.actor.lastName">
          <a-router-link-profile :username="pin.actor.username">
            <a-user-profile-pic :imageSize="userImageSize" :username="pin.actor.username"></a-user-profile-pic>
          </a-router-link-profile>
        </b-tooltip>
      </div>

      <div v-if="!pins || !pins.length">
        {{ $t('dict.noPinsYet') }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.OItemPins {
  padding: .5rem 0 .5rem;
}

.pins-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.user-pin {
  flex-shrink: 0;
}

</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ItemDetailStore } from '@/store';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';
import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkProfile from '@/components/atoms/link/ARouterLinkProfile.vue';

@Component({
  components: {
    ARouterLinkProfile,
    AUserProfilePic,
  }
})
export default class OItemPins extends Vue {
  userImageSize = Size.The60X60;
  loading: boolean = false;

  get pins () {
    const allPins = ItemDetailStore.getItemPins;

    return allPins.filter(pinObj => pinObj.pin);
  }
}
</script>
