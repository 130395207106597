<template>
  <b-button
      class="btn-create-channel is-outlined"
      type="is-primary"
      @click="$emit('click')"
  >
    <plus-icon size="1.5x"/>
    {{ $t('dict.newItem') }}
  </b-button>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { PlusIcon } from 'vue-feather-icons';

@Component({
  components: {
    PlusIcon
  }
})
export default class AAddItemButton extends Vue {
}
</script>
