<template>
  <div class="card">
    <p class="image is-64x64">
      <b-skeleton circle width="64px" height="64px"></b-skeleton>
    </p>
    <p>
      <b-skeleton width="180px"></b-skeleton>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.card {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
</style>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MPersonCardSkeleton extends Vue {
}
</script>