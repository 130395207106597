<template>
  <div class="language-switcher">
    <b-select v-if="isSelect" :value="currentLanguage" @input="changeLanguage">
      <option
          v-for="language in languages"
          :key="language"
          :value="language"
      >
        {{ $t('language.' + language) }}
      </option>
    </b-select>
    <b-dropdown v-else :mobile-modal="false" aria-role="list" position='is-top-right'>
      <span slot="trigger" class="trigger">
        {{ $t('language.' + currentLanguage) }}
        <chevron-down-icon class="chevron-icon" size="1x"></chevron-down-icon>
      </span>

      <b-dropdown-item
          v-for="language in languages"
          v-bind:key="language"
          aria-role="listitem"
          @click="changeLanguage(language)"
      >
        <span :class="'fi-' + localeToISOA2(language)" class="fi"></span>
        {{ $t('language.' + language) }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<style lang="scss" scoped>
.trigger {
  display: flex;
  align-items: center;
  cursor: pointer;
}

::v-deep .dropdown-menu::after {
  content: unset;
}

.dropdown-item {
  color: var(--text-color);
  text-align: left;
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { translation } from '@/plugins/i18n/Translation';
import { ChevronDownIcon } from 'vue-feather-icons';
import setLanguage from '@/utils/setLanguage';
import setHtmlLang from '@/utils/setHtmlLang';

@Component({
  components: { ChevronDownIcon }
})
export default class MLanguageSwitcher extends Vue {
  @Prop()
  isSelect!: boolean;

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get languages () {
    return translation.supportedLanguages;
  }

  async changeLanguage (language) {
    await setLanguage(language);
    setHtmlLang(language);
  }

  localeToISOA2 (locale: string): string {
    return locale.slice(-2).toLowerCase();
  }
}
</script>
