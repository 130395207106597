import Cookie from './Cookies';
import jwtDecode from '@/utils/jwtDecode';
import HttpService from '@/services/HttpService';
import { AuthenticationStore } from '@/store';
import { getDateInXMinutes, getDateInXWeeks } from 'common-utils/time';
import ApiHelper from '@/services/ApiHelper';
import { CookieNames } from '@/enums/CookieNames';

const RenewalTokenExpiresInXWeek = 26; // Match the expiry as defined by the API

class AuthenticationService {
  /**
   * Gets the access JWT token from cookie storage
   */
  async getAccessJWT (): Promise<string | undefined> {
    // try get the cookie the normal way
    let token = await Cookie.get(CookieNames.JWT_ACCESS);

    // check if the token is still valid and unset the token if it is invalid
    if (token && this.doesJWTRequireRenewal(token)) {
      token = undefined;
    }

    if (!token) {
      // Try fetching from the store
      const tokens = AuthenticationStore.getTokens;
      token = tokens.accessToken;
      if (token) {
        // set this local store cookie to disk
        await this.injectAccessJWT(token, true);
      }

      if (!token) {
        // try call the renew access token which will use a renewal if present.
        await ApiHelper.renewJWTAccess();
        token = await Cookie.get(CookieNames.JWT_ACCESS);
      }
    }
    return token;
  }

  async injectAccessJWT (token: string, cookieOnly?: boolean): Promise<void> {
    await Cookie.set(CookieNames.JWT_ACCESS, token, { expires: getDateInXMinutes(20), });
    HttpService.headers = { Authorization: 'Bearer ' + token };
    if (!cookieOnly) {
      AuthenticationStore.SET_ACCESS_TOKEN(token);
    }
  }

  doesJWTRequireRenewal (encodedToken?: string, minAgeTillExpirationInSeconds = 5): boolean {
    if (encodedToken) {
      const decodedToken: any = jwtDecode(encodedToken);
      if (decodedToken.exp) {
        const maxAge = (new Date()).getTime() + minAgeTillExpirationInSeconds * 1000;
        const tokenExpMS = (new Date(decodedToken.exp * 1000)).getTime();
        if (tokenExpMS <= maxAge) {
          return true;
        }
      }
    }
    return false;
  }

  async doesRenewalJWTRequireRenewal (): Promise<boolean> {
    return this.doesJWTRequireRenewal(await this.getRenewalJWT(), 10 * 60);
  }

  async doesAccessJWTRequireRenewal (): Promise<boolean> {
    return this.doesJWTRequireRenewal(await this.getAccessJWT());
  }

  async ejectAccessJWT (): Promise<void> {
    delete HttpService.headers.Authorization;
    await Cookie.remove(CookieNames.JWT_ACCESS);
  }

  async getRenewalJWT (): Promise<string | undefined> {
    let token = await Cookie.get(CookieNames.JWT_RENEWAL);
    if (!token) {
      // Try fetching from the store
      const tokens = AuthenticationStore.getTokens;
      token = tokens.renewalToken;
    }
    return token;
  }

  async injectRenewalJWT (token: string, cookieOnly?: boolean): Promise<void> {
    await Cookie.set(CookieNames.JWT_RENEWAL, token, { expires: getDateInXWeeks(RenewalTokenExpiresInXWeek), });
    if (!cookieOnly) {
      AuthenticationStore.SET_RENEWAL_TOKEN(token);
    }
  }

  async ejectRenewalJWT (): Promise<void> {
    await Cookie.remove(CookieNames.JWT_RENEWAL);
  }
}

export default new AuthenticationService();
