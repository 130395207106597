<template>
  <div class="MItemEntryCustomFields">
    <template v-if="!fetchingMetaData">
      <m-item-entry-custom-field
          v-for="(field, index) in customFieldsMap"
          v-model="internalValue[index]"
          :key="index"
          :fieldNumber="index + 1"
          :channel-custom-field-data="field"
          :all-channel-unit-data="allChannelFieldUnitData"
          :currencies-unique="currenciesUnique"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
.MItemEntryCustomFields {
  overflow: hidden;
}
</style>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { CustomField as ItemCustomField } from '@/api/ms-item/services/interfaces/ItemPost';
import { CustomField as ChannelCustomField } from '@/api/ms-channel/services/interfaces/Channel';
import MItemEntryCustomField from '@/components/molecules/itemEntry/MItemEntryCustomField.vue';
import { CustomFieldsUnitGroupeds } from '@/api/ms-static-api-data/services/interfaces';
import StaticApiDataService from '@/services/StaticApiDataService';
import { Datum as Currency } from '@/api/ms-static-api-data/services/interfaces/Currenciess';

@Component({
  components: { MItemEntryCustomField }
})
export default class MItemEntryCustomFields extends Vue {
  @Model('updateModelValue')
  readonly activeValue!: ItemCustomField[];

  @Prop({ required: true, default: () => [] })
  customFieldsMap!: ChannelCustomField[];

  value: ItemCustomField[] = [];

  internalValue: ItemCustomField[] = [];

  allChannelFieldUnitData: CustomFieldsUnitGroupeds = [];
  currenciesUnique: Currency[] = [];

  fetchingMetaData = true;

  async created () {
    this.value = this.activeValue;
    this.allChannelFieldUnitData = await StaticApiDataService.channelsCustomFieldsAllGet();
    this.currenciesUnique = await StaticApiDataService.currenciesUniqueGet();
    this.setInternalValue();
    this.fetchingMetaData = false;
  }

  setInternalValue () {
    // console.log(this.value);
    // console.log(this.customFieldsMap);
    this.customFieldsMap.forEach((channelMap) => {
      // find the related field
      let found: ItemCustomField | undefined = undefined;
      if (this.value) {
        found = this.value.find((itemValue) => itemValue._id === channelMap._id);
      }
      if (found) {
        this.internalValue.push(found);
      } else {
        this.internalValue.push({
          ...channelMap,
          value: ''
        });
      }
    });
  }

  @Watch('internalValue', { deep: true })
  internalValueHandle () {
    this.value = this.internalValue.filter((internal) => internal.value !== '');
  }

  @Watch('value')
  updateValue () {
    this.$emit('updateModelValue', this.value);
  }
}
</script>
