<template>
  <div class="tabs-container">
    <b-tabs
        v-model="innerSelectedTab"
        :animated="false"
        position="is-centered"
        size="is-large"
        v-on:input="handleTabChange"
    >
      <b-tab-item
          v-if="tabs.includes('items')"
          headerClass="items"
          value="items"
      >
        <template #header>
          <span>{{ $t('tabs.allItems') }}</span>
        </template>
      </b-tab-item>

      <b-tab-item
          v-if="tabs.includes('pinned')"
          headerClass="pinned"
          value="pinned"
      >
        <template #header>
          <span class="is-relative">
            {{ $t('tabs.pinned') }}
          </span>
        </template>
      </b-tab-item>

      <b-tab-item
          v-if="tabs.includes('channels')"
          headerClass="channels"
          value="channels"
      >
        <template #header>
          <span class="is-relative">
            {{ $t('tabs.channels') }}
            <sup v-if="getPendingChannel > 0 && !isViewingSomeoneElsesProfile" class="tab-indicator font-sub2">({{ getPendingChannel }})</sup>
          </span>
        </template>
      </b-tab-item>

      <b-tab-item
          v-if="tabs.includes('people')"
          headerClass="people"
          value="people"
      >
        <template #header>
          <span class="is-relative" :disabled="!isAuthenticated">
            {{ $t('tabs.people') }}
            <sup v-if="getPendingPeople > 0 && !isViewingSomeoneElsesProfile && !isViewingChannel" class="tab-indicator font-sub2">({{ getPendingPeople }})</sup>
          </span>
        </template>
      </b-tab-item>


      <b-tab-item
          v-if="tabs.includes('explore')"
          headerClass="explore"
          value="explore"
      >
        <template #header>
          <span class="is-relative">
            {{ $t('tabs.explore') }}
          </span>
        </template>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<style lang="scss" scoped>
.tabs-container ::v-deep .b-tabs {
  .tab-indicator {
    position: absolute;
    top: -3px;
    padding: 2px;
    color: var(--primary-color);
  }

  .tab-content {
    padding: 0;
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ASearchBarTabType } from '@/enums/SearchBarTabType';
import { AuthenticationStore, ChannelsStore, PeopleStore } from '@/store';
import { RouteNames } from '@/router/RouteNames';
import { EnumRegisteredFromAction } from '@/store/modules/interfaces/AuthenticationModule';

const defaultTabs = [
  ASearchBarTabType.items,
  ASearchBarTabType.pinned,
  ASearchBarTabType.channels,
  ASearchBarTabType.people,
  ASearchBarTabType.explore
];
@Component
export default class MSearchBarTabs extends Vue {
  @Prop({
    default: () => defaultTabs,
    validator: (value) => {
      if (!Array.isArray(value)) {
        return false;
      }
      let pass = true;
      value.forEach((item) => {
        if (!defaultTabs.includes(item)) {
          pass = false;
        }
      });
      return pass;
    }
  })
  tabs!: string[];

  @Prop({ default: ASearchBarTabType.items })
  selectedTab!: ASearchBarTabType;

  innerSelectedTab: ASearchBarTabType = ASearchBarTabType.items;

  get getPendingPeople (): number {
    return PeopleStore.getConnectionRequests.length;
  }

  get getPendingChannel (): number {
    return ChannelsStore.getChannelInvites.channels.length;
  }

  get isAuthenticated () {
    return AuthenticationStore.getAuthenticated;
  }

  get isViewingSomeoneElsesProfile () {
    const username = AuthenticationStore.user.username;
    return this.$route.name === RouteNames.ROUTE_PROFILE && this.$route.path !== `/profile/${username}/`;
  }

  get isViewingChannel () {
    return this.$route.name === RouteNames.ROUTE_CHANNEL_VIEW;
  }

  created () {
    this.innerSelectedTab = this.selectedTab;
  }

  handleTabChange (tabName: ASearchBarTabType) {
    if (!this.isAuthenticated && tabName !== 'items') {
      AuthenticationStore.TOGGLE_PROMPT_SIGNUP({
        state: true,
        metaData: {
          registeredFromAction: EnumRegisteredFromAction.ctaSearchBarTabs
        }
      });
      this.selectedTab = ASearchBarTabType.items;
    } else {
      this.$emit('child-output', tabName);
    }
  }

  @Watch('selectedTab')
  selectedTabKeyHandle (newTabKey: ASearchBarTabType) {
    if (newTabKey) {
      this.innerSelectedTab = newTabKey;
    }
  }
}
</script>
