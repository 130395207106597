<template>

  <div class="wrapper">

    <ul class="pt-6">
      <li>
        <a role="button"
           class="navbar-burger"
           :class="{'is-active': burgerHover}"
           aria-label="menu"
           aria-expanded="false"
           data-target="mainNavbar"
           @mouseover="burgerHover = true"
           @mouseleave="burgerHover = false"
           @click="$emit('close')"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </li>

      <li class="pt-5 pb-5" v-if="isAuthenticated">
        <router-link :to="{name: routeNames.ROUTE_DASHBOARD}">
          Dashboard
        </router-link>
      </li>

      <li class="pt-5 pb-5" v-if="!isAuthenticated || isBusinessLandingPage">
        <m-login-button-trigger
            v-if="!isBusinessLandingPage"
            classes="is-outlined"
        />
        <a v-else :href="accountHref">
          <b-button class="is-outlined" type="is-primary" @click="accountsClick">
            Business Login
          </b-button>
        </a>
      </li>

      <li v-for="(link, index) in footerLinks" :key="index" class="pt-5 pb-5">
        <a-link v-if="link.link"
                :link="link.link"
                :key="index"
        > {{ $t(link.textKey) }}
        </a-link>
        <router-link
            v-else
            :to="{ name: link.name }"
            @click.native="$emit('close')"
        >
          <span
              :class="{'link-active font-bold': link.activeAlsoWith.concat([link.name]).includes($route.name)}">
            {{ $t(link.textKey) }}
          </span>
        </router-link>

      </li>

      <li>
        <b-dropdown
            append-to-body
            aria-role="menu"
            scrollable
            trap-focus
            :mobile-modal="false"
            position="is-top-left"
        >
          <template #trigger>
            <a
                class="navbar-item"
                role="button">
              <div class="is-flex is-align-items-center">
               <span>
                  <!-- <span class="lng-text">{{ $t('language.' + currentLanguage) }}</span>-->
                  <span :class="'fi-' + currentLanguageISOAlpha2" class="fi"></span>
                </span>
                <chevron-down-icon size="1.25x"/>
              </div>
            </a>
          </template>

          <b-dropdown-item
              v-for="(language, index) in languages"
              v-bind:key="index"
              aria-role="menuitem"
              :separator="language === 'DIVIDE'"
              @click="() => language !== 'DIVIDE' && changeLanguage(language)"
          >
            <template v-if="language !== 'DIVIDE'">
              <span :class="'fi-' + localeToISOA2(language)" class="fi"></span>
              {{ $t('language.' + language) }}
            </template>
          </b-dropdown-item>
        </b-dropdown>
      </li>
    </ul>

  </div>

</template>

<style scoped lang="scss">
.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 260px;
  height: 100vh;
  overflow-y: auto;
  background-color: white;
  border-left: solid rgba(81, 156, 123, .3) 1px;

  ul {
    width: 100%;

    li {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 2rem;
      text-align: right;
    }

    li:not(:last-child) {
      border-bottom: solid rgba(81, 156, 123, .3) 1px;
    }
  }
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ChevronDownIcon } from 'vue-feather-icons';
import { RouteNames } from '@/router/RouteNames';
import ALink from '@/components/atoms/link/ALink.vue';
import MHeaderLoginTrigger from '@/components/molecules/MHeaderLoginTrigger.vue';
import config from '@/config';
import { AuthenticationStore } from '@/store';
import MLoginButtonTrigger from '@/components/molecules/buttons/MLoginButtonTrigger.vue';
import EventBus, { EventBusEvents } from '@/EventBus';
import { translation } from '@/plugins/i18n/Translation';
import { intersperse } from 'common-utils/array';

@Component({
  components: {
    MLoginButtonTrigger,
    MHeaderLoginTrigger,
    ALink,
    ChevronDownIcon
  }
})
export default class MHeaderSeoSidebarContent extends Vue {
  burgerHover = false;

  accountHref = config.accountsHref;

  routeNames = RouteNames;

  footerLinks: {
    name?: RouteNames,
    link?: string,
    textKey: string,
    activeAlsoWith: RouteNames[]
  }[] = [{
    name: this.isAuthenticated ? RouteNames.ROUTE_INFO_WHAT_IS_AUTHED : RouteNames.ROUTE_INFO_WHAT_IS,
    activeAlsoWith: [RouteNames.ROUTE_DASHBOARD],
    textKey: 'footer.forPeople'
  }, {
    name: RouteNames.ROUTE_INFO_BUSINESS,
    activeAlsoWith: [],
    textKey: 'footer.forBusiness'
  }, {
    name: RouteNames.ROUTE_INFO_CONTACT,
    activeAlsoWith: [],
    textKey: 'footer.contact'
  }, {
    link: 'https://blog.liffery.com',
    activeAlsoWith: [],
    textKey: 'footer.blog'
  }, {
    name: RouteNames.ROUTE_INFO_CAREERS,
    activeAlsoWith: [],
    textKey: 'footer.careers'
  }, {
    link: 'https://help.liffery.com',
    activeAlsoWith: [],
    textKey: 'footer.help'
  }];

  localeToISOA2 = translation.localeToISOA2;

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get currentLanguageISOAlpha2 () {
    return translation.currentLanguageISOAlpha2;
  }

  get languages () {
    return intersperse(translation.supportedLanguages, 'DIVIDE');
  }

  get isAuthenticated () {
    return AuthenticationStore.getAuthenticated;
  }

  get isBusinessLandingPage () {
    return this.$route.name === RouteNames.ROUTE_INFO_BUSINESS;
  }

  created () {
    this.eventsBind();
  }

  beforeDestroy () {
    this.eventsUnbind();
  }

  eventsBind () {
    EventBus.$on(EventBusEvents.AUTH_LOGIN_CLICKED, 'MHeaderSeoSidebarContent', () => {
      this.close();
    });
  }

  eventsUnbind () {
    EventBus.$remove(EventBusEvents.AUTH_LOGIN_CLICKED, 'MHeaderSeoSidebarContent');
  }

  close () {
    this.$emit('close');
    this.burgerHover = true;
  }

  accountsClick () {
    window.location.href = this.accountHref;
  }
}
</script>
