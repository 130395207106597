<template>
  <svg class="AChannelSwipeItemUp" viewBox="0 0 37 37" :style="style" fill="none" xmlns="http://www.w3.org/2000/svg" @mouseover="mouseover = true" @mouseout="mouseover = false">
<!-- Star svg (old):    <circle cx="18.4674" cy="18.3319" r="17.1199" :fill="fillColour" :stroke="circleStrokeColour" stroke-width="1.5"/>-->
<!--    <path d="M18.4673 5.96362L21.2442 14.5099H30.2302L22.9604 19.7917L25.7372 28.3379L18.4673 23.0561L11.1975 28.3379L13.9743 19.7917L6.70446 14.5099H15.6905L18.4673 5.96362Z" :fill="fillColour" :stroke="strokeColour" stroke-width="2" stroke-linejoin="round"/>-->

    <circle cx="18.4674" cy="18.3319" r="17.1199" :fill="fillColour" :stroke="circleStrokeColour" stroke-width="1.5"/>

    <path d="M22.2738 15.4846C22.0204 15.8254 22.0087 16.2859 22.2441 16.6387C23.1531 18.0003 23.3297 19.6036 23.2487 20.9379C23.2266 21.3025 23.1856 21.6395 23.1379 21.936L12.6077 15.923C12.8296 15.7369 13.0901 15.5364 13.3855 15.3382C14.4656 14.6138 15.9572 13.9561 17.7305 14.0614C18.1574 14.0867 18.5547 13.8463 18.7263 13.4591L20.316 9.87048C20.4871 9.48413 20.3973 9.03324 20.0906 8.74004L19.7591 8.42305C19.5285 8.20245 19.4792 7.8549 19.6396 7.58007C19.8328 7.24929 20.2604 7.13595 20.5949 7.32694L26.7089 10.8181C27.0434 11.0091 27.1578 11.4321 26.9649 11.7629C26.8044 12.0377 26.4755 12.1693 26.167 12.082L25.7236 11.9566C25.3136 11.8406 24.8738 11.989 24.6211 12.3288L22.2738 15.4846Z" :stroke="strokeColour" :fill="fillColour" stroke-width="2" stroke-linejoin="round"/>

    <path d="M10.6018 31.4295L15.3709 20.6638L17.644 21.9617L10.6018 31.4295Z" :fill="strokeColour" :stroke="strokeColour" stroke-width="0.5"/>
  </svg>
</template>

<style scoped lang="scss">
.AChannelSwipeItemUp {
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AChannelSwipeItemUp extends Vue {
  @Prop({default: '1x'})
  size!: string;
  @Prop({required: false, default: false})
  fill!: boolean;
  @Prop({ required: false, default: false })
  disabled!: boolean;

  mouseover = false;

  colours: {
    disabled: string,
    swipeUp: string,
    white: string
  } = {
    disabled: 'var(--grey-mid-color)',
    swipeUp: 'rgb(var(--channel-swipe-up))',
    white: 'var(--white-color)'
  };

  get style () {
    const size = parseFloat(this.size);
    return `width: ${size}rem; height: ${size}rem;`;
  }

  get fillColour () {
    if (this.disabled) {
      return this.colours.white;
    }

    return (this.mouseover || this.fill) ? this.colours.swipeUp : this.colours.white;
  }

  get strokeColour () {
    if (this.disabled) {
      return this.colours.disabled;
    }

    return (!this.mouseover && !this.fill) ? this.colours.swipeUp : this.colours.white;
  }

  get circleStrokeColour () {
    return this.disabled ? this.colours.disabled : this.colours.swipeUp;
  }
}
</script>