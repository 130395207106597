<template>
  <div id="top">
    <!--    <m-coming-soon-banner v-if="!isApp"/>-->
    <router-view/>
    <m-footer-seo/>
  </div>
</template>

<style>
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import OModalsContainer from '@/components/organisms/OModalsContainer.vue';
import MHeaderSeo from '@/components/molecules/landing/MHeaderSeo.vue';
import MFooterSeo from '@/components/molecules/landing/MFooterSeo.vue';
import MComingSoonBanner from '@/components/molecules/landing/MComingSoonBanner.vue';
import isApp from '@/utils/isApp';
import set1stEverPageLoaded from '@/utils/set1stEverPageLoaded';

@Component({
  components: {
    MComingSoonBanner,
    MFooterSeo,
    MHeaderSeo,
    OModalsContainer
  },
})
export default class TMainLayoutSEO extends Vue {
  get isApp (): boolean {
    return isApp();
  }

  created () {
    set1stEverPageLoaded().catch(console.error);
  }
}
</script>
