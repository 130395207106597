<template>
  <div class="OWelcomeGuide">
    <b-steps
        v-model="activeStep"
        :animated="true"
        :has-navigation="false"
        :mobile-mode="'compact'"
        :rounded="true">
      <b-step-item :clickable="isStepsClickable" label="Items" step="1">
        <div class="columns">
          <div class="column is-one-third">
            <img
                :alt="$t('whatIsLiffery.addItemsTitle')"
                class="section-image"
                src="@/assets/svg/what-is-add-items.svg"
            />
          </div>
          <div class="column is-flex is-align-items-center welcome-text">
            {{ $t('welcome.items') }}
          </div>
        </div>

        <p class="has-text-centered">
          <b-button class="is-primary is-outlined" @click="() => ++activeStep">{{ $t('dict.next') }}</b-button>
        </p>
      </b-step-item>

      <b-step-item :clickable="isStepsClickable" label="Channels" step="2">
        <div class="columns">
          <div class="column is-flex is-align-items-center welcome-text">
            {{ $t('welcome.channels') }}
          </div>
          <div class="column is-one-third">
            <img
                :alt="$t('whatIsLiffery.organiseInChannelsTitle')"
                class="section-image"
                src="@/assets/svg/what-is-organise-in-channels.svg"
            />
          </div>
        </div>
        <p class="has-text-centered">
          <b-button class="is-primary is-outlined" @click="() => --activeStep">{{ $t('dict.back') }}</b-button>
          <b-button class="is-primary is-outlined ml-4" @click="() => ++activeStep">{{ $t('dict.next') }}</b-button>
        </p>
      </b-step-item>

      <b-step-item :clickable="isStepsClickable" label="People" step="3">
        <div class="columns">
          <div class="column is-one-third">
            <img
                :alt="$t('whatIsLiffery.connectWithPeopleTitle')"
                class="section-image"
                src="@/assets/svg/what-is-connect-with-people.svg"
            />
          </div>
          <div class="column is-flex is-align-items-center welcome-text">
            {{ $t('welcome.people') }}
          </div>
        </div>

        <p class="has-text-centered">
          <b-button class="is-primary is-outlined" @click="() => --activeStep">{{ $t('dict.back') }}</b-button>
          <b-button class="is-primary is-outlined ml-4" @click="closeModal">{{ $t('dict.go') }}</b-button>
        </p>
      </b-step-item>
    </b-steps>
  </div>
</template>

<style lang="scss" scoped>
.section-image {
  padding-top: 15%;

  @media screen and (max-width: 770px) {
    display: block;
    max-height: 12rem;
    margin: 0 auto;
    padding-top: 1rem;
  }
}

.welcome-text {
  line-height: 150%;
}
</style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';

/**
 * This component is no longer used in preference of vue-tour, but kept in case decide to use again
 */
@Component({
  components: { MLanguageSwitcher }
})
export default class OWelcomeGuide extends Vue {
  isStepsClickable = false;
  activeStep = 0;

  async closeModal () {
    await AuthenticationStore.updateUserWelcomed(true);
    EventBus.$emit(EventBusEvents.ITEM_ADD_PULSE);
    this.$emit('welcomed');
  }
}
</script>
