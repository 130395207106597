<template>
  <div>
    <b-loading :active="loading"/>
    <b-tooltip :label="tooltipText" :position="tooltipPosition">
      <user-plus-icon
          v-if="connectionType === 'sendConnectionRequest'"
          :size="size"
          @click="$emit('send-connection-request')"
      />
      <user-x-icon
          v-else-if="connectionType === 'cancelConnectionRequest'"
          :size="size"
          @click="$emit('cancel-connection-request')"
      />
      <user-check-icon
          v-else-if="connectionType === 'acceptConnectionRequest'"
          :size="size"
          @click="$emit('accept-connection-request')"
      />
      <user-minus-icon
          v-else-if="connectionType === 'removeConnection'"
          :size="size"
          @click="$emit('remove-connection-request')"
      />
      <slot name="helper-text"></slot>
    </b-tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserCheckIcon, UserMinusIcon, UserPlusIcon, UserXIcon } from 'vue-feather-icons';

@Component({
  components: {
    UserPlusIcon,
    UserXIcon,
    UserMinusIcon,
    UserCheckIcon
  }
})
export default class AConnectionIcon extends Vue {
  @Prop({ required: true })
  size!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ required: true })
  connectionType!: string;

  @Prop({ required: true })
  tooltipText!: string;

  @Prop({ default: 'is-top' })
  tooltipPosition!: string;
}
</script>
