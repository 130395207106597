<template>
  <div class="MChannelSelectorSkeleton">
    <div class="image is-64x64">
      <b-skeleton circle width="64px" height="64px"></b-skeleton>
    </div>
    <div class="channel-text">
      <b-skeleton width="45%"></b-skeleton>
      <b-skeleton width="85%"></b-skeleton>
    </div>
    <div class="channel-numbers">
      <div>
        <b-skeleton width="100%"></b-skeleton>
        <b-skeleton width="85%"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.MChannelSelectorSkeleton {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding: 15px 2px;
  border-bottom: 1px solid #bfbfbf;
}

.channel-text {
  width: 70%;
}

.channel-numbers {
  width: 20%;
}
</style>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MChannelSelectorSkeleton extends Vue {
}
</script>