<template>
  <p>
    {{ formattedDate }}
  </p>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomField, FieldType } from '@/api/ms-item/services/interfaces/Item';
import formatDateWithoutTime from '@/utils/formatDateWithoutTime';

@Component
export default class ACustomFieldDisplayDate extends Vue {
  @Prop({ required: true })
  field!: CustomField;

  date!: Date;
  formattedDate: string = '';

  created () {
    if( this.field.fieldType !== FieldType.Date ){
      console.error('Wrong field type. Expects date.');
    } else {
      this.formatDate();
    }
  }

  formatDate () {
    this.date = new Date(this.field.value);
    switch( this.field.unit ){
      case 'year': this.formatDateYearOnly(); break;

      default: this.formatDateFull(); break;
    }
  }

  formatDateFull () {
    this.formattedDate = formatDateWithoutTime(this.date);
  }

  formatDateYearOnly () {
    this.formattedDate = this.date.getFullYear() as unknown as string;
  }
}
</script>