<template>
  <span :class="{dotted}">
    <slot v-if="!dotted && !discreet"></slot>
    <b-tooltip :label="tip"
               :multilined="multilined"
               :size="size"
               :position="position">
      <slot v-if="dotted || discreet"></slot>
      <sup v-if="!discreet" class="font-bold"> (?) </sup>
    </b-tooltip>
  </span>
</template>

<style lang="scss" scoped>
.dotted {
  cursor: help;
  text-decoration: underline;
  text-decoration-style: dotted;
}

sup {
  cursor: help;
}

sup,
.dotted {
  &:hover {
    color: var(--primary-color);
  }
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ATooltip extends Vue {
  @Prop({
    required: true,
    type: String
  })
  tip!: string;

  @Prop({ default: 'is-bottom' })
  position!: string;

  @Prop({ default: false })
  dotted!: boolean;

  // if true will hide the question mark - i.e. if you want to use this instead of the title attribute
  @Prop({default: false})
  discreet!: boolean;

  @Prop()
  size?: string

  @Prop({default: true})
  multilined!: boolean;
}
</script>
