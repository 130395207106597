import { render, staticRenderFns } from "./MChatToggleHandle.vue?vue&type=template&id=17c27688&scoped=true"
import script from "./MChatToggleHandle.vue?vue&type=script&lang=ts"
export * from "./MChatToggleHandle.vue?vue&type=script&lang=ts"
import style0 from "./MChatToggleHandle.vue?vue&type=style&index=0&id=17c27688&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17c27688",
  null
  
)

export default component.exports