<template>
  <svg
      data-v-327ea383="" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      :width="width"
      :height="height"
      :viewBox="viewBox"
      xml:space="preserve"
      :class="{'pinned-item': pinned}"
  >
    <g transform="matrix(1 0 0 1 4.98 7.14)">
      <g vector-effect="non-scaling-stroke">
        <g transform="matrix(1 0 0 1 0.52 -2.56)">
          <path stroke="currentColor"
                vector-effect="non-scaling-stroke"
                transform=" translate(-11.14, -10.33)"
                d="M 12.4579 10.8226 C 12.3213 11.0083 12.315 11.2593 12.4419 11.4516 C 12.9319 12.1937 13.0271 13.0675 12.9834 13.7947 C 12.9715 13.9934 12.9494 14.1771 12.9237 14.3387 L 7.24752 11.0615 C 7.36715 10.9601 7.50752 10.8508 7.66678 10.7428 C 8.249 10.348 9.053 9.98955 10.0089 10.0469 C 10.239 10.0607 10.4532 9.92969 10.5457 9.71863 L 11.4026 7.7628 C 11.4948 7.55223 11.4464 7.30649 11.2811 7.14669 L 11.1024 6.97393 C 10.9781 6.8537 10.9515 6.66428 11.038 6.51449 C 11.1421 6.33421 11.3726 6.27244 11.5529 6.37653 L 14.8486 8.27929 C 15.0289 8.38337 15.0906 8.6139 14.9866 8.79418 C 14.9001 8.94396 14.7228 9.01567 14.5565 8.9681 L 14.3175 8.89975 C 14.0965 8.83652 13.8594 8.91744 13.7232 9.10262 L 12.4579 10.8226 Z"
                stroke-linecap="round"
                style="stroke-width: 1.1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: round; stroke-miterlimit: 4; fill: none; fill-rule: nonzero; opacity: 1;">
          </path>
        </g>
        <g transform="matrix(1 0 0 1 -2.56 3.69)">
          <path fill="currentColor"
                vector-effect="non-scaling-stroke"
                transform=" translate(-8.06, -16.58)"
                d="M 6.16626 19.5128 L 8.73701 13.6453 L 9.9623 14.3527 L 6.16626 19.5128 Z"
                stroke-linecap="round"
                style="stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1;">
          </path>
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped lang="scss">
.pinned-item {
  color: var(--secondary-color);
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class APinSvgIcon extends Vue {
  @Prop()
  pinned!: boolean;

  @Prop({ default: 1 })
  size!: number;

  width = 0;
  height = 0;

  viewBox = '';
  viewMinX = 0;
  viewMinY = -2;
  viewWidth = 11;
  viewHeight = 15;

  created () {
    this.width = 14 * this.size;
    this.height = 19 * this.size;
    const minYK = Math.ceil((1 - this.size) * 10);
    if (this.size > 1) {
      this.viewMinY -= minYK;
    } else if (this.size < 1) {
      this.viewMinY += minYK;
    }
    this.viewBox = `${this.viewMinX} ${this.viewMinY} ${this.viewWidth} ${this.viewHeight}`;
  }
}
</script>
