<template>
  <div class="MUpdateAppMajor">
    <div class="versions">
      <p>{{ $t('newReleaseAvailable.app.major.latest') }}: {{ appVersions.latestVersion }}</p>
      <p>{{ $t('newReleaseAvailable.app.major.local') }}: {{ appVersions.localVersion }}</p>
    </div>
    <div class="releaseMessage mt-3 mb-5">
      {{ $t('newReleaseAvailable.app.major.message') }}
    </div>
    <div class="has-text-centered">
      <a-link :link="updateLink">
        <b-button
            class="button is-primary is-centered"
        >
          {{ $t('newReleaseAvailable.app.major.button') }}
        </b-button>
      </a-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.MUpdateAppMajor {
}
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ALink from '@/components/atoms/link/ALink.vue';
import { OModalsContainerAppUpdateAvailable } from '@/components/organisms/OModalsContainer.vue';
import { AppType } from '@/api/ms-version-control/services/interfaces/LatestVersionGetQuery';
import config from '@/config';

@Component({
  components: {
    ALink
  }
})
export default class MUpdateAppMajor extends Vue {
  @Prop()
  appVersions!: OModalsContainerAppUpdateAvailable;

  appType = AppType;
  appLinks = config.appLinks;

  get updateLink () {
    return this.appVersions.appType === this.appType.Android ? this.appLinks.android : this.appLinks.apple;
  }
}
</script>